import React, { useEffect, useRef, useState } from "react";
import Loading from "../../common/Loading";
import { Box, Grid } from "@mui/material";
import Textfield from "../../common/Components/TextField";
import CustomCommonButton from "../../common/Components/CustomCommonButton";
import {
  CommonAddIcon,
  CommonSaveIcon,
  CommonUpdateIcon,
  commonCancelIcon,
} from "../../common/Components/IconsAndComponents";
import SnackBar from "../../common/SnackBar";
import GridTable from "../../common/GridTable";
import { AppData } from "../../common/AppData";
import api from "../../api/http-common";
import { MoreVert } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Logger from "../../common/Logger";

const ExpenditureType = ({ expenditureTypeRef }) => {
  const { t } = useTranslation("global");
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const entity_id = parseInt(sessionStorage.getItem("entityId"));
  const logger = new Logger({ level: "log" });
  const [loading, setLoading] = useState(false);
  const [addExpenditureTypeField, setAddExpenditureTypeField] = useState(false);
  const [addbuttonDisabled, setAddbuttonDisabled] = useState(false);
  const [expenditureType, setExpenditureType] = useState("");
  const [updateData, setUpdateData] = useState(null);
  const [expenditureTypes, setExpenditureTypes] = useState([]);

  const addExpenditureType = async () => {
    if (expenditureType) {
      try {
        handleLoadingOpen();
        setAddbuttonDisabled(true);
        const data = {
          expenditureType,
          entity: entity_id,
        };
        await api
          .post(`/ExpenditureType`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        SnackBar.toastMsg(
          "success",
          t("toast_messages.expenditure_type_added")
        );
        getExpenditure();
        setExpenditureType("");
        handleFieldsClose();
      } catch (error) {
        logger.log(error);
      } finally {
        setAddbuttonDisabled(false);
      }
    } else {
      SnackBar.toastMsg("error", t("toast_messages.please_enter_ex_type"));
    }
  };

  const getExpenditure = async () => {
    try {
      handleLoadingOpen();
      const data = await api
        .get(`/ExpenditureType/ByEntity/${entity_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      setExpenditureTypes(data);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };

  useEffect(() => {
    getExpenditure();
  }, []);

  const columns = AppData.expenditureType.map((item) => ({
    ...item,
    headerName: t(`expenditure.${item.field}`),
  }));
  const initiateUpdate = (item) => {
    setUpdateData(item);
    setAddExpenditureTypeField(true);
    setExpenditureType(item.expenditureType);
  };

  const updateExpenditure = async () => {
    if (expenditureType) {
      try {
        handleLoadingOpen();
        setAddbuttonDisabled(true);
        const data = {
          expenditureType,
          entity: entity_id,
        };
        const Res = await api
          .put(`/ExpenditureType/${updateData.expenditureType_id}`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        SnackBar.toastMsg("success", "Expenditure Type Updated Successfully");
        getExpenditure();
        setExpenditureType("");
        handleFieldsClose();
        setUpdateData(null);
      } catch (error) {
        logger.log(error);
      } finally {
        setAddbuttonDisabled(false);
      }
    } else {
      SnackBar.toastMsg("error", "Please Enter The Expenditure Type");
    }
  };

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };

  const handleFieldsClose = () => {
    setUpdateData(null);
    setExpenditureType("");
    setAddExpenditureTypeField(false);
  };
  const rows = expenditureTypes.map((item, index) => ({
    ...item,
    id: index + 1,
    moreinfo: (
      <MoreVert
        sx={{ cursor: "pointer" }}
        onClick={() => initiateUpdate(item)}
      />
    ),
  }));
  return (
    <>
      {(role_id === 1 || role_id === 2) && (
        <div className="add-item-comp" ref={expenditureTypeRef}>
          <Loading open={loading} handleClose={handleLoadingClose} />
          <h1>{t("expenditure.add_expenditure")}</h1>
          <div className="add-fields">
            {addExpenditureTypeField ? (
              <Grid
                container
                spacing={{ xs: 2, md: 3, sm: 4 }}
                columns={{ xs: 1, sm: 3, md: 4 }}
                sx={{
                  elevation: 3,
                  mb: 2,
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Grid item xs={1} sm={2} md={1}>
                  <Textfield
                    label={t("expenditure.add_expenditure")}
                    value={expenditureType}
                    onChange={(e) => setExpenditureType(e.target.value)}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={2}
                  md={3}
                  lg={3}
                  sx={{ display: "flex", justifyContent: "end", width: "100%" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: { lg: "30%", md: "50%", sm: "70%", xs: "100%" },
                    }}
                  >
                    {updateData ? (
                      <CustomCommonButton
                        text={t("settings.update")}
                        icon={CommonUpdateIcon}
                        onClick={updateExpenditure}
                        disabled={addbuttonDisabled}
                      />
                    ) : (
                      <CustomCommonButton
                        text={t("settings.save_item")}
                        icon={CommonSaveIcon}
                        onClick={addExpenditureType}
                        disabled={addbuttonDisabled}
                      />
                    )}
                    <CustomCommonButton
                      text={t("settings.cancel")}
                      color={"error"}
                      icon={commonCancelIcon}
                      onClick={handleFieldsClose}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <CustomCommonButton
                  text={t("expenditure.add_expenditure")}
                  onClick={() => setAddExpenditureTypeField(true)}
                  icon={CommonAddIcon}
                />
              </Box>
            )}
          </div>
          {/* <div className="table-component"> */}
          <GridTable
            rows={rows}
            columns={columns}
            height={"60vh"}
            isLoading={loading}
          />
          {/* </div> */}
        </div>
      )}
    </>
  );
};

export default ExpenditureType;
