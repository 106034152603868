import {
  Approval,
  CreditCardOff,
  CreditScore,
  Pending,
  TaskAlt,
  ThumbDownAlt,
  ThumbUp,
} from "@mui/icons-material";
import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import api from "../../../api/http-common";
import AuctionTable from "../AuctionTable";
import "../ManagerDashboard.css";
import Logger from "../../../common/Logger";

const ContainerData = [
  {
    id: 1,
    title: "Total Loans",
    name: "total_loan",
    color: "#ca641a",
    symbol: "+",
    icon: <CreditScore className="icon" style={{ color: `#ca641a` }} />,
    percentage: 1.29,
  },
  {
    id: 2,
    title: "Active",
    name: "total_active",
    color: "#28667c",
    symbol: "+",
    icon: <ThumbUp className="icon" style={{ color: `#28667c` }} />,
    percentage: 1.19,
  },
  {
    id: 3,
    title: "Rejected",
    name: "total_rejected",
    color: "#9C0808",
    symbol: "-",
    icon: <ThumbDownAlt className="icon" style={{ color: `#9C0808` }} />,
    percentage: 1.29,
  },
  {
    id: 4,
    title: "Closed",
    name: "total_closed",
    color: "#729d71",
    symbol: "+",
    icon: <TaskAlt className="icon" style={{ color: `#729d71` }} />,
    percentage: 1.45,
  },
  {
    id: 5,
    title: "EMI Defaulters",
    name: "total_defaulter",
    color: "#ad5373",
    symbol: "-",
    icon: <CreditCardOff className="icon" style={{ color: `#ad5373` }} />,
    percentage: 1.29,
  },
];
const TodayArray = [
  {
    id: 1,
    title: "loans_created_today",
    name: "total_loan",
    color: "#ca641a",
    symbol: "+",
    icon: <CreditScore className="icon" style={{ color: `#ca641a` }} />,
    percentage: 1.29,
  },
  {
    id: 2,
    title: "loans_approved_today",
    name: "total_active",
    color: "#28667c",
    symbol: "+",
    icon: <Approval className="icon" style={{ color: `#28667c` }} />,
    percentage: 1.19,
  },
  {
    id: 3,
    title: "total_pending_today",
    name: "total_pending",
    color: "#9C0808",
    symbol: "-",
    icon: <Pending className="icon" style={{ color: `#9C0808` }} />,
    percentage: 1.29,
  },
];

const ManagerContainer = ({ handleLoadingON, handleLoadingOff }) => {
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const { t } = useTranslation("global");
  const logger = new Logger({ level: "log" });
  const [totalCardData, setTotalCardData] = useState([]);
  const [auctionLoans, setAuctionLoans] = useState([]);
  const [todayCardData, setTodayCardData] = useState({});
  const [fund, setFund] = useState([]);

  const handleLoadingOpen = () => {
    handleLoadingON(true);
    return true;
  };

  const handleLoadingClose = () => {
    handleLoadingOff(false);
    return true;
  };

  useEffect(() => {
    const getTotalCardsData = async () => {
      try {
        handleLoadingOpen();
        const data = await api
          .get(`/dashboard/CardsByBranch/${branch_id}/0000-00-00/0000-00-00`)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        setTotalCardData(data);
      } catch (error) {
        logger.log(error);
      } finally {
        handleLoadingClose();
      }
    };
    getTotalCardsData();
  }, []);

  const getAuctionData = useCallback(async () => {
    try {
      const LoanAPI = `/loan/AuctionEligibleListByBranch/${branch_id}`;

      const loans = await api
        .get(LoanAPI)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      setAuctionLoans(loans);
    } catch (error) {
      logger.log(error);
    }
  }, [branch_id]);

  const containers = ContainerData.map((data) => ({
    ...data,
    count: `${totalCardData[data.name]}`,
  }));

  const fundData = useCallback(async () => {
    try {
      const today = new Date().toISOString().split("T")[0];
      const fundAPI = `/dashboard/fundTransferSumByBranch/${branch_id}`;
      const response = await api
        .get(fundAPI)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      setFund(response || []);
      const today_res = await api
        .get(`/dashboard/CardsByBranch/${branch_id}/${today}/${today}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      setTodayCardData(today_res);
    } catch (err) {
      logger.log(err)
    }
  }, [branch_id]);

  useEffect(() => {
    getAuctionData();
    fundData();
  }, [fundData, getAuctionData]);

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        sx={{ mb: 1 }}
        columns={{ xs: 2, sm: 4, md: 4, lg: 8 }}
      >
        {containers.map((item) => {
          return (
            item.name !== "total_defaulter" && (
              <Grid item xs={1} sm={1} md={1} lg={1} key={item.id}>
                <Paper
                  elevation={8}
                  className={"amount_wrapper"}
                  // onClick={() => handleContainerClick(index)}
                >
                  <Box className="title">
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                      }}
                      variant="h6"
                      component="p"
                    >
                      {t(`dashboard.${item.title}`)}
                    </Typography>
                  </Box>
                  <Box className="man_detail">
                    {item.icon}
                    <span className="man_count">
                      <CountUp
                        start={0}
                        end={item.count}
                        duration={2.5}
                        useIndianSeparators={true}
                        style={{ fontSize: 55 }}
                      />{" "}
                    </span>
                  </Box>
                </Paper>
              </Grid>
            )
          );
        })}
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <Paper
            elevation={8}
            className={"amount_wrapper"}
            // onClick={() => handleContainerClick(index)}
          >
            <Box className="title">
              <Typography
                sx={{
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                }}
                variant="h6"
                component="p"
              >
                {t(`dashboard.EMI Defaulters`)}
              </Typography>
            </Box>
            <Box className="man_detail">
              <CreditCardOff className="icon" style={{ color: `#ad5373` }} />
              <span className="man_count">
                <CountUp
                  start={0}
                  end={auctionLoans?.length}
                  duration={2.5}
                  useIndianSeparators={true}
                  // style={{ textShadow: "2px 0px 4px rgba(0, 0, 0, 0.5)" }}
                  style={{ fontSize: 55 }}
                />{" "}
              </span>
            </Box>
          </Paper>
        </Grid>
        {TodayArray.map((item) => {
          return (
            <Grid item xs={1} sm={1} md={1} lg={1} key={item.id}>
              <Paper
                elevation={8}
                className={"amount_wrapper"}

                // onClick={() => handleContainerClick(index)}
              >
                <Box className="title">
                  <Typography
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--font-size)",
                    }}
                    variant="h6"
                    component="p"
                  >
                    {t(`dashboard.${item.title}`)}
                    {/* <span style={{ fontSize: "10px" }}>
                      {t("common.today")}
                    </span> */}
                  </Typography>
                </Box>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box className="man_detail" width={"100%"}>
                    {item.icon}
                    <span className="man_count">
                      <CountUp
                        start={0}
                        end={todayCardData[item.name]}
                        duration={2.5}
                        useIndianSeparators={true}
                        // style={{ textShadow: "2px 0px 4px rgba(0, 0, 0, 0.5)" }}
                        style={{ fontSize: 55 }}
                      />
                    </span>
                  </Box>
                  <div
                    className="silver-text"
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "end",
                      color: "#848482",
                    }}
                  >
                    <Typography
                      component={"p"}
                      variant="p"
                      sx={{ fontSize: 15 }}
                      className="vertical-text"
                    >
                      <b>{t("common.today")}</b>
                    </Typography>
                  </div>
                </div>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
      <AuctionTable />
    </>
  );
};

export default ManagerContainer;
