import api from "../api/http-common";
import SnackBar from "./SnackBar";

const entityName = sessionStorage.getItem("entityName");

class SMS_API_FUNCTION {
  async loanCreationSMS_API(loan_id, date, cus_mob_number,msg,amount) {
    const data = {
      message: msg,
      finance_name: entityName,
      loan_on: loan_id,
      mobile_num: cus_mob_number,
      date: date,
      loan_amount :amount.toString()
    };
    console.log(data)
    await api
      .post(`loan/sendSMS`, data)
      .then((res) => {
        SnackBar.toastMsg("success", "SMS sent successfully");
      })
      .catch((err) => {
        console.log(err)});
    console.log("data", data);
  }
}
export default new SMS_API_FUNCTION();
