import { Key, TaskAlt, ThumbUp } from "@mui/icons-material";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import {
  Backdrop,
  Box,
  Fade,
  Grid,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import api from "../../../api/http-common";
import { AppData } from "../../../common/AppData";
import Extension from "../../../common/Extension";
import GridTable from "../../../common/GridTable";
import "./Container.css";

const data = [
  {
    title: "Total Loans",
    name: "total_loan",
    count: 235,
    color: "#ca641a",
    symbol: "+",
    icon: "CreditScoreIcon",
    percentage: 1.29,
  },
  {
    title: "Active",
    name: "total_active",
    count: 123,
    color: "#28667c",
    symbol: "+",
    icon: "ThumbUp",
    percentage: 1.19,
  },
  {
    title: "Rejected",
    name: "total_rejected",
    count: 21,
    color: "#9C0808",
    symbol: "-",
    icon: "ThumbDownAltIcon",
    percentage: 1.29,
  },
  {
    title: "Closed",
    name: "total_closed",
    count: 82,
    color: "#729d71",
    symbol: "+",
    icon: "TaskAlt",
    percentage: 1.45,
  },
];

export const Container = ({ countData, rows }) => {
  const { t } = useTranslation("global");
  const columns = AppData.auctionDashboard.map((column) => ({
    ...column,
    headerName: t(`auction process.${column.field}`),
  }));
  // const role_id = parseInt(sessionStorage.getItem("roleId"));
  // const branch_id = sessionStorage.getItem("branchId");
  const entity_Id = sessionStorage.getItem("entityId");
  const [showModal, setShowModal] = useState(false);
  const [auctionLoans, setAuctionLoans] = useState([]);

  const containers = data?.map((data) => ({
    ...data,
    count: `${countData?.[data.name]}`,
  }));

  const handleIcon = (icon, color) => {
    switch (icon) {
      case "CreditScoreIcon":
        return (
          <CreditScoreIcon className="icon" style={{ color: `${color}` }} />
        );
      case "ThumbDownAltIcon":
        return (
          <ThumbDownAltIcon className="icon" style={{ color: `${color}` }} />
        );
      case "CreditCardOffIcon":
        return (
          <CreditCardOffIcon className="icon" style={{ color: `${color}` }} />
        );
      case "ThumbUp":
        return <ThumbUp className="icon" style={{ color: `${color}` }} />;
      case "TaskAlt":
        return <TaskAlt className="icon" style={{ color: `${color}` }} />;
      default:
        return (
          <CreditScoreIcon
            key={Key}
            className="icon"
            style={{ color: `${color}` }}
          />
        );
    }
  };
  const handleContainerClick = () => {
    setShowModal(true);
  };;

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
    >
      {containers.map((item, index) => {
        return (
          <Grid item xs={6} sm={6} md={4} lg={2.4} key={index}>
            <Paper
              elevation={8}
              className={"container-wrapper"}
              // onClick={() => handleContainerClick(index)}
            >
              <Box className="title">
                <Typography
                  className="common-font-style"
                  variant="h6"
                  component="p"
                >
                  {t(`dashboard.${item.title}`)}
                </Typography>
              </Box>
              <Box className="man_detail">
                {handleIcon(item.icon, item.color)}
                <span className="man_count">
                  <CountUp
                    start={0}
                    end={item?.count}
                    duration={2.5}
                    // className="common-font-style"
                    useIndianSeparators={true}
                    style={{ fontSize: 55 }}
                  />
                </span>
              </Box>
            </Paper>
          </Grid>
        );
      })}
      <Grid item xs={6} sm={6} md={4} lg={2.4}>
        <Paper
          elevation={8}
          className={"container-wrapper-auctioned"}
          onClick={handleContainerClick}
        >
          <Box className="title">
            <Typography
              className="common-font-style"
              variant="h6"
              component="p"
            >
              {t(`dashboard.EMI Defaulters`)}
            </Typography>
          </Box>
          <Box className="man_detail">
            {handleIcon("CreditCardOffIcon", "#ad5373")}
            <span className="man_count">
              <CountUp
                start={0}
                end={rows?.length}
                // className="common-font-style"
                duration={2.5}
                useIndianSeparators={true}
                style={{ fontSize: 55 }}
              />
            </span>
          </Box>
        </Paper>
      </Grid>
      <Modal
        sx={{ width: "50%" }}
        open={showModal}
        onClose={() => setShowModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <Box
            className="modal-content"
            sx={{
              width: "99%",
              height: "65%",
              margin: "auto",
              marginTop: "20vh",
              marginLeft: "55vh",
              padding: "18px",
              backgroundColor: "var(--commonpaper-background)",
              borderRadius: "8px",
              boxShadow: 8,
            }}
          >
            <GridTable rows={rows?.slice(0, 10)} columns={columns} />
          </Box>
        </Fade>
      </Modal>
    </Grid>
  );
};
