// LoanCreation.js

import html2pdf from "html2pdf.js"; // Import html2pdf library
import Extension from "../Extension";

export const LoanCreation = (data, download) => {

  const printWindow = window.open("", "_blank");
  printWindow.document.write("<html><head><title>Print</title></head><body>");
  printWindow.document.write(
    '<div style="display: none;" id="printable-content">'
  );
  printWindow.document.write(
    `<style>
      body {
        font-family: Arial, sans-serif;
      } 
      .receipt {
        /* margin-bottom: 20px; */
        padding: 20px;
    }
    
    .receipt-wrapper {
        padding: 0 20px 20px 20px;
        border: 2px solid #dedede;
        border-radius: 20px;
        background-color: #fbfbfb;
    
    }
    
    .receipt-header {
        width: 100%;
        display: flex;
        /* grid-template-columns: repeat(3, 1fr); */
        justify-content: space-between;
        align-items: center;
        height: 13vh;
        font-size: 10px;
        margin-top: -40px;
    }
    
    .reciept-container .title {
        font-size: 22px;
        font-weight: 600;
    }
    
    .reciept-container:nth-child(2) {
        text-align: center;
        /* margin-left: -80px; */
        margin-top: 30px;
    }
    
    .reciept-container:nth-child(2) .text,
    .address {
        font-size: 10px;
        font-weight: 500;
    }
    
    .reciept-container:last-child {
        // padding-right: 80px;
    }
    .right {
      margin: 30px 30px 0 0;
    }

    .left {
      margin: 30px 0 0 30px;
    }
    .left .text {
      font-size: larger;
    }
    .right .text {
      font-size: larger;
    }
    .main-table-container {
        display: flex;
        gap: 10px;
        margin-top: -20px;
        width: 100%;
    }
    
    .personal-detail-table,
    .loan-detail-table {
        width: 50%;
    }
    
    .main-table-container h1,
    .item-detail h1,
    .disclaimer h1 {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }
    
    /* .loan-detail-table h1 {
        text-align: right;
    } */
    
    .table {
        width: 100%;
    }
    
    .t-row {
        display: flex;
        width: 100%;
    }
    
    .t-row .td {
        border: 1px solid #dedede;
        background-color: #FFFFFF;
        padding: 10px 0 10px 5px;
        font-size: 12px;
    }
    .t-row .td:first-child {
        font-weight: 600;
    }
    
    .t-row .td:first-child {
        width: 40%;
    }
    
    .t-row .td:last-child {
        width: 60%;
    }
    
    .t-row:first-child .td:first-child {
        border-radius: 10px 0 0 0;
    }
    
    .t-row:first-child .td:last-child {
        border-radius: 0 10px 0 0;
    }
    
    .t-row:last-child .td:first-child {
        border-radius: 0 0 0 10px;
    }
    
    .t-row:last-child .td:last-child {
        border-radius: 0 0 10px 0;
    }
    
    .item-detail {
        width: 100%;
    }
    
    .item-detail .table {
        /* padding: 0 50px; */
    }
    
    .item-detail .t-row .td {
        width: 20%;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: 40px;
    }
    
    .disclaimer {
        width: 100%;
        /* padding: 0 50px; */
    }
    
    .disclaimer h1 {
        text-align: center;
    }
    
    .disclaimer ol {
        padding: 0;
        padding-left: 15px;
    }
    
    .disclaimer ol li {
        padding: 0;
        font-size: 10px;
        font-weight: 500;
    }
    
    .disclaimer-content1,
    .disclaimer-content2 {
        display: flex;
        font-size: 10px;
        align-items: end;
        font-weight: 600;
        justify-content: space-between;
    }
    
    .disclaimer p {
        margin-top: 8px;
        font-size: 10px;
        font-weight: 500;
    }
    
    .disclaimer-content2 {
        padding-bottom: 5px;
        border-bottom: 2px dotted #000;
        margin-bottom: 10px;
    }
    
    .disclaimer-footer {
        display: flex;
        font-size: 10px;
        width: 100%;
        font-family: 500;
        align-items: center;
    }
    
    .disclaimer-footer .content {
        width: 50%;
    }
    
    .disclaimer-footer .content:last-child {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
</style>`
  );
  printWindow.document.write(`<div class="receipt container">
    <div class="receipt-wrapper">
      <div class="receipt-header ">
        <div class="reciept-container">
          <div class="left">
            <div class="text">LICENSE NO : 12/2021-2024</div>
            <div class="text">BRANCH NAME : ${data.branch_name}</div>
          </div>
        </div>
        <div class="reciept-container">
          <div class="title">${data.name}</div>
          <div class="address">
          ${data.address}
          </div>
        </div>
        <div class="reciept-container">
          <div class="right">
            <div class="text">RECEIPT NO : ${data.receipt_no}</div>
            <div class="text">LOAN NO : ${data.loan_number}</div>
          </div>
        </div>
      </div>
      <div class="main-table-container">
        <div class="personal-detail-table">
          <h1>PERSONAL DETAIL</h1>
          <div class="table">
            <div class="t-row">
              <div class="td">Name</div>
              <div class="td">${data.customer_name}</div>
            </div>
            <div class="t-row">
              <div class="td">Father / Mother / Husband Name</div>
              <div class="td">--</div>
            </div>
            <div class="t-row">
              <div class="td">Address</div>
              <div class="td">${data.customer_address}</div>
            </div>
            <div class="t-row">
              <div class="td">Phone No.</div>
              <div class="td">${data.phone_no}</div>
            </div>
            <div class="t-row">
              <div class="td">Net Worth till Date</div>
              <div class="td"></div>
            </div>
          </div>
        </div>
        <div class="loan-detail-table">
          <h1>LOAN DETAIL</h1>
          <div class="table">
            <div class="t-row">
              <div class="td">Loan Amount</div>
              <div class="td">\u20B9 ${Extension.formatAsIndianRupees(
                data.loan_amount
              )} - ${data.amount_in_english}</div>
            </div>
            <div class="t-row">
              <div class="td">Scheme Name</div>
              <div class="td">${data.scheme_name}</div>
            </div>
            <div class="t-row">
              <div class="td">Loan Date</div>
              <div class="td">${new Date(data.loan_date)
                .toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })
                .replace(/\//g, "-")}</div>
            </div>
            <div class="t-row">
              <div class="td">Expected Loan Closure Date</div>
              <div class="td">${new Date(data.loan_close_date)
                .toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })
                .replace(/\//g, "-")}</div>
            </div>
            <div class="t-row">
              <div class="td">Net Weight</div>
              <div class="td">${data.total_net_weight} g</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item-detail">
        <h1>ITEM DETAIL</h1>
        <div class="table">
          <div class="t-row">
            <div class="td">S.no</div>
            ${(() => {
              if (
                data.Item_Category === "Gold" ||
                data.Item_Category === "gold"
              ) {
                return ` <div class="td">Gold Type</div>`;
              } else {
                return ``;
              }
            })()}
            <div class="td">Item Name</div>
            <div class="td">Quantity</div>
            <div class="td">GW(g)</div>
            <div class="td">NW(g)</div>
            <div class="td">Shop Rate</div>
            <div class="td">Market Rate</div>
            <div class="td">Quality</div>
          </div>`);
  for (let i = 0; i < data.loan_details.length; i++) {
    const loan = data.loan_details[i];
    printWindow.document.write(`
                  <div class="t-row">
                    <div class="td">${i + 1}</div>
                    ${(() => {
                      if (
                        data.Item_Category === "Gold" ||
                        data.Item_Category === "gold"
                      ) {
                        return ` <div class="td">${
                          loan.gold_category ?? "--"
                        }</div>`;
                      } else {
                        return ``;
                      }
                    })()}
                    <div class="td">${loan.item_name}</div>
                    <div class="td">${loan.qty}</div>
                    <div class="td">${loan.gross_weight} g</div>
                    <div class="td">${loan.net_weight} g</div>
                    <div class="td">\u20B9 ${
                      loan.gold_category === "Normal Gold"
                        ? Extension.formatAsIndianRupees(data.normal_gold_rate)
                        : loan.gold_category === "Hallmark Gold(916)"
                        ? Extension.formatAsIndianRupees(
                            data.hallmark_gold_rate
                          )
                        : Extension.formatAsIndianRupees(data.silver_rate)
                    }</div>
                    <div class="td">${
                      loan.gold_category === "Normal Gold" ||
                      loan.gold_category === "Hallmark Gold(916)"
                        ? data.market_gold_rate
                          ? `\u20B9 ${Extension.formatAsIndianRupees(
                              data.market_gold_rate
                            )}`
                          : "--"
                        : data.market_silver_rate
                        ? `\u20B9 ${Extension.formatAsIndianRupees(
                            data.market_silver_rate
                          )}`
                        : "--"
                    }</div>
                    <div class="td"></div>
                  </div>
            `);
  }
  // <div class="t-row">
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //         </div>
  printWindow.document.write(`
          
        </div>
      </div>
      <div class="disclaimer">
        <h1>disclaimer</h1>
        <ol>
          <li>
            I have pledged all the above items to KVM Finance and got my Loan
            amount.
          </li>
          <li>
            Failure to renew or pay the interest within the agreed period or
            month will result in a penalty charge according to the scheme.
          </li>
          <li>Renew the loan every one year . </li>
          <li>
            If this ace is missed, you will have to give a powerful bite on
            the court.
          </li>
          <li>
            I have verified all the above information and conditions and
            hereby sign in full and wholeheartedly.
          </li>
          <li>I have received the loan amount accordingly. </li>
        </ol>
        <div class="disclaimer-content1">
          <div class="date">
            <div class="text">Date : ${new Date()
              .toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })
              .replace(/\//g, "-")}</div>
            <div class="text">Place : </div>
          </div>
          <div class="signature">Signature of the Borrower</div>
        </div>
        <p>
          7. I have recovered the above pledged items from the management
          without any damages on payment of principal and interest and
          undertake that I will not cause any trouble to the management
          regarding this item.
        </p>
        <div class="disclaimer-content2">
          <div class="date">
            <div class="text">Date : </div>
            <div class="text">Place : </div>
          </div>
          <div class="signature1">Signature of the Borrower</div>
        </div>
        <ol>
          <li>
            I have checked the details of the above person and the quality of
            the items pledged by him/her.
          </li>
          <li>The above items have been pledged to me. </li>
          <li>I gave him/her the loan amount due to him/her. </li>
        </ol>
        <div class="disclaimer-footer">
          <div class="content">
            <div class="text">Date : ${new Date()
              .toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })
              .replace(/\//g, "-")}</div>
            <div class="text">Place : </div>
          </div>
          <div class="content">
            <div> 
            <p>${data.loan_created_by}</p>
            <p>Loan Created By</p>
            </div>
            <div>
            <p>${data.loan_approved_by}</p>
            <p>Loan Approved By</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
    `);
  // printWindow.document.write("<div class='receipt-header'>");
  // printWindow.document.write(`<div class="receipt-container>">
  // <p>Name</p>
  // <h1>KVM</h1>
  // <div class=""
  // </div>`)
  // printWindow.document.write("<h2>Receipt</h2>");
  // printWindow.document.write("</div>");
  // printWindow.document.write("<table>");
  // printWindow.document.write(
  //   "<thead><tr><th>Item</th><th>Quantity</th><th>Price</th></tr></thead>"
  // );
  // printWindow.document.write("<tbody>");
  // data.forEach((item, index) => {
  //   printWindow.document.write(
  //     `<tr><td>${item.name}</td><td>${
  //       item.quantity
  //     }</td><td>$${item.price.toFixed(2)}</td></tr>`
  //   );
  // });
  // printWindow.document.write("</tbody></table>");
  // printWindow.document.write(
  //   `<p>Total: $${data
  //     .reduce((total, item) => total + item.quantity * item.price, 0)
  //     .toFixed(2)}</p>`
  // );
  // printWindow.document.write("</div>");
  printWindow.document.write("</div></body></html>");
  printWindow.document.getElementById("printable-content").style.display =
    "block";
  printWindow.document.close();
  // // Use html2pdf library to generate a PDF
  if (!download) {
    printWindow.print();
  } else {
    html2pdf(printWindow.document.body, {
      margin: 10,
      filename: "receipt.pdf", // Set the desired file name
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    });
  }

  printWindow.onafterprint = function () {
    printWindow.close();
  };
};
