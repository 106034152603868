import { Login } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import notFound from "../assets/404.notFound.png";
import CustomCommonButton from "../common/Components/CustomCommonButton";

const NotFound = () => {
  const Navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection:'column',
        alignItems: "center",
      }}
    >
      <img src={notFound} alt="" />
      <CustomCommonButton
        onClick={() => Navigate("/")}
        icon={<Login />}
        text={"Back To Login"}
      />
    </Box>
  );
};

export default NotFound;
