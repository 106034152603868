import html2pdf from "html2pdf.js"; // Import html2pdf library
import Extension from "../Extension";

export const LoanCreationTM = (data, download) => {
  const printWindow = window.open("", "_blank");
  printWindow.document.write("<html><head><title>Print</title></head><body>");
  printWindow.document.write(
    '<div style="display: none;" id="printable-content">'
  );
  printWindow.document.write(
    `<style>
      body {
        font-family: Arial, sans-serif;
      } 
      .receipt {
        /* margin-bottom: 20px; */
        padding: 20px;
    }
    
    .receipt-wrapper {
        padding: 0 20px 20px 20px;
        border: 2px solid #dedede;
        border-radius: 20px;
        background-color: #fbfbfb;
    
    }
    
    .receipt-header {
        width: 100%;
        display: flex;
        /* grid-template-columns: repeat(3, 1fr); */
        justify-content: space-between;
        align-items: center;
        height: 13vh;
        font-size: 10px;
        margin-top: -40px;
    }
    
    .reciept-container .title {
        font-size: 22px;
        font-weight: 600;
    }
    
    .reciept-container:nth-child(2) {
        text-align: center;
        /* margin-left: -80px; */
    }
    
    .reciept-container:nth-child(2) .text,
    .address {
        font-size: 10px;
        font-weight: 500;
    }
    
    .reciept-container:last-child {
        // padding-right: 80px;
    }
    .right {
      margin: 30px 30px 0 0;
    }

    .left {
      margin: 30px 0 0 30px;
    }
    .left .text {
      font-size: larger;
    }
    .right .text {
      font-size: larger;
    }
    .main-table-container {
        display: flex;
        gap: 10px;
        margin-top: -20px;
        width: 100%;
    }
    
    .personal-detail-table,
    .loan-detail-table {
        width: 50%;
    }
    
    .main-table-container h1,
    .item-detail h1,
    .disclaimer h1 {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }
    
    /* .loan-detail-table h1 {
        text-align: right;
    } */
    
    .table {
        width: 100%;
    }
    
    .t-row {
        display: flex;
        width: 100%;
    }
    
    .t-row .td {
        border: 1px solid #dedede;
        background-color: #FFFFFF;
        padding: 10px 0 10px 5px;
        font-size: 12px;
    }
    .t-row .td:first-child {
        font-weight: 600;
    }
    
    .t-row .td:first-child {
        width: 40%;
    }
    
    .t-row .td:last-child {
        width: 60%;
    }
    
    .t-row:first-child .td:first-child {
        border-radius: 10px 0 0 0;
    }
    
    .t-row:first-child .td:last-child {
        border-radius: 0 10px 0 0;
    }
    
    .t-row:last-child .td:first-child {
        border-radius: 0 0 0 10px;
    }
    
    .t-row:last-child .td:last-child {
        border-radius: 0 0 10px 0;
    }
    
    .item-detail {
        width: 100%;
    }
    
    .item-detail .table {
        /* padding: 0 50px; */
    }
    
    .item-detail .t-row .td {
        width: 20%;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        height: 40px;
    }
    
    .disclaimer {
        width: 100%;
        /* padding: 0 50px; */
    }
    
    .disclaimer h1 {
        text-align: center;
    }
    
    .disclaimer ol {
        padding: 0;
        padding-left: 15px;
    }
    
    .disclaimer ol li {
        padding: 0;
        font-size: 10px;
        font-weight: 500;
    }
    
    .disclaimer-content1,
    .disclaimer-content2 {
        display: flex;
        font-size: 10px;
        align-items: end;
        font-weight: 600;
        justify-content: space-between;
    }
    
    .disclaimer p {
        margin-top: 8px;
        font-size: 10px;
        font-weight: 500;
    }
    
    .disclaimer-content2 {
        padding-bottom: 5px;
        border-bottom: 2px dotted #000;
        margin-bottom: 10px;
    }
    
    .disclaimer-footer {
        display: flex;
        font-size: 10px;
        width: 100%;
        font-family: 500;
        align-items: center;
    }
    
    .disclaimer-footer .content {
        width: 50%;
    }
    
    .disclaimer-footer .content:last-child {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
</style>`
  );
  printWindow.document.write(`<div class="receipt container">
    <div class="receipt-wrapper">
      <div class="receipt-header ">
        <div class="reciept-container">
          <div class="left">
            <div class="text">உரிமம் எண் : 12/2021-2024</div>
            <div class="text">கிளை பெயர் : ${data.branch_name_tn}</div>
          </div>
        </div>
        <div class="reciept-container">
          <div class="title">${data.name_tn}</div>
          <div class="address">${data.address_tn}</div>
        </div>
        <div class="reciept-container">
          <div class="right">
            <div class="text">ரசீது எண் : ${data.receipt_no}</div>
            <div class="text">கடன் எண் : ${data.loan_number}</div>
          </div>
        </div>
      </div>
      <div class="main-table-container">
        <div class="personal-detail-table">
          <h1>தனிப்பட்ட விவரம்</h1>
          <div class="table">
            <div class="t-row">
              <div class="td">பெயர்</div>
              <div class="td">${data.customer_name_tn}</div>
            </div>
            <div class="t-row">
              <div class="td">தந்தை / தாய் / கணவர் பெயர்</div>
              <div class="td">--</div>
            </div>
            <div class="t-row">
              <div class="td">முகவரி</div>
              <div class="td">${data.customer_address_tn}</div>
            </div>
            <div class="t-row">
              <div class="td">தொலைபேசி எண்</div>
              <div class="td">${data.phone_no}</div>
            </div>
            <div class="t-row">
              <div class="td">இன்றுவரை நிகர மதிப்பு</div>
              <div class="td"></div>
            </div>
          </div>
        </div>
        <div class="loan-detail-table">
          <h1>கடன் விவரங்கள்</h1>
          <div class="table">
            <div class="t-row">
              <div class="td">கடன் தொகை</div>
              <div class="td">\u20B9 ${Extension.formatCurrency(
                data.loan_amount
              )} - ${data.amount_in_tamil}</div>
            </div>
            <div class="t-row">
              <div class="td">திட்டம் பெயர்</div>
              <div class="td">${data.scheme_name_tn}</div>
            </div>
            <div class="t-row">
              <div class="td">கடன் தேதி</div>
              <div class="td">${new Date(data.loan_date)
                .toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })
                .replace(/\//g, "-")}</div>
            </div>
            <div class="t-row">
              <div class="td">எதிர்பார்க்கப்படும் கடன் முடிவு தேதி</div>
              <div class="td">${new Date(data.loan_close_date)
                .toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })
                .replace(/\//g, "-")}</div>
            </div>
            <div class="t-row">
              <div class="td">நிகர எடை (கிராம்)</div>
              <div class="td">${data.total_net_weight} கிராம்</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item-detail">
        <h1>பொருளின் விவரம்</h1>
        <div class="table">
          <div class="t-row">
            <div class="td">வ.எண்</div>
            ${(() => {
              if (
                data.Item_Category === "Gold" ||
                data.Item_Category === "gold"
              ) {
                return ` <div class="td">தங்கம் வகை</div>`;
              } else {
                return ``;
              }
            })()}
            <div class="td">பொருளின் பெயர்</div>
            <div class="td">அளவு</div>
            <div class="td">GW(கிராம்)</div>
            <div class="td">NW(கிராம்)</div>
            <div class="td">கடை விலை</div>
            <div class="td">சந்தை விலை</div>
            <div class="td">தரம்</div>
          </div>`);
  for (let i = 0; i < data.loan_details.length; i++) {
    const loan = data.loan_details[i];
    printWindow.document.write(`
                  <div class="t-row">
                    <div class="td">${i + 1}</div>
                    ${(() => {
                      if (
                        data.Item_Category === "Gold" ||
                        data.Item_Category === "gold"
                      ) {
                        return ` <div class="td">${
                          loan.gold_category_tn ? loan.gold_category_tn : "--"
                        }</div>`;
                      } else {
                        return ``;
                      }
                    })()}
                    <div class="td">${loan.item_name_tn}</div>
                    <div class="td">${loan.qty}</div>
                    <div class="td">${loan.gross_weight} g</div>
                    <div class="td">${loan.net_weight} g</div>
                    <div class="td">\u20B9 ${
                      loan.gold_category === "Normal Gold"
                        ? Extension.formatAsIndianRupees(data.normal_gold_rate)
                        : loan.gold_category === "Hallmark Gold(916)"
                        ? Extension.formatAsIndianRupees(
                            data.hallmark_gold_rate
                          )
                        : Extension.formatAsIndianRupees(data.silver_rate)
                    }</div>
                    <div class="td">${
                      loan.gold_category === "Normal Gold" ||
                      loan.gold_category === "Hallmark Gold(916)"
                        ? data.market_gold_rate
                          ? `\u20B9 ${Extension.formatAsIndianRupees(
                              data.market_gold_rate
                            )}`
                          : "--"
                        : data.market_silver_rate
                        ? `\u20B9 ${Extension.formatAsIndianRupees(
                            data.market_silver_rate
                          )}`
                        : "--"
                    }</div>
                    <div class="td"></div>
                  </div>
            `);
  }
  // <div class="t-row">
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //           <div class="td"></div>
  //         </div>
  printWindow.document.write(`
          
        </div>
      </div>
      <div class="disclaimer">
        <h1>மறுப்பு</h1>
        <ol>
          <li>
          மேலே உள்ள அனைத்து பொருட்களையும் நான் KVM ஃபைனான்ஸ் நிறுவனத்தில் அடகு வைத்து எனது கடனைப் பெற்றுள்ளேன் தொகை.
          </li>
          <li>
          ஒப்புக்கொள்ளப்பட்ட காலம் அல்லது மாதத்திற்குள் வட்டியைப் புதுப்பிக்கவோ அல்லது செலுத்தவோ தவறினால், திட்டத்தின் படி அபராதம் விதிக்கப்படும்.
          </li>
          <li>ஒவ்வொரு வருடமும் கடனை புதுப்பிக்கவும்.</li>
          <li>
          இந்த சீட்டு தவறினால் கோர்ட்டில் பவர்ஃபுல் கடி கொடுக்க வேண்டி வரும்.
          </li>
          <li>
          நான் மேலே உள்ள அனைத்து தகவல்களையும் நிபந்தனைகளையும் சரிபார்த்து, இதன் மூலம் முழு மனதுடன் உள்நுழைகிறேன்.
          </li>
          <li>அதன்படி கடன் தொகை பெற்றுள்ளேன்.</li>
        </ol>
        <div class="disclaimer-content1">
          <div class="date">
            <div class="text">தேதி : ${new Date()
              .toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })
              .replace(/\//g, "-")}</div>
            <div class="text">இடம் : </div>
          </div>
          <div class="signature">கடன் வாங்குபவரின் கையொப்பம்</div>
        </div>
        <p>
          7. நான் மேற்கூறிய உறுதிமொழிப் பொருட்களை நிர்வாகத்திடம் இருந்து அசல் மற்றும் வட்டிக்கு எந்த சேதமும் இன்றி மீட்டுக்கொண்டேன், மேலும் இந்த உருப்படி தொடர்பாக நிர்வாகத்திற்கு எந்த சிக்கலையும் ஏற்படுத்த மாட்டேன் என்று உறுதியளிக்கிறேன்.
        </p>
        <div class="disclaimer-content2">
          <div class="date">
            <div class="text">தேதி : </div>
            <div class="text">இடம் : </div>
          </div>
          <div class="signature1">கடன் வாங்குபவரின் கையொப்பம்</div>
        </div>
        <ol>
          <li>
          மேற்சொன்ன நபரின் விவரங்களையும் அவர்/அவர் அடகு வைத்த பொருட்களின் தரத்தையும் சரிபார்த்துள்ளேன்.
          </li>
          <li>மேற்கூறிய பொருட்கள் என்னிடம் அடகு வைக்கப்பட்டுள்ளன. </li>
          <li>அவனுக்கு/அவளுக்கு கொடுக்க வேண்டிய கடன் தொகையை கொடுத்தேன்.</li>
        </ol>
        <div class="disclaimer-footer">
          <div class="content">
            <div class="text">தேதி : ${new Date()
              .toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })
              .replace(/\//g, "-")}</div>
            <div class="text">இடம் : </div>
          </div>
          <div class="content">
            <div> 
            <p>${data.loan_created_by_tn}</p>
            <p>கடன் உருவாக்கியது</p>
            </div>
            <div>
            <p>${data.loan_approved_by_tn}</p>
            <p>கடன் அங்கீகரிக்கப்பட்டது</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
    `);
  // printWindow.document.write("<div class='receipt-header'>");
  // printWindow.document.write(`<div class="receipt-container>">
  // <p>Name</p>
  // <h1>KVM</h1>
  // <div class=""
  // </div>`)
  // printWindow.document.write("<h2>Receipt</h2>");
  // printWindow.document.write("</div>");
  // printWindow.document.write("<table>");
  // printWindow.document.write(
  //   "<thead><tr><th>Item</th><th>Quantity</th><th>Price</th></tr></thead>"
  // );
  // printWindow.document.write("<tbody>");
  // data.forEach((item, index) => {
  //   printWindow.document.write(
  //     `<tr><td>${item.name}</td><td>${
  //       item.quantity
  //     }</td><td>$${item.price.toFixed(2)}</td></tr>`
  //   );
  // });
  // printWindow.document.write("</tbody></table>");
  // printWindow.document.write(
  //   `<p>Total: $${data
  //     .reduce((total, item) => total + item.quantity * item.price, 0)
  //     .toFixed(2)}</p>`
  // );
  // printWindow.document.write("</div>");
  printWindow.document.write("</div></body></html>");
  printWindow.document.getElementById("printable-content").style.display =
    "block";
  printWindow.document.close();
  // // Use html2pdf library to generate a PDF
  if (!download) {
    printWindow.print();
  } else {
    html2pdf(printWindow.document.body, {
      margin: 10,
      filename: "receipt.pdf", // Set the desired file name
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    });
  }

  printWindow.onafterprint = function () {
    printWindow.close();
  };
};
