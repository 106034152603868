import { Paper } from "@mui/material";
import React from "react";
import WorkInProgress from "../assets/work-in-progress.svg";
const UnderDevelopment = () => {
  return (
    <Paper
      className="commonpaper"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <img src={WorkInProgress} alt="" height={250} />
    </Paper>
  );
};

export default UnderDevelopment;
