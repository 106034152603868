import {
  ArrowCircleLeftOutlined,
  DownloadForOffline,
  UploadFileRounded,
} from "@mui/icons-material";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../api/http-common";

import ExcelJS from "exceljs";
import { saveAs } from "save-as";
import * as XLSX from "xlsx";
import SnackBar from "../../common/SnackBar";
import Extension from "../Extension";
import Loading from "../Loading";
import Styles from "../Styles";
import CustomCommonButton from "./CustomCommonButton";
import Dropdown from "./Dropdown";
import Logger from "../Logger";

const logger = new Logger({ level: "log" });

const BulkUpload = ({ onClose, open, getCustomerdata }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const { t } = useTranslation("global");
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const entity_id = sessionStorage.getItem("entityId");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const [branchPrefix, setBranchPrefix] = useState("");
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  //fetch initial data of the branch
  const getData = useCallback(async () => {
    try {
      handleLoadingOpen();
      const branchAPI =
        roleId === 1 ? `/branch/byEntity/${entity_id}` : `branch/${branch_id}`;
        logger.log("Initiate Fetch branch")
        const branchRes = await api.get(branchAPI);
        logger.log("Fetch branch Successfully")
      setBranches(branchRes.data);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  }, [branch_id, entity_id, roleId]);

  useEffect(() => {
    getData();
  }, [getData]);

  //open confirmation popup
  const openConfirmation = () => {
    if (!selectedFile) {
      //check file is selected
      SnackBar.toastMsg("error", t("bulk upload.please_choose_the_file"));
      return;
    }
    if (!selectedBranch && roleId === 1) {
      //check if the roleId is 1 then need to select the branch
      SnackBar.toastMsg("error", t("bulk upload.please_select_the_branch"));
      return;
    }
    setConfirmationOpen(true);
  };

  //close the confirmation popup
  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };

  //confirmation handle function
  const handleConfirmation = async (choice) => {
    if (choice === "yes") {
      await handleUpload();
    }
    closeConfirmation();
  };

  const handleSampleExcel = async () => {
    const sampleData = [
      "S.No",
      "OLD CUS ID",
      "FIRST NAME",
      "FIRST NAME TAMIL",
      "LAST NAME",
      "LAST NAME TAMIL",
      "GENDER",
      "GENDER TAMIL",
      "DOB",
      "OCCUPATION",
      "OCCUPATION TAMIL",
      "PERMANENT ADDRESS",
      "PERMANENT ADDRESS TAMIL",
      "CURRENT ADDRESS",
      "CURRENT ADDRESS TAMIL",
      "EMAIL ID",
      "MOBILE NUMBER",
      "AADHAR CARD NUMBER",
      "BANK NAME",
      "BANK NAME TAMIL",
      "BANK ACCOUNT NUMBER",
      "BRANCH NAME",
      "BRANCH NAME TAMIL",
      "IFSC CODE",
      "REFERENCE BY",
      "REFERENCE BY TAMIL",
    ];

    const GENDER = ['Male', 'Female', 'Trans'];
    const GENDERTN = ['ஆண்', 'பெண்', 'திருநங்கை'];
    const OCCUPATION = ['Government', 'Private', 'Self-Employment', 'Engineer', 'Doctor', 'Teacher', 'Farmer', 'Others'];
    const OCCUPATION_TAMIL = ['அரசாங்கம்', 'தனியார்', 'சுய வேலைவாய்ப்பு', 'பொறியாளர்', 'மருத்துவர்', 'ஆசிரியர்', 'உழவர்', 'மற்றவை'];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Customer List");
    worksheet.addRow(sampleData);

    worksheet.getCell("G2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`"${GENDER.join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Value",
      error: "Please select a valid value from the list",
    };

    worksheet.getCell("H2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`"${GENDERTN.join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Value",
      error: "Please select a valid value from the list",
    };

    worksheet.getCell("J2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`"${OCCUPATION.join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Value",
      error: "Please select a valid value from the list",
    };

    worksheet.getCell("K2").dataValidation = {
      type: "list",
      allowBlank: true,
      formulae: [`"${OCCUPATION_TAMIL.join(",")}"`],
      showErrorMessage: true,
      errorTitle: "Invalid Value",
      error: "Please select a valid value from the list",
    };

    // Date of Birth validation
    worksheet.getCell("I2").dataValidation = {
      type: "date",
      operator: "between",
      formula1: "DATE(1900,1,1)",
      formula2: "DATE(2100,12,31)",
      showErrorMessage: true,
      errorTitle: "Invalid Date",
      error: "Please enter a valid date in the format YYYY-MM-DD",
    };

    // Email validation using regex
    worksheet.getCell("P2").dataValidation = {
      type: "custom",
      formula1: 'ISNUMBER(SEARCH("@",P2))*ISNUMBER(SEARCH(".",P2))',
      showErrorMessage: true,
      errorTitle: "Invalid Email",
      error: "Please enter a valid email address",
    };

    // Mobile number validation (assuming 10 digits)
    worksheet.getCell("Q2").dataValidation = {
      type: "custom",
      formula1: 'AND(ISNUMBER(Q2),LEN(Q2)=10)',
      showErrorMessage: true,
      errorTitle: "Invalid Phone Number",
      error: "Please enter a valid 10-digit phone number",
    };

    const buffer = await workbook.xlsx.writeBuffer();

    // Save the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "Customer List.xlsx");
};
  

  //excel upload function
  const handleUpload = async (e) => {
    if (selectedFile) {
      handleLoadingOpen();
      const branch = roleId === 1 ? selectedBranch : branch_id;
      const branch_prefix =
        roleId === 1 ? branchPrefix : branches.branch_prefix;
        logger.log("Initiate Fetch customer")
      const customers = await api
        .get(`/customer/branch/${branch}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
        logger.log("Fetch customer Successfully")
      const reader = new FileReader();
      reader.readAsBinaryString(selectedFile);
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        try {
          let parsedData = []; //initiate the parsed data

          workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            parsedData = XLSX.utils.sheet_to_json(sheet, { raw: false });
          });

          let ids = customers.map((item) => item.cus_login_id);
          let sortedSequences = ids.sort((a, b) => a.localeCompare(b)); //sort the customer login id based on the branch
          let lastSequence = "";
          let lastSequences = [];
          if (parsedData.length <= 0) {
            //check the there is nay data to add
            return SnackBar.toastMsg(
              "error",
              t("toast_message.there_is_no_data")
            );
          }
          for (let element of parsedData) {
            //generate the customer login ids dor the n number of the parsed data
            lastSequence = Extension.findLastSequence(
              sortedSequences,
              branch_prefix
            );
            sortedSequences = [...sortedSequences, lastSequence]; //store in the sorted sequence
            lastSequences = [...lastSequences, lastSequence];
          }
          //the mandatory fields
          const mandatoryFields = [
            "FIRST NAME",
            "FIRST NAME TAMIL",
            "LAST NAME",
            "LAST NAME TAMIL",
          ];
          let formatWrong = [];
          //Check the mandatory fields are includes or not?
          parsedData.forEach((item, index) => {
            mandatoryFields.forEach((field) => {
              if (!(field in item) || item[field] === "") {
                formatWrong.push(parsedData[index]);
              }
            });
          });
          if (formatWrong.length > 0) {
            SnackBar.toastMsg("error", t("bulk upload.must_include"));
            return;
          }
          const formattedData = parsedData.map((item, index) => {
            lastSequence = Extension.findLastSequence(
              sortedSequences,
              branch_prefix
            ); //Find the customer login ID
            ids = [...ids, lastSequence];

            return {
              Customer_Num: item["OLD CUS ID"] || "",
              New_Customer_Num: lastSequences[index],
              First_Name: item["FIRST NAME"] || "",
              First_Name_tn: item["FIRST NAME TAMIL"] || "",
              Last_Name: item["LAST NAME"] || "",
              Last_Name_tn: item["LAST NAME TAMIL"] || "",
              Gender: item["GENDER"] || "",
              Gender_tn: item["GENDER TAMIL"] || "",
              DOB: item["DOB"]
                ? Extension.formatDate(new Date(item["DOB"]))
                : "",
              Occupation: item["OCCUPATION"] || "",
              Occupation_tn: item["OCCUPATION TAMIL"] || "",
              Permanent_Address: item["PERMANENT ADDRESS"] || "",
              Permanent_Address_tn: item["PERMANENT ADDRESS TAMIL"] || "",
              Current_Address: item["CURRENT ADDRESS"] || "",
              Current_Address_tn: item["CURRENT ADDRESS TAMIL"] || "",
              Email_ID: item["EMAIL ID"] || "",
              Mobile_Number: item["MOBILE NUMBER"] || "",
              AddharCard: item["AADHAR CARD NUMBER"] || "",
              Bank_Name: item["BANK NAME"] || "",
              Bank_Name_tn: item["BANK NAME TAMIL"] || "",
              Account_Number: item["BANK ACCOUNT NUMBER"] || "",
              Bank_Branch_Name: item["BRANCH NAME"] || "",
              Bank_Branch_Name_tn: item["BRANCH NAME TAMIL"] || "",
              IFSC_Code: item["IFSC CODE"] || "",
              Reference_By_Name: item["REFERENCE BY"] || "",
              Reference_By_Name_tn: item["REFERENCE BY TAMIL"] || "",
              Reference_By_ID: "",
            };
          });
          logger.log("Initiate post bulk customers")
          await api.post(
            `/bulkUpload/customers/${entity_id}/${branch}`,
            formattedData
          );
          logger.log("Bulk customers post Successfully")
          SnackBar.toastMsg(
            "success",
            t("toast_messages.customers_added_successfully")
          );

          onClose();
          closeField();
          getCustomerdata();
        } catch (error) {
          logger.log(error);
          if (error.response.data.error) {
            SnackBar.toastMsg("error", error.response.data.error);
            return;
          }
          if (error.response.data) {
            SnackBar.toastMsg("error", error.response.data);
            return;
          }
          SnackBar.toastMsg("error", t("bulk upload.issue_on_upload"));
        } finally {
          handleLoadingClose();
        }
      };
    }
  };

  //branch change function
  const handleBranchChange = (e) => {
    const branch_prefix = branches.find(
      (item) => parseInt(item.branch_id) === parseInt(e.target.value)
    ).branch_prefix;
    setBranchPrefix(branch_prefix);
    setSelectedBranch(e.target.value);
  };

  //loading open func
  const handleLoadingOpen = () => {
    setLoading(true);
  };
  //loading close func
  const handleLoadingClose = () => {
    setLoading(false);
  };

  //file selection function
  const handleFileChanges = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.name.endsWith(".xlsx")) {
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
      }
    } else {
      setSelectedFile(null);
    }

    if (fileInputRef.current) {
      // Reset the input value to clear the selected file
      fileInputRef.current.value = "";
    }
  };

  //clear field
  const closeField = () => {
    setSelectedFile(null);
    setSelectedBranch("");
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        closeField();
        getCustomerdata();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ ...Styles.widthPopupStyles(), width: 775, height: 330 }}>
        <Loading open={loading} handleClose={handleLoadingClose} />
        <Box
          sx={{
            backgroundColor: "var(--app-bar-color)",
            p: 1,
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Typography
            sx={{
              color: "var(--header-font-color)",
              fontSize: "var(--font-size)",
              fontFamily: "var(--font-family)",
            }}
          >
            <ArrowCircleLeftOutlined
              onClick={onClose}
              sx={{ cursor: "pointer", mr: 1 }}
            />
            {t("bulk upload.bulkUpload")}
          </Typography>
        </Box>
        <Box
          style={{
            width: "93%",
            marginLeft: "3%",
            marginRight: "3%",
            marginTop: "2%",
            marginBottom: "2%",
            padding: "0%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h7" sx={{ mt: 1.5, ml: 0.5 }}>
            {t("bulk upload.selectFiles")}
          </Typography>
          <Box
            sx={{
              backgroundColor: "#B8B3B352",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              ml: 0.5,
              borderRadius: 1,
            }}
          >
            <Button
              style={{
                backgroundColor: "#FFFFFF",
                borderColor: "#B8B3B396",
                border: "2px solid #000000",
                color: "#3c5d90",
              }}
              variant="contained"
              component="label"
              startIcon={<AttachFileRoundedIcon />}
              sx={{
                mb: 2,
                mt: 2,
                ml: 2,
                height: 48,
                width: "35%",
                borderRadius: 1,
              }}
            >
              {t("bulk upload.uploadFile")}
              <input
                type="file"
                onChange={handleFileChanges}
                accept=".xlsx"
                style={{ display: "none", color: "black" }}
                ref={fileInputRef}
              />
            </Button>

            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: "var(--font-color)",
                fontSize: "var(--font-size)",
                fontFamily: "var(--font-family)",
                textAlign: "center",
                mb: 1,
                // mt: 3.5,
                ml: 2,
              }}
            >
              {selectedFile
                ? t("bulk upload.selectFile", { fileName: selectedFile.name })
                : t("bulk upload.noFile")}
            </Typography>
            <Button
              style={{ backgroundColor: "#3c5d90" }}
              variant="contained"
              sx={{ width: "30%", mt: 2, mb: 2, mr: 1.5, borderRadius: 2 }}
              onClick={handleSampleExcel}
            >
              <DownloadForOffline />
              {t("bulk upload.sampleExcel")}
            </Button>
          </Box>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "nowrap",
            p: "0 30px",
            mt: roleId === 1 ? 0 : 3,
          }}
        >
          <Grid
            item
            xs={1}
            sm={1}
            md={10}
            sx={{
              width: "70%",
              ml: 3.5,
              mb: roleId === 1 ? 4 : 0,
            }}
          >
            {roleId === 1 ? (
              <Dropdown
                label={t("common.branch name")}
                value={selectedBranch}
                onChange={handleBranchChange}
                array={branches}
                menuValue={"branch_id"}
                display={Extension.languageBasedValue(
                  "branch_name",
                  "branch_name_tn"
                )}
              />
            ) : roleId === 2 ? (
              <Typography variant="h5" sx={Styles.titleText()}>
                {Extension.languageBasedValue(
                  branches.branch_name,
                  branches.branch_name_tn
                )}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <Button
              style={{ backgroundColor: "#3c5d90", color: "white" }}
              variant="contained"
              sx={{
                width: "80%",
                height: "45px",
                borderRadius: 2,
                ml: 1,
                "&:disabled": {
                  backgroundColor: "#a0a0a0",
                },
              }}
              startIcon={<UploadFileRounded />}
              onClick={openConfirmation}
            >
              {t("bulk upload.upload")}
            </Button>
          </Grid>
        </Grid>

        {/* Confirmation Dialog */}
        <Dialog
          open={confirmationOpen}
          onClose={closeConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              backgroundColor: "var(--commonpaper-background)",
              color: "var(--font-color)",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
          >
            {t("bulk upload.confirm")}
          </DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: "var(--commonpaper-background)",
              color: "var(--font-color)",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
          >
            <Loading open={loading} handleClose={handleLoadingClose} />
            <Typography
              sx={{
                backgroundColor: "var(--commonpaper-background)",
                color: "var(--font-color)",
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              }}
            >
              {t("bulk upload.customer")}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: "var(--commonpaper-background)",
              color: "var(--font-color)",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
          >
            <CustomCommonButton
              onClick={() => handleConfirmation("no")}
              color="error"
              text={t("bulk upload.no")}
            />
            <CustomCommonButton
              onClick={() => handleConfirmation("yes")}
              text={t("bulk upload.yes")}
              disabled={loading}
            />
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};
export default BulkUpload;
