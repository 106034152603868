import { MenuItem, Select, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Style from "../../../common/Styles";
import api from "../../../api/http-common";

const getFontData = (language) => {
  if (language === "tm") {
    return [
      { name: "அரியல் (Arial)", family: "Arial, sans-serif" },
      { name: "கலிப்ரி (Calibri)", family: "Calibri, sans-serif" },
      { name: "லெக்செண்ட் (Lexend)", family: "Lexend, sans-serif" },
      { name: "மேரிவெதர் (Merriweather)", family: "Merriweather, serif" },
      { name: "காமிக் சான்ஸ் எம்.எஸ். (Comic Sans MS)", family: "'Comic Sans MS', cursive" },
      { name: "லோரா (Lora)", family: "Lora, serif" },
      { name: "கோரியர் நியூ (Courier New)", family: "'Courier New', monospace" },
      { name: "வேர்டானா (Verdana)", family: "Verdana, sans-serif" },
      { name: "காம்ப்ரியா (Cambria)", family: "Cambria, serif" },
      { name: "ரோபோடோ (Roboto)", family: "Roboto, sans-serif" },
      { name: "ஓபன் சான்ஸ் (Open Sans)", family: "Open Sans, sans-serif" },
      { name: "மொன்செராட் (Montserrat)", family: "Montserrat, sans-serif" },
      { name: "பாப்பின்ஸ் (Poppins)", family: "Poppins, sans-serif" },
      { name: "சோர்ஸ் சான்ஸ் ப்ரோ (Source Sans Pro)", family: "'Source Sans Pro', sans-serif" },
      { name: "லேட்டோ (Lato)", family: "Lato, sans-serif" },
      { name: "நுனிடோ (Nunito)", family: "Nunito, sans-serif" },
      { name: "பி.டி. சான்ஸ் (PT Sans)", family: "'PT Sans', sans-serif" },
      { name: "ராலேவே (Raleway)", family: "Raleway, sans-serif" },
      { name: "டைம்ஸ் நியூ ரோமன் (Times New Roman)", family: "'Times New Roman', serif" },
      { name: "ஜார்ஜியா (Georgia)", family: "Georgia, serif" },
      { name: "கரமொண்ட் (Garamond)", family: "Garamond, serif" },
      { name: "பாஸ்கர்வில் (Baskerville)", family: "Baskerville, serif" },
      { name: "ப்ளேஃபேர் டிஸ்ப்ளே (Playfair Display)", family: "'Playfair Display', serif" },
      { name: "மேரிவெதர் (Merriweather)", family: "Merriweather, serif" },
      { name: "டான்ஸிங் ஸ்கிரிப்ட் (Dancing Script)", family: "'Dancing Script', cursive" },
      { name: "பசிபிகோ (Pacifico)", family: "'Pacifico', cursive" },
      { name: "சாக்ரமென்டோ (Sacramento)", family: "'Sacramento', cursive" },
    ];
  } else {
    return [
      { name: "Arial", family: "Arial, sans-serif" },
      { name: "Calibri", family: "Calibri, sans-serif" },
      { name: "Lexend", family: "Lexend, sans-serif" },
      { name: "Merriweather", family: "Merriweather, serif" },
      { name: "Comic Sans MS", family: "'Comic Sans MS', cursive" },
      { name: "Lora", family: "Lora, serif" },
      { name: "Courier New", family: "'Courier New', monospace" },
      { name: "Verdana", family: "Verdana, sans-serif" },
      { name: "Cambria", family: "Cambria, serif" },
      { name: "Roboto", family: "Roboto, sans-serif" },
      { name: "Open Sans", family: "Open Sans, sans-serif" },
      { name: "Montserrat", family: "Montserrat, sans-serif" },
      { name: "Poppins", family: "Poppins, sans-serif" },
      { name: "Source Sans Pro", family: "'Source Sans Pro', sans-serif" },
      { name: "Lato", family: "Lato, sans-serif" },
      { name: "Nunito", family: "Nunito, sans-serif" },
      { name: "PT Sans", family: "'PT Sans', sans-serif" },
      { name: "Raleway", family: "Raleway, sans-serif" },
      { name: "Times New Roman", family: "'Times New Roman', serif" },
      { name: "Georgia", family: "Georgia, serif" },
      { name: "Garamond", family: "Garamond, serif" },
      { name: "Baskerville", family: "Baskerville, serif" },
      { name: "Playfair Display", family: "'Playfair Display', serif" },
      { name: "Merriweather", family: "Merriweather, serif" },
      { name: "Dancing Script", family: "'Dancing Script', cursive" },
      { name: "Pacifico", family: "'Pacifico', cursive" },
      { name: "Sacramento", family: "'Sacramento', cursive" },
    ];
  }
};

const CustomFont = ({ theme, default_theme, fontColor }) => {
  const language = localStorage.getItem("language");
  const data = getFontData(language);

  const userId = parseInt(sessionStorage.getItem("userId"));
  const { t } = useTranslation("global");
  const rootStyles = getComputedStyle(document.documentElement);
  const fontfamily = rootStyles.getPropertyValue('--common-font-size').trim();
  const [fontStyle, setFontStyle] = useState(fontfamily);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await api.get(`/themes/${userId}`);
        setSettings(response.data);
        setFontStyle(response.data.font_family);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };
    fetchThemes();
  }, [userId]);

  const updateTheme = async (updatedTheme) => {
    try {
      await api.put(`/themes/${userId}`, updatedTheme);
    } catch (error) {
      console.error("Error updating theme:", error);
    }
  };

  useEffect(() => {
    document.documentElement.style.setProperty("--font-family", fontStyle);
    if (settings) {
      const updatedTheme = { ...settings, font_family: fontStyle };
      updateTheme(updatedTheme);
    }
  }, [fontStyle, settings]);

  useEffect(() => {
    if (default_theme) {
      setFontStyle("Arial, sans-serif");
      document.documentElement.style.setProperty("--font-family", "Arial, sans-serif");

      if (settings) {
        const updatedTheme = {
          ...settings,
          font_family: "Arial, sans-serif",
        };
        updateTheme(updatedTheme);
      }
    }
  }, [default_theme, settings]);

  const handleCustomFont = (e) => {
    setFontStyle(e.target.value);
  };

  return (
    <div className="language-preference">
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "start",
        }}
      >
        <Typography className="common-font-style">
          {t("settings.customFont")}
        </Typography>
      </div>
      <div
        style={{
          width: "70%",
          display: "flex",
          justifyContent: "start",
          gap: "20px",
        }}
      >
        <Select
          value={fontStyle}
          onChange={handleCustomFont}
          sx={{
            backgroundColor: "var(--text-field)",
            minHeight: "32px",
            maxHeight: "40px",
            width: 300,
            fontFamily: "var(--font-family)",
            color: fontColor,
            fontSize: "var(--font-size)",
            "& .MuiSvgIcon-root": {
              color: fontColor,
            },
            "& .MuiInputBase-input": {
              color: fontColor,
            }
          }}
          inputProps={{
            style: {
              minHeight: "15px",
            },
          }}
          MenuProps={Style.MenuProps()}
        >
          {data.map((font) => (
            <MenuItem
              sx={{ fontSize: "var(--font-size)", fontFamily: font.family, color:fontColor }}
              key={font.name}
              value={font.family}
            >
              {font.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default CustomFont;
