import { Paper } from "@mui/material";
import React, { useState } from "react";
import Extension from "../../common/Extension";
import Loading from "../../common/Loading";
import TotalCollected from "./TotalCollected";
import ManagerContainer from "./container/ManagerContainer";

const ManagerDashboard = () => {
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const [loading, setLoading] = useState(false);
  if (role_id !== 2 || !role_id) {
    Extension.logout();
  }

  return (
    <Paper elevation={3} className="man_dashboard-wrapper" variant="outlined">
      <Loading open={loading} />
      <TotalCollected />
      <ManagerContainer
        handleLoadingON={(data) => setLoading(data)}
        handleLoadingOff={(data) => setLoading(data)}
      />
    </Paper>
  );
};

export default ManagerDashboard;
