import { AddOutlined, Settings } from "@mui/icons-material";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import HelpProvider from "../HelpProvider";

const drawerWidth = 350;
const SettingsNavBar = ({
  generalref,
  additemRef,
  expenditureTypeRef,
  children,
  scrollToSelection,
  onClose,
  handleHelpProvider,
}) => {
  // const { userCredentails, setUserCredentails } =
  //   useContext(UserDetailsContext);
  const { t } = useTranslation("global");
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [helpOpen, setHelpOpen] = useState(false);
  const role_id = parseInt(sessionStorage.getItem("roleId"));

  const Settings_List = [
    {
      text: "General",
      name: "general",
      icon: <Settings />,
      ref: generalref,
    },
    {
      text: "Add Items",
      name: "additem",
      icon: <AddOutlined />,
      ref: additemRef,
    },
    {
      text: "Add Expenditure Type",
      name: "addExpenditureType",
      icon: <AddOutlined />,
      ref: expenditureTypeRef,
    },
    // {
    //   text: "Help",
    //   name: "help",
    //   icon: <InfoOutlined />,
    //   path: "/help",
    // },
  ];

  const Manager_Settings_List = [
    {
      text: "General",
      name: "general",
      icon: <Settings />,
      ref: generalref,
    },
    {
      text: "Add Expenditure Type",
      name: "addExpenditureType",
      icon: <AddOutlined />,
      ref: expenditureTypeRef,
    },
    // {
    //   text: "Help",
    //   name: "help",
    //   icon: <InfoOutlined />,
    //   path: "/help",
    // },
  ];

  const Super_Admin_List = [
    {
      text: "General",
      name: "general",
      icon: <Settings />,
      ref: generalref,
    },
    // {
    //   text: "Help",
    //   name: "help",
    //   icon: <InfoOutlined />,
    //   path: "/help",
    // },
  ];

  const handleNavigate = (item) => {
    setHelpOpen(true);
    // Navigate(item.path);
  };

  const handleHome = () => {
    setHelpOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setLastScrollY(scrollPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Box sx={{ display: "flex" }}>
      {helpOpen ? (
        <div style={{ width: "100%" }}>
          <HelpProvider open={helpOpen} setHelpVisible={handleHome} />
        </div>
      ) : (
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              borderRight: "2px solid black",
              height: "75vh",
              "& .MuiDrawer-paper": {
                width: drawerWidth,
              },
            }}
            variant="permanent"
            anchor="left"
          >
            {/* <Divider /> */}
            <List>
              {role_id === 1
                ? Settings_List.map((item, index) => (
                    <React.Fragment key={index}>
                      {index === 0 && <></>}
                      <ListItem key={index} disablePadding>
                        <ListItemButton
                          sx={{ float: item.name === "help" ? "bottom" : "" }}
                          onClick={
                            item.name === "help"
                              ? () => handleNavigate(item)
                              : () => scrollToSelection(item.ref)
                          }
                        >
                          <ListItemIcon
                            sx={{ color: "var(--font-color) !important" }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <Typography
                            component={"div"}
                            sx={{
                              fontFamily: "var(--font-family) !important",
                              fontSize: "var(--font-size) !important",
                            }}
                          >
                            {t(`settings.${item.name}`)}
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))
                : role_id === 2
                ? Manager_Settings_List.map((item, index) => (
                    <React.Fragment key={index}>
                      {index === 0 && <></>}
                      <ListItem key={index} disablePadding>
                        <ListItemButton
                          sx={{
                            float: item.name === "help" ? "bottom" : "",
                          }}
                          onClick={
                            item.name === "help"
                              ? () => handleNavigate(item)
                              : () => scrollToSelection(item.ref)
                          }
                        >
                          <ListItemIcon
                            sx={{ color: "var(--font-color) !important" }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <Typography
                            component={"div"}
                            sx={{
                              fontFamily: "var(--font-family) !important",
                              fontSize: "var(--font-size) !important",
                            }}
                          >
                            {t(`settings.${item.name}`)}
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))
                : role_id === 3
                ? Super_Admin_List.map((item, index) => (
                    <React.Fragment key={index}>
                      {index === 0 && <></>}
                      <ListItem key={index} disablePadding>
                        <ListItemButton
                          sx={{
                            float: item.name === "help" ? "bottom" : "",
                          }}
                          onClick={
                            item.name === "help"
                              ? () => handleNavigate(item)
                              : () => scrollToSelection(item.ref)
                          }
                        >
                          <ListItemIcon
                            sx={{ color: "var(--font-color) !important" }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <Typography
                            component={"div"}
                            sx={{
                              fontFamily: "var(--font-family) !important",
                              fontSize: "var(--font-size) !important",
                            }}
                          >
                            {t(`settings.${item.name}`)}
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))
                : null}
            </List>
            {/* <Divider orientation="vertical" flexItem/> */}
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: `var(--commonpaper-background)`, p: 3 }}
          >
            <main>{children}</main>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SettingsNavBar;
