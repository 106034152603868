import {
  CalendarMonth,
  ExpandLess,
  ExpandMore,
  Logout,
} from "@mui/icons-material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Avatar,
  Badge,
  Box,
  Collapse,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import { styled, useTheme } from "@mui/material/styles";
import CryptoJS from "crypto-js";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  MdAccountBalanceWallet,
  MdAccountBox,
  MdAddHomeWork,
  MdAddToQueue,
  MdAssignment,
  MdAssuredWorkload,
  MdCurrencyExchange,
  MdDashboard,
  MdDesktopWindows,
  MdDonutSmall,
  MdGavel,
  MdInventory,
  MdListAlt,
  MdOutlineReceiptLong,
  MdPersonAdd,
  MdReceipt,
  MdSettings,
  MdSummarize,
} from "react-icons/md";
import { TiThList } from "react-icons/ti";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Notification from "../Pages/Notification/Notification";
import api from "../api/http-common";
import LoanIcon from "../assets/GoldSilver.png";
import Extension from "./Extension";
import ItemRateFixation from "./ItemRateFixation";
// import SettingsComp from "../Pages/Help/Settings/SettingsComp";
import Logger from "./Logger";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "#6ca0dc",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
    backgroundColor: "#6ca0dc",
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    backgroundColor: "#6ca0dc",
  }),
}));

function JSLAppBar({ children }) {
  // const { userCredentails, usetUserCredentails } =
  //   useContext(UserDetailsContext);

  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();
  const location = useLocation();
  const showAppBar = location.pathname;
  const logger = new Logger({ level: "log" });

  // Dailag open close function call
  const [dropDownMenu, setDropDownMenu] = useState({
    master: false,
    reports: false,
  });

  // const [profile, setProfile] = useState(null);
  // const role_id = userCredentails.userdata.role_id;
  // const [activeSubMenu, setActiveSubMenu] = useState("Overview");
  // const [activeMainMenu, setActiveMainMenu] = useState("");
  // const roleId = parseInt(userCredentails.userdata.role_id);
  // const entityId = userCredentails.entitydata.entity_id;
  // const user_id = userCredentails.userdata.id;
  // const entityName = userCredentails.entitydata.name;
  // const entityName_tm = userCredentails.entitydata.name_tn;
  // const UserName =
  //   userCredentails.userdata.first_name + userCredentails.userdata.last_name;
  // const UserName_tn =
  //   userCredentails.userdata.first_name_tn +
  //   userCredentails.userdata.last_name_tn;
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const userId = parseInt(sessionStorage.getItem("userId"));
  const [activeSubMenu, setActiveSubMenu] = useState("Overview");
  const [activeMainMenu, setActiveMainMenu] = useState("");
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const entityId = sessionStorage.getItem("entityId");
  const entityName = sessionStorage.getItem("entityName");
  const entityName_tm = sessionStorage.getItem("entityName_tm");
  const UserName = sessionStorage.getItem("userName");
  const UserName_tn = sessionStorage.getItem("userName_tn");
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isScreenLarge = useMediaQuery(theme.breakpoints.down("lg"));
  const localChecked = localStorage.getItem("language");
  const [entityDetails, setEntityDetails] = useState([]);
  const [isChecked, setIsChecked] = useState(localChecked);
  const [profile, setProfile] = useState(null);

  const rootStyles = getComputedStyle(document.documentElement);
  const fontfamily = rootStyles.getPropertyValue('--common-font-size').trim();
  const fontsize = rootStyles.getPropertyValue('--font-family').trim();
  const body = document.querySelector('body');
  const currentTheme = body.getAttribute('data-theme');
  const color = rootStyles.getPropertyValue('--app-bar-color').trim();
  const headerfont = rootStyles.getPropertyValue('--header-font-color').trim();
  const m1color = rootStyles.getPropertyValue('--menu-color-1').trim();
  const m2color = rootStyles.getPropertyValue('--menu-color-2').trim();
   const font = currentTheme=='dark'?"#ffffff":"#000000"
  const [fontFamily, setFontFamily] = useState(fontfamily);
  const [fontSize, setFontSize] = useState(fontsize);
  const [appBarColor, setAppBarColor] = useState(color);
  const [color1, setColor1] = useState(m1color);
  const [color2, setColor2] = useState(m2color);
  const [appTheme, setAppTheme] = useState(currentTheme);
  const [fontColor, setFontColor] = useState(font);
  const [headerFontColor, setHeaderFontColor] = useState(headerfont);

  const [open, setOpen] = useState(false);
  const menuActiveBGColor = "";

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await api.get(`/themes/${userId}`);
        setFontFamily(response.data.font_family);
        setFontSize(response.data.font_size);
        setAppBarColor(response.data.app_bar_color);
        setColor1(response.data.menu_color_1);
        setColor2(response.data.menu_color_2);
        setAppTheme(response.data.theme);
        setFontColor(response.data.font_color);
        setHeaderFontColor(response.data.header_font_color);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchThemes();
  }, [userId]);

  // Toggle the clicked menu value
  const handleMenuClick = (menuKey) => {
    const newMenuState = Object.fromEntries(
      Object.keys(dropDownMenu).map((key) => [key, false])
    );
    // Toggle the clicked menu value
    newMenuState[menuKey] = !dropDownMenu[menuKey];

    setDropDownMenu(newMenuState);
  };

  const hanldeNavigate = (url, activeSubMenu, menuKey) => {
    if (menuKey === undefined) setActiveMainMenu("");
    setActiveSubMenu(activeSubMenu); // set the active for menu
    sessionStorage.setItem("activeSubMenu", activeSubMenu);
    sessionStorage.setItem(
      "activeMainMenu",
      menuKey !== undefined ? menuKey : ""
    );
    if (menuKey === undefined) {
      sessionStorage.setItem("activeMainMenu", "");
      const newMenuState = Object.fromEntries(
        Object.keys(dropDownMenu).map((key) => [key, false])
      );
      // Toggle the clicked menu value
      newMenuState[menuKey] = false;

      setDropDownMenu(newMenuState);
    }
    navigate(url);
    setOpen(false);
  };

  useEffect(() => {
    // if (role_id === 1) {
    //   setRoleName("ADMIN");
    // } else if (role_id === 2) {
    //   setRoleName("MANAGER");
    // }

    const getProfile = async () => {
      // It's for checking the market rate updated
      try {
        logger.log("Initiate Fetch user");
        const res = await api.get(`users/${userId}`);
        setProfile(res.data.image);
        if (roleId === 3) {
          return;
        }
        logger.log("Fetch user Successfully");
        logger.log("Initiate Fetch entity data");
        await api
          .get(`/entity/${entityId}`)
          .then((res) => {
            setEntityDetails(res.data);
          })
          .catch((err) => logger.log(err));
        logger.log("Fetch entity data Successfully");
        logger.log("Initiate Fetch current item rate");
        await api
          .get(`loan/current-item-rate/${entityId}`)
          .then((res) => res.data)
          .catch((error) => {
            Swal.fire({
              title:
                roleId === 1
                  ? "Market rate has not been updated!"
                  : "Market rate has not been updated. Please contact the admin!",
              icon: "error",
              // text: "Please contact with admin!",
              showCancelButton: false,
              confirmButtonText: "Ok",
              confirmButtonColor: "#1976d2",
            });
          });
        logger.log("Fetch current item rate Successfully");
      } catch (error) {
        logger.log(error);
      }
    };
    getProfile();
  }, []);

  //GET the current date
  const [showItemRateFixation, setShowItemRateFixation] = useState(false);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleImageClick = () => {
    setShowItemRateFixation(true);
  };

  const handleCloseItemRateFixation = () => {
    setShowItemRateFixation(false);
  };

  const handleLogout = async () => {
    try {
      const refresh = sessionStorage.getItem(`refresh`);
      const Token = CryptoJS.AES.decrypt(refresh, "jsl_graspear").toString(
        CryptoJS.enc.Utf8
      );
      logger.log("Initiate logout");
      await api.post("/logout", { refresh_token: Token });
      logger.log("logout Successfully");
      sessionStorage.clear();
      navigate("/");
    } catch (error) {
      logger.log(error);
    }
  };

  // Menu list sytle
  const menuStyle = {
    listItem: {
      backgroundColor: `var(--app-bar)`,
      display: "block",
      mb: 0.5,
    },
    dropDownButton1: {
      // color: "#FFFFF0",
      minHeight: 35,
      display: "flex",
      flexDirection: "column",
      justifyContent: open ? "initial" : "left",
      ml: -0.5,
    },
    listButton: {
      color: "#FFFFFF",
      fontSize: "30px",
      minHeight: 30,
      justifyContent: open ? "initial" : "center",
      px: 2.5,
    },
    listIcon: {
      // color: "var(--header-font-color)",
      minWidth: 0,
      fontSize: "40px",
      mr: open ? 1.5 : "auto",
      justifyContent: "center",
    },
    listIcon1: {
      color: "var(--menu-color-2)",
      minWidth: 0,
      fontSize: "40px",
      mr: open ? 1.5 : "auto",
      justifyContent: "center",
    },
    listIcon2: {
      color: "var(--menu-color-1)",
      minWidth: 0,
      fontSize: "40px",
      mr: open ? 1.5 : "auto",
      justifyContent: "center",
    },
    listIcon3: {
      color: "var(--menu-color-2)",
      minWidth: 0,
      fontSize: "40px",
      mr: open ? 1.5 : "auto",
      justifyContent: "center",
    },
    listIcon4: {
      color: "var(--menu-color-1)",
      minWidth: 0,
      fontSize: "40px",
      mr: open ? 1.5 : "auto",
      justifyContent: "center",
    },
    listText: {
      fontSize: "30px",
      opacity: open ? 1 : 0,
    },
  };

  const AdminList = [
    {
      text: "Admin",
      menu: [
        {
          style: "listIcon1",
          text: "Dashboard",
          icon: <MdDashboard />,
          path: "/dashboard",
          subMenu: null,
        },
        {
          // text: "Master",
          style: "listIcon2",
          icon: <MdDesktopWindows />,
          menuKey: "Master",
          dropDownMenu1: dropDownMenu.Master,
          subMenu: [
            { text: "User Master", icon: <MdAccountBox />, path: "/user" },
            { text: "Loan Scheme Master", icon: <TiThList />, path: "/scheme" },
            {
              text: "Customer Master",
              icon: <MdPersonAdd />,
              path: "/customer",
            },
            // {
            //   text: "Alerts",
            //   icon: <MdChatBubble />,
            //   path: "/UnderDevelopment",
            // },
          ],
        },
        {
          style: "listIcon1",
          icon: <MdDonutSmall />,
          menuKey: "Loanprocess",
          dropDownMenu1: dropDownMenu.Loanprocess,
          subMenu: [
            {
              text: "Loan Creation",
              icon: <MdAddHomeWork />,
              path: "/createLoan",
              subMenu: null,
            },
            {
              text: "Loan List",
              icon: <MdListAlt />,
              path: "/loanprocess",
              subMenu: null,
            },
            {
              text: "Receipt Generation",
              icon: <MdReceipt />,
              path: "/receipt",
              subMenu: null,
            },
            {
              text: "Auction Loan Proccess",
              icon: <MdGavel />,
              path: "/auction",
              subMenu: null,
            },
          ],
        },
        {
          style: "listIcon2",
          icon: <MdAssuredWorkload />,
          menuKey: "Administration",
          dropDownMenu1: dropDownMenu.Administration,
          subMenu: [
            {
              text: "Loan Transaction",
              icon: <MdAccountBalanceWallet />,
              path: "/transactions",
              subMenu: null,
            },
            {
              text: "Inventory",
              icon: <MdInventory />,
              path: "/inventory",
              subMenu: null,
            },
            {
              text: "Expenditure",
              icon: <MdCurrencyExchange />,
              path: "/expenditure",
              subMenu: null,
            },
          ],
        },
        {
          style: "listIcon1",
          icon: <MdOutlineReceiptLong />,
          menuKey: "Report",
          dropDownMenu1: dropDownMenu.Report,
          subMenu: [
            {
              text: "Stock Report",
              icon: <MdAssignment />,
              path: "/stockreport",
              subMenu: null,
            },
            {
              text: "Summary",
              icon: <MdSummarize />,
              path: "/summary",
              subMenu: null,
            },
            {
              text: "Fund Transaction",
              icon: <MdCurrencyExchange />,
              path: "/fundtransaction",
              subMenu: null,
            },
          ],
        },
      ],
    },
  ];

  const ManagerList = [
    {
      text: "Manager",
      menu: [
        {
          style: "listIcon1",
          text: "Dashboard",
          icon: <MdDashboard />,
          path: "/managerdashboard",
          subMenu: null,
        },
        {
          style: "listIcon2",
          icon: <MdDesktopWindows />,
          menuKey: "Master",
          dropDownMenu1: dropDownMenu.Master,
          subMenu: [
            {
              text: "Customer Master",
              icon: <MdPersonAdd />,
              path: "/customer",
            },
          ],
        },
        {
          style: "listIcon1",
          icon: <MdDonutSmall />,
          menuKey: "Loanprocess",
          dropDownMenu1: dropDownMenu.Loanprocess,
          subMenu: [
            {
              text: "Loan Creation",
              icon: <MdAddHomeWork />,
              path: "/createLoan",
              subMenu: null,
            },
            {
              text: "Loan List",
              icon: <MdListAlt />,
              path: "/loanprocess",
              subMenu: null,
            },
            {
              text: "Receipt Generation",
              icon: <MdReceipt />,
              path: "/receipt",
              subMenu: null,
            },
            {
              text: "Auction Loan Proccess",
              icon: <MdGavel />,
              path: "/auction",
              subMenu: null,
            },
          ],
        },
        {
          style: "listIcon2",
          icon: <MdAssuredWorkload />,
          menuKey: "Administration",
          dropDownMenu1: dropDownMenu.Administration,
          subMenu: [
            {
              text: "Loan Transaction",
              icon: <MdAccountBalanceWallet />,
              path: "/transactions",
              subMenu: null,
            },
            {
              text: "Inventory",
              icon: <MdInventory />,
              path: "/inventory",
              subMenu: null,
            },
            {
              text: "Expenditure",
              icon: <MdCurrencyExchange />,
              path: "/expenditure",
              subMenu: null,
            },
          ],
        },
        {
          style: "listIcon1",
          icon: <MdOutlineReceiptLong />,
          menuKey: "Report",
          dropDownMenu1: dropDownMenu.Report,
          subMenu: [
            {
              text: "Stock Report",
              icon: <MdAssignment />,
              path: "/stockreport",
              subMenu: null,
            },
            {
              text: "Summary",
              icon: <MdSummarize />,
              path: "/summary",
              subMenu: null,
            },
            {
              text: "Fund Transaction",
              icon: <MdCurrencyExchange />,
              path: "/fundtransaction",
              subMenu: null,
            },
          ],
        },
      ],
    },
  ];

  const SuperAdminList = {
    text: "SuperAdmin",
    menu: [
      {
        style: "listIcon2",
        text: "Tenant Master",
        icon: <MdListAlt />,
        path: "/tenantmaster",
        subMenu: null,
      },
      {
        style: "listIcon1",
        text: "Tenant Creation",
        icon: <MdAddToQueue />,
        path: "/tenantcreation",
        subMenu: null,
      },
    ],
  };

  const handleChangeLanguage = (language) => {
    // const language = checked ? "tm" : "en";
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
    setIsChecked(language);
  };

  useEffect(() => {
    handleChangeLanguage(isChecked);
  }, [isChecked]);

  // Assuming the path contains the page route
  const currentPath = location.pathname;

  const findTextFromPath = (currentPath, menuList) => {
    for (const menu of menuList) {
      if (menu.menu) {
        for (const item of menu.menu) {
          if (item.path === currentPath) {
            return item.text.toUpperCase();
          }
          if (item.subMenu) {
            for (const subItem of item.subMenu) {
              if (subItem.path === currentPath) {
                return subItem.text.toUpperCase();
              }
            }
          }
        }
      }
    }
    return null;
  };

  const Settings_List = {
    text: "Admin",
    menu: [
      {
        text: "Settings",
        icon: <MdSettings />,
        path: "/settings",
        subMenu: null,
      },
    ],
  };

  const toCamelCase = (text) => {
    const words = text.split(" ");
    const camelCasedWords = words.map((word) => {
      // Capitalize each word
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return camelCasedWords.join(" ");
  };

  const pathText =
    currentPath === "/"
      ? ""
      : toCamelCase(
          findTextFromPath(
            currentPath,
            AdminList.concat([Settings_List, SuperAdminList, ...ManagerList])
          )
        );

  const calLang = Extension.languageBasedValue("en-US", "ta-US");
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const handleNotificationClick = () => {
    setShowNotifyModal(true);
  };
  const entity_id = sessionStorage.getItem("entityId");
  const [statusCount, setStatusCount] = useState("");
  const [auctionCount, setAuctionCount] = useState("");

  async function notificationStatus() {
    try {
      if (roleId !== 1) {
        return;
      }
      logger.log("Initiate Fetch loans");
      const response = await api
        .get(`/loan/loans/${entity_id}`)
        .catch((err) => logger.log(err));
      logger.log("Fetch loans Successfully");
      const filterResponse = response.data.filter(
        (item) => item.status === "Pending"
      );
      const data = filterResponse.map((item) => item.status === "Pending");
      const pendingData = data?.length;
      logger.log("Initiate Fetch auction eligible list");
      const auction = await api
        .get(`/loan/AuctionEligibleList/${entity_id}`)
        .catch((err) => logger.log(err));
      logger.log("Fetch auction eligible list Successfully");
      const auctionData = auction?.data?.length;
      setStatusCount(pendingData);
      setAuctionCount(auctionData);
    } catch (err) {
      logger.log(err);
    }
  }
  useEffect(() => {
    notificationStatus();
  }, []);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    // Update CSS variable when fontStyle changes
    document.documentElement.style.setProperty("--font-family",fontFamily);
    document.documentElement.style.setProperty("--font-size",fontSize);
    document.documentElement.style.setProperty("--app-bar-color",appBarColor);
    document.documentElement.style.setProperty("--header-font-color", headerFontColor);
    document.documentElement.style.setProperty("--menu-color-1",color1);
    document.documentElement.style.setProperty("--menu-color-2",color2 );
    document.documentElement.style.setProperty("--font-color", fontColor);
    document.querySelector("body").setAttribute("data-theme", appTheme);
  }, [
    appBarColor,
    appTheme,
    color1,
    color2,
    fontColor,
    fontFamily,
    fontSize,
    headerFontColor,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "var(--back-parent-color)",
        color: "var(--header-font-color)",
      }}
    >
      <CssBaseline />
      <Toaster />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: `var(--app-bar-color)`,
          color: "var(--header-font-color)",
        }}
        PaperProps={{ sx: { backgroundColor: `var(--app-bar-color)` } }}
        open={open}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "100%",
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-size)",
            color: "var(--header-font-color)",
          }}
        >
          <Avatar
            src={entityDetails?.image || LoanIcon}
            alt="logo"
            // variant="rounded"
            sx={{ width: 50, height: 50, ml: 1 }}
          />
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              textTransform: "uppercase  ",
              ml: 3,
              borderRight: "0.001em solid ivory",
              padding: "0.1em",
              fontFamily: "var(--font-family)",
              fontSize: 26,
              color: "var(--header-font-color)",
            }}
          >
            {Extension.languageBasedValue(entityName, entityName_tm)}
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 50,
              ml: 2,
              fontFamily: "var(--font-family)",
              fontSize: "var(--common-font-size)",
              color: "var(--header-font-color)",
            }}
          >
            {t(`appbar.${pathText}`)}
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {!isScreenSmall && (
              <Box sx={{ display: "flex", mr: 1 }}>
                {!isScreenLarge && <></>}
                {role_id === 1 ? (
                  <>
                    <Box className="headerContent" sx={{ mr: 2 }}>
                      <Tooltip
                        className="common-font-style"
                        sx={{
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--font-size)",
                        }}
                        title={t("appbar.showitemfix")}
                      >
                        <CurrencyRupeeIcon
                          sx={{
                            width: "45px",
                            height: "45px",
                            cursor: "pointer",
                          }}
                          onClick={handleImageClick}
                        />
                      </Tooltip>
                      {showItemRateFixation && (
                        <ItemRateFixation
                          onClose={handleCloseItemRateFixation}
                        />
                      )}
                    </Box>
                    <Typography
                      variant="subtitle"
                      component="div"
                      sx={{
                        display: "flex",
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--common-font-size)",
                        margin: "auto",
                        alignItems: "center",
                      }}
                    >
                      <CalendarMonth />
                      {currentTime.toLocaleTimeString(calLang, options)}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    variant="subtitle"
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--common-font-size)",
                      margin: "auto",
                      alignItems: "center",
                    }}
                  >
                    <CalendarMonth sx={{ marginBottom: "5px" }} />
                    {currentTime.toLocaleTimeString(calLang, options)}
                  </Typography>
                )}
              </Box>
            )}
            <div>
              {roleId === 1 ? (
                <Tooltip title={t("notification.notify")}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      marginLeft: "-15px",
                    }}
                    onClick={handleNotificationClick}
                    role="button"
                  >
                    {statusCount > 0 || auctionCount > 0 ? (
                      <Badge
                        badgeContent={statusCount + auctionCount}
                        color="error"
                        overlap="circular"
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        sx={{ marginLeft: "10px" }}
                      >
                        <div>
                          <NotificationsIcon
                            onClick={handleOpenUserMenu}
                            style={{ fontSize: "40px" }}
                          />
                        </div>
                      </Badge>
                    ) : (
                      <div>
                        <NotificationsIcon
                          onClick={handleOpenUserMenu}
                          style={{ fontSize: "40px" }}
                        />
                      </div>
                    )}
                  </div>
                </Tooltip>
              ) : (
                <div></div>
              )}
            </div>

            {showNotifyModal && (
              <Notification
                anchorElUser={anchorElUser}
                handleCloseUserMenu={handleCloseUserMenu}
              />
            )}
            <Typography
              variant="subtitle"
              component="div"
              sx={{
                display: "flex",
                fontFamily: "var(--font-family)",
                fontSize: "var(--common-font-size)",
                margin: "auto",
                alignItems: "center",
                textTransform: "uppercase",
              }}
            >
              {Extension.languageBasedValue(UserName, UserName_tn)}
            </Typography>
            <Avatar
              id="avatar-image"
              alt={Extension.languageBasedValue(UserName, UserName_tn)}
              src={profile}
              sx={{ margin: "left", width: 40, height: 40, mr: 3 }}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        // anchor="right"
        open={open}
        sx={{ backgroundColor: `var(--app-bar-color)` }}
        PaperProps={{ sx: { backgroundColor: `var(--app-bar-color)` } }}
      >
        <DrawerHeader></DrawerHeader>
        <Divider />
        {roleId === 1 ? (
          <List>
            {AdminList.map((row) =>
              row.menu.map((item, index) =>
                item.subMenu && !item.nonVisible ? (
                  <>
                    <ListItem
                      key={index}
                      sx={{
                        ...menuStyle.listItem,
                        color:
                          activeMainMenu === item.menuKey
                            ? "var(--app-bar-color)"
                            : "",
                        background:
                          activeMainMenu === item.menuKey
                            ? menuActiveBGColor
                            : "",
                      }}
                      disablePadding
                    >
                      <Tooltip
                        title={
                          item.text
                            ? t(`appbar.${item.text}`)
                            : t(`appbar.${item.menuKey}`)
                        }
                        placement="left"
                      >
                        <ListItemButton
                          onClick={() => handleMenuClick(item.menuKey)}
                          sx={{
                            ...menuStyle.dropDownButton1,
                            color:
                              index % 2 === 0
                                ? "var(--menu-color-2)"
                                : "var(--menu-color-1)",
                          }}
                        >
                          <ListItemIcon sx={menuStyle[item.style]}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.text}
                            sx={menuStyle.listText}
                          />
                          {item.dropDownMenu1 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>
                    <Collapse
                      in={item.dropDownMenu1}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {item.subMenu.map((subMenu, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              ...menuStyle.listItem,
                              background:
                                activeSubMenu === subMenu.text
                                  ? menuActiveBGColor
                                  : "",
                            }}
                            onClick={() =>
                              hanldeNavigate(
                                subMenu.path,
                                subMenu.text,
                                item.menuKey
                              )
                            }
                            disablePadding
                          >
                            <Tooltip
                              title={t(`appbar.${subMenu.text}`)}
                              placement="left"
                            >
                              <ListItemButton sx={menuStyle.dropDownButton2}>
                                <ListItemIcon sx={menuStyle[item.style]}>
                                  {subMenu.icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={subMenu.text}
                                  sx={menuStyle.listText}
                                />
                              </ListItemButton>
                            </Tooltip>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  !item.nonVisible && (
                    <ListItem
                      key={index}
                      disablePadding
                      onClick={() =>
                        hanldeNavigate(item.path, item.text, item.menuKey)
                      }
                      sx={{
                        ...menuStyle.listItem,
                        background:
                          activeSubMenu === item.text ? menuActiveBGColor : "",
                      }}
                    >
                      <Tooltip
                        title={t(`appbar.${item.text}`)}
                        placement="left"
                      >
                        <ListItemButton sx={menuStyle.listButton}>
                          <ListItemIcon sx={menuStyle[item.style]}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.text}
                            sx={menuStyle.listText}
                          />
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>
                  )
                )
              )
            )}
          </List>
        ) : roleId === 2 ? (
          <List>
            {ManagerList.map((row) =>
              row.menu.map((item, index) =>
                item.subMenu && !item.nonVisible ? (
                  <>
                    <ListItem
                      key={index}
                      sx={{
                        ...menuStyle.listItem,
                        background:
                          activeMainMenu === item.menuKey
                            ? menuActiveBGColor
                            : "",
                      }}
                      disablePadding
                    >
                      <Tooltip
                        title={
                          item.text
                            ? t(`appbar.${item.text}`)
                            : t(`appbar.${item.menuKey}`)
                        }
                        placement="left"
                      >
                        <ListItemButton
                          onClick={() => handleMenuClick(item.menuKey)}
                          sx={{
                            ...menuStyle.dropDownButton1,
                            color:
                              index % 2 === 1
                                ? "var(--menu-color-1)"
                                : "var(--menu-color-2)",
                          }}
                        >
                          <ListItemIcon sx={menuStyle[item.style]}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.text}
                            sx={menuStyle.listText}
                          />
                          {item.dropDownMenu1 ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>
                    <Collapse
                      in={item.dropDownMenu1}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {item.subMenu.map((subMenu, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              ...menuStyle.listItem,
                              background:
                                activeSubMenu === subMenu.text
                                  ? menuActiveBGColor
                                  : "",
                            }}
                            onClick={() =>
                              hanldeNavigate(
                                subMenu.path,
                                subMenu.text,
                                item.menuKey
                              )
                            }
                            disablePadding
                          >
                            <Tooltip
                              title={t(`appbar.${subMenu.text}`)}
                              placement="left"
                            >
                              <ListItemButton sx={menuStyle.dropDownButton2}>
                                <ListItemIcon sx={menuStyle[item.style]}>
                                  {subMenu.icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={subMenu.text}
                                  sx={menuStyle.listText}
                                />
                              </ListItemButton>
                            </Tooltip>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  !item.nonVisible && (
                    <ListItem
                      key={index}
                      disablePadding
                      onClick={() =>
                        hanldeNavigate(item.path, item.text, item.menuKey)
                      }
                      sx={{
                        ...menuStyle.listItem,
                        background:
                          activeSubMenu === item.text ? menuActiveBGColor : "",
                      }}
                    >
                      <Tooltip
                        title={t(`appbar.${item.text}`)}
                        placement="left"
                      >
                        <ListItemButton sx={menuStyle.listButton}>
                          <ListItemIcon sx={menuStyle[item.style]}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.text}
                            sx={menuStyle.listText}
                          />
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>
                  )
                )
              )
            )}
          </List>
        ) : roleId === 3 ? (
          <List>
            {SuperAdminList.menu.map((listitem) => (
              <ListItem
                key={listitem.text}
                sx={{
                  ...menuStyle.listItem,
                  background:
                    activeMainMenu === listitem.menuKey
                      ? menuActiveBGColor
                      : "",
                  marginLeft: "-5px",
                }}
                disablePadding
                onClick={() =>
                  hanldeNavigate(listitem.path, listitem.text, listitem.menuKey)
                }
              >
                <Tooltip title={t(`appbar.${listitem.text}`)} placement="left">
                  <ListItemButton sx={menuStyle.dropDownButton2}>
                    <ListItemIcon sx={menuStyle[listitem.style]}>
                      {listitem.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={listitem.text}
                      sx={menuStyle.listText}
                    />
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        ) : null}
        <Divider
          sx={{ backgroundColor: "var(--font-color)", height: 10, ml: "0px" }}
        />
        <List component="div" style={{ marginLeft: "-5px" }} disablePadding>
          {Settings_List.menu.map((settings) => (
            <ListItem
              key={settings.text}
              sx={{
                ...menuStyle.listItem,
                background:
                  activeSubMenu === settings.text ? menuActiveBGColor : "",
              }}
              onClick={() =>
                hanldeNavigate(settings.path, settings.text, settings.menuKey)
              }
              disablePadding
            >
              <Tooltip title={t(`appbar.${settings.text}`)} placement="left">
                <ListItemButton sx={menuStyle.dropDownButton2}>
                  <ListItemIcon sx={menuStyle.listIcon2}>
                    {settings.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={settings.text}
                    sx={menuStyle.listText}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
          <ListItem sx={{ ...menuStyle.listItem }} disablePadding>
            <Tooltip title={t("appbar.logout")} placement="left">
              <ListItemButton onClick={handleLogout} sx={menuStyle.listButton}>
                <ListItemIcon sx={{ ...menuStyle.listIcon1, ml: "5px" }}>
                  <Logout style={{ fontSize: 35 }} />
                </ListItemIcon>
                <ListItemText primary="Logout" sx={menuStyle.listText} />
              </ListItemButton>
            </Tooltip>
          </ListItem>
        </List>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <main>{children}</main>
        <footer
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem",
            paddingBottom: "-10px",
            color: `var(--app-bar-color)`,
          }}
        >
          <div> Copyright &copy; 2024</div>
          <div>Graspear Solutions Private Limited</div>
          <div>All Rights Reserved</div>
        </footer>
      </Box>
    </Box>
  );
}
export default JSLAppBar;
