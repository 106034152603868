import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 25,
  height: 25,
  fontFamily: "var(--font-family)",
  fontSize: "var(--font-size)",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#EBEBEB",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#FFFFFF",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
  "&:before": {
    display: "block",
    width: 25,
    height: 25,
    borderRadius: "50%",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
    backgroundImage:
      "radial-gradient(#1285CD, #2778AA , #296D97 ,#486A83 60%,transparent 10%)",
    content: '""',
  },
});

function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const language = localStorage.getItem("language");


export default function CustomRadioButton(props) {
  const { t,i18n } = useTranslation("global");
  const currentLang = i18n.language;
  const { array, value, onChange } = props;
  return (
    <FormControl className="common-font-style">
      <RadioGroup
        defaultChecked={array[0]}
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%" /* mb: 1 */,
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
        }}
        value={value}
        onChange={onChange}
      >
        {array.map((array) => (
          <FormControlLabel
            key={array.name}
            className="common-font-style"
            sx={{
              ml: language === "tm" ? 1 : 2,
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
              "& .MuiFormControlLabel-label": {
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              },
            }}
            value={array.value}
            control={<BpRadio />}
            label={array.name}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
