import {
  ArrowCircleLeftOutlined,
  Save,
  Send,
  SendOutlined,
} from "@mui/icons-material";
import { Box, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Chrono } from "react-chrono";
import { useTranslation } from "react-i18next";
import api from "../../../api/http-common";
import CustomCommonButton from "../../../common/Components/CustomCommonButton";
import CustomRadioButton from "../../../common/Components/CustomRadioButton";
import Dropdown from "../../../common/Components/Dropdown";
import Textfield from "../../../common/Components/TextField";
import Extension from "../../../common/Extension";
import Loading from "../../../common/Loading";
import SnackBar from "../../../common/SnackBar";
import Styles from "../../../common/Styles";
import Logger from "../../../common/Logger";
import "./Interaction.css";
import SMS_API_FUNCTION from "../../../common/SMS_API_FUNCTION";

const Interactions = [
  { name: "Through SMS", name_tn: "SMS மூலம்" },
  { name: "Through Calls", name_tn: "தொலைபேசி அழைப்புகள் மூலம்" },
  { name: "Through Mails", name_tn: "அஞ்சல்கள் மூலம்" },
  { name: "Direct Visit", name_tn: "நேரடி வருகை" },
];
const RADIO_ARRAY = [
  {
    name: "Yes",
    value: true,
  },
  {
    name: "No",
    value: false,
  },
];

const logger = new Logger({ level: "log" });
const Interaction = (props) => {
  const { loan_id, open, items, onClose, fetchData, user } = props;
  const { t } = useTranslation("global");
  const user_id = parseInt(sessionStorage.getItem("userId"));
  const [loading, setLoading] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [remarks_tn, setRemarks_tn] = useState("");
  const [interaction_type, setInteraction_type] = useState("Through Calls");
  const [responses, setResponses] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState(0);

  console.log(props);

  const inputFields = [
    {
      name: "interaction_type",
      field: "select",
      title: "interaction_type",
      value: interaction_type,
      onChange: (e) => setInteraction_type(e.target.value),
      gridSize: { xs: 1, sm: 1, md: 0.5, lg: 0.5 },
      data: [],
    },
    {
      name: "response",
      field: "radio",
      type: "click",
      value: responses,
      onChange: (e) => setResponses(e.target.value),
      gridSize: { xs: 1, sm: 1, md: 0.5, lg: 0.5 },
      title: "Response",
    },
    {
      name: "remarks",
      field: "input",
      value: remarks,
      onChange: (e) => handleRemarksChange(e),
      gridSize: { xs: 1, sm: 1, md: 0.5, lg: 0.5 },
      type: "text",
      title: "Remarks",
    },
    {
      name: "remarks_tn",
      field: "input",
      value: remarks_tn,
      onChange: (e) => setRemarks_tn(e.target.value),
      gridSize: { xs: 1, sm: 1, md: 0.5, lg: 0.5 },
      type: "text",
      title: "கருத்துக்கள்",
    },
  ];



  const VALUE_SMS = [
    {
      name: "Scheme is going to expire",
      name_tn: "திட்டம் காலாவதியாகப் போகிறது",
      value: 1,
    },
    {
      name: "The scheme has been expired",
      name_tn: "திட்டம் காலாவதியாகிவிட்டது",
      value: 2,
    },
    {
      name: "The loan is going to get auctioned",
      name_tn: "கடன் ஏலம் போகப் போகிறது",
      value: 3,
    },
  ];

  const details = items.map((res) => {
    const thisUser = user.find(
      (item) => parseInt(item.id) === parseInt(res.interaction_made_by)
    );
    return {
      title: Extension.formatAMPMDate(res.interaction_made_on),
      cardTitle: Extension.languageBasedValue(
        res.interaction_type,
        res.interaction_type_tn
      ),
      icon: "interaction_type",
      url: "",
      cardSubtitle: `By ${Extension.languageBasedValue(
        `${thisUser.first_name} ${thisUser.last_name}`,
        `${thisUser.first_name_tn} ${thisUser.last_name_tn}`
      )}`,
      cardDetailedText: Extension.languageBasedValue(
        res.remarks,
        res.remarks_tn
      ),
    };
  });

  const handleForm = async () => {
    if (
      interaction_type === "Through SMS"
        ? selectedCheckbox
        : remarks && interaction_type && remarks_tn
    ) {
      try {
        handleLoadingOpen();
        let interaction_type_tn = Interactions.find(
          (item) => item.name === interaction_type
        ).name_tn;
        const remark = VALUE_SMS.find(
          (item) => item.value === selectedCheckbox
        )?.name;
        const remark_tn = VALUE_SMS.find(
          (item) => item.value === selectedCheckbox
        )?.name_tn;
        const data = {
          remarks: remarks || remark,
          response: selectedCheckbox ? false : responses,
          interaction_type,
          interaction_type_tn,
          loan: loan_id.loan_id,
          interaction_made_on: Extension.getCurrentDateTime(),
          interaction_made_by: user_id,
          remarks_tn: remarks_tn || remark_tn,
        };
        await api
          .post(`/loan/interaction`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        SnackBar.toastMsg("success", "Remarks Added Successfully");
        if (interaction_type === "Through SMS" && selectedCheckbox) {
          SMS_API_FUNCTION.loanCreationSMS_API(
            loan_id?.item.loan_num,
            loan_id?.item.loan_date,
            loan_id?.item.customer_mobile_num,
            169260,
            loan_id?.item.loan_amount
          );
        }
        clearTextFields();
        onClose();
        fetchData(loan_id.loan_id);
      } catch (error) {
        logger.log(error);
      } finally {
        handleLoadingClose();
      }
    } else {
      SnackBar.toastMsg(
        "error",
        interaction_type === "Through SMS"
          ? "Please Select The Message"
          : "Please Fill All The Fields"
      );
      return;
    }
  };

  const clearTextFields = () => {
    setRemarks("");
    setRemarks_tn("");
    setResponses(false);
    setInteraction_type("");
  };

  useEffect(() => {
    handleLoadingClose();
  }, []);

  const handleCheckboxChange = (value) => {
    setSelectedCheckbox(value === selectedCheckbox ? null : value);
  };

  const handleLoadingOpen = () => {
    setLoading(true);
  };

  const handleRemarksChange = (e) => {
    Extension.translateInput(e, setRemarks_tn);
    setRemarks(e.target.value);
  };

  const handlePopupClose = () => {
    onClose();
    clearTextFields();
  };

  const handleLoadingClose = () => {
    setLoading(false);
  };

  const loan_date = new Date(loan_id?.item.loan_date);
  loan_date.setMonth(loan_date.getMonth() + loan_id?.item.duration_month);

  const isExpired = loan_date.toISOString() <= new Date().toISOString();

  return (
    <Modal
      open={open}
      onClose={handlePopupClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={Styles.widthHeightPopupStyles()}>
        <Loading open={loading} />
        <Box
          sx={{
            backgroundColor: "var(--app-bar-color)",
            p: 1,
            borderRadius: "10px 10px 0 0",
            position: "sticky",
            top: 0,
            zIndex: 999,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "var(--header-font-color)",
              fontSize: "var(--font-size)",
              fontFamily: "var(--font-family)",
            }}
          >
            <ArrowCircleLeftOutlined
              onClick={onClose}
              sx={{ cursor: "pointer", marginRight: "1rem" }}
            />
            <Typography
              className="loan_number_style"
              sx={{
                color: "var(--header-font-color)",
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              }}
            >
              {loan_id.loan_num}
            </Typography>
          </Box>
          <Typography
            className="cus_style"
            sx={{
              color: "var(--header-font-color)",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
              marginLeft: "auto",
            }}
          >
            {loan_id.customer_name} - {loan_id.customer_num}
          </Typography>
        </Box>

        <Box className="interaction-component">
          {items.length > 0 ? (
            <Chrono
              items={details}
              mode={"VERTICAL_ALTERNATING"}
              className="chorno-style"
              theme={{
                primary: "#155ECC",
                secondary: "#155ECC",
                cardBgColor: "#F2F2F2",
                titleColor: "black",
              }}
              cardHeight={70}
              hideControls
              activeItemIndex={null}
              disableToolbar={true}
              highlightCardsOnHover={true}
              disableInteraction={true}
              disableClickOnCircle={true}
            />
          ) : (
            <p className="follow-text-style">{t("followup.no_followup")}</p>
          )}
        </Box>
        <div className="interaction-form">
          <h5 className="add_remarks">{t("followup.add_remarks")}</h5>
          <Grid container spacing={2} columns={1}>
            {inputFields.map((field, index) => (
              <Grid
                item
                sm={field.gridSize.sm}
                md={field.gridSize.md}
                lg={field.gridSize.lg}
                xs={field.gridSize.xs}
                key={index}
              >
                {field.field === "input" &&
                  interaction_type !== "Through SMS" && (
                    <Textfield
                      label={field.title}
                      name={field.name}
                      required={true}
                      multiline={true}
                      type={field.type}
                      value={field.value}
                      rows={2}
                      onChange={field.onChange}
                    />
                  )}

                {field.field === "radio" &&
                  interaction_type !== "Through SMS" && (
                    <div className="radio_response">
                      <Typography className="common-font-style">
                        {t("followup.give_response")}
                      </Typography>
                      <CustomRadioButton
                        array={RADIO_ARRAY}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </div>
                  )}
                {field.field === "select" && (
                  <Dropdown
                    label={t("followup.interaction_type")}
                    array={Interactions}
                    name={field.name}
                    onChange={field.onChange}
                    menuValue={"name"}
                    display={Extension.languageBasedValue("name", "name_tn")}
                  />
                )}
              </Grid>
            ))}
            {loan_id?.item.schemeExpiry &&
              interaction_type === "Through SMS" && (
                <Grid item sm={1} md={0.5} lg={0.5} xs={1}>
                  <div>
                    <input
                      type="checkbox"
                      style={{ margin: "10px" }}
                      checked={selectedCheckbox === 1}
                      onChange={() => handleCheckboxChange(1)}
                      disabled={
                        loan_date.toISOString() <= new Date().toISOString()
                      }
                    />

                    <Typography variant="h7" className="common-font-style"  style={{ color: isExpired ? 'grey' : 'initial' }}>
                      Scheme is going to expire
                    </Typography>
                  </div>
                </Grid>
              )}
            {loan_id?.item.schemeExpiry &&
              interaction_type === "Through SMS" && (
                <Grid item sm={1} md={0.5} lg={0.5} xs={1}>
                  <div>
                    <input
                      type="checkbox"
                      style={{ margin: "10px" }}
                      checked={selectedCheckbox === 2}
                      onChange={() => handleCheckboxChange(2)}
                      disabled={
                        loan_date.toISOString() >= new Date().toISOString()
                      }
                    />

                    <Typography variant="h7" className="common-font-style" style={{ color: !isExpired ? 'grey' : 'initial' }}>
                      Scheme has been expired
                    </Typography>
                  </div>
                </Grid>
              )}
            {!loan_id?.item.schemeExpiry &&
              interaction_type === "Through SMS" && (
                <Grid item sm={1} md={0.5} lg={0.5} xs={1}>
                  <div>
                    <input
                      type="checkbox"
                      style={{ margin: "10px" }}
                      checked={selectedCheckbox === 3}
                      onChange={() => handleCheckboxChange(3)}
                    />

                    <Typography variant="h7" className="common-font-style">
                      Loan is going to get auctioned
                    </Typography>
                  </div>
                </Grid>
              )}
            <div className="save-btn-style">
              <CustomCommonButton
                icon={interaction_type === "Through SMS" ? <Send /> : <Save />}
                onClick={handleForm}
              />
            </div>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
};

export default Interaction;
