import { Typography } from "@mui/material";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import "react-color-palette/css";
import { useTranslation } from "react-i18next";
import "./ColorPicking.css";
import { ColorPicker } from "antd";
import { FormatColorFill } from "@mui/icons-material";
import api from "../../../api/http-common";

const colors = [
  {
    name: "blue",
    value: "#3c5d90",
    fontColor: "white",
    menu_bar_1: "#4682B4",
    menu_bar_2: "#0080FF",
    complementary_1: "#D0F0C0",
    complementary_2: "#A0B0D0",
    background: "white",
  },
  {
    name: "blue2",
    value: "#0E3B82",
    fontColor: "white",
    menu_bar_1: "#4169E1",
    menu_bar_2: "#0000FF",
    complementary_1: "#D0D0F0",
    complementary_2: "#B0C0D0",
    background: "white",
  },
  {
    name: "purple",
    value: "#550737",
    fontColor: "white",
    menu_bar_1: "#F67599",
    menu_bar_2: "#FFE3EB",
    complementary_1: "#D0B0F0",
    complementary_2: "#C0A0D0",
    background: "black",
  },
  {
    name: "indigo",
    value: "#464D66",
    fontColor: "#CFCFCF",
    menu_bar_1: "#4B0082",
    menu_bar_2: "#483D8B",
    complementary_1: "#E0513E",
    complementary_2: "#CFCFCF",
  },
  {
    name: "black",
    value: "#0D1117",
    fontColor: "#CCCCCC",
    menu_bar_1: "#808080",
    menu_bar_2: "#696969",
    complementary_1: "#CCCCCC",
    complementary_2: "#A0A0A0",
  },
];

const dark_data = [
  {
    name: "blue",
    value: "#3c5d90",
    fontColor: "white",
    menu_bar_1: "#4682B4",
    menu_bar_2: "#0080FF",
    complementary_1: "#D0F0C0",
    complementary_2: "#A0B0D0",
    background: "white",
  },
  {
    name: "brown",
    value: "#EEEBE2",
    fontColor: "#080909",
    menu_bar_1: "#A52A2A",
    menu_bar_2: "#8B0000",
    complementary_1: "#080909",
    complementary_2: "#C87E74",
    background: "black",
  },
  {
    name: "red",
    value: "#ffcdd2",
    fontColor: "#353535",
    menu_bar_1: "#FF6347",
    menu_bar_2: "#FF0000",
    complementary_1: "#5C1E5F",
    complementary_2: "#282528",
    background: "black",
  },
  {
    name: "gray",
    value: "#DBDBDB",
    fontColor: "#353535",
    menu_bar_1: "#808080",
    menu_bar_2: "#696969",
    complementary_1: "#FFA390",
    complementary_2: "#454545",
    background: "white",
  },
];

const getThemeColors = (theme) => {
  return theme === "dark" ? dark_data : colors;
};

const ColorPicking = ({ mode, handleTheme, handleDefaultTheme, themeChanged, setThemeChanged ,loader , setLoader}) => {
  const { t } = useTranslation("global");
  const rootStyles = getComputedStyle(document.documentElement);
  const fontfamily = rootStyles.getPropertyValue('--common-font-size').trim();
  const fontsize = rootStyles.getPropertyValue('--font-family').trim();
  const body = document.querySelector('body');
  const currentTheme = body.getAttribute('data-theme');
  const color = rootStyles.getPropertyValue('--app-bar-color').trim();
  const headerfont = rootStyles.getPropertyValue('--header-font-color').trim();
  const m1color = rootStyles.getPropertyValue('--menu-color-1').trim();
  const m2color = rootStyles.getPropertyValue('--menu-color-2').trim();
  
  
    const font = currentTheme=='dark'?"#ffffff":"#000000"
  const userId = parseInt(sessionStorage.getItem("userId"));
  const [themes, setThemes] = useState([]);
  const [settings, setSettings] = useState(null);
  const [fontFamily, setFontFamily] = useState(fontfamily);
  const [fontSize, setFontSize] = useState(fontsize);
  const [appBarColor, setAppBarColor] = useState(color);
  const [color1, setColor1] = useState(m1color);
  const [color2, setColor2] = useState(m2color);
  const [appTheme, setAppTheme] = useState(currentTheme);
  const [fontColor, setFontColor] = useState(font);
  const [headerFontColor, setHeaderFontColor] = useState(headerfont);

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await api.get(`/themes/${userId}`);
        setSettings(response.data);
        setFontFamily(response.data.font_family);
        setFontSize(response.data.font_size);
        setAppBarColor(response.data.app_bar_color);
        setColor1(response.data.menu_color_1);
        setColor2(response.data.menu_color_2);
        setAppTheme(response.data.theme);
        setFontColor(response.data.font_color);
        setHeaderFontColor(response.data.header_font_color);
        setLoader(false)
      } catch (error) {
        console.error('Error fetching themes:', error);
      }
    };
    fetchThemes();
  }, [userId]);

  const hexString = useMemo(
    () => (typeof appBarColor === "string" ? appBarColor : appBarColor?.toHexString()),
    [appBarColor]
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setAppTheme(getThemeColors(appTheme));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [appTheme]);

  const updateTheme = async (updatedTheme) => {
    try {
      await api.put(`/themes/${userId}`, updatedTheme);
    } catch (error) {
      console.error('Error updating theme:', error);
    }
  };

  useEffect(() => {
    handleDefaultTheme(false);
    setThemeChanged(false);
    setLoader(true)
    console.log("@@@@@@@@@@@@@*************@@@@@@@@@@@")
    const themeColors = themes.find((item) => item.value === hexString);

    document.documentElement.style.setProperty("--app-bar-color", hexString);
    document.querySelector("body").setAttribute("data-theme", mode);
    document.documentElement.style.setProperty("--header-font-color", themeColors?.fontColor ?? color1);
    document.documentElement.style.setProperty("--menu-color-1", themeColors?.complementary_1 ?? color1);
    document.documentElement.style.setProperty("--menu-color-2", themeColors?.complementary_2 ?? color2);

    const newFontColor = mode === "dark" ? "#FFFFFF" : "#000000";
    document.documentElement.style.setProperty("--font-color", newFontColor);
    setFontColor(newFontColor);

    const updatedTheme = {
      ...settings,
      font_color: newFontColor,
      app_bar_color: hexString,
      theme:mode,
      menu_color_1: themeColors?.complementary_1 ?? color1,
      menu_color_2: themeColors?.complementary_2 ?? color2,
      header_font_color: themeColors?.fontColor ?? color1,
    };

    updateTheme(updatedTheme);

    setAppBarColor(hexString);
    setColor1(themeColors?.complementary_1 ?? color1);
    setColor2(themeColors?.complementary_2 ?? color2);
    setHeaderFontColor(themeColors?.fontColor ?? color1);
    setLoader(false)
  }, [appBarColor, hexString, color1, color2, mode, themes]);

  const handleColorChange = (value) => {
    setAppBarColor(value);
  };

  const handleDefaultColor = useCallback(() => {
    setLoader(true)
    const defaultTheme = {
      font_color: "#000000",
      app_bar_color: "#3c5d90",
      theme: "light",
      font_family: "Arial, sans-serif",
      font_size: "18px",
      menu_color_1: "#c2ddfb",
      menu_color_2: "#f3c0af",
      header_font_color: "#ffffff",
    };

    document.documentElement.style.setProperty("--font-color", defaultTheme.font_color);
    document.documentElement.style.setProperty("--common-font-size", defaultTheme.font_size);
    document.documentElement.style.setProperty("--font-family", defaultTheme.font_family);
    document.documentElement.style.setProperty("--app-bar-color", defaultTheme.app_bar_color);
    document.documentElement.style.setProperty("--menu-color-1", defaultTheme.menu_color_1);
    document.documentElement.style.setProperty("--menu-color-2", defaultTheme.menu_color_2);
    document.documentElement.style.setProperty("--header-font-color", defaultTheme.header_font_color);

    document.querySelector("body").setAttribute("data-theme", "light");

    updateTheme({ ...settings, ...defaultTheme });
    setAppTheme("light");
    handleTheme("light");
    setAppBarColor(defaultTheme.app_bar_color);
    setLoader(false)
  }, [handleTheme, settings]);

  useEffect(() => {
    setThemes(mode === "dark" ? dark_data : colors);
  }, [mode]);

  return (
    <div className="language-preference">
      <div style={{ width: "30%", display: "flex", justifyContent: "start" }}>
        <Typography className="common-font-style">{t("settings.color")}</Typography>
      </div>
      <div className="color-container">
        <button onClick={handleDefaultColor} className={"color-circle-default"}>
          <div className="icon"></div>
        </button>
        {themes?.map((color, index) => (
          <button key={index} onClick={() => handleColorChange(color.value)} className={`color-circle ${color.name}`}>
            <div className="icon"></div>
          </button>
        ))}
        <ColorPicker
          format="hex"
          defaultValue={appBarColor}
          value={appBarColor}
          onChange={setAppBarColor}
          // showText={() => (
          //   <FormatColorFill style={{ color: "black" }} />
          // )}
          size="large"
        />
      </div>
    </div>
  );
};

export default ColorPicking;
