import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import { Box, ListSubheader } from "@mui/material";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Extension from "../../common/Extension";
import HelpScreen from "./HelpScreen";
import HelpScreen_tm from "./HelpScreen_tm";
import { useTranslation } from "react-i18next";

const HelpProvider = ({ setHelpVisible }) => {
  const { t } = useTranslation("global");

  // const handleBack=()
  return (
    <div /*  style={{display:'flex'}} */>
      <Box
        sx={{
          bgcolor: "background.paper",
          color: "black",
          position: "sticky",
          display: "flex",
          m: "auto",
        }}
      >
        <ArrowCircleLeftOutlined onClick={() => setHelpVisible(false)} />
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ fontWeight: "var(--font-weight)" }}
        >
          {t("appbar.Settings")}
        </Typography>
      </Box>
      {Extension.languageBasedValue(<HelpScreen />, <HelpScreen_tm />)}
      {/* </HeaderComponent> */}
    </div>
  );
};

export default HelpProvider;
