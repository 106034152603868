/* eslint-disable no-sparse-arrays */
const dayInMonthComparator = (v1, v2) => {
  const date1 = parseDate(v1);
  const date2 = parseDate(v2);
  return date1 - date2;
};

const AmountComparator = (v1, v2) => {
  const amt1 = parseAmount(v1);
  const amt2 = parseAmount(v2);

  return amt1 - amt2;
};

const nameComaparator = (v1, v2) => {
console.log("@@@@@@@@@@@@@@@@@@@")
return v2-v1;
};

// Helper function to parse the amount
const parseAmount = (amountString) => {
  // Extract the part after the rupee symbol and remove commas
  const cleanAmount = amountString.replace(/[^\d.]/g, '').replace(/,/g, '').trim();
  // Convert the cleaned string to a float
  return parseFloat(cleanAmount);
};

// Helper function to parse dates in dd-mm-yyyy format
const parseDate = (dateString) => {
  const [day, month, year] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};
export const AppData = {
  user: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "image",
      headerName: "profile",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.image,
    },
    {
      field: "loginid",
      headerName: "Login ID",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "firstname",
      headerName: "First Name",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "lastname",
      headerName: "Last Name",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.role,
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "moreinfo",
      headerName: "More Info",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.moreinfo,
    },
  ],
  customer: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "image",
      headerName: "profile",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.image,
    },
    {
      field: "customerId",
      headerName: "Customer ID",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.customerId,
    },
    {
      field: "customername",
      headerName: "Customer Name",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.customername,
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.branch,
    },
    {
      field: "occupation",
      headerName: "Occupation",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.occupation,
    },
    {
      field: "mbno",
      headerName: "Mobile Number",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.mbno,
    },
    {
      field: "moreinfo",
      headerName: "More Info",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.moreinfo,
    },
  ],
  loanprocess: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.id,
    },
    {
      field: "loan_date",
      headerName: "Loan Date",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.loan_date,
      sortComparator: dayInMonthComparator,
    },
    {
      field: "loan_number",
      headerName: "Loan No.",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.loan_number,
      
    },
    {
      field: "cus_login_id",
      headerName: "Customer ID",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.cus_login_id,
      sortComparator:(v1, v2) => {
        // Extract the children from the React elements
        const extractValue = (element) => {
          // Check if the element has props and children
          if (element && element.props && element.props.children) {
            return element.props.children;
          }
          return ''; // Return an empty string or a default value if extraction fails
        };
    
        const value1 = extractValue(v1);
        const value2 = extractValue(v2);
    
        // Convert the values to strings for comparison, if they aren't already strings
        const strValue1 = String(value1);
        const strValue2 = String(value2);
    
        // Compare the extracted string values
        if (strValue1 < strValue2) return -1;
        if (strValue1 > strValue2) return 1;
        return 0;
      }
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.customer_name,
      sortComparator:(v1, v2) => {
        // Extract the children from the React elements
        const extractValue = (element) => {
          // Check if the element has props and children
          if (element && element.props && element.props.children) {
            return element.props.children;
          }
          return ''; // Return an empty string or a default value if extraction fails
        };
    
        const value1 = extractValue(v1);
        const value2 = extractValue(v2);
    
        // Convert the values to strings for comparison, if they aren't already strings
        const strValue1 = String(value1);
        const strValue2 = String(value2);
    
        // Compare the extracted string values
        if (strValue1 < strValue2) return -1;
        if (strValue1 > strValue2) return 1;
        return 0;
      }
    },
    {
      field: "scheme_name",
      headerName: "Scheme Name",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.scheme_name,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.amount,
      sortComparator: AmountComparator,
      
    },
    // {
    //   field: "loan_amount",
    //   headerName: "Principle Amount",
    //   flex: 1,
    //   headerClassName: "grid-header",
    // },
    // {
    //   field: "interestrateMin",
    //   headerName: "Interest Rate(Min)",
    //   flex: 1,
    //   headerClassName: "grid-header",
    // },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.created_by,
    },
    {
      field: "approved_by",
      headerName: "Approved By",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.approved_by,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.status,
    },
    {
      field: "moreinfo",
      headerName: "More Info",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.moreinfo,
    },
  ],
  scheme: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "schemename",
      headerName: "Scheme Name",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "item_category",
      headerName: "Item Category",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "duration_month",
      headerName: "Duration",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "interestrateMin",
      headerName: "Interest Rate(Min)",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "interestrateMax",
      headerName: "Interest Rate(Max)",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "moreinfo",
      headerName: "More Info",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.moreinfo,
    },
  ],
  inventory: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "loanDate",
      headerName: "Loan Date",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "itemName",
      headerName: "Item Name",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "itemCat",
      headerName: "Item Category",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "qty",
      headerName: "Item Count",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "gross",
      headerName: "Gross Weight(gram)",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "net",
      headerName: "Net Weight(gram)",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "rates",
      headerName: "Gold/Silver Rate",
      flex: 1.5,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "cusId",
      headerName: "Customer ID",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.cusId,
    },
    {
      field: "cusName",
      headerName: "Customer Name",
      flex: 1.5,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.cusName,
    },
    {
      field: "branch",
      headerName: "Branch",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "loanId",
      headerName: "Loan ID",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.loanId,
    },
  ],
  transaction: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "loan_date",
      headerName: "Loan Date",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "cusID",
      headerName: "Customer ID",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.cusID,
      sortComparator:(v1, v2) => {
        // Extract the children from the React elements
        const extractValue = (element) => {
          // Check if the element has props and children
          if (element && element.props && element.props.children) {
            return element.props.children;
          }
          return ''; // Return an empty string or a default value if extraction fails
        };
    
        const value1 = extractValue(v1);
        const value2 = extractValue(v2);
    
        // Convert the values to strings for comparison, if they aren't already strings
        const strValue1 = String(value1);
        const strValue2 = String(value2);
    
        // Compare the extracted string values
        if (strValue1 < strValue2) return -1;
        if (strValue1 > strValue2) return 1;
        return 0;
      }
    },
    {
      field: "cusName",
      headerName: "Customer Name",
      flex: 1.5,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.cusName,
      sortComparator:(v1, v2) => {
        // Extract the children from the React elements
        const extractValue = (element) => {
          // Check if the element has props and children
          if (element && element.props && element.props.children) {
            return element.props.children;
          }
          return ''; // Return an empty string or a default value if extraction fails
        };
    
        const value1 = extractValue(v1);
        const value2 = extractValue(v2);
    
        // Convert the values to strings for comparison, if they aren't already strings
        const strValue1 = String(value1);
        const strValue2 = String(value2);
    
        // Compare the extracted string values
        if (strValue1 < strValue2) return -1;
        if (strValue1 > strValue2) return 1;
        return 0;
      }
    },
    {
      field: "schemename",
      headerName: "Scheme Name",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "loanId",
      headerName: "Loan ID",
      flex: 1,
      headerClassName: "grid-header",
      
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "moreinfo",
      headerName: "More Info",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.moreinfo,
    },
  ],
  transactionDetails: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "bal_pa",
      headerName: "Balance PA.",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "interest_rate",
      headerName: "Interest Rate",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "due_amount",
      headerName: "Interest Amount",
      flex: 1.5,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "paid_on",
      headerName: "Paid On",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator:dayInMonthComparator,
    },
    {
      field: "payment_mode",
      headerName: "Payment Mode",
      flex: 1,
      headerClassName: "grid-header",
    },

    {
      field: "trans_or_recipt_id",
      headerName: "Trans/Receipt ID",
      flex: 1,
      headerClassName: "grid-header",
    },
  ],

  transactionManagerDetails: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "bal_pa",
      headerName: "Balance PA.",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "interest_rate",
      headerName: "Interest Rate",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "paid_on",
      headerName: "Paid On",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "payment_mode",
      headerName: "Payment Mode",
      flex: 1,
      headerClassName: "grid-header",
    },

    {
      field: "trans_or_recipt_id",
      headerName: "Trans/Receipt ID",
      flex: 1,
      headerClassName: "grid-header",
    },
  ],
  transactionClosureDetails: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "bal_pa",
      headerName: "Balance PA.",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "due_amount",
      headerName: "Closure Amount",
      flex: 1.5,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "paid_on",
      headerName: "Paid On",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "payment_mode",
      headerName: "Payment Mode",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "trans_or_recipt_id",
      headerName: "Trans/Receipt ID",
      flex: 1,
      headerClassName: "grid-header",
    },
  ],

  receipt: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "paid_on",
      headerName: "Paid On",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "pay_off_amount",
      headerName: "Pay Off Amount",
      flex: 1.5,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "cus_id",
      headerName: "Customer ID",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "cus_name",
      headerName: "Customer Name",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "loan_id",
      headerName: "Loan ID",
      flex: 1,
      headerClassName: "grid-header",
    },
  ],
  Collections: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "paid_on",
      headerName: "Date",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "pay_off_amount",
      headerName: "Amount",
      flex: { md: 1, sm: 0.3, lg: 0.5, xs: 0.5 },
      // width: 150,
      headerClassName: "grid-header",
      align: "left",
      headerAlign: "left",
      cellClassName: "grid-cell-left",
      renderCell: (params) => params.row.pay_off_amount,
      sortComparator:  (v1, v2) => {
        const parseAmount = (value) => {
          console.log(value.props.children)
          value =value.props.children[0]+value.props.children[3]
          const cleanedValue = value.replace(/[,]/g, '').trim();
          return parseFloat(cleanedValue) || 0;
        };
        return parseAmount(v1) -parseAmount(v2);
      }
    },
    {
      field: "cus_id",
      headerName: "Customer ID",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.cus_id,
      sortComparator:(v1, v2) => {
        // Extract the children from the React elements
        const extractValue = (element) => {
          // Check if the element has props and children
          if (element && element.props && element.props.children) {
            return element.props.children;
          }
          return ''; // Return an empty string or a default value if extraction fails
        };
    
        const value1 = extractValue(v1);
        const value2 = extractValue(v2);
    
        // Convert the values to strings for comparison, if they aren't already strings
        const strValue1 = String(value1);
        const strValue2 = String(value2);
    
        // Compare the extracted string values
        if (strValue1 < strValue2) return -1;
        if (strValue1 > strValue2) return 1;
        return 0;
      }
    },
    {
      field: "cus_name",
      headerName: "Customer Name",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.cus_name,
      sortComparator:  (v1, v2) => {
        // Function to extract text content from a React element
        const extractText = (element) => {
          // Check if the element has props and children
          if (element && element.props && element.props.children) {
            return String(element.props.children).trim(); // Convert to string and trim any whitespace
          }
          return ''; // Return an empty string or a default value if extraction fails
        };
    
        const text1 = extractText(v1);
        const text2 = extractText(v2);
    
        // Compare the extracted text values alphabetically
        if (text1 < text2) return -1;
        if (text1 > text2) return 1;
        return 0;
      }
    },
    {
      field: "loan_id",
      headerName: "Loan ID",
      flex: 1,
      headerClassName: "grid-header",
    },
  ],

  summary: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "loan_number",
      headerName: "Loan Number",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "loan_amount",
      headerName: "Principle Amount",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "loan_date",
      headerName: "Loan Date",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "scheme_name",
      headerName: "Scheme Name",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "mobile_no",
      headerName: "Mobile No.",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "adhaar_no.",
      headerName: "Adhaar No.",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "total_interest_paid.",
      headerName: "Total Interest Paid",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "dueDate.",
      headerName: "Last Due Date",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "balance_closure.",
      headerName: "Balance Closure Amount",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "no_of_item.",
      headerName: "No Of Item",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "net_weight.",
      headerName: "Net Wt",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "planned_closure_date.",
      headerName: "Plan Close Date",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "actual_closure_date.",
      headerName: "Actual Closed Date",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "rate",
      headerName: "Item Rate(gram)",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "status",
      headerName: "Status ",
      flex: 1,
      headerClassName: "grid-header",
    },
  ],

  receiptHeaderInputFields1: [
    {
      title: "cus_id",
      type: "text",
      inputType: "input",
      name: "cus_login_id",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
    },
    {
      title: "cus_name",
      type: "text",
      inputType: "input",
      name: "cus_name",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
    },
    {
      title: "loan number",
      type: "text",
      inputType: "input",
      name: "loan_number",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
    },
    {
      title: "principle amount",
      type: "text",
      inputType: "input",
      name: "loan_amount",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
      ComboFixWord: "\u20B9",
      position: "start",
      sortComparator: AmountComparator,
    },
    {
      title: "loan date",
      type: "date",
      inputType: "input",
      name: "created_on",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
      sortComparator: dayInMonthComparator,
    },
    {
      title: "scheme_data",
      type: "text",
      inputType: "input",
      name: "scheme_data",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
    },
  ],

  receiptHeaderInputFields2: [
    {
      visible: true,
      title: "balance_pa",
      type: "text",
      inputType: "input",
      name: "current_balance_pa",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
      ComboFixWord: "\u20B9",
      position: "start",
    },
    {
      visible: true,
      title: "time period",
      type: "text",
      inputType: "input",
      name: "min_time_period",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
    },
    ,
    {
      visible: true,
      title: "exceeded period",
      type: "text",
      inputType: "input",
      name: "max_time_period",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
    },
    {
      visible: true,
      title: "interest",
      type: "float",
      inputType: "input",
      name: "interest_percentage",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
      ComboFixWord: "%",
      position: "end",
      clickType: true,
    },
    {
      visible: false,
      title: "interest_rate_method",
      type: "text",
      inputType: "select",
      name: "interest_rate_method",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
      data: [
        { value: "day", text: "Day", text_tn: "நாள்" },
        { value: "week", text: "Weekly", text_tn: "வாரந்தோறும்" },
        { value: "month", text: "Monthly", text_tn: "மாதாந்திர" },
      ],
      display_name: "text",
      value: "value",
      tm: "text_tn",
      clickFunction: `interesMethodChange`,
      clickType: "onChange",
    },
    {
      visible: false,
      title: "additional interest",
      type: "text",
      inputType: "select",
      name: "addtional_interest",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: false,
      data: [
        { value: "true", text: "Yes.", text_tn: "ஆம்" },
        { value: "false", text: "No", text_tn: "இல்லை" },
      ],
      clickFunction: `additionalInterst`,
      clickType: "onChange",
      display_name: "text",
      value: "value",
      tm: "text_tn",
    },
    {
      visible: true,
      title: "postal_charge",
      type: "number",
      inputType: "input",
      name: "postal_charge",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: false,
      ComboFixWord: "\u20B9",
      position: "start",
    },
    {
      visible: true,
      title: "interest_amount",
      type: "number",
      inputType: "input",
      name: "interest_amount",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: true,
      ComboFixWord: "\u20B9",
      position: "start",
      sortComparator: AmountComparator,
    },
    {
      visible: true,
      title: "pay_off_amount",
      type: "number",
      inputType: "input",
      name: "repayment_amount",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: false,
      required: false,
      ComboFixWord: "\u20B9",
      position: "start",
      sortComparator: AmountComparator,
    },
    {
      visible: true,
      title: "payment_mode",
      type: "text",
      inputType: "select",
      name: "payment_mode",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: false,
      display_name: "text",
      value: "value",
      required: true,
      tm: "text_tn",
      data: [
        { text: "Cash", value: "By Cash", text_tn: "பணம்" },
        {
          text: "Account Transfer",
          value: "By Account",
          text_tn: "கணக்கு பரிமாற்றம்",
        },
        { text: "UPI/ Others", value: "UPI/Others", text_tn: "UPI/ மற்றவை" },
      ],
    },
    {
      visible: true,
      title: "trans_or_recipt_id",
      type: "text",
      inputType: "input",
      name: "transaction_id",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: false,
      required: false,
    },
    {
      visible: true,
      title: "paid_on",
      type: "date",
      inputType: "input",
      name: "paid_on",
      gridSize: { xs: 12, sm: 6, md: 4, lg: 2 },
      readOnly: false,
      required: false,
      sortComparator: dayInMonthComparator,
    },
  ],

  // eslint-disable-next-line no-sparse-arrays
  transactionTableHeader: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "trans_seq_num",
      headerName: "Receipt ID",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "paid_date",
      headerName: "Paid Date",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "paid_amount",
      headerName: "Pay Off Amount",
      flex: 1.5,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "interest_amount",
      headerName: "Interest Amount",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    ,
    {
      field: "paid_pa",
      headerName: "Paid Principal Amount",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "balance_pa",
      headerName: "Balance Principle Amount",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "payment_mode",
      headerName: "Payment Mode",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "trans_or_recipt_id",
      headerName: "Transaction ID",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "print",
      headerName: "Action",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.print,
    },
  ],

  transactionManagerClosureDetails: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "bal_pa",
      headerName: "Balance PA.",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "due_amount",
      headerName: "Closure Amount",
      flex: 1.5,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "paid_on",
      headerName: "Paid On",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator:dayInMonthComparator,
    },
    {
      field: "payment_mode",
      headerName: "Payment Mode",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "trans_or_recipt_id",
      headerName: "Trans/Receipt ID",
      flex: 1,
      headerClassName: "grid-header",
    },
  ],

  auction: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "loan_date",
      headerName: "Loan Date",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.loan_date,
      sortComparator: dayInMonthComparator,
    },
    {
      field: "loan_num",
      headerName: "Loan Number",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.loan_num,
    },
    {
      field: "cus_login_id",
      headerName: "Customer ID",
      flex: 1.5,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.cus_login_id,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.customer_name,
    },
    {
      field: "scheme_name",
      headerName: "Scheme Name",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.scheme_name,
    },
    {
      field: "amount",
      headerName: "Loan Amount",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.amount,
      sortComparator: AmountComparator,
    },
    {
      field: "last_paid",
      headerName: "Last Paid",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.last_paid,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.status,
    },
    {
      field: "moreinfo",
      headerName: "More Info",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.moreinfo,
    },
  ],
  auctionmanager: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "loan_date",
      headerName: "Loan Date",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.loan_date,
      sortComparator: dayInMonthComparator,
    },
    {
      field: "loan_num",
      headerName: "Loan Number",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.loan_num,
    },
    {
      field: "cus_login_id",
      headerName: "Customer ID",
      flex: 1.5,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.cus_login_id,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.customer_name,
    },
    {
      field: "scheme_name",
      headerName: "Scheme Name",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.scheme_name,
    },
    {
      field: "amount",
      headerName: "Loan Amount",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.amount,
      sortComparator: AmountComparator,
    },
    {
      field: "last_paid",
      headerName: "Last Paid",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.last_paid,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   headerClassName: "grid-header",
    //   renderCell: (params) => params.row.status,
    // },
    {
      field: "moreinfo",
      headerName: "More Info",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.moreinfo,
    },
  ],
  auctionDashboard: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "loan_date",
      headerName: "Loan Date",
      flex: 0.8,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "loan_num",
      headerName: "Loan Number",
      flex: 0.8,
      headerClassName: "grid-header",
    },
    {
      field: "cus_login_id",
      headerName: "Customer ID",
      flex: 1.5,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.cus_login_id,
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1.5,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.customer_name,
    },
    {
      field: "amount",
      headerName: "Loan Amount",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
    {
      field: "last_paid",
      headerName: "Last Paid",
      flex: 1,
      headerClassName: "grid-header",
    },
  ],

  loan_item: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "item_category",
      headerName: "Item Category",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "item_category_tn",
      headerName: "பொருள் வகை",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "item_name",
      headerName: "Item Name",
      flex: 1.5,
      headerClassName: "grid-header",
    },
    {
      field: "item_name_tn",
      headerName: "பொருளின் பெயர்",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "moreinfo",
      headerName: "More Info",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.moreinfo,
    },
  ],

  Tenant: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.image,
    },
    {
      field: "name",
      headerName: "Tenant Name",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "phone",
      headerName: "Phone no",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "mail1",
      headerName: "mail1",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "moreinfo",
      headerName: "More Info",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.moreinfo,
    },
  ],
  fundtransaction: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "funded_on",
      headerName: "Date",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "amount",
      headerName: "Amount",
      // flex: 1,
      width: 200,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.amount,
      sortComparator:  (v1, v2) => {
        const parseAmount = (value) => {
          value =value.props.children
          const cleanedValue = value.replace(/[,₹\s]/g, '');
          console.log(cleanedValue)
          return parseFloat(cleanedValue) || 0;
        };
        return parseAmount(v1) -parseAmount(v2);
      }
    },
    {
      field: "fund_type",
      headerName: "Fund Type",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "funded_by",
      headerName: "Funded By",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "funded_branch",
      headerName: "Funded Branch",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "remarks",
      headerName: "Remarks",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "total_fund_amount",
      headerName: "total_fund_amount",
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
  ],
  closeInventory: [
    {
      field: "id",
      flex: 0.5,
      cellRenderer: "agGroupCellRenderer",
      headerClass: "header-name",
      headerName: "S.No",
    },
    { field: "loan_date", headerClass: "header-account", flex: 1 },
    {
      field: "loan_number",
      headerClass: "header-minutes",
      flex: 1,
    },
    {
      field: "cus_login_id",
      headerClass: "header-name",
      flex: 1,
    },
    {
      field: "customer_name",
      headerClass: "header-name",
      flex: 1,
    },
    {
      field: "scheme_name",
      headerClass: "header-name",
      flex: 1,
    },
    {
      field: "loan_amount",
      headerClass: "header-name",
      flex: 1,
      sortComparator: AmountComparator,
    },
    {
      field: "grossWeight",
      headerClass: "header-name",
      flex: 1,
    },
    {
      field: "total_net_weight",
      headerClass: "header-name",
      flex: 1,
    },
  ],
  closeInventoryExpand: [
    {
      field: "gold_category",
      headerClassName: "grid-header",
      flex: 1,
    },
    {
      field: "item_name",
      headerClassName: "grid-header",
      flex: 1,
    },
    {
      field: "qty",
      headerClassName: "grid-header",
      flex: 1,
    },
    {
      field: "no_of_stone",
      headerClassName: "grid-header",
      flex: 1,
    },
    {
      field: "gross_weight",
      headerClassName: "grid-header",
      flex: 1,
    },
    {
      field: "net_weight",
      headerClassName: "grid-header",
      flex: 1,
    },
    {
      field: "image",
      headerClassName: "grid-header",
      flex: 1,
    },
  ],
  expenditureType: [
    {
      field: "id",
      headerName: "S.No",
      flex: 0.5,
      headerClassName: "grid-header",
    },
    {
      field: "expenditureType",
      headerName: "Expenditure Type",
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "moreinfo",
      headerName: "More Info",
      flex: 1,
      headerClassName: "grid-header",
      renderCell: (params) => params.row.moreinfo,
    },
  ],
  expenditureData: [
    {
      field: "id",
      headerName: "s.no",
      // width: 100,
      headerClassName: "grid-header",
    },
    {
      field: "expenditureBy",
      headerName: "expenditure_by",
      // width: 250,
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "expenditureType",
      headerName: "expenditure_type",
      // width: 300,
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "description",
      headerName: "description",
      // width: 350,
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "expenditureDate",
      headerName: "date",
      // width: 250,
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: dayInMonthComparator,
    },
    {
      field: "transaction_type",
      headerName: "payment_type",
      // width: 250,
      flex: 1,
      headerClassName: "grid-header",
    },
    {
      field: "amount",
      headerName: "amount",
      // // width: 260,
      flex: 1,
      headerClassName: "grid-header",
      sortComparator: AmountComparator,
    },
  ],
};
