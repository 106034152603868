import { Menu, MenuItem, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../api/http-common";
import Logger from "../../common/Logger";
import { NotificationDetailsContext } from "../../context/NotificationContext";
import "./Notification.css";

const logger = new Logger({ level: "log" });
const Notification = ({ anchorElUser, handleCloseUserMenu }) => {
  const { t } = useTranslation("global");
  const entity_id = sessionStorage.getItem("entityId");
  const [loanDetails, setLoanDetails] = useState([]);
  const [auctiondata, setAuctionData] = useState([]);
  const { setNotifyPendingList } = useContext(NotificationDetailsContext);

  const navigate = useNavigate();

  const handlePendingList = () => {
    if (window.location.pathname !== "/loanprocess") {
      setNotifyPendingList("Pending"); // Set the pending list filter
      navigate("/loanprocess"); // Navigate to the loan process page
    }
    setNotifyPendingList("Pending");
  };
  const handleAuction = () => {
    navigate("/auction");
  };
  const loanStatus = useCallback(async () => {
    try {
      logger.log("Initiate Fetch  loans");
      const response = await api.get(`/loan/loans/${entity_id}`);
      logger.log("Fetch loans Successfully");
      const filterResponse = response.data.filter(
        (item) => item.status === "Pending"
      );
      logger.log("Initiate Fetch auction eligible loans");
      const aution = await api.get(`/loan/AuctionEligibleList/${entity_id}`);
      logger.log("Fetch AuctionEligibleList Successfully");
      const auctionData = aution.data;
      setLoanDetails(filterResponse.length);
      setAuctionData(auctionData.length);
    } catch (err) {
      logger.log(err);
    }
  }, [entity_id]);

  useEffect(() => {
    loanStatus();
  }, [loanStatus]);

  return (
    <>
      {(loanDetails > 0 || auctiondata > 0) && (
        <Menu
          sx={{
            mt: "45px",
            minWidth: 250,
            "& .MuiMenuItem-root": {
              minHeight: "auto",
              minWidth: "200px",
              display: "flex",
              justifyContent: "space-between",
            },
          }}
          id="menu-appbar"
          anchorEl={(loanDetails > 0 || auctiondata > 0) && anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            sx: {
              backgroundColor: "var(--commonpaper-background)",
              color: "var(--font-color)",
            },
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {loanDetails > 0 && (
            <MenuItem onClick={handlePendingList} className="menu-list">
              <Typography
                sx={{
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                }}
                textAlign="left"
              >
                {t("notification.pending_list")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                }}
                textAlign="right"
              >
                {loanDetails}
              </Typography>
            </MenuItem>
          )}
          {auctiondata > 0 && (
            <MenuItem onClick={handleAuction} className="menu-list">
              <Typography
                sx={{
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                }}
                component={"div"}
                textAlign="left"
              >
                {t("notification.auction_list")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                }}
                textAlign="right"
              >
                {auctiondata}
              </Typography>
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};

export default Notification;
