import React, { useRef } from "react";
import "./Help.css";
import HeaderComponent from "./HeaderComponent";
import ScrollToTop from "../FirstPage/scrollOntop";
const HelpScreen_tm = () => {
  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const dashboardRef = useRef(null);
  const userRef = useRef(null);
  const CustomerRef = useRef(null);
  const SchemeRef = useRef(null);
  const AlertRef = useRef(null);
  const loanCreationRef = useRef(null);
  const loanListRef = useRef(null);
  const receiptRef = useRef(null);
  const auctionRef = useRef(null);
  const inventoryRef = useRef(null);
  const transationRef = useRef(null);
  const summaryRef = useRef(null);
  return (
    <div>
      <HeaderComponent
        dashboardRef={dashboardRef}
        summaryRef={summaryRef}
        userRef={userRef}
        SchemeRef={SchemeRef}
        CustomerRef={CustomerRef}
        AlertRef={AlertRef}
        loanCreationRef={loanCreationRef}
        loanListRef={loanListRef}
        receiptRef={receiptRef}
        auctionRef={auctionRef}
        inventoryRef={inventoryRef}
        transationRef={transationRef}
        scrollToSection={scrollToSection}
        children={
          //   <div className="help-component">
          <div className="help-container">
            {/* <ScrollToTop /> */}
            <div className="dashborad-content" ref={dashboardRef}>
              <h2>டாஷ்போர்டு</h2>
              <ul>
                <li>
                  டாஷ்போர்டு கடன்களும் வாடிக்கையாளுக்களும் ஒரு பரிசோதனையை
                  அளிக்கின்றது.
                </li>
                <li>
                  தற்போதை விற்பனையிலுள்ள தங்கமும் வெள்ளியின் பரஸ்பர மதிப்பு
                  காண்பிக்கப்படுகின்றது.
                </li>
                <li>
                  மொத்த கடன் எண்ணிக்கையும், கடன்களின் நிலையும் (திறந்தது,
                  மூடியது, நிராகரிக்கப்பட்டது, கடன் கசக்குவரத்தார்)
                  எண்ணிக்கையும் காண்பிக்கப்படுகின்றது.
                </li>
                <li>
                  கிரெடிட் தொகையை மாதம், வாரம், ஆண்டுகளிலும் காட்டுகின்ற
                  கிளையும், அனைத்து தகவலும்.
                </li>
                <li>
                  கிளைகளின் விலை படி செலவு மாதம், வாரம், ஆண்டுகளிலும்
                  காட்டுகின்ற கிளைகளின் படி செலவுக்குப் பிரிக்குகின்ற சார்டும்.
                </li>
                <li>
                  கொடுக்கப்படும் கடன் vs. வட்டி சேகரிக்கப்படும் சார்டு, மாதம்,
                  வாரம், ஆண்டுகளிலும் காட்டுகின்றது, அனைத்து தகவலும்.
                </li>
                <li>
                  ஒப்பிந்தியம் விலையிலுள்ள ஒவ்வொரு திட்டம் மாதம், வாரம்,
                  ஆண்டுகளிலும் காட்டுகின்றது, அனைத்து தகவலும்.
                </li>
                <li>
                  ஆப் பாரில் இருந்து ரூபாய் ஐகானின் மூலம், பொருத்தான், சாதாரண
                  தங்கமும் வெள்ளியும் ஒரு கிராம் கொண்டு மீட்கலாம், மற்றும்
                  தங்கமும் வெள்ளியும் சந்திப்புக்களுக்கு சந்திக்கலாம்.
                </li>
                <li>
                  பொருட் விலை சார்டு நிரூபிதமாகும் தேதியில் மாறுதல்
                  காட்டுகின்றது.
                </li>
              </ul>
            </div>
            <div className="dashborad-content" ref={userRef}>
              <h2>பயனர் முகவரி</h2>
              <p>
                உரிமையாளர் ஒவ்வொரு கிளைக்கும் மேலாளர் மற்றும் உரிமையாளரை
                உருவாக்கலாம்/திருத்தலாம் மற்றும் பயனர் மாஸ்டரைப் பயன்படுத்தி
                அவர்களுக்கான உள்நுழைவு சான்றுகளை உருவாக்கலாம்
              </p>
            </div>
            <div className="dashborad-content" ref={SchemeRef}>
              <h2>கடன் திட்டம் முகவரி</h2>
              <p>
                உரிமையாளர் ஸ்கீம் மாஸ்டரைப் பயன்படுத்தி கடன்களுக்கான திட்டங்களை
                உருவாக்கலாம்/திருத்தலாம்
              </p>
            </div>
            <div className="dashborad-content" ref={CustomerRef}>
              <h2>வாடிக்கையாளர் முகவரி</h2>
              <p>
                உரிமையாளர் மற்றும் மேலாளர் வாடிக்கையாளர் மாஸ்டரைப் பயன்படுத்தி
                வாடிக்கையாளரை உருவாக்கலாம்/திருத்தலாம்.
              </p>
            </div>
            <div className="dashborad-content" ref={loanCreationRef}>
              <h2>கடன் உருவாக்கம்</h2>
              <ul>
                <li>
                  பயனர் கடன் உருவாக்க முகவரியில் உருவாக்க முடியும், மற்றும் கடன்
                  உருவாக்கப்பட்ட படிவம் தானாகவே உருவாக்கப்படும்.
                </li>
                <li>
                  தட்டுமானம் தேர்ந்தெடுக்கப்படும் திட்டத்தின் அடிப்படையில்
                  வடிவமைக்கப்படும்.
                </li>
                <li>
                  அமைவு மதிப்பு, தவறுக்காக மதிப்பாய்வு செய்யப்படும், மற்றும்
                  கடன் அதிகதிகளை விளக்கப்படுத்தல் வழங்கப்படும்.
                </li>
                <li>
                  கடன் கேட்கும் தொகை தட்டுமானத்தில் அதிகமானது என்றால்,
                  உருவாக்குநர் அங்கீகரித்து வேண்டும்.
                </li>
                <li>
                  கேட்கப்பட்ட தொகை வருமான மதிப்புக்கு அதிகமான விலையில் என்னும்
                  பரிமாணம் இருந்தால், கடன் செயல்முறை முடக்கப்படும்.
                </li>
                <li>
                  தங்கம் மற்றும் வெள்ளிகளுக்கு KDM மற்றும் Hallmark (916)
                  தங்கங்களுக்கு விதிக்கப்படுகின்றன.
                </li>
                <li>
                  தங்கமும் வெள்ளியும் குறித்து குறியீடுகள் பகுதியின்
                  அடையாளத்தினை அழுத்தி போகும் திட்டமாக பணம் உருவாக்கப்படலாம்.
                </li>
                <li>
                  நீட் எடை 0.5 கிராமாக தானாக கணக்கிடப்படும், மற்றும் ஒவ்வொரு கல்
                  குறைக்கப்படும் 0.025 கிராமாக தானாக கணக்கிடப்படும்.
                </li>
              </ul>
            </div>
            <div className="dashborad-content" ref={receiptRef}>
              <h2>ரசீது உருவாக்கம்</h2>
              <ul>
                <li>
                  ரசீது பக்கத்தில் பயனர் பிரதியுத்தனமாக வடிவமைக்கலாம், வடிவமைக்க
                  மதிப்பீடு மற்றும் கோடு அளிக்கலாம்.
                </li>
                <li>
                  வடிவமைக்கப்படும் வடிவமைக்கப்படும் கோடு மதிப்புக்கு
                  அந்தஸ்தமானது கணக்கிடப்படும், மற்றும் தற்போதைய இருப்பு பிஏ
                  காட்டப்படும்.
                </li>
                <li>
                  பரிந்துரை வரலாறு வழங்கப்படும்; ஒவ்வொரு பரிந்துரைக்கும் ரசீதை
                  அச்சிடுகின்றது, மேலும் பதிவிறக்கம் செய்யலாம்.
                </li>
                <li>
                  தடங்குதல் மதிப்பிற்கு திட்டத்தின் அடிப்படையில்
                  வடிவமைக்கப்படும்.
                </li>
              </ul>
            </div>
            <div className="dashborad-content" ref={auctionRef}>
              <h2>ஏல கடன் செயல்முறை</h2>
              <ul>
                <li>
                  பயன்பாடு தானாகவே கடன் தவறிசெய்தவர்களை தெரிவிக்கும்; மேலும்
                  நிர்வாகம், கடனை நிறுத்தி விற்பனைக்கு அணுகல் உள்ளது.
                </li>
              </ul>
            </div>
            <div className="dashborad-content" ref={AlertRef}>
              <h2>எச்சரிக்கைகள் முகவரி</h2>
              <ul>
                <li>
                  பயன்பாடு இயல்புநிலையில் ஒரு ஆண்டு நேரத்திற்கு கடன்
                  தவறிசெய்தவர்களுக்கு எச்சரிக்கை அளிப்பது.
                </li>
                <li>
                  மேனேஜர்கள் வாடிக்கையாளர்களை எதிர்காலத்தில் எதிர்காலத்திற்கு
                  அழைத்த முன் எண்ணிக்கொள்ளும் முறைகள் எச்சரிக்கையில்
                  கையேற்றப்படும்.
                </li>
              </ul>
            </div>
            <div className="dashborad-content" ref={transationRef}>
              <h2>பரிவர்த்தனை</h2>
              <p>
                பயனர் கடன் மற்றும் கடன் அளிக்கப்பட்ட தொகை விவரங்களைக் காணலாம்.
              </p>
            </div>
            <div className="dashborad-content" ref={summaryRef}>
              <h2>சுருக்கம்</h2>
              <p>
                பயனர் ஒவ்வொரு கடனின் சுருக்கம் பெறலாம், மற்றும் தரவு பதிவிறக்கம்
                செய்யலாம்.
              </p>
            </div>
            <div className="dashborad-content" ref={inventoryRef}>
              <h2>சரக்கு</h2>
              <p>
                பயனர் சேமிப்புக் குறிப்பிட்டவரின் விரிவான பார்வை, பொருட்கள்
                மற்றும் அவற்றின் விலைகளைப் பார்க்கலாம்.
              </p>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default HelpScreen_tm;
