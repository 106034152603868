import { Brightness1 } from "@mui/icons-material";
import { Box, Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Style from "../Styles";
import "./RadioBtnFilter.css";

export const RadioBtnFilter = ({ handleRadioChange, option }) => {
  const { t } = useTranslation("global");
  const [selectedOption, setSelectedOption] = useState(option);

  //Option change function
  const handleChange = (event) => {
    const value = event ? event : "all";
    setSelectedOption(value);
    handleRadioChange(value);
  };

  useEffect(() => {
    handleChange(option);
  }, [selectedOption, option]);

  return (
    <>
      {/* <CustomRadioButton
        array={array}
        value={selectedOption}
        onChange={(value) => handleChange(value.target.value)}
      /> */}
      <Box className="dateContainer">
        <Box className="dateContent">
          <Checkbox
            checked={selectedOption === "weekly"}
            icon={
              <Brightness1
                sx={{
                  color: "#F8F9F5",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                  borderRadius: "50%",
                }}
              />
            }
            checkedIcon={
              <Brightness1
                sx={{
                  color: "#3d5e91",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                  borderRadius: "50%",
                }}
              />
            }
            onClick={() => handleChange("weekly")}
          />
          <Typography
            sx={{
              ...Style.textlabel(),
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
            variant="h6"
          >
            {t("dashboard.weekly")}
          </Typography>
        </Box>
        <Box className="dateContent">
          <Checkbox
            checked={selectedOption === "monthly"}
            icon={
              <Brightness1
                sx={{
                  color: "#F8F9F5",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                  borderRadius: "50%",
                }}
              />
            }
            checkedIcon={
              <Brightness1
                sx={{
                  color: "#3d5e91",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                  borderRadius: "50%",
                }}
              />
            }
            onChange={() => handleChange("monthly")}
          />
          <Typography
            sx={{
              ...Style.textlabel(),
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
            variant="h6"
          >
            {t("dashboard.monthly")}
          </Typography>
        </Box>
        <Box className="dateContent">
          <Checkbox
            checked={selectedOption === "yearly"}
            icon={
              <Brightness1
                sx={{
                  color: "#F8F9F5",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                  borderRadius: "50%",
                }}
              />
            }
            checkedIcon={
              <Brightness1
                sx={{
                  color: "#3d5e91",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                  borderRadius: "50%",
                }}
              />
            }
            onChange={() => handleChange("yearly")}
          />
          <Typography
            sx={{
              ...Style.textlabel(),
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
            variant="h6"
          >
            {t("dashboard.yearly")}
          </Typography>
        </Box>
        <Box className="dateContent">
          <Checkbox
            defaultChecked
            checked={selectedOption === "all"}
            icon={
              <Brightness1
                sx={{
                  color: "#F8F9F5",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                  borderRadius: "50%",
                }}
              />
            }
            checkedIcon={
              <Brightness1
                sx={{
                  color: "#3d5e91",
                  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.9)",
                  borderRadius: "50%",
                }}
              />
            }
            onChange={() => handleChange("all")}
          />
          <Typography
            sx={{
              ...Style.textlabel(),
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
            variant="h6"
          >
            {t("dashboard.all")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
