import { Box, Paper } from "@mui/material";
import React, { useRef } from "react";
import AddItemComp from "./Help/AddItemComp";
import ExpenditureType from "./Help/ExpenditureType";
import LanguagePreference from "./Help/Settings/LanguagePreference";
import "./Help/Settings/Setting.css";
import SettingsNavBar from "./Help/Settings/SettingsNavBar";

const SettingsComp = () => {
  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const generalref = useRef(null);
  const additemRef = useRef(null);

  const expenditureTypeRef = useRef(null);
  // const navigate = useNavigate();
  // const handleClick = () => {
  //   navigate('/superAdmin');
  // };

  return (
    <Paper className="commonpaper" sx={{ pr: 0 }}>
      <Box>
        <SettingsNavBar
          generalref={generalref}
          scrollToSelection={scrollToSection}
          additemRef={additemRef}
          expenditureTypeRef={expenditureTypeRef}
        >
          <Box className="settings-comp">
            <LanguagePreference generalref={generalref} />
            <AddItemComp additemRef={additemRef} />
            <ExpenditureType expenditureTypeRef={expenditureTypeRef} />
          </Box>
        </SettingsNavBar>
      </Box>
    </Paper>
  );
};

export default SettingsComp;
