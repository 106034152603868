/* eslint-disable react-hooks/exhaustive-deps */
import {
  AddOutlined,
  ArrowCircleLeftOutlined,
  MoreVert,
  PublishedWithChanges,
  SaveOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import "../App.css";
import api from "../api/http-common";
import AddImageIcon from "../assets/AddImageIcon.svg";
import { AppData } from "../common/AppData";
import CustomCommonButton from "../common/Components/CustomCommonButton";
import Dropdown from "../common/Components/Dropdown";
import ErrorTextfield from "../common/Components/ErrorTextfield";
import MultiDisplayDropdown from "../common/Components/MultiDisplayDropdown";
import Textfield from "../common/Components/TextField";
import Extension from "../common/Extension";
import GridTable from "../common/GridTable";
import Loading from "../common/Loading";
import SnackBar from "../common/SnackBar";
import Styles from "../common/Styles";
import Logger from "../common/Logger";

const User = () => {
  // const { userCredentails, setUserCredentails } =
  //   useContext(UserDetailsContext);
  // const entity_id = userCredentails.entitydata.entity_id;
  const entity_id = sessionStorage.getItem("entityId");
  const { t } = useTranslation("global");
  const logger = new Logger({ level: "log" });

  const Columns = AppData.user.map((column) => ({
    ...column,
    headerName: t(`user master.${column.field}`),
  }));

  const [users, setUsers] = useState([]);
  const [branches, setBranches] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [firstName, setFirstname] = useState("");
  const [firstName_tn, setFirstname_tn] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastName_tn, setLastName_tn] = useState("");
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [branchId, setBranchId] = useState("");
  const [image, setImage] = useState("noimage");
  const [roleId, setRoleId] = useState("");
  const [readOnly, setReadOnly] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  const [loading, setLoading] = useState(true);
  const [addbuttonDisabled, setAddbuttonDisabled] = useState(false);
  const [isActive, setIsActive] = useState(true);

  //Hard code Roles
  const roles = [
    { name: "ADMIN", name_tn: "நிர்வாகி", roleId: 1 },
    { name: "MANAGER", name_tn: "மேலாளர்", roleId: 2 },
  ];

  //Clear Text Fields
  const clearTextFields = () => {
    setFirstname("");
    setLastName("");
    setLoginId("");
    setPassword("");
    setEmailId("");
    setMobileNumber("");
    setBranchId("");
    setImage("");
    setRoleId("");
    setFirstname_tn("");
    setLastName_tn("");
  };

  // Get User Data initially
  useEffect(() => {
    getUserData();
  }, []);

  //Get user data
  const getUserData = async () => {
    try {
      handleLoadingOpen(); //Start the loading
      logger.log("Initiate Fetch branch");
      const branchRes = await api.get(`/branch/byEntity/${entity_id}`); //Get Branch on the entity
      logger.log("Fetch branch Successfully");
      const branchData = branchRes.data;
      setBranches(branchData);
      logger.log("Initiate Fetch users");
      const response = await api.get(`/users/byEntity/${entity_id}`); //Get Users on the entity
      logger.log("Fetch users Successfully");
      setUsers(response.data);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose(); //Stop the loading
    }
  };

  //Image Conversion to Base64
  function convertBase64(e, maxWidth, maxHeight) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target.result) {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            // Check if image needs resizing
            if (width > maxWidth || height > maxHeight) {
              const ratio = Math.min(maxWidth / width, maxHeight / height);
              width *= ratio;
              height *= ratio;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            const base64Data = canvas.toDataURL("image/jpeg", 0.7); // Convert to base64
            setImage(base64Data);
          };

          img.src = event.target.result;
        } else {
          SnackBar.toastMsg("error", t("toast_messages.failed_to_load"));
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  //Validation messages
  const validations = [
    {
      value: firstName,
      message: "Please Enter First Name",
      message_tn: "தயவுசெய்து முதல் பெயரை உள்ளிடவும்",
    },
    {
      value: lastName,
      message: "Please Enter Last Name",
      message_tn: "தயவுசெய்து கடைசி பெயரை உள்ளிடவும்",
    },
    {
      value: emailId,
      message: "Please Enter Email ID",
      message_tn: "தயவுசெய்து மின்னஞ்சல் ஐடியை உள்ளிடவும்",
    },
    {
      value: password,
      message: "Please Enter Password",
      message_tn: "தயவுசெய்து கடவுச்சொல்லை உள்ளிடவும்",
    },
    {
      value: loginId,
      message: "Please Enter Login ID",
      message_tn: "தயவுசெய்து உள்நுழைவு ஐடியை உள்ளிடவும்",
    },
    {
      value: roleId,
      message: "Please Select Role",
      message_tn: "தயவுசெய்து பாதுகாப்புக் குழுவை தேர்ந்தெடுக்கவும்",
    },
    {
      value: mobileNumber,
      message: "Please Enter Mobile Number",
      message_tn: "தயவுசெய்து கைபேசி எண் உள்ளிடவும்",
    },
    {
      value: branchId,
      message: "Please Select Branch",
      message_tn: "தயவுசெய்து கிளைக்கையை தேர்ந்தெடுக்கவும்",
    },
  ];

  //Add User Function
  const addUser = async () => {
    if (
      loginId &&
      emailId &&
      firstName &&
      lastName &&
      roleId &&
      password &&
      mobileNumber &&
      branchId &&
      passwordError === "" &&
      emailError === "" &&
      mobileError === ""
    ) {
      try {
        handleLoadingOpen(); //Start Loading
        setAddbuttonDisabled(true); //Disable the save button
        const user = {
          login_id: loginId,
          email: emailId,
          first_name: firstName,
          first_name_tn: firstName_tn,
          last_name: lastName,
          last_name_tn: lastName_tn,
          role_id: roleId,
          image: image,
          password,
          mobile_number: mobileNumber,
          entity_id: entity_id,
          branch_id: branchId,
          is_active: isActive,
        };
        logger.log("Initiate Post User ");
        await api.post("users", user); //post user
        logger.log("Post User Successfully");
        SnackBar.toastMsg(
          "success",
          t("toast_messages.user_added_successfully")
        );
        getUserData(); //get user data after add user
        handleCloseUserPopup(); //close the user popup
      } catch (error) {
        logger.log(error);
        if (error.response.data.login_id) {
          SnackBar.toastMsg("error", t("toast_messages.login_id"));
        } else if (error.response.data.email) {
          SnackBar.toastMsg("error", t("toast_messages.email"));
          return;
        } else {
          logger.log(error);
        }
      } finally {
        setAddbuttonDisabled(false); //enable save button
        handleLoadingClose(); //stop loading
      }
    } else {
      for (const validation of validations) {
        //handle validations
        if (!validation.value) {
          SnackBar.toastMsg(
            "error",
            Extension.languageBasedValue(
              validation.message,
              validation.message_tn
            )
          );
          return;
        }
      }
      if (passwordError || emailError || mobileError) {
        SnackBar.toastMsg("error", t("toast_messages.enter_valid_values"));
      }
    }
  };

  //Popup set Data for update
  const [editUser, setEditUser] = useState(null);
  const enterEditingMode = (user) => {
    setEditUser(user);
    setFirstname(user.first_name);
    setLastName(user.last_name);
    setEmailId(user.email);
    setBranchId(user.branch_id);
    setLoginId(user.login_id);
    setPassword(user.password);
    setMobileNumber(user.mobile_number);
    setRoleId(user.role_id);
    setImage(user.image);
    setFirstname_tn(user.first_name_tn);
    setLastName_tn(user.last_name_tn);
    setIsActive(user.is_active);
    handleOpenUserPopup();
    setReadOnly(true);
  };

  //Update User
  const updateUser = async () => {
    try {
      handleLoadingOpen(); //start the loading
      setAddbuttonDisabled(true); //disable save button
      if (editUser) {
        const Id = editUser.id;
        const updatedUser = {
          ...editUser.user,
          login_id: loginId,
          email: emailId,
          first_name: firstName,
          first_name_tn: firstName_tn,
          last_name: lastName,
          last_name_tn: lastName_tn,
          role_id: roleId,
          image,
          password,
          mobile_number: mobileNumber,
          entity_id: entity_id,
          branch_id: branchId,
          is_active: isActive,
        };
        logger.log("Initiate Post Update");
        await api.put(`users/${Id}`, updatedUser);
        logger.log("Update User Successfully");
        SnackBar.toastMsg(
          "success",
          t("toast_messages.user_updated_successfully")
        );
        getUserData();
        handleCloseUserPopup();
      }
    } catch (error) {
      logger.log(error);
      if (error.response.data.login_id) {
        SnackBar.toastMsg("error", t("toast_messages.login_id"));
      } else if (error.response.data.email) {
        SnackBar.toastMsg("error", t("toast_messages.email"));
        return;
      } else {
        logger.log(error);
      }
    } finally {
      setAddbuttonDisabled(false); //enable save button
      handleLoadingClose(); //stop loading
    }
  };

  //Validation Concepts

  //Password Validation
  const validatePassword = (value) => {
    const alpha = /[a-zA-Z]/;
    const numeric = /\d/;
    const spl = /[!@#$%^&*(),.?":{}|<>]/;

    if (!value) {
      setPasswordError("Password is required");
    } else if (!alpha.test(value)) {
      setPasswordError("Password Must Be Include Letters.");
    } else if (!numeric.test(value)) {
      setPasswordError("Password Must Be Numbers.");
    } else if (!spl.test(value)) {
      setPasswordError(
        "Password Must Be Include Atleast One Special Character."
      );
    } else if (value.length < 12) {
      setPasswordError("Password Must Be Atleast 12 Characters Long .");
    } else {
      setPasswordError("");
    }
  };

  //Email Validation
  const validateEmail = (email) => {
    const emailRegex = /^[\w.-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/;

    if (!email) {
      setEmailError("Email Is Required");
    } else if (!emailRegex.test(email)) {
      setEmailError("Please Provide A Valid Email");
    } else {
      setEmailError("");
    }
  };

  //Mobile Number validation
  const validateMobile = (phone) => {
    const phoneRegex = /^\+?[0-9-]+$/;

    if (!phone) {
      setMobileError("Mobile Number Is Required");
    } else if (!phoneRegex.test(phone) || phone.length !== 10) {
      setMobileError("Please Provide A Valid Number");
    } else {
      setMobileError("");
    }
  };

  //Password Change Function
  const handlePasswordChange = (e) => {
    e.target.value = e.target.value.slice(0, 20);
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  //Email Change Function
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailId(email);
    validateEmail(email);
  };

  //Mobile Number Change Function
  const handlemobileNumberChange = (e) => {
    const mobile = e.target.value;
    setMobileNumber(mobile);
    validateMobile(mobile);
  };

  //Translate First name english to tamil
  const handleFirstNameChange = (e) => {
    Extension.translateInput(e, setFirstname_tn);
    setFirstname(Extension.onlyText(e));
  };

  //Translate Last name english to tamil
  const handleLastNameChange = (e) => {
    Extension.translateInput(e, setLastName_tn);
    setLastName(Extension.onlyText(e));
  };

  //Filter user
  const filteredUsers = users.filter(
    (user) =>
      (selectedBranch === "" ||
        selectedBranch === "All" ||
        user.branch_id.toString() === selectedBranch.toString()) &&
      (user.login_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.last_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.first_name_tn.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.last_name_tn.toLowerCase().includes(searchQuery.toLowerCase()))
    // user.branch_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  //Table row
  const rows = filteredUsers.map((user, index) => {
    const branch = branches.find(
      (branch) => parseInt(branch.branch_id) === parseInt(user.branch_id)
    );
    const role = roles.find(
      (role) => parseInt(role.roleId) === parseInt(user.role_id)
    );
    return {
      id: index + 1,
      image: (
        <Avatar
          alt={user.name}
          src={user.image !== null ? `${user.image}` : null}
          sx={{ width: 36, height: 36 }}
        />
      ),
      loginid: user.login_id,
      firstname:
        Extension.languageBasedValue(user.first_name, user.first_name_tn) ??
        "--",
      lastname:
        Extension.languageBasedValue(user.last_name, user.last_name_tn) ?? "--",
      role: Extension.languageBasedValue(role.name, role.name_tn) ?? "--",
      branch:
        Extension.languageBasedValue(
          branch.branch_name,
          branch.branch_name_tn
        ) ?? "--",
      moreinfo: (
        <MoreVert
          sx={{ cursor: "pointer" }}
          onClick={() => enterEditingMode(user)}
        />
      ),
    };
  });

  const [userPopup, setUserPopup] = useState(false);

  const addUserPopup = () => {
    handleOpenUserPopup();
    setImage("noimage");
  };

  const handleOpenUserPopup = () => {
    setUserPopup(true);
  };

  const handleCloseUserPopup = () => {
    setReadOnly(false);
    setMobileError(false);
    setEmailError(false);
    setPasswordError(false);
    setIsActive(true);
    setUserPopup(false);
    setImage(null);
    setEditUser(null);
    clearTextFields();
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };

  const handleConfirmDialogue = () => {
    setIsActive(!isActive);
  };

  return (
    <>
      <Toaster limit={3} />
      <Paper className="commonpaper">
        <Loading open={loading} handleClose={handleLoadingClose} />
        <Grid
          container
          spacing={{ xs: 2, md: 4, sm: 4 }}
          columns={{ xs: 1, sm: 3, md: 3 }}
          sx={{ elevation: 3, mb: 2, display: "flex", alignItems: "flex-end" }}
        >
          <Grid item xs={1} sm={1} md={0.6}>
            <CustomCommonButton
              onClick={addUserPopup}
              icon={<AddOutlined />}
              text={t("user master.new user")}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Dropdown
              firstOption={t("common.all")}
              search={true}
              label={t("common.select branch")}
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
              array={branches}
              menuValue={"branch_id"}
              display={Extension.languageBasedValue(
                "branch_name",
                "branch_name_tn"
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Textfield
              label={t("user master.search user")}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Grid>
        </Grid>
        <GridTable rows={rows} columns={Columns} isLoading={loading} />
        {/* User Add Popup */}
        <Modal
          open={userPopup}
          onClose={handleCloseUserPopup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={Styles.widthPopupStyles()}>
            <Loading open={loading} handleClose={handleLoadingClose} />
            <Box
              sx={{
                backgroundColor: "var(--app-bar-color)",
                p: 1,
                borderRadius: "10px 10px 0 0",
              }}
            >
              <Typography
                sx={{
                  color: "var(--header-font-color)",
                  fontSize: "var(--font-size)",
                  fontFamily: "var(--font-family)",
                }}
              >
                <ArrowCircleLeftOutlined
                  onClick={handleCloseUserPopup}
                  sx={{ cursor: "pointer", mr: 1 }}
                />
                {editUser
                  ? t("user master.update user")
                  : t("user master.add user")}
              </Typography>
            </Box>
            <Box sx={{ p: 5, pt: 2 }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <input
                  type="file"
                  id="image-upload"
                  style={{ display: "none" }}
                  onChange={convertBase64}
                />
                <Avatar
                  src={image !== "noimage" ? `${image}` : AddImageIcon}
                  alt=""
                  sx={{ height: 75, width: 75, cursor: "pointer" }}
                  onClick={() =>
                    document.getElementById("image-upload").click()
                  }
                />
              </Box>
              <br />
              <Grid
                container
                spacing={{ xs: 2, md: 3, sm: 3 }}
                columns={{ xs: 1, sm: 2, md: 2 }}
                sx={{ elevation: 3, mb: 2 }}
              >
                <Grid item xs={1} sm={1} md={0.5}>
                  <Textfield
                    value={firstName}
                    readOnly={readOnly}
                    onChange={handleFirstNameChange}
                    maxLength={25}
                    label={"First Name"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={0.5}>
                  <Textfield
                    value={firstName_tn}
                    readOnly={readOnly}
                    onChange={(e) => setFirstname_tn(e.target.value)}
                    maxLength={25}
                    label={"முதல் பெயர்"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={0.5}>
                  <Textfield
                    value={lastName}
                    readOnly={readOnly}
                    onChange={handleLastNameChange}
                    maxLength={25}
                    label={"Last Name"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={0.5}>
                  <Textfield
                    value={lastName_tn}
                    readOnly={readOnly}
                    onChange={(e) => setLastName_tn(e.target.value)}
                    maxLength={25}
                    label={"கடைசி பெயர்"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <ErrorTextfield
                    label={"Email/ மின்னஞ்சல்"}
                    maxLength={50}
                    type={"text"}
                    value={emailId}
                    required={false}
                    onBlur={() => validateEmail(emailId)}
                    error={emailError}
                    onChange={handleEmailChange}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <Typography sx={Styles.textlabel()}>
                    Password/ கடவுச்சொல்
                  </Typography>
                  <Paper>
                    <TextField
                      sx={{ backgroundColor: "var(--text-field)" }}
                      fullWidth
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={handlePasswordChange}
                      onBlur={() => validatePassword(password)}
                      size="small"
                      inputProps={{
                        style: {
                          minHeight: "15px",
                          maxHeight: "22px",
                          color: "var(--font-color)",
                          fontFamily: "var(--font-family)",
                          fontSize: "var(--font-size)",
                          "& .MuiSvgIcon-root,.MuiTypography-root": {
                            color: "var(--font-color)", // Change the color of the dropdown icon
                          },
                        },
                      }}
                      error={Boolean(passwordError)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Paper>
                  {passwordError ? (
                    <span style={{ ...Styles.textlabel(), color: "red" }}>
                      {passwordError}
                    </span>
                  ) : null}
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <Textfield
                    value={loginId}
                    readOnly={readOnly}
                    onChange={(e) => setLoginId(e.target.value)}
                    label={"Login ID/ உள்நுழைவு ஐடி"}
                    maxLength={20}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <ErrorTextfield
                    label={"Mobile Number/ கைபேசி எண்"}
                    value={mobileNumber}
                    required={false}
                    maxLength={10}
                    type={"number"}
                    onBlur={() => validateMobile(mobileNumber)}
                    error={mobileError}
                    onChange={handlemobileNumberChange}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <MultiDisplayDropdown
                    label={"Branch/ கிளை"}
                    array={branches}
                    value={branchId}
                    onChange={(e) => setBranchId(e.target.value)}
                    menuValue={"branch_id"}
                    display={["branch_name", "branch_name_tn"]}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <MultiDisplayDropdown
                    label={"Role/ பதவி"}
                    array={roles}
                    value={roleId}
                    onChange={(e) => setRoleId(e.target.value)}
                    menuValue={"roleId"}
                    display={["name", "name_tn"]}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={2} lg={2} sx={{ display: "flex" }}>
                  <Checkbox
                    value={isActive}
                    checked={isActive}
                    onChange={handleConfirmDialogue}
                  />

                  <p className="restrict-text" style={{ marginLeft: "10px" }}>
                    This User is Marked as Active / இந்த பயனர் செயலில் உள்ளதாகக்
                    குறிக்கப்பட்டுள்ளார்
                  </p>
                  {editUser ? (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <CustomCommonButton
                        icon={<PublishedWithChanges />}
                        onClick={updateUser}
                        disabled={addbuttonDisabled ? true : false}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <CustomCommonButton
                        icon={<SaveOutlined />}
                        onClick={addUser}
                        disabled={addbuttonDisabled ? true : false}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Paper>
    </>
  );
};
export default User;
