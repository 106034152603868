import React from "react";
import ReactApexChart from "react-apexcharts";

export const Chart = (props) => {
    const {options, series, type} = props;
  return (
    <ReactApexChart
      className="chart"
      height={350}
      options={options}
      series={series}
      type={type}
    />
  );
};
