import { DownloadForOffline } from "@mui/icons-material";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import React, { useCallback, useEffect, useState } from "react";
import CountUp from "react-countup";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import api from "../../api/http-common";
import { AppData } from "../../common/AppData";
import CustomCommonButton from "../../common/Components/CustomCommonButton";
import CustomDateRangePicker from "../../common/Components/CustomDateRangePicker";
import CustomRadioButton from "../../common/Components/CustomRadioButton";
import Dropdown from "../../common/Components/Dropdown";
import { rupeeSymbol } from "../../common/Components/IconsAndComponents";
import Extension from "../../common/Extension";
import GridTable from "../../common/GridTable";
import Loading from "../../common/Loading";
import Style from "../../common/Styles";
import Logger from "../../common/Logger";

const Filter = [
  {
    label: "Yearly",
    value: "yearly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Today",
    value: "today",
  },
];
// InterestAmountCredit, LoanClosureAmountCredit, LoanAmountDebit, ExpenditureAmountDebit
const fund_type = [
  {
    label: "Fund Amount",
    label_tn: "முதலீடு",
    value: "FundAmountCredit",
  },
  {
    label: "Interest Amount",
    label_tn: "வட்டித் தொகை",
    value: "InterestAmountCredit",
  },
  {
    label: "Loan Closure Amount",
    label_tn: "கடன் மூடல் தொகை ",
    value: "LoanClosureAmountCredit",
  },
  {
    label: "Loan Amount",
    label_tn: "கடன் தொகை",
    value: "LoanAmountDebit",
  },
  {
    label: "Expenditure Amount",
    label_tn: "செலவுத் தொகை",
    value: "ExpenditureAmountDebit",
  },
];

const paymentMethods = [
  { text: "Cash", value: "By Cash", text_tn: "பணம்" },
  {
    text: "Account Transfer",
    value: "By Account",
    text_tn: "கணக்கு பரிமாற்றம்",
  },
  { text: "UPI/ Others", value: "UPI/Others", text_tn: "UPI/ மற்றவை" },
];
const FundTransaction = () => {
  const { t,i18n } = useTranslation("global");
  const currentLang = i18n.language;
  const logger = new Logger({ level: "log" });
  const entity_id = parseInt(sessionStorage.getItem("entityId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([]);
  const [cumFilter, setCumFilter] = useState("All");
  const [radioFilter, setRadioFilter] = useState("");
  const [transData, setTransData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [users, setUsers] = useState([]);
  const [latestFund, setLatestFund] = useState({});
  const [exType, setExType] = useState("All");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [entityLatestFund, setEntityLatestFund] = useState("");

  const PeriodFilter = Filter.map((fil) => ({
    ...fil,
    label: t(`transaction.${fil.value}`),
  }));

  const RadioArray = [
    {
      name: t("transaction.all"),
      value: "",
    },
    {
      name: t("transaction.credit"),
      value: "credit_amount",
    },
    {
      name: t("transaction.debit"),
      value: "debit_amount",
    },
  ];

  const getFund = useCallback(async () => {
    try {
      const ENTITY_API =
        selectedBranch === "All" &&
        role_id === 1 &&
        `/Fund/CurrentTotalAmount/${entity_id}`;
      const BRANCH_API =
        role_id === 1 && selectedBranch !== "All"
          ? `/Fund/CurrentTotalAmountByBranch/${selectedBranch}`
          : `/Fund/CurrentTotalAmountByBranch/${branch_id}`;
      let Entity_res = 0;
      if (selectedBranch === "All" && role_id === 1) {
        logger.log("Initiate CurrentTotalAmount loans");
        Entity_res = await api
          .get(ENTITY_API)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Fetch CurrentTotalAmount Successfully");
        setEntityLatestFund(Entity_res);
        return;
      }
      logger.log("Initiate Current Total Amount By Branch loans");
      const crrapi = await api
        .get(BRANCH_API)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch Current Total Amount By Branch Successfully");
      setLatestFund(crrapi);
    } catch (error) {
      logger.log(error);
    }
  }, [branch_id, entity_id, role_id, selectedBranch]);

  const getData = async () => {
    try {
      handleLoadingOpen();
      const transAPI =
        role_id === 1
          ? `/Fund/FundTransferByEntity/${entity_id}`
          : `/Fund/FundTransferByBranch/${branch_id}`;
      logger.log("Initiate Fetch users");
      const userData = await api
        .get(`/users/byEntity/${entity_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch users Successfully");
      setUsers(userData);
      const BranchAPI =
        role_id === 1
          ? `/branch/byEntity/${entity_id}`
          : `/branch/${branch_id}`;
      logger.log("Initiate Fetch branch");
      const branchData = await api
        .get(BranchAPI)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch sbranch Successfully");
      setBranches(branchData);
      const transres = await api
        .get(transAPI)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      setTransData(transres);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getFund();
  }, [getFund]);

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };

  const handleDateChange = (value) => {
    if (value && value.length > 0) {
      setDates(value.map((item) => dayjs(item)));
    } else {
      setDates([]);
    }
  };

  const handleCumFilter = async (e) => {
    const today = new Date();
    const monthly = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    ).toISOString();
    const yearly = new Date(today.getFullYear(), 0, 1).toISOString();
    const weekly = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 1
    ).toISOString();
    const currentday = Extension.getCurrentDateTime();
    const todayWithoutTime = format(today, "yyyy-MM-dd");
    const value = e ? e.target.value : "today";
    setCumFilter(value);
    const transAPI =
      role_id === 1
        ? `/Fund/FundTransferByEntity/${entity_id}`
        : `/Fund/FundTransferByBranch/${branch_id}`;
    logger.log("Initiate Fetch FundTransfer");
    const transres = await api.get(transAPI);
    logger.log("Fetch FundTransfer Successfully");
    const transData = transres.data;

    switch (value) {
      case "weekly":
        const weekData = transData.filter(
          (data) =>
            new Date(data.funded_on) >= new Date(weekly) &&
            new Date(data.funded_on) <= new Date(currentday)
        );
        setTransData(weekData);
        break;
      case "monthly":
        const monthData = transData.filter(
          (data) =>
            new Date(data.funded_on) >= new Date(monthly) &&
            new Date(data.funded_on) <= new Date(currentday)
        );
        setTransData(monthData);
        break;
      case "yearly":
        const yearlyData = transData.filter(
          (data) =>
            new Date(data.funded_on) >= new Date(yearly) &&
            new Date(data.funded_on) <= new Date(currentday)
        );
        setTransData(yearlyData);
        break;
      case "All":
        setTransData(transData);
        break;
      default:
        const todayData = transData.filter((data) => {
          const itemDate = format(new Date(data.funded_on), "yyyy-MM-dd");
          return itemDate === todayWithoutTime;
        });
        setTransData(todayData);
        break;
    }
  };

  const filteredRows = transData.filter((item) => {
    const itemDate = new Date(item.funded_on);

    const fromDate = dates[0] ? new Date(dates[0]) : undefined;
    const toDate = dates[1] ? new Date(dates[1]) : undefined;
    if (fromDate) {
      fromDate.setHours(0, 0, 0, 0);
    }
    if (toDate) {
      toDate.setHours(23, 59, 59, 999);
    }
    const isBranchMatch =
      selectedBranch === "All" ||
      selectedBranch === "" ||
      parseInt(selectedBranch) === parseInt(item.branch);
    const isExpenditureMatch =
      exType === "All" ||
      exType === "" ||
      exType.toLowerCase() === item.fund_type.toLowerCase();

    const isWithinRange =
      (!fromDate || itemDate.toISOString() >= fromDate.toISOString()) &&
      (!toDate || itemDate.toISOString() <= toDate.toISOString());

    const isCategoryMatch =
      radioFilter === "" ||
      (radioFilter === "credit_amount" && item.credit_amount) ||
      (radioFilter === "debit_amount" && item.debit_amount);

    return (
      isCategoryMatch && isBranchMatch && isWithinRange && isExpenditureMatch
    );
  });

  const rows = filteredRows.map((item, index) => {
    const formattedTotalFundAmount =
      Extension.formatCurrency(item?.total_fund_amount) || "--";
    const user = users.find(
      (data) => parseInt(data.id) === parseInt(item.funded_By)
    );
    const branch =
      role_id === 1 && branches.length > 0
        ? branches.find(
            (data) => parseInt(data.branch_id) === parseInt(item.branch)
          )
        : parseInt(branches.branch_id) === parseInt(item.branch) && branches;
    const fund = fund_type.find((data) => data.value === item.fund_type);
    const pay_method = paymentMethods.find(
      (data) => data.value === item.transaction_type
    );
    return {
      ...item,
      id: index + 1,
      funded_on: item.funded_on
        ? Extension.formatdateTime(item.funded_on)
        : "--",
      total_fund_amount: isNaN(parseInt(formattedTotalFundAmount))
        ? "--"
        : `₹ ${formattedTotalFundAmount}`,
      transaction_type:
        Extension.languageBasedValue(pay_method?.text, pay_method?.text_tn) ||
        "--",
      fund_type:
        Extension.languageBasedValue(fund.label, fund.label_tn) || "--",
      funded_branch:
        Extension.languageBasedValue(
          branch?.branch_name,
          branch?.branch_name_tn
        ) || "--",
      funded_by: `${Extension.languageBasedValue(
        user?.first_name,
        user?.first_name_tn
      )} ${Extension.languageBasedValue(user?.last_name, user?.last_name_tn)}`,
      remarks:
        Extension.languageBasedValue(item.remarks, item.remarks_tn) || "--",
      amount: (
        <span
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            color: item.credit_amount
              ? "var(--positive-color)"
              : "var(--negative-color)",
            // fontWeight: 800,
          }}
        >
          {isNaN(
            parseInt(
              Extension.formatCurrency(item.credit_amount ?? item.debit_amount)
            )
          )
            ? "--"
            : `${item.credit_amount ? "+" : "-"} ₹
          ${Extension.formatCurrency(item.credit_amount ?? item.debit_amount)}`}
        </span>
      ),
    };
  });

  const Columns = AppData.fundtransaction.map((column) => ({
    ...column,
    headerName: t(`fundtransaction.${column.field}`),
  }));

  const exportToXLSX = () => {
    const dataRows = rows.map((trans) => {
      return {
        [t("fundtransaction.id")]: trans.id,
        [t("fundtransaction.amount")]: `${
          trans.credit_amount ? "+" : "-"
        } ${rupeeSymbol} ${Extension.formatCurrency(
          trans.credit_amount ?? trans.debit_amount
        )}`,
        [t("fundtransaction.fund_type")]: trans.fund_type,
        [t("fundtransaction.funded_on")]: trans.funded_on,
        [t("fundtransaction.funded_by")]: trans.funded_by,
        [t("fundtransaction.funded_branch")]: trans.funded_branch,
        [t("fundtransaction.remarks")]: trans.remarks,
        [t("fundtransaction.transaction_type")]: trans.transaction_type,
        [t("fundtransaction.total_fund_amount")]: trans.total_fund_amount,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(dataRows);
    worksheet["!cols"] = [
      { wch: 20 },
      { wch: 15 },
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 30 },
    ];
    dataRows.forEach((rowData, rowIndex) => {
      const isFradulent = rows[rowIndex] && rows[rowIndex].isFradulent;
      const rowNumber = rowIndex + 2;
      const fontColor = isFradulent ? "red" : "black";
      const fontWeight = isFradulent ? "bold" : "normal";

      const style = {
        font: { color: { rgb: fontColor }, bold: fontWeight === "bold" },
      };

      XLSX.utils.sheet_add_aoa(worksheet, [[]], {
        origin: { r: rowNumber, c: 0 },
        style,
      });
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FundTransactions");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "FundTransactions.xlsx");
  };
  const currentFundAmount =
    role_id === 1 && selectedBranch === "All"
      ? entityLatestFund?.entity_total_fund
      : latestFund?.total_fund_amount;

  return (
    <Paper className="commonpaper">
      <Toaster limit={3} />
      <Loading open={loading} handleClose={handleLoadingClose} />
      <Grid
        container
        spacing={2}
        columns={{ xs: 1, sm: 1, md: 3, lg: 4 }}
        sx={{ elevation: 3, mb: 2, display: "flex", alignItems: "flex-end" }}
      >
        <Grid item xs={1} sm={1} md={0.8} lg={0.6}>
          {role_id === 1 ? (
            <Dropdown
              firstOption={t("common.all")}
              search={true}
              label={t("common.branch name")}
              value={selectedBranch}
              onChange={(e) => setSelectedBranch(e.target.value)}
              array={branches}
              menuValue={"branch_id"}
              display={Extension.languageBasedValue(
                "branch_name",
                "branch_name_tn"
              )}
            />
          ) : role_id === 2 ? (
            <Typography variant="h5" sx={Style.titleText()}>
              {Extension.languageBasedValue(
                branches.branch_name,
                branches.branch_name_tn
              )}
            </Typography>
          ) : null}
        </Grid>

        <Grid item xs={1} sm={1} md={0.8} lg={0.6}>
          <Dropdown
            firstOption={t("transaction.all time")}
            label={t("transaction.custom filter")}
            value={cumFilter}
            onChange={handleCumFilter}
            search={true}
            array={PeriodFilter}
            menuValue={"value"}
            display={"label"}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1.4}
          lg={1}
          sx={{ display: "flex", justifyContent: "center"}}
        >
          <CustomRadioButton
            array={RadioArray}
            onChange={(e) => setRadioFilter(e.target.value)}
            value={radioFilter}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={0.8} lg={0.6}>
          <Dropdown
            label={t("transaction.select_expenditure_type")}
            value={exType}
            onChange={(e) => setExType(e.target.value)}
            search={true}
            firstOption={t("common.all")}
            array={fund_type}
            menuValue={"value"}
            display={Extension.languageBasedValue("label", "label_tn")}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={0.8} lg={0.6}>
          <CustomDateRangePicker
            label={t("transaction.select range")}
            values={dates}
            onChange={handleDateChange}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1.4}
          lg={0.6}
          sx={{ display: "flex", justifyContent: { md: "end" } }}
        >
          <Paper
            elevation={3}
            sx={{
              /*width: 220,*/ height: 100,
              width: { lg: "100%", md: "50%", sm: "100%", xs: "100%" },
              p: 2,
              // mb: 2,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background:
                "linear-gradient(to bottom, #58A3FF, #81BAFF, #B0D4FF, #BCDAFF, #DEEAF9)",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "var(--font-weight)",
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              }}
            >
              {t("fundtransaction.fund_amount")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // mt: 2,
              }}
            >
              <CountUp
                start={0}
                end={currentFundAmount}
                duration={2.5}
                decimals={2}
                useIndianSeparators={true}
                prefix={`₹`}
                style={{ fontSize: "21px" }}
              />
            </Box>
          </Paper>
        </Grid>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
          }}
        >
          <CustomCommonButton
            text={t("summary.download")}
            onClick={exportToXLSX}
            icon={<DownloadForOffline />}
          />
        </div>
      </Grid>

      <GridTable
        rows={rows}
        columns={Columns}
        isLoading={loading}
        height={{ lg: "80vh", md: "75vh", sm: "60vh", xs: "50vh" }}
      />
    </Paper>
  );
};

export default FundTransaction;
