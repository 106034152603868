import { Avatar, Box, IconButton, Modal } from "@mui/material";
import React from "react";
import Styles from "../Styles";
import { Cancel } from "@mui/icons-material";

const ImageProvision = ({
  contextMenu,
  handleClose,
  image,
  setContextMenu,
}) => {
  return (
    <div>
      {" "}
      <Modal
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
      >
        <Box
          sx={{
            ...Styles.customPopUp(),
            width: "50%",
            height: "95%",
          }}
        >
          {/* <Paper> */}
          <IconButton
            onClick={() => setContextMenu(null)}
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              zIndex: 999,
            }}
          >
            <Cancel sx={{ color: "var(--font-color)" }} />
          </IconButton>
          <Avatar
            src={image}
            variant="rounded"
            alt=""
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
            }}
          />
          {/* </Paper> */}
        </Box>
      </Modal>
    </div>
  );
};

export default ImageProvision;
