import React, { useCallback, useEffect, useState } from "react";
import CreditIcon from "../../assets/Credit.svg";
import DebitIcon from "../../assets/Debit.svg";
import { Box, Grid, Paper, Typography } from "@mui/material";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { rupeeSymbol } from "../../common/Components/IconsAndComponents";
import "./ManagerDashboard.css";
import api from "../../api/http-common";
import Style from "../../common/Styles";
import Extension from "../../common/Extension";
import Logger from "../../common/Logger";

const data_array = [
  {
    id: 1,
    title: "available_fund_amount",
    name: "available_fund_amount",
    color: "#729d71",
  },
  {
    id: 2,
    title: "interest_amount_collected_today",
    name: "total_InterestAmountCredit",
    color: "#729d71",
    icon: CreditIcon,
  },
  {
    id: 3,
    title: "loan_given_today",
    name: "total_LoanAmountDebit",
    color: "#ad5373",
    icon: DebitIcon,
  },
];

const TotalCollected = () => {
  const { t } = useTranslation("global");
  const entity_id = parseInt(sessionStorage.getItem("entityId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const [marketValue, setMarketValue] = useState([]);
  const [itemRate, setItemRate] = useState([]);
  const [branchLatestFund, setBranchLatestFund] = useState({});
  const [branch, setBranch] = useState({});
  const logger = new Logger({ level: "log" });
  const fetchData = useCallback(async () => {
    try {
      logger.log("Initiate Fetch current market value");
      let marketValue = await api
        .get(`/loan/CurrentMarketValue/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch current market value Successfully");
      setMarketValue(marketValue);
      logger.log("Initiate Fetch branches");
      let branchData = await api
        .get(`branch/${branch_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch branches Successfully");
      setBranch(branchData);
    } catch (error) {
      logger.log(error);
    }
  }, [branch_id]);

  const fetchRate = useCallback(async () => {
    try {
      logger.log("Initiate Fetch current item rate");
      let itemRate = await api
        .get(`/loan/current-item-rate/${entity_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch current item rate Successfully");
      setItemRate(itemRate);
    } catch (error) {
      logger.log(error);
    }
  }, [entity_id]);

  const getFund = useCallback(async () => {
    try {
      const BRANCH_API = `/Fund/CurrentTotalAmountByBranch/${branch_id}`;
      logger.log("Initiate Fetch current total fund");
      const Branch_res = await api.get(BRANCH_API);
      logger.log("Fetch current total fund Successfully");
      setBranchLatestFund(Branch_res.data);
    } catch (error) {
      logger.log(error);
    }
  }, [branch_id]);

  useEffect(() => {
    fetchData();
    fetchRate();
    getFund();
  }, [fetchData, fetchRate, getFund]);
  const [total_collection, setTotal_collection] = useState([]);

  const fundData = useCallback(async () => {
    try {
      const fundAPI = `/dashboard/fundTransferSumTodayByBranch/${branch_id}`;
      logger.log("Initiate Fetch fund transfer sum today by branch");
      const response = await api
        .get(fundAPI)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch fund transfer sum today by branch Successfully");
      setTotal_collection(response || []);
    } catch (err) {
      logger.log(err);
    }
  }, [branch_id]);

  useEffect(() => {
    fundData();
  }, [fundData]);

  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={2} sx={{ mb: 1 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              ...Style.titleText(),
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
          >
            {branch &&
              Extension.branchSuffix(branch.branch_name, branch.branch_name_tn)}
          </Typography>
        </Grid>

        {/* Data Cards */}
        {data_array.map((item) => (
          <Grid item xs={12} sm={12} md={6} lg={2} key={item.id}>
            {item.title !== "available_fund_amount" && (
              <Paper elevation={8} className={"amount_wrapper"}>
                <Box className="title">
                  <Typography
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--font-size)",
                    }}
                    variant="h6"
                    component="p"
                  >
                    {t(`dashboard.${item.title}`)}
                  </Typography>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Box
                    className="man_detail"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <img src={item.icon} alt="JSL" height={40} width={40} />
                    <span className="man_count">
                      <CountUp
                        start={0}
                        end={total_collection[item.name]}
                        duration={2.5}
                        prefix={rupeeSymbol}
                        useIndianSeparators={true}
                        style={{ fontSize: 40 }}
                      />
                    </span>
                  </Box>
                  <div
                    className="silver-text"
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "end",
                      color: "#848482",
                    }}
                  >
                    <Typography
                      component={"p"}
                      variant="p"
                      className="vertical-text"
                    >
                      <b>{t("common.today")}</b>
                    </Typography>
                  </div>
                </div>
              </Paper>
            )}
            {item.title === "available_fund_amount" && (
              <Paper
                elevation={8}
                className={"amount_wrapper"}
                sx={{
                  background:
                    "linear-gradient(to bottom, #58A3FF, #81BAFF, #B0D4FF, #BCDAFF, #DEEAF9)",
                }}
              >
                <Box className="title">
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--font-size)",
                      color: "black",
                    }}
                    component="p"
                  >
                    {t("dashboard.available_fund_amount")}
                  </Typography>
                </Box>
                <Box className="man_detail">
                  <span className="man_count">
                    <CountUp
                      start={0}
                      end={branchLatestFund.total_fund_amount}
                      duration={2.5}
                      // decimals={2}
                      prefix={rupeeSymbol}
                      useIndianSeparators={true}
                      style={{ fontSize: 40, color: "black" }}
                    />
                  </span>
                </Box>
              </Paper>
            )}
          </Grid>
        ))}

        {/* Market Rates */}
        <Grid item xs={12} sm={12} md={12} lg={4} sx={{ width: "100%" }}>
          <Grid
            container
            columnSpacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "end",
            }}
          >
            <Grid item xs={6} className="header-content" height={"100%"}>
              <Paper
                className="item_rate_silver"
                elevation={8}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  // height:'14vh'
                }}
              >
                <div className="item_names_rates">
                  <div className="man_item_names_rates">
                    <div
                      className="item_rate_type"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span className="silver" style={{fontSize:'16.5px',fontWeight:'500'}}>{t("dashboard.shop_rate")}</span>
                      <div>
                        <span style={{ margin: "0 5px" }}>:&nbsp;</span>
                        <CountUp
                          style={{ marginRight: "20px" }}
                          start={0}
                          end={itemRate ? itemRate.silver_rate : 0}
                          duration={2.5}
                          decimals={2}
                          prefix={`${rupeeSymbol} `}
                          useIndianSeparators={true}
                        />
                      </div>
                    </div>

                    <div
                      className="item_rate_type"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span className="silver" style={{fontSize:'16.5px',fontWeight:'500'}}>
                        {t(`dashboard.market_rate`)}
                      </span>
                      <div>
                        <span style={{ margin: "0 5px" }}>:&nbsp;</span>
                        <CountUp
                          // className="common-font-style"
                          style={{ marginRight: "20px" }}
                          start={0}
                          end={marketValue ? marketValue.Silver : 0}
                          duration={2.5}
                          decimals={2}
                          prefix={`${rupeeSymbol} `}
                          useIndianSeparators={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="silver-text"
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "end",
                      color: "#848482",
                    }}
                  >
                    <Typography
                      component={"p"}
                      variant="p"
                      sx={{ fontSize: 20 }}
                      className="vertical-text"
                    >
                      <b>{t("dashboard.silver")}</b>
                    </Typography>
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={6} className="header-content">
              <Paper
                className="item_rate_gold"
                elevation={8}
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div className="item_names_rates">
                  <div className="man_item_names_rates">
                    <div
                      className="item_rate_type"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* <span className="silver">
                        {t("dashboard.shopRate")}:&nbsp;
                      </span> */}
                      <span className="gold" style={{fontSize:'16.5px',fontWeight:'500'}}>{t("dashboard.normal_gold")}</span>
                      <div>
                        <span style={{ margin: "0 5px" }}>:&nbsp;</span>
                        <CountUp
                          style={{ marginRight: "20px" }}
                          start={0}
                          end={itemRate ? itemRate.gold_rate : 0}
                          duration={2.5}
                          decimals={2}
                          prefix={`${rupeeSymbol} `}
                          useIndianSeparators={true}
                        />
                      </div>
                    </div>
                    <div
                      className="item_rate_type"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* <span className="silver">
                        {t("dashboard.shopRate")}:&nbsp;
                      </span> */}
                      <span className="gold"  style={{fontSize:'16.5px',fontWeight:'500'}}>{t("dashboard.hall_mark")}</span>
                      <div>
                        <span style={{ margin: "0 5px" }}>:&nbsp;</span>
                        <CountUp
                          style={{ marginRight: "20px" }}
                          start={0}
                          end={itemRate ? itemRate.hallmark_gold_rate : 0}
                          duration={2.5}
                          decimals={2}
                          prefix={`${rupeeSymbol} `}
                          useIndianSeparators={true}
                        />
                      </div>
                    </div>
                    <div
                      className="item_rate_type"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span className="gold" style={{fontSize:'16.5px',fontWeight:'500'}}>
                        {t("dashboard.market_rate")}
                      </span>
                      <div>
                        <span style={{ margin: "0 5px" }}>:&nbsp;</span>
                        <CountUp
                          style={{ marginRight: "20px" }}
                          start={0}
                          end={marketValue ? marketValue.Gold : 0}
                          duration={2.5}
                          decimals={2}
                          prefix={`${rupeeSymbol} `}
                          useIndianSeparators={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="silver-text"
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "end",
                      top:-15,
                      color: "#A38A00",
                    }}
                  >
                    <Typography
                      component={"p"}
                      variant="p"
                      sx={{ fontSize: 20 }}
                      className="vertical-text"
                    >
                      <b>{t("dashboard.gold")}</b>
                    </Typography>
                  </div>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TotalCollected;
