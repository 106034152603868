import { InputAdornment, TextField } from "@mui/material";
import React from "react";

export const CommonTextField = (props) => {
  const {
    name,
    type,
    register,
    readOnly,
    comboFixWord,
    position,
    pattern,
    clickFunction,
    clickEvent,
    defaultValue,
  } = props;

  const currentDate = new Date().toISOString().split("T")[0];

  const handleInput = (event) => {
    // Allow only numbers and a dot
    if (pattern === "float" || type === "number") {
      // Replace any character that is not a digit or dot
      event.target.value = event.target.value.replace(/[^0-9.]/g, "");

      // Ensure only one dot is present
      const dotCount = (event.target.value.match(/\./g) || []).length;
      if (dotCount > 1) {
        // If more than one dot, remove the extra dots
        event.target.value = event.target.value.replace(
          /\./g,
          (match, offset) => {
            return offset === event.target.value.lastIndexOf(".") ? "." : "";
          }
        );
      }

      // Restrict decimal values to two digits
      const decimalIndex = event.target.value.indexOf(".");
      if (decimalIndex !== -1) {
        const integerPart = event.target.value.slice(0, decimalIndex);
        let decimalPart = event.target.value.slice(decimalIndex + 1);
        decimalPart = decimalPart.slice(0, 3); // Take only two decimal places
        event.target.value = `${integerPart}.${decimalPart}`;
      }

      if (clickEvent) clickFunction(event.target.value);
    } else {
      if (clickEvent) clickFunction(event.target.value);
    }
  };

  return (
    <TextField
      {...register(name, {
        required:
          name === "transaction_id" ||
          name === "locker_id" ||
          name === "repayment_amount"
            ? false
            : true,
      })}
      required={
        name === "transaction_id" ||
        name === "locker_id" ||
        name === "repayment_amount"
          ? false
          : true
      }
      sx={{
        backgroundColor: "var(--text-field)",
        "& .MuiSvgIcon-root,.MuiTypography-root": {
          color: "var(--font-color)", // Change the color of the dropdown icon
        },
      }}
      fullWidth
      size="small"
      onInput={handleInput}
      type={type}
      defaultValue={
        defaultValue
          ? defaultValue
          : type === "number"
          ? 0
          : type === "date"
          ? currentDate
          : ""
      }
      onKeyDown={(e) => {
        if (type === "number") {
          ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
        } else {
          return;
        }
      }}
      InputProps={{
        readOnly: readOnly,
        inputProps: {
          min: 0,
        },
        startAdornment: position === "start" && (
          <InputAdornment position={position}>{comboFixWord}</InputAdornment>
        ),
        endAdornment: position === "end" && (
          <InputAdornment position={position}>{comboFixWord}</InputAdornment>
        ),
        style: {
          backgroundColor: "var(--text-field)",
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          height: "40px",
          color: "var(--font-color)",
          fontWeight:
            (name === "repayment_amount" ||
              name === "hands_on_amount" ||
              name === "fund_amount") &&
            "800",
        },
      }}
    />
  );
};
