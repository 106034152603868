import CryptoJS from "crypto-js";
import moment from "moment";
import translate from "translate";
import api from "../api/http-common";
import Logger from "./Logger";

// const Navigate = useNavigate();
const logger = new Logger({ level: "log" });
class Extension {
  formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  formatDateDMY(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  }

  async logout() {
    try {
      const refresh = sessionStorage.getItem(`refresh`);
      if (
        refresh !== undefined ||
        (refresh !== null) | (refresh !== "") ||
        !refresh
      ) {
        const Token = CryptoJS.AES.decrypt(refresh, "jsl_graspear").toString(
          CryptoJS.enc.Utf8
        );
        ////console.log(Token);
        logger.log("Logout initiated");
        await api.post("/logout", { refresh_token: Token });
        sessionStorage.clear();
        logger.log("Logout successful");
        // Navigate("/login");
        window.location.href = "/";
        return;
      }
      sessionStorage.clear();
      // Navigate("/login");
      window.location.href = "/";
      return;
    } catch (error) {
      logger.log(error);
    }
  }

  onlyText = (e) => {
    const newValue = e.target.value;
    const containsNonLettersOrSpaces = /[^a-zA-Z\s]/.test(newValue);

    if (containsNonLettersOrSpaces) {
      // If the new value contains non-letters or non-spaces, remove them
      e.target.value = newValue.replace(/[^a-zA-Z\s]/g, "");
    } else {
      // If the new value doesn't contain non-letters or non-spaces, update the value as usual
      return newValue;
    }
  };

  onlyTamilText = (e) => {
    const newValue = e.target.value;
    const containsNonTamilCharacters = /[^அ-ஹ\s]/.test(newValue);

    if (containsNonTamilCharacters) {
      // If the new value contains non-Tamil characters or non-spaces, remove them
      e.target.value = newValue.replace(/[^\u0B80-\u0BFF\s]/g, "");
    } else {
      // If the new value doesn't contain non-Tamil characters or non-spaces, update the value as usual
      return newValue;
    }
  };

  onlyTextAndNumber = (e) => {
    const newValue = e.target.value;
    const containsNonLettersOrSpaces = /[^a-zA-Z0-9\s]/.test(newValue);

    if (containsNonLettersOrSpaces) {
      // If the new value contains non-letters or non-spaces, remove them
      e.target.value = newValue.replace(/[^a-zA-Z0-9\s]/g, "");
    } else {
      // If the new value doesn't contain non-letters or non-spaces, update the value as usual
      return newValue;
    }
  };

  findLastSequence = (sequences, nprefix) => {
    if (sequences.length === 0) {
      return `${nprefix}A001`; // No sequences available
    }

    // Extract the prefix and numeric parts from the last sequence in the sorted array
    const lastSequence = sequences[sequences.length - 1];
    const [_, prefix, numberPart] = lastSequence.match(/([A-Z]+)(\d+)/);

    // Find the maximum numeric part
    const lastNumber = parseInt(numberPart, 10);

    // Determine the prefix for the final sequence
    let nextPrefix = prefix; // Use the prefix of the last sequence as a starting point
    let nextNumber = lastNumber + 1;

    // Check if the numeric part exceeds 1000
    if (nextNumber > 1000) {
      // Increment the third character of the prefix
      nextPrefix = String.fromCharCode(prefix.charCodeAt(2) + 1);

      // If the third character reaches 'Z', reset to 'A' and increment the second character
      if (nextPrefix > "Z") {
        nextPrefix = String.fromCharCode(prefix.charCodeAt(0) + 1) + "A";
      }

      nextNumber = 1; // Reset the numeric part to 1
    }

    // Create the final sequence based on the determined prefix and numeric part
    let finalSequence =
      nextNumber == 1
        ? `${nprefix}${nextPrefix}${nextNumber.toString().padStart(3, "0")}`
        : `${nextPrefix}${nextNumber.toString().padStart(3, "0")}`;

    return finalSequence;
  };

  findLoanSequence = (sequences) => {
    if (sequences.length === 0) {
      return "LON001"; // No sequences available
    }

    // Extract the prefix and numeric parts from the last sequence in the sorted array
    const lastSequence = sequences[sequences.length - 1];
    const [_, prefix, numberPart] = lastSequence.match(/([A-Z]+)(\d+)/);

    // Find the maximum numeric part
    const lastNumber = parseInt(numberPart, 10);

    // Determine the prefix for the final sequence
    let nextPrefix = prefix; // Use the prefix of the last sequence as a starting point
    let nextNumber = lastNumber + 1;

    // Check if the numeric part exceeds 1000
    if (nextNumber > 1000) {
      // Increment the third character of the prefix
      nextPrefix = String.fromCharCode(prefix.charCodeAt(2) + 1);

      // If the third character reaches 'Z', reset to 'A' and increment the second character
      if (nextPrefix > "Z") {
        nextPrefix = String.fromCharCode(prefix.charCodeAt(0) + 1) + "A";
      }

      nextNumber = 1; // Reset the numeric part to 1
    }

    // Create the final sequence based on the determined prefix and numeric part
    let finalSequence =
      nextNumber == 1
        ? `AL${nextPrefix}${nextNumber.toString().padStart(3, "0")}`
        : `${nextPrefix}${nextNumber.toString().padStart(3, "0")}`;

    return finalSequence;
  };

  interestRateCalculation = (
    balancepa,
    lastduedate,
    duration,
    inrmin,
    inrmax
  ) => {
    const today = new Date();
    const P = balancepa;
    const dueDate = lastduedate;
    const dats = new Date(dueDate);
    const diffTime = Math.abs(today - dats);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24) - 1);
    const N = diffDays / 365;
    const R = N < duration / 12 ? inrmin : inrmax;
    const I = (P * N * R) / 100;
    //console.log(N);
    //console.log(today);
    //console.log(dats);
    return Math.floor(I);
  };

  interestRateCul = (
    method,
    principalAmount,
    loanDate,
    lastDueDate,
    duration,
    minRate,
    maxRate,
    loanCloser,
    loanCreation,
    custInterest,
    interest_per,
    additionl_interest,
    paid_on
  ) => {
    let currentDate = paid_on ? new Date(paid_on) : new Date(); //get the current Date
    let dueDate = new Date(lastDueDate); //change the duedate format for narmal
    let timeDifference;
    let totalDays;
    let interest = 0;
    let minInterestPerc = 0;
    let maxInterestPerc = 0;
    let minTimePeriod = {
      totalDays: 0,
      months: 0,
      remainingDays: 0,
      remainingWeeks: 0,
    };
    let maxTimePeriod = {
      totalDays: 0,
      months: 0,
      remainingDays: 0,
      remainingWeeks: 0,
    };

    //Calculate the min and max interest rate based on loan duration and loan created date
    const loanDateObject = new Date(loanDate);
    const loanEndDate = new Date(loanDateObject);
    loanEndDate.setMonth(loanEndDate.getMonth() + duration);
    // campare to max and min interest duration
    if (loanCreation) {
      interest = this.loanCreationInterestRateCul(
        principalAmount,
        loanDate,
        lastDueDate,
        minRate
      );
    }

    if (!loanCreation) {
      let minInterest = this.minInterestRateCul(
        method,
        principalAmount,
        loanDate,
        lastDueDate,
        duration,
        minRate,
        loanCloser,
        additionl_interest,
        currentDate
      );
      interest = parseFloat(interest) + minInterest.interest;
      minTimePeriod = minInterest.timeDifference;
      minInterestPerc = minInterest.interest_percentage;
      //console.log("Mim -", minInterest);
    }
    //console.log("Min interest  - ", interest, additionl_interest);

    if (loanEndDate < currentDate && !additionl_interest) {
      //console.log("max Interst before - ", interest);
      let maxInterest = this.maxInterestRateCul(
        method,
        principalAmount,
        loanDate,
        lastDueDate,
        duration,
        minRate,
        maxRate,
        loanCloser,
        loanCreation,
        custInterest,
        interest_per,
        currentDate
      );
      interest = parseFloat(interest) + maxInterest.interest;
      maxTimePeriod = maxInterest.timeDifference;
      maxInterestPerc = maxInterest.interest_percentage;
      //console.log("max Interst  - ", maxInterest);
    }
    //console.log("Before Interest - ", interest);
    const initialInterest = interest;
    // Calculate the differences in days, weeks, and months
    //console.log("current date - ", currentDate);
    //console.log("dueDate date - ", dueDate);
    timeDifference = currentDate - dueDate;

    const startDate = moment(dueDate);
    const endDate = moment(currentDate);

    totalDays = endDate.diff(startDate, "days");

    // Calculate the difference in months
    const months = endDate.diff(startDate, "months");

    // Calculate the remaining days
    const remainingDays = endDate.diff(startDate.add(months, "months"), "days");

    // Calculate remaining weeks
    const weeks = Math.floor(remainingDays / 7); // Quotient
    const weekRemainder = remainingDays % 7; // Remainder
    const remainingWeeks = weekRemainder > 0 ? 1 + weeks : weeks;
    const R =
      custInterest && !additionl_interest
        ? interest_per
        : loanEndDate >= currentDate || loanCreation
        ? minRate
        : additionl_interest && additionl_interest === true
        ? minRate
        : maxRate;
    const repaymentAmount = loanCloser
      ? (
          parseFloat(principalAmount) + parseFloat(interest > 0 ? interest : 0)
        ).toFixed(2)
      : 0;
    const interest_perc_type =
      loanEndDate >= currentDate || loanCreation
        ? "minRate"
        : additionl_interest
        ? "minRate"
        : "maxRate";
    const timePeriod =
      totalDays > 0
        ? months > 0
          ? `${months} months ${remainingDays} days`
          : `${remainingDays} days`
        : 0;
    minTimePeriod =
      minTimePeriod.totalDays > 0
        ? minTimePeriod.months > 0
          ? `${minTimePeriod.months} months ${minTimePeriod.remainingDays} days`
          : `${minTimePeriod.remainingDays} days`
        : `0 day`;
    maxTimePeriod =
      maxTimePeriod.totalDays > 0
        ? maxTimePeriod.months > 0
          ? `${maxTimePeriod.months} months ${maxTimePeriod.remainingDays} days`
          : `${maxTimePeriod.remainingDays} days`
        : `0 day`;
    return {
      interest_amount: interest < 0 ? 0 : parseFloat(interest).toFixed(0),
      repayment_amount: parseInt(repaymentAmount).toFixed(0),
      remainingDays: remainingDays,
      time_period: timePeriod,
      min_time_period: `${minTimePeriod} - ${minInterestPerc} %`,
      max_time_period: `${maxTimePeriod} - ${maxInterestPerc} %`,
      interest_percentage: R,
      interest_perc_type: interest_perc_type,
      initialInterest: initialInterest,
    };
  };

  // calculate the minimun interest rate
  minInterestRateCul = (
    method,
    principalAmount,
    loanDate,
    lastDueDate,
    duration,
    minRate,
    loanCloser,
    additionl_interest,
    paid_on
  ) => {
    let last_due_date = new Date(lastDueDate);
    let date1 = new Date(paid_on); /* ? new Date(paid_on) : new Date() */
    let selected_date = new Date(
      paid_on
    ); /* ? new Date(paid_on) : new Date() */
    //Calculate loan end date based on the loan duration and loan created date
    const loanDateObject = new Date(loanDate);
    const loanEndDate = new Date(loanDateObject);
    loanEndDate.setMonth(loanEndDate.getMonth() + duration);
    if (loanEndDate < selected_date && loanEndDate > last_due_date) {
      date1 = loanEndDate;
    }
    // get the interest time period
    let timeDifference = this.timeDifference(date1, last_due_date);
    if (last_due_date >= loanEndDate) {
      timeDifference = this.timeDifference(selected_date, selected_date);
    }
    if (additionl_interest) {
      timeDifference = this.timeDifference(selected_date, last_due_date);
    }
    const P = principalAmount; // current principal amount
    const N = timeDifference.months;
    const R = minRate;
    let interest = (P * R * N) / 100;
    method = timeDifference.remainingDays > 0 ? method : "";
    // Calculate the remainder days interest rate calculation
    let remainterDaysInteres = 0;
    switch (method) {
      case "month":
        remainterDaysInteres = (P * 1 * R) / 100;
        //console.log("remainder month -", 1, remainterDaysInteres);
        break;
      case "week":
        remainterDaysInteres = (P * timeDifference.remainingWeeks * R) / 400;
        break;
      case "day":
        remainterDaysInteres = (P * timeDifference.remainingDays * R) / 3000;
        break;
      default:
        break;
    }
    return {
      interest: parseFloat(
        parseFloat(interest + remainterDaysInteres).toFixed(2)
      ),
      timeDifference: timeDifference,
      interest_percentage: R,
    };
  };

  // calculate the maximum interest rate
  maxInterestRateCul = (
    method,
    principalAmount,
    loanDate,
    lastDueDate,
    duration,
    minRate,
    maxRate,
    loanCloser,
    loanCreation,
    custInterest,
    interest_per,
    paid_on
  ) => {
    let last_due_date = new Date(lastDueDate);
    let date1 = paid_on;
    // ? new Date(paid_on).toISOString().split("T")[0]
    // : new Date().toISOString().split("T")[0];
    //Calculate loan end date based on the loan duration and loan created date
    const loanDateObject = new Date(loanDate);
    const loanEndDate = new Date(loanDateObject);
    loanEndDate.setMonth(loanEndDate.getMonth() + duration);
    //console.log("last loan date", loanEndDate.toISOString().split("T")[0]);
    if (last_due_date < loanEndDate) {
      last_due_date = loanEndDate.setDate(loanEndDate.getDate() + 1);
    }
    // get the interest time period
    let timeDifference = this.timeDifference(date1, last_due_date);
    //console.log("Max loan time difference - ", date1, last_due_date);
    const P = principalAmount; // current principal amount
    const N = timeDifference.months;
    const R = custInterest ? interest_per : maxRate;
    let interest = (P * R * N) / 100;

    method = timeDifference.remainingDays > 0 ? method : "";
    let remainterDaysInteres = 0;
    switch (method) {
      case "month":
        remainterDaysInteres = (P * 1 * R) / 100;
        //console.log("remainder month -", 1, remainterDaysInteres);
        break;
      case "week":
        remainterDaysInteres = (P * timeDifference.remainingWeeks * R) / 400;
        break;
      case "day":
        remainterDaysInteres =
          (P * (timeDifference.remainingDays + 1) * R) / 3000;
        break;
      default:
        break;
    }
    return {
      interest: parseFloat(
        parseFloat(interest + remainterDaysInteres).toFixed(2)
      ),
      timeDifference: timeDifference,
      interest_percentage: R,
    };
  };

  //1 month interest calculation for loan creation
  loanCreationInterestRateCul = (
    principalAmount,
    loanDate,
    lastDueDate,
    minRate
  ) => {
    //console.log("Loan creation");
    // get the interest time period
    let timeDifference = this.timeDifference(lastDueDate, loanDate);
    const P = principalAmount; // current principal amount
    const N = 1;
    const R = minRate;
    const interest = (P * N * R) / 100;
    return interest;
  };

  //calculate the two date time differece
  timeDifference = (date1, date2) => {
    const startDate = moment(date2);
    const endDate = moment(date1);

    // Calculate the difference in days
    const totalDays = endDate.diff(startDate, "days");

    // Calculate the difference in months
    const months = endDate.diff(startDate, "months");

    // Calculate the remaining days
    const remainingDays = endDate.diff(startDate.add(months, "months"), "days");

    // Calculate the remaining weeks
    const weeks = Math.floor(remainingDays / 7);
    const remainingWeeks = weeks + (remainingDays % 7 > 0 ? 1 : 0);

    return { totalDays, months, remainingDays, remainingWeeks };
  };

  languageBasedValue(value, tm_value) {
    const language = localStorage.getItem("language");

    if (language === "tm") {
      return tm_value;
    } else {
      return value;
    }
  }
  translateInput(e, setState) {
    translate.engine = "google";
    translate.key = process.env.DEEPL_KEY;

    translate(
      e === "" || e === undefined || e === null ? "Type Here" : e.target.value,
      "ta"
    )
      .then((result) => {
        setState(result);
      })
      .catch((error) => {
        setState("");
      });
  }
  translateInputfrom(fromState, e, setState, textOnly) {
    translate.engine = "google";
    translate.key = process.env.DEEPL_KEY;

    fromState(textOnly ? this.onlyText(e) : e.target.value);
    translate(
      e === "" || e === undefined || e == null ? "Type Here" : e.target.value,
      "ta"
    )
      .then((result) => {
        setState(result);
      })
      .catch((error) => {
        setState("");
        //logger.log(error);
      });
  }

  formatCurrency(value) {
    const Val = parseInt(value);
    const formatter = new Intl.NumberFormat("en-IN", {
      // style: "currency",
      currency: "INR",
    });
    return formatter.format(Val);
  }
  //Normal number change to the Indian currency format
  formatAsIndianRupees(value) {
    return value.toLocaleString("en-IN");
  }

  //Convert Indian currency to normal number
  convertIndianCurrencyToNumber(formattedValue) {
    // Remove commas and convert to a number
    return parseFloat(formattedValue.replace(/,/g, ""));
  }

  getCurrentDateTime() {
    const now = new Date();

    // Date
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(now.getDate()).padStart(2, "0");

    // Time
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

    // Formatted date and time
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return formattedDateTime;
  }

  formatAMPMDate(dateString) {
    // const dateString = "2024-02-10T15:13:49.831000Z";

    // Create a Date object from the string
    const date = new Date(dateString);

    // Extract the time part separately (optional)
    const timeString = dateString.slice(11, 19);

    // Create a new Date object with the desired time zone (UTC)
    const utcDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      timeString.substring(0, 2),
      timeString.substring(3, 5),
      0
    );

    // Format the date and time in UTC
    const formattedDate = utcDate.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedDate;
  }

  formatdateTime(dateTime) {
    let date = dateTime
      .toString()
      .replace("T", " ")
      .replace("Z", " ")
      .split(".")[0]
      .split(" ")[0];
    let time = dateTime
      .toString()
      .replace("T", " ")
      .replace("Z", " ")
      .split(".")[0]
      .split(" ")[1];
    let formattedDate = this.formatDateDMY(new Date(date));

    return `${formattedDate} ${time}`;
  }
  branchSuffix(branch_name, branch_name_tn) {
    const isBranchContains =
      branch_name && branch_name.toLowerCase().includes("branch");
    const isBranchTContains =
      branch_name_tn && branch_name_tn.toLowerCase().includes("கிளை");
    const returnBranch = isBranchContains
      ? branch_name.split(/branch/i)[0]
      : branch_name;
    const returnTBranch = isBranchTContains
      ? branch_name_tn.split(/கிளை/i)[0]
      : branch_name_tn;
    return this.languageBasedValue(
      returnBranch && `${returnBranch} Branch`,
      returnTBranch && `${returnTBranch} கிளை`
    );
  }

  convertToK(number) {
    let num = parseInt(number);
    if (num >= 1000) {
      return num / 1000 + "K";
    } else {
      return num;
    }
  }
}
export default new Extension();
