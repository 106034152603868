import React, { useRef } from "react";
import "./Help.css";
import HeaderComponent from "./HeaderComponent";
const HelpScreen = () => {
  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const dashboardRef = useRef(null);
  const userRef = useRef(null);
  const CustomerRef = useRef(null);
  const SchemeRef = useRef(null);
  const AlertRef = useRef(null);
  const loanCreationRef = useRef(null);
  const loanListRef = useRef(null);
  const receiptRef = useRef(null);
  const auctionRef = useRef(null);
  const inventoryRef = useRef(null);
  const transationRef = useRef(null);
  const summaryRef = useRef(null);

  return (
    <div>
      <HeaderComponent
        dashboardRef={dashboardRef}
        summaryRef={summaryRef}
        userRef={userRef}
        SchemeRef={SchemeRef}
        CustomerRef={CustomerRef}
        AlertRef={AlertRef}
        loanCreationRef={loanCreationRef}
        loanListRef={loanListRef}
        receiptRef={receiptRef}
        auctionRef={auctionRef}
        inventoryRef={inventoryRef}
        transationRef={transationRef}
        scrollToSection={scrollToSection}
        children={
          <div className="help-container">
            <div className="dashborad-content" ref={dashboardRef}>
              <h2>Dashboard</h2>
              <ul>
                <li>
                  The dashboard gives an analytical view of the loans and
                  customers.
                </li>
                <li>
                  The current Market value of the commodity Gold and Silver has
                  been displayed.
                </li>
                <li>
                  The total Loan count, and the status of the loans (opened,
                  closed, rejected, and loan defaulters) count has been
                  displayed.
                </li>
                <li>
                  Branch Income chart gives the credit amount monthly, weekly,
                  and yearly, and all data.
                </li>
                <li>
                  The Branch Wise Expense chart gives the debit amount monthly,
                  weekly, and yearly and all data.
                </li>
                <li>
                  Loan Given vs. interest collected chart gives the comparison
                  of income and expense monthly, weekly, and yearly and all
                  data.
                </li>
                <li>
                  Income by schemes gives the comparison of each scheme
                  collection monthly, weekly, and yearly and all data.
                </li>
                <li>
                  Through the rupee icon in the app bar, the proprietor can fix
                  the rate for hallmark, normal gold, and silver per gram, and
                  the market value for gold and silver has been displayed.
                </li>
                <li>Item Rate chart gives the fluctuation in the HOD date.</li>
              </ul>
            </div>
            <div className="dashborad-content" ref={userRef}>
              <h2>User Master</h2>
              <p>
                The proprietor can create/edit the Manager and proprietor for
                each branch and create login credentials for them using User
                Master
              </p>
            </div>
            <div className="dashborad-content" ref={SchemeRef}>
              <h2>Loan Scheme Master</h2>
              <p>
                proprietor can create/edit schemes for loans using Scheme Master
              </p>
            </div>
            <div className="dashborad-content" ref={CustomerRef}>
              <h2>Customer Master</h2>
              <p>
                The proprietor and Manager can create/edit the customer using
                Customer Master.
              </p>
            </div>
            <div className="dashborad-content" ref={loanCreationRef}>
              <h2>Loan Creation</h2>
              <ul>
                <li>
                  User can create a loan on the create loan page and can
                  auto-generate the loan creation form.
                </li>
                <li>
                  Interest rate will be considered based on the scheme
                  selection.
                </li>
                <li>
                  Threshold value will be calculated based on the HOD rate, and
                  the loan maximum amount estimation will be provided.
                </li>
                <li>
                  If the loan request amount is greater than the threshold
                  amount, proprietor approval is required.
                </li>
                <li>
                  Loan cannot be processed when the requested amount is greater
                  than the market value.
                </li>
                <li>
                  Gold rates are handled separately for KDM and Hallmark (916)
                  gold.
                </li>
                <li>
                  Silver items can also be pledged under the scheme based on the
                  particular item category.
                </li>
                <li>
                  Net weight will be auto-calculated by 0.5g, and for each
                  stone, 0.025g will be deducted.
                </li>
              </ul>
            </div>
            <div className="dashborad-content" ref={receiptRef}>
              <h2>Receipt Generation</h2>
              <ul>
                <li>
                  On the receipt page, the user can calculate the interest value
                  and generate the ID and receipt for each transaction.
                </li>
                <li>
                  The interest amount will be calculated, and the current
                  balance PA will be displayed.
                </li>
                <li>
                  Transaction history will be provided; for each transaction,
                  the receipt can be printed out and also can be downloaded.
                </li>
                <li>
                  Interest calculations will be calculated based on the scheme.
                </li>
              </ul>
            </div>
            <div className="dashborad-content" ref={auctionRef}>
              <h2>Auction Loan Process</h2>
              <ul>
                <li>
                  The application will auto-filter the Loan defaulters, and the
                  admin has access to dispose of the loan and move to the
                  auction.
                </li>
              </ul>
            </div>
            <div className="dashborad-content" ref={AlertRef}>
              <h2>Alerts</h2>
              <ul>
                <li>
                  The application will alert the loan defaulters after one year
                  of default duration by default.
                </li>
                <li>
                  The number of times managers contacted the customers will be
                  captured in the alert.
                </li>
              </ul>
            </div>
            <div className="dashborad-content" ref={inventoryRef}>
              <h2>Inventory</h2>
              <p>
                The user can see a detailed view of inventory, items, and their
                rate.
              </p>
            </div>
            <div className="dashborad-content" ref={transationRef}>
              <h2>Transactions</h2>
              <p>The user can see the credit and debit amount details.</p>
            </div>
            <div className="dashborad-content" ref={summaryRef}>
              <h2>Summary</h2>
              <p>
                The user can get the summary of each loan, and the data can be
                downloaded.
              </p>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default HelpScreen;
