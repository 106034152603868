import axios from "axios";
import CryptoJS from "crypto-js";

const api = axios.create({
  // baseURL:"http://44.200.12.148:8000/",
  baseURL: "https://api.jslpro.in:4652/",
  
  // baseURL: "https://api.jslpro.in:4652/",
  // baseURL: "https://34.203.201.18:8084/",
  // baseURL:"http://34.203.201.18:8001/",
  // baseURL: "https://34.203.201.18:8001/",
  // baseURL: "https://jslpro.in:8083/",
  // baseURL: "http://127.0.0.1:8000/",

  headers: {
    "Content-Type": "application/json",
  },
}); 

const setAuthorizationHeader = (config) => {
  const token = sessionStorage.getItem("access");
  if (config.url === "login" /* || window.location.pathname == "/" */) {
    return config;
  }
  if (config.url !== "login" && token) {
    const accessToken = CryptoJS.AES.decrypt(token, "jsl_graspear").toString(
      CryptoJS.enc.Utf8
    );
    config.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    window.location.href = "/";
  }
  return config;
};

api.interceptors.request.use(
  (config) => setAuthorizationHeader(config),
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.config.url !== "login" &&
      error.response.status === 401
    ) {
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default api;
