import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router";

const NotificationDetailsContext = createContext();

const NotificationDetailsProvider = ({ children }) => {
  var [notifyPendingList, setNotifyPendingList] = useState(null);
  const location = useLocation();
  // const history = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/loanprocess") {
      setNotifyPendingList(null);
    }
  }, [location.pathname]);
  return (
    <NotificationDetailsContext.Provider
      value={{ notifyPendingList, setNotifyPendingList }}
    >
      {children}
    </NotificationDetailsContext.Provider>
  );
};

export { NotificationDetailsContext, NotificationDetailsProvider };
