import { MoreVert } from "@mui/icons-material";
import { Avatar, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import api from "../../api/http-common";
import { AppData } from "../../common/AppData";
import GridTable from "../../common/GridTable";
import JSLAppBar from "../../common/JSLAppBar";
import Loading from "../../common/Loading";
import "./SuperAdmin.css";
import TenantCreation from "./TenantCreation";
import Logger from "../../common/Logger";

const SuperAdmin = () => {
  const { t } = useTranslation("global");
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const [entityUpdate, setEntityUpdate] = useState(null);
  const [getEntity, setGetEntity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entityID, setEntityID] = useState("");
  const logger = new Logger({ level: "log" });
  const Columns = AppData.Tenant.map((column) => ({
    ...column,
    headerName: t(`Tenant.${column.field}`),
  }));

  const handleLoadingOpen = () => {
    setLoading(true);
  };

  const handleLoadingClose = () => {
    setLoading(false);
  };

  const getData = async () => {
    handleLoadingOpen();
    try {
      const data = await api
        .get("entity")
        .then((res) => res.data)
        .catch((err) => logger.log(err));
        console.log(data)
      setGetEntity(data);
    } catch (err) {
      logger.log(err)
    } finally {
      handleLoadingClose();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const enterEntityUpdate = async (data) => {
    handleLoadingOpen();
    setEntityUpdate(true);
    setEntityID(data.entity_id);
    handleLoadingClose();
  };

  const rows = getEntity.filter(data => data.name.toLowerCase() !== "graspear").map((data, index) => {
    return {
      id: index + 1,
      name: data.name,
      phone: data.phone,
      mail1: data.mail_id1,
      image: <Avatar src={data?.image} alt="no image" />,
      moreinfo: (
        <MoreVert
          onClick={() => enterEntityUpdate(data)}
          sx={{ cursor: "pointer" }}
        />
      ),
    };
  });

  // if (role_id !== 3 || !role_id) {
  //   Extension.logout();
  // }
  return (
    <>
      {entityUpdate ? (
        <TenantCreation
          updateEntitys={entityUpdate}
          entityUpdateClose={setEntityUpdate}
          entity_id={entityID}
        />
      ) : (
        <Paper className="commonpaper">
          <Loading open={loading} />
          <Toaster />
          <GridTable rows={rows} columns={Columns} height={"80vh"} />
        </Paper>
      )}
    </>
  );
};

export default SuperAdmin;
