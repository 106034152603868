import {
  DeleteRounded,
  EditNoteSharp,
  KeyboardBackspace,
  LibraryAddCheckRounded,
  LibraryAddRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CountUp from "react-countup";
import { Controller, useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import translate from "translate";
import api from "../../api/http-common";
import AddImage from "../../assets/AddImageIcon.svg";
import ImageProvision from "../../common/Components/ImageProvision";
import Extension from "../../common/Extension";
import Loading from "../../common/Loading";
import { LoanCreation } from "../../common/ReceiptGenerate/LoanCreation";
import { LoanCreationTM } from "../../common/ReceiptGenerate/LoanCreationTM";
import SnackBar from "../../common/SnackBar";
import Styles from "../../common/Styles";
import { CommonTextField } from "../../ui/CommonTextField";
import { SelectDropDown } from "../../ui/SelectDropDown";
import { AppendLoan } from "./AppendLoan";
import "./LoanProcess.css";
import Logger from "../../common/Logger";
import SMS_API_FUNCTION from "../../common/SMS_API_FUNCTION";

//Pledge item Name drop down values
const inputFieldsData = [
  {
    field: "branch",
    subset: false,
    title: "branch",
    type: "text",
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    inputType: "select",
    name: "branch_id",
    value: "branch_id",
    display_name: "branch_name",
    tm: "branch_name_tn",
    readOnly: true,
    ComboFixWord: "",
    position: "start",
    data: [],
    clickEvent: true,
    clickType: "onChange",
  },
  {
    field: "scheme",
    subset: false,
    title: "scheme name",
    type: "text",
    inputType: "select",
    name: "scheme",
    display_name: "scheme_name",
    value: "scheme_id",
    tm: "scheme_name_tn",
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    readOnly: false,
    ComboFixWord: "",
    position: "start",
    data: [],
    clickEvent: true,
    clickType: "onChange",
  },
  {
    field: "customer",
    subset: false,
    title: "search customer",
    type: "text",
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    inputType: "select",
    name: "customer",
    readOnly: true,
    ComboFixWord: "",
    display_name: "cus_name",
    value: "customer_id",
    tm: "cus_name_tn",
    position: "start",
    data: [],
  },
  {
    subset: true,
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    subsets: [
      {
        title: "loan date",
        type: "date",
        inputType: "input",
        name: "created_on",
        gridSize: { xs: 6, sm: 6, md: 6, lg: 6 },
        readOnly: true,
        ComboFixWord: "",
        position: "start",
      },
      {
        title: "pledge material type",
        type: "text",
        inputType: "input",
        gridSize: { xs: 6, sm: 6, md: 6, lg: 6 },
        name: "pledgeMaterialType",
        readOnly: true,
        ComboFixWord: "",
        position: "start",
        data: [],
      },
    ],
  },
  {
    subset: true,
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    subsets: [
      {
        field: "mode_of_payment",
        title: "mode of payment",
        type: "text",
        inputType: "select",
        name: "mode_of_payment",
        gridSize: { xs: 6, sm: 6, md: 6, lg: 6 },
        readOnly: true,
        ComboFixWord: "",
        position: "start",
        data: [
          { text: "Cash", value: "By Cash", text_tn: "பணம்" },
          {
            text: "Account Transfer",
            value: "By Account",
            text_tn: "கணக்கு பரிமாற்றம்",
          },
          { text: "UPI/ Others", value: "UPI/Others", text_tn: "UPI/ மற்றவை" },
        ],
        display_name: "text",
        tm: "text_tn",
        value: "value",
        clickEvent: false,
        clickType: "onChange",
      },
      {
        title: "threshold amount",
        type: "number",
        inputType: "input",
        name: "max_loan_amount",
        gridSize: { xs: 6, sm: 6, md: 6, lg: 6 },
        readOnly: true,
        ComboFixWord: "\u20B9",
        position: "start",
      },
    ],
  },
  {
    subset: false,
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    title: "requested loan amount",
    type: "number",
    inputType: "input",
    name: "loan_amount",
    readOnly: false,
    ComboFixWord: "\u20B9",
    position: "start",
  },
  {
    subset: false,
    title: "locker id",
    type: "text",
    inputType: "input",
    name: "locker_id",
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    readOnly: false,
  },
  {
    subset: false,
    title: "documentation charges",
    type: "number",
    inputType: "input",
    name: "doc_charges",
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    readOnly: false,
    ComboFixWord: "\u20B9",
    position: "start",
  },
  {
    subset: true,
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    subsets: [
      {
        subset: false,
        title: "hands on amount",
        type: "number",
        inputType: "input",
        name: "hands_on_amount",
        gridSize: { xs: 6, sm: 6, md: 6, lg: 6 },
        readOnly: true,
        ComboFixWord: "\u20B9",
        position: "start",
      },
      {
        title: "fund amount",
        type: "number",
        inputType: "input",
        name: "fund_amount",
        gridSize: { xs: 6, sm: 6, md: 6, lg: 6 },
        readOnly: true,
        ComboFixWord: "\u20B9",
        position: "start",
      },
    ],
  },
];

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#777",
    color: "#fff",
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
    textAlign: "center",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#D9D9D9",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "#999", // Change the background color on hover
  },
  "&:last-child": {
    position: "sticky",
    bottom: 0,
    backgroundColor: "#999", // Change the background color of the sticky row
  },
  "&:last-child td": {
    color: "#fff",
    fontSize: 18,
    padding: 8,
  },
  "&:last-child td:first-child": {
    color: "#fff",
    fontSize: 28,
  },
}));

export const EditLoanProcess = (props) => {
  const { t } = useTranslation("global");
  const logger = new Logger({ level: "log" });
  const { loanId, setShowEditProcess, fetchLoanDetails } = props;
  //console.log("Props - ", props);
  const { register, setValue, handleSubmit, control, getValues } = useForm();
  const [inputFields, setInputFields] = useState(inputFieldsData);
  const [materialList, setMaterialList] = useState([]);
  const [materialHeader, setMaterialHeader] = useState([]);
  const [totalMaterial, setTotalMaterial] = useState([]);
  const [saveBtnVisible, setSaveBtnVisible] = useState(false);
  const [pledgeItemDropDown, setPledgeItemDropDown] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [documentCharge, setDocumentCharge] = useState(0);
  const [requestedamount, setRequestedAmount] = useState(0);
  const [thresHoldAmount, setThresHoldAmount] = useState(0);
  const [status, setStatus] = useState("Pending");
  const entityId = sessionStorage.getItem("entityId");
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const [totalAmount, setTotalAmount] = useState(0);
  const [pledgeMaterialType, setPledgeMaterialType] = useState("");
  const [remarks, setRemarks] = useState("");
  const [materialRate, setMaterialRate] = useState(null);
  const [editLoan, setEditLoan] = useState(false);
  const [appendEdit, setAppendEdit] = useState(false);
  const [loanNumber, setLoanNumber] = useState("");
  const [headerTxt, setHeaderTxt] = useState(t("common.loan details"));
  const [editLoanBtn, setEditLoanBtn] = useState(false);
  const userId = sessionStorage.getItem("userId");
  const [loading, setLoading] = useState(false); // pre loader initial false.
  const language = localStorage.getItem("language");
  const [schemeId, setSchemeId] = useState(0);
  const [marketRate, setMarketRate] = useState(null);
  const [image, setImage] = useState(null);
  const [contextMenu, setContextMenu] = React.useState(null);
  const [rowContextMenu, setRowContextMenu] = React.useState(null);
  const [selectedRow, setSelectedRow] = useState([]);

  const statusCategory = {
    Approved: "அனுமதிக்கப்பட்டது",
    Pending: "நிலுவையில் உள்ளது",
    Closed: "மூடப்பட்டது",
    Rejected: "நிராகரிக்கப்பட்டது",
    Disposed: "அகற்றப்பட்டது",
  };
  const [goldCategory, setGoldCategory] = useState([
    { text: "Normal Gold", value: "Normal Gold", text_tn: "சாதாரண தங்கம்" },
    {
      text: "Hallmark Gold(916)",
      value: "Hallmark Gold(916)",
      text_tn: "ஹால்மார்க் தங்கம் (916)",
    },
  ]);
  const [stoneType, setStoneType] = useState("");

  const Navigate = useNavigate();

  useEffect(() => {
    handleLoadingOpen();
    setMaterialList([]);
    fetchInitialDatas();
    fetchData();
  }, []);
  const fetchDataAndTransform = async (apiEndpoint, idKey, nameKey) => {
    logger.log(`Initiate Fetch ${apiEndpoint}`);
    const response = await api.get(apiEndpoint);
    logger.log(`Fetch ${apiEndpoint} Successfully`);
    return response.data.map((item) => ({
      ...item,
      value: item[idKey],
      text: item[nameKey],
    }));
  };

  const fetchInitialDatas = async () => {
    const branchList = await fetchDataAndTransform(
      `branch/byEntity/${entityId}`,
      "branch_id",
      "branch_name"
    );
    //console.log("Branch list - ", branchList);
    handleInputFields("branch", branchList);

    const schemeList = await fetchDataAndTransform(
      `scheme/schemes/${entityId}`,
      "scheme_id",
      "scheme_name"
    );
    handleInputFields("scheme", schemeList);
  };

  //get the edit record
  const fetchData = async () => {
    logger.log("Initiate Fetch loans");
    const loanRes = await api
      .get(`loan/${loanId}`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch loans Successfully");
    setMaterialHeader(loanRes);
    //console.log("Loan Response - ", loanRes);

    const originalDate = new Date(loanRes.created_on);
    const newDate = new Date(originalDate);
    const loanDate = new Date(newDate.setDate(originalDate.getDate() + 10))
      .toISOString()
      .split("T")[0];
    const currentDate = new Date().toISOString().split("T")[0];
    setEditLoanBtn(currentDate === loanRes.created_on);
    setAppendEdit(loanDate >= currentDate && !loanRes.loan_append);
    //console.log(loanDate >= currentDate && !loanRes.loan_append);
    //console.log(loanDate, currentDate, !loanRes.loan_append);
    logger.log("Initiate Fetch current item rate");
    const market = await api
      .get(`loan/current-item-rate/${entityId}`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch current item rate Successfully");
    setMarketRate(market);
    //console.log("Market Rate - ", market);
    logger.log("Initiate Fetch current item rate by id");
    const shop = await api
      .get(`loan/current-item-rate-by-id/${loanRes.itemRate}`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch current item rate by id Successfully");
    setMaterialRate((prevData) => {
      return shop;
    });
    //console.log("edit load  - ", loanRes);
    if (loanRes) {
      logger.log("Initiate Fetch scheme");
      const scheme_data = await api
        .get(`scheme/${loanRes.scheme}/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch scheme Successfully");
      //get the schemelist
      const schemeList = await fetchDataAndTransform(
        `scheme/schemes/${entityId}`,
        "scheme_id",
        "scheme_name"
      );
      // update the scheme list based on the pledge material type
      handleInputFields("scheme", schemeList, scheme_data.item_category);
      handleBranchChange(loanRes.branch);
      handleSchemeChange(loanRes.scheme);
      //console.log("status - ", loanRes.status);
      setStatus(loanRes.status);
      setLoanNumber(loanRes.loan_number);
      setRequestedAmount(loanRes.loan_amount);
      setDocumentCharge(loanRes.documentation_charge);
      setTotalAmount(loanRes.loan_amount);
      setRemarks(loanRes.remarks);
      getFundAmount(loanRes.branch);
      const loanHeader = {
        loan_id: loanRes.loan_id,
        branch_id: loanRes.branch,
        scheme: loanRes.scheme,
        loan_number: loanRes.loan_number,
        status: loanRes.status,
        customer: loanRes.customer,
        created_on: loanRes.created_on,
        remarks: loanRes.remarks,
        mode_of_payment: loanRes.mode_of_payment,
        loan_amount: loanRes.loan_amount,
        locker_id: loanRes.locker_id,
        hands_on_amount: loanRes.hands_on_amount,
        doc_charges: loanRes.documentation_charge,
      };
      //console.log("Header id - ", loanHeader);
      //console.log("Header id - ", loanRes);
      Object.entries(loanHeader).forEach(([key, value]) => {
        setValue(key, value);
      });
      //console.log(loanHeader);
      logger.log("Initiate Fetch loan details by loan");
      const loanDetail = await api
        .get(`loan/loandetailsloan/${loanId}/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch loan details by loan Successfully");
      const updateMaterial = loanDetail.map((item) => {
        return { ...item, ...loanHeader, item_id: item.item };
      });
      //console.log("initial list - ", updateMaterial);
      handleTotalMat(updateMaterial, shop);
      setMaterialList(updateMaterial);
      setValue("id", "");
      setValue("qty", "");
      setValue("gross_weight", "");
      setValue("net_weight", "");
      setValue("item_id", "");
      setImage("");
      setSaveBtnVisible(false);
      //console.log("load details - ", loanDetail);
      //console.log("updated details - ", updateMaterial);
      handleLoadingClose();
    } else {
      alert("Some think went wrong!");
    }
  };

  const handsOnAmountSet = useCallback(async () => {
    var created_on = getValues("created_on");
    var addOneMonth = new Date(created_on);
    addOneMonth.setMonth(addOneMonth.getMonth() + 1);
    var lastDueDate = addOneMonth.toISOString().split("T")[0];
    if (schemeId > 0) {
      logger.log("Initiate Fetch scheme");
      var scheme_data = await api
        .get(`scheme/${schemeId}/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch scheme Successfully");
      //console.log(scheme_data);
      const loan_amount = requestedamount;
      const interestDetail = Extension.interestRateCul(
        "month",
        loan_amount,
        created_on,
        lastDueDate,
        scheme_data.duration,
        scheme_data.rate_of_interest_minimum,
        scheme_data.rate_of_interest_maximum,
        false,
        true
      );
      const str = (str) => {
        const data = str.replace(/\.00$/, "");
        return parseInt(data);
      };
      //console.log("Interest Rate - ", interestDetail);
      //console.log("Interest Amount Handle - ", loan_amount);
      //console.log(parseInt(documentCharge));
      //console.log(parseInt(documentCharge));
      //console.log("requested Amount- ", parseInt(requestedamount));
      //console.log("Interest Amount", parseInt(interestDetail.interest_amount));

      let handsOnAmount = 0;
      if (isNaN(parseInt(documentCharge))) {
        handsOnAmount =
          parseInt(requestedamount) - str(interestDetail.interest_amount);
      } else {
        handsOnAmount =
          parseInt(requestedamount) -
          (parseInt(documentCharge) + str(interestDetail.interest_amount));
      }
      //console.log("Hands On Amount- ", handsOnAmount);
      setValue("hands_on_amount", handsOnAmount >= 0 ? handsOnAmount : 0);
    }
  }, [documentCharge, getValues, requestedamount, schemeId, setValue]);

  useEffect(() => {
    handsOnAmountSet();
  }, [requestedamount, documentCharge, handsOnAmountSet]);

  //handle submit form data
  const handleForm = (data) => {
    const loanTotalAmt = getValues("loan_amount");
    const stoneType = data.stone_type;
    const noOfStone = data.no_of_stone;
    const qty = data.qty;
    //console.log("data - data", data);
    //validate the stone type is with stone min required no of stone is 1
    if (
      (stoneType === "with_stone" || stoneType === "with_enamel") &&
      noOfStone <= 0
    ) {
      SnackBar.toastMsg("error", "No.of Stones Is Requied Field!");
      return;
    }
    if (qty <= 0) {
      SnackBar.toastMsg("error", "Enter the valid Quantity");
      return;
    }
    setStatus((prevData) => {
      //console.log(prevData);
      return data.loan_amount <= data.max_loan_amount || roleId === 1
        ? "Approved"
        : "Pending";
    });
    //console.log(
    //   data.loan_amount <= data.max_loan_amount ? "Approved" : "Pending"
    // );
    if (loanTotalAmt <= 0) {
      SnackBar.toastMsg("error", "Please fill the request loan amount!");
      return;
    }
    if (image !== null || image !== "") {
      data.image = image;
    } else {
      data.image = "";
    }
    //console.log("Form Data - ", data.image);
    if (data.id === "") {
      var updateMaterial = [...materialList, data];
    } else {
      const newData = [...materialList];
      newData[data.id] = { ...newData[data.id], ...data };
      var updateMaterial = newData;
    }
    handleTotalMat(updateMaterial, materialRate);
    setMaterialList(updateMaterial);
    setValue("id", "");
    setValue("qty", "1");
    setValue("gross_weight", "");
    setValue("net_weight", "");
    setValue("item_id", "");
    setValue("gold_category", "");
    setValue("stone_type", "");
    setValue("no_of_stone", "");
    setImage("");
    setSaveBtnVisible(false);
  };

  //update the initial select drop down fields
  const handleInputFields = (field, list, type) => {
    var list = list;
    //console.log("type - ", type);
    if (type !== undefined) {
      list = list.filter((item) => item.item_category === type);
    }
    setInputFields((prevInputFields) => {
      //console.log("initial - value ", prevInputFields);
      const updatedFields = prevInputFields.map((item) => {
        if (item.field === field) {
          return {
            ...item,
            data: list,
          };
        }
        return item;
      });
      //console.log("update list - ", updatedFields);
      return updatedFields;
    });
  };

  const handleStoneType = (value) => {
    setStoneType(value);
    if (value !== "with_stone" || value !== "with_enamel") {
      setValue("no_of_stone", 0);
    }
  };

  //handle the branch name change fun.
  const handleBranchChange = async (value) => {
    logger.log("Initiate Fetch customer");
    const customer = await api
      .get(`customer/branch/${value}`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch customer Successfully");
    setCustomers(customer);
    const customerList = customer.map((item) => ({
      ...item,
      value: item.customer_id,
      cus_name: `${item.cus_login_id} - ${item.mobile_number} - ${item.first_name} ${item.last_name}`,
      cus_name_tn: `${item.cus_login_id} - ${item.mobile_number} - ${item.first_name_tn} ${item.last_name_tn}`,
    }));
    //console.log("customer - ", customerList);
    //udpate the inputField like field wise
    handleInputFields("customer", customerList);
  };
  const handleEditMaterial = (id) => {
    const itemAtIndex = { ...materialList[id], id: id };
    //console.log("material index value - ", itemAtIndex);
    setStoneType(itemAtIndex.no_of_stone <= 0 ? "without_stone" : "with_stone");
    setImage(itemAtIndex.image);
    setValue(
      "stone_type",
      itemAtIndex.no_of_stone <= 0 ? "without_stone" : "with_stone"
    );
    setValue("item_id", itemAtIndex.item);
    //console.log("i");
    Object.entries(itemAtIndex).forEach(([key, value]) => {
      setValue(key, value);
    });
    setSaveBtnVisible(true);
  };
  //handle the scheme name change fun.
  const handleSchemeChange = async (value) => {
    logger.log("Initiate Fetch scheme");
    const scheme_data = await api
      .get(`scheme/${value}/`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch scheme Successfully");
    setValue("pledgeMaterialType", scheme_data.item_category);
    setSchemeId(scheme_data.scheme_id);
    setPledgeMaterialType(scheme_data.item_category);

    logger.log("Initiate Fetch loan item");
    const item_category_list = await api
      .get(`/loan/items/${entityId}`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch loan item Successfully");
    const item_category = item_category_list
      .filter((item) => item.item_category === scheme_data.item_category)
      .map((item) => ({
        ...item,
        value: item.item_id,
        text: item.item_name,
      }));
    setPledgeItemDropDown(item_category);
  };

  const [entity, setEntity] = useState([]);
  const getEntityData = useCallback(async () => {
    try {
      logger.log("Initiate Fetch entity");
      const entityData = await api
        .get(`/entity/${entityId}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch entity Successfully");
      setEntity(entityData);
    } catch (error) {
      logger.log(error);
    }
  }, [entityId]);

  useEffect(() => {
    getEntityData();
  }, [getEntityData]);

  //Confirmation message popup
  const handleConfirm = (value, data) => {
    Swal.fire({
      title: `Updated!`,
      icon:
        value === "Rejected"
          ? "error"
          : (value === "Approved" || value === "Update")
          ? "success"
          : "error",
      text:
        (value === "Approved" || value === "Update")
          ? `Loan ${data.loan_number} has been updated!`
          : "Loan Rejected!",
      showCancelButton: false,
      confirmButtonText: "Ok",
      confirmButtonColor: "#1976d2",
      showDenyButton:
        value === "Rejected"
          ? false
          : (value === "Approved" || value === "Update")
          ? true
          : false,
      denyButtonText: "Print",
      preConfirm: () => {
        backBtn();
      },
      didClose: () => {
        backBtn();
      },
    })
      .then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/loanprocess";
          // Navigate("/loanprocess");
        } else if (result.isDenied) {
          if (language === "tm") {
            LoanCreationTM({ ...data, ...entity }, false);
          } else {
            LoanCreation({ ...data, ...entity }, false);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          //console.log("cancel button");
        }
      })
      .catch((err) => logger.log(err));
  };
  

  const handleCancel = () => {
    Swal.fire({
      title: "Cancelled!",
      icon: "error",
      text: "Loan process cancelled!",
      showCancelButton: false,
      confirmButtonText: "Ok",
      confirmButtonColor: "#1976d2",
    });
  };

  const showConfirmationDialog = (value) => {
    const loanamount =
      parseInt(requestedamount) - parseInt(materialHeader.loan_amount);
    const remarks = getValues("remarks");
    const market_amount = parseInt(getValues("market_max_amount"));
    //console.log("Market Amount", market_amount);
    if (!remarks && value === "Rejected") {
      SnackBar.toastMsg("error", "Remarks is a required field!");
      return;
    }
    if (
      parseInt(getValues("fund_amount")) < parseInt(requestedamount) &&
      materialHeader.status === "Pending" &&
      value !== "Rejected"
    ) {
      SnackBar.toastMsg(
        "error",
        "The Requested Amount Is Higher Than The Available Fund Amount"
      );
      return;
    }
    if (
      parseInt(getValues("fund_amount")) < parseInt(loanamount) &&
      value !== "Rejected" &&
      parseInt(materialHeader.loan_amount) !== parseInt(requestedamount)
    ) {
      SnackBar.toastMsg(
        "error",
        "The Requested Amount Is Higher Than The Available Fund"
      );
      return;
    }

    if (
      market_amount < parseInt(loanamount) &&
      value !== "Rejected" &&
      materialHeader.status === "Pending"
    ) {
      SnackBar.toastMsg(
        "error",
        "The Requested Amount Is Higher Than The Maximum Market Amount"
      );
      return;
    }
    if (market_amount < parseInt(requestedamount)) {
      SnackBar.toastMsg(
        "error",
        "The Requested Amount Is Higher Than The Maximum Market Amount"
      );
      return;
    }
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: `Do you want to ${value}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#00A550",
        cancelButtonColor: "#d33",
        input: value === "Rejected" ? "" : "checkbox",
        inputPlaceholder:
          value === "Rejected" ? "" : "Do you want to send the SMS",
        inputValue: value === "Rejected" ? "" : 1,
        preConfirm: (checkbox) => {
          resolve(checkbox); // Resolve with the checked value
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            const checkedValue = result.value; // Get the checked value
            handleFormData(value, checkedValue);
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            handleCancel();
          }
        })
        .catch((err) => logger.log(err));
    });
  };

  const handleTotalMat = (materials, materialRates) => {
    //console.log("update material - ", materials);

    // //console.log("update material1 - ", rate);
    //console.log("update material2 - ", materialRate);
    const totals = materials.reduce(
      (acc, item) => {
        const rate = materialRates;
        //console.log("rate list - ", rate);
        const qty = parseInt(item.qty, 10);
        const grossWeight = parseFloat(item.gross_weight);
        const netWeight = parseFloat(item.net_weight);
        const noOfStone = parseFloat(item.no_of_stone);
        const materialType = getValues("pledgeMaterialType");
        if (
          (item.gold_category === "normal" ||
            item.gold_category === "Normal Gold") &&
          (materialType === "Gold" || materialType === "தங்கம்")
        ) {
          //console.log("rate - ", rate.gold_rate);
          acc.maxLoanAmt += !isNaN(netWeight) ? rate.gold_rate * netWeight : 0;
        } else if (
          (item.gold_category === "916" ||
            item.gold_category === "Hallmark Gold(916)") &&
          (materialType === "Gold" || materialType === "தங்கம்")
        ) {
          acc.maxLoanAmt += !isNaN(netWeight)
            ? rate.hallmark_gold_rate * netWeight
            : 0;
          //console.log("rate - ", rate);
        } else {
          acc.maxLoanAmt += !isNaN(netWeight)
            ? rate?.silver_rate * netWeight
            : 0;
        }
        //console.log(
        //   "total net weight - ",
        //   netWeight,
        //   item.gold_category,
        //   "-",
        //   materialType
        // );
        acc.qtyTotal += !isNaN(qty) ? qty : 0;
        acc.grossWeightTotal += !isNaN(grossWeight) ? grossWeight : 0;
        acc.netWeightTotal += !isNaN(netWeight) ? netWeight : 0;
        acc.noOfStone += !isNaN(netWeight) ? noOfStone : 0;
        //console.log(acc);
        return acc;
      },
      {
        qtyTotal: 0,
        grossWeightTotal: 0,
        netWeightTotal: 0,
        noOfStone: 0,
        maxLoanAmt: 0,
      }
    );
    //console.log("total - ", totals);
    // setValue("max_loan_amount", totals.maxLoanAmt);
    setThresHoldAmount(parseInt(totals.maxLoanAmt).toFixed(0));
    setTotalMaterial(totals);

    const marketHallGold = parseInt(marketRate?.market_gold_rate) - 300;
    const marketNormalGold = parseInt(marketRate?.market_gold_rate) - 700;

    if (editLoan) {
      const totally = materials.reduce(
        (acc, item) => {
          //console.log("rate list - ", marketHallGold, marketNormalGold);
          const qty = parseInt(item.qty, 10);
          const grossWeight = parseFloat(item.gross_weight);
          const netWeight = parseFloat(item.net_weight);
          const noOfStone = parseFloat(item.no_of_stone);
          const materialType = getValues("pledgeMaterialType");
          if (
            (item.gold_category === "normal" ||
              item.gold_category === "Normal Gold") &&
            (materialType === "Gold" || materialType === "தங்கம்")
          ) {
            acc.maxLoanAmt += !isNaN(netWeight)
              ? marketNormalGold * netWeight
              : 0;
          } else if (
            (item.gold_category === "916" ||
              item.gold_category === "Hallmark Gold(916)") &&
            (materialType === "Gold" || materialType === "தங்கம்")
          ) {
            acc.maxLoanAmt += !isNaN(netWeight)
              ? marketHallGold * netWeight
              : 0;
          } else {
            acc.maxLoanAmt += !isNaN(netWeight)
              ? marketRate?.silver_rate * netWeight
              : 0;
          }
          //console.log(
          //   "total net weight - ",
          //   netWeight,
          //   item.gold_category,
          //   "-",
          //   materialType
          // );
          acc.qtyTotal += !isNaN(qty) ? qty : 0;
          acc.grossWeightTotal += !isNaN(grossWeight) ? grossWeight : 0;
          acc.netWeightTotal += !isNaN(netWeight) ? netWeight : 0;
          acc.noOfStone += !isNaN(netWeight) ? noOfStone : 0;
          //console.log(acc);
          return acc;
        },
        {
          qtyTotal: 0,
          grossWeightTotal: 0,
          netWeightTotal: 0,
          noOfStone: 0,
          maxLoanAmt: 0,
        }
      );
      setValue("market_max_amount", parseInt(totally?.maxLoanAmt).toFixed(0));
    }
  };

  //handle the net weigth change
  const handleNetWeight = (value) => {
    const grossWeight = getValues("gross_weight");
    const netWeight = parseFloat(value);
    if (grossWeight === "") {
      SnackBar.toastMsg("error", "Gross Weight is required field!");
      setValue("gross_weight", "");
      setValue("net_weight", "");
      return;
    }
    //console.log("gross weight - ", grossWeight);
    if (netWeight >= parseFloat(grossWeight)) {
      setValue("net_weight", "");
      SnackBar.toastMsg("error", "Enter the less value of gross weight!");
    }
  };

  //handle the gross weigth change
  const handleGrossWeigth = (value) => {
    const grossWeight = value;
    const stoneType = getValues("stone_type");

    if (stoneType === "") {
      SnackBar.toastMsg("error", "Item Type is required field!");
      setValue("gross_weight", "");
      setValue("net_weight", "");
      return;
    }
    if (grossWeight === "") {
      setValue("net_weight", "");
      return;
    }
    const noOfStone = getValues("no_of_stone");
    const deduction =
      stoneType === "with_stone" || stoneType === "with_enamel"
        ? noOfStone * 0.025
        : 0;
    //console.log("deduction - ", deduction);
    setValue(
      "net_weight",
      parseFloat(grossWeight - deduction - 0.5).toFixed(3) < 0
        ? 0
        : parseFloat(grossWeight - deduction - 0.5).toFixed(3)
    );
    //console.log("Item type - ", grossWeight);
    //console.log(typeof grossWeight);
  };

  const getFundAmount = async (branch) => {
    try {
      //console.log("Get Branch id", getValues("branch_id"));
      const BRANCH_API = `/Fund/CurrentTotalAmountByBranch/${
        branch ?? getValues("branch_id")
      }`;
      logger.log("Initiate Fetch CurrentTotalAmountByBranch");
      const Branch_res = await api
        .get(BRANCH_API)
        .then((res) => setValue("fund_amount", res.data.total_fund_amount ?? 0))
        .catch((err) => {
          logger.log(err);
          setValue("fund_amount", 0);
        });
      logger.log("Fetch CurrentTotalAmountByBranch Successfully");
      // setValue("fund_amount", Branch_res.total_fund_amount);
    } catch (error) {
      logger.log(error);
    }
  };

  useEffect(() => {
    getFundAmount();
  }, []);

  const handleFundAmount = async (value, inr) => {
    try {
      if (value !== "Approved") {
        return;
      }
      if (
        materialHeader.status !== "Pending" &&
        value === "Approved" &&
        parseInt(materialHeader.loan_amount) === parseInt(requestedamount)
      ) {
        return;
      }
      const loanamount =
        materialHeader.status === "Pending"
          ? parseInt(materialHeader.loan_amount)
          : parseInt(requestedamount) - parseInt(materialHeader.loan_amount);
      let total_fund_amount = 0;
      //console.log("Fund Amount", getValues("fund_amount"));
      total_fund_amount =
        parseInt(getValues("fund_amount")) -
        parseInt(value === "Approved" ? loanamount : requestedamount);
      if (
        value === "Approved" &&
        requestedamount < materialHeader.loan_amount &&
        !inr
      ) {
        const data = {
          credit_amount:
            parseInt(materialHeader.loan_amount) - parseInt(requestedamount),
          debit_amount: null,
          funded_on: Extension.getCurrentDateTime(),
          total_fund_amount,
          fund_type: "LoanClosureAmountCredit",
          transaction_type: getValues("mode_of_payment"),
          branch: getValues("branch_id"),
          entity: entityId,
          funded_By: userId,
        };
        //console.log("Add Expenditure", data);
        logger.log("Initiate post fund");
        const response = await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("post fund Successfully");
        //console.log("Add Expenditure res", response);
        // await getFundAmount();
        return;
      }
      if (materialHeader.status === "Pending" && value === "Approved" && !inr) {
        const data = {
          credit_amount: null,
          debit_amount: value === "Approved" ? loanamount : requestedamount,
          funded_on: new Date(materialHeader.created_on).toISOString(),
          total_fund_amount,
          transaction_type: getValues("mode_of_payment"),
          fund_type: "LoanAmountDebit",
          branch: getValues("branch_id"),
          entity: entityId,
          funded_By: userId,
        };
        //console.log("Approved Expenditure", data);
        logger.log("Initiate post fund");
        const response = await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("post fund Successfully");
        //console.log("Approved Expenditure res", response);
        // await getFundAmount();
        return;
      }
      if (
        materialHeader.status !== "Pending" &&
        !(requestedamount < materialHeader.loan_amount) &&
        value === "Approved" &&
        !inr
      ) {
        const data = {
          credit_amount: null,
          debit_amount: value === "Approved" ? loanamount : requestedamount,
          funded_on: new Date(materialHeader.created_on).toISOString(),
          total_fund_amount,
          transaction_type: getValues("mode_of_payment"),
          fund_type: "LoanAmountDebit",
          branch: getValues("branch_id"),
          entity: entityId,
          funded_By: userId,
        };
        //console.log("Loan Expenditure", data);
        logger.log("Initiate post fund");
        const response = await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("post fund Successfully");
        //console.log("Loan Expenditure res", response);
        // await getFundAmount();
        return;
      }
      total_fund_amount = parseInt(total_fund_amount) + parseInt(inr);
      if (inr) {
        const data = {
          credit_amount: parseInt(inr),
          debit_amount: null,
          funded_on:
            materialHeader.status === "Pending"
              ? new Date(materialHeader.created_on).toISOString()
              : Extension.getCurrentDateTime(),
          total_fund_amount,
          transaction_type: getValues("mode_of_payment"),
          fund_type: "InterestAmountCredit",
          branch: getValues("branch_id"),
          entity: entityId,
          funded_By: userId,
        };
        //console.log(data);
        logger.log("Initiate post fund");
        const response = await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("post fund Successfully");
        //console.log("Inr Pick", response);
        // await getFundAmount();
        return;
      }
    } catch (error) {
      logger.log(error);
    }
  };

  const handleDeleteMat = (id) => {
    const updateList = [...materialList];
    updateList.splice(id, 1);
    setMaterialList(updateList);
    handleTotalMat(updateList, materialRate);
  };

  const getpaymenttype = (value, key) => {
    const paymentMethods = [
      { text: "Cash", value: "By Cash", text_tn: "பணம்" },
      {
        text: "Account Transfer",
        value: "By Account",
        text_tn: "கணக்கு பரிமாற்றம்",
      },
      { text: "UPI/ Others", value: "UPI/Others", text_tn: "UPI/ மற்றவை" },
    ];
    const method = paymentMethods.find((method) => method.value === value);
    return method ? method[key] : "Not found";
  };
  const getgoldCategory = (value, key) => {
    const method = goldCategory.find((method) => method.value === value);
    return method ? method[key] : "";
  };
  const handleFormData = async (value, checkedValue) => {
    handleLoadingOpen();
    const status = value === "Update" ? "Approved" : value;
    // const loanAmount = getValues("loan_amount");
    const loanAmount = requestedamount;
    const modeOfPayement = getValues("mode_of_payment");
    const scheme = getValues("scheme");
    const customer = getValues("customer");
    const remarks = getValues("remarks");
    const remarks_tn = getValues("");
    const lockerId = getValues("locker_id");
    const doc_charges = getValues("doc_charges");
    const hands_on_amount = getValues("hands_on_amount");

    const cus_num = customers.find(
      (item) => parseInt(item.customer_id) === parseInt(customer)
    ).mobile_number;
    var created_on = materialHeader.created_on;
    var addOneMonth = new Date(created_on);
    addOneMonth.setMonth(addOneMonth.getMonth() + 1);
    const currentBalance =
      materialHeader.current_balance_pa +
      (loanAmount - materialHeader.loan_amount);

    var lastDueDate = addOneMonth.toISOString().split("T")[0];
    const updateLoan = {
      ...materialHeader,
      remarks: remarks,
      remarks_tn: remarks_tn,
      status: status,
      status_tn: statusCategory[status],
      loan_amount: loanAmount,
      mode_of_payment: modeOfPayement,
      mode_of_payment_tn: getpaymenttype(modeOfPayement, "text_tn"),
      scheme: scheme,
      customer: customer,
      current_balance_pa: currentBalance,
      created_on: created_on,
      last_due_date: lastDueDate,
      locker_id: lockerId,
      branch: materialHeader.branch,
      hands_on_amount: hands_on_amount,
      documentation_charge: documentCharge,
      approved_on:
        status !== "Rejected"
          ? new Date().toISOString().split("T")[0]
          : materialHeader.approved_on,
      actual_closer_date:
        status === "Rejected" ? new Date().toISOString().split("T")[0] : null,
      plan_closer_date:
        status === "Rejected"
          ? new Date().toISOString().split("T")[0]
          : materialHeader.plan_closer_date,
      approved_by: userId,
    };

    //console.log(updateLoan);
    logger.log("Initiate update loan");
    const response = await api
      .put(`loan/${updateLoan.loan_id}`, updateLoan)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("update loan Successfully");
    //console.log("response - ", response);
    if (checkedValue) {
      SMS_API_FUNCTION.loanCreationSMS_API(
        materialHeader.loan_number,
        created_on,
        cus_num,
        169259,
        updateLoan.loan_amount
      );
    }
    handleFundAmount(status);
    logger.log("Initiate fetch loan details");
    const exisitingLoanDetails = await api
      .get(`loan/loandetailsloan/${response.loan_id}/`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("fetch loan details Successfully");
    logger.log("Initiate delete loan details");
    exisitingLoanDetails.map(async (item) => {
      await api.delete(`loan/loandetails/${item.Loan_details_id}`);
    });
    logger.log("delete loan details Successfully");
    if (status !== "Rejected") {
      logger.log("Initiate fetch TransactionsByLoan");
      const exisitingLoanTransaction = await api
        .get(`loan/TransactionsByLoan/${updateLoan.loan_id}`)
        .then((res) => res.data[0])
        .catch((err) => logger.log(err));
      logger.log("fetch TransactionsByLoan Successfully");
      logger.log("Initiate fetch scheme");
      const scheme_data = await api
        .get(`scheme/${updateLoan.scheme}/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("fetch scheme Successfully");
      const interestDetail = Extension.interestRateCul(
        "month",
        loanAmount,
        created_on,
        lastDueDate,
        scheme_data.duration,
        scheme_data.rate_of_interest_minimum,
        scheme_data.rate_of_interest_maximum,
        false,
        true
      );
      var transaction;

      var updateTransaction;
      if (exisitingLoanTransaction === undefined) {
        let sequence;
        let sequenceNumber;
        logger.log("Initiate fetch entity");
        const entityDetail = await api
          .get(`entity/${entityId}`)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("fetch entity Successfully");
        if (
          entityDetail.last_interest_num === null ||
          entityDetail.last_interest_num === ""
        ) {
          sequence = "INTA0001";
          sequenceNumber = "A0001";
        } else {
          const { alphabeticPart, numericPart } = splitString(
            entityDetail.last_interest_num
          );

          const updateNumber = generateSequence(alphabeticPart, numericPart);
          sequenceNumber = updateNumber;
          sequence = `INT${updateNumber}`;
        }

        transaction = {
          amount_paid: interestDetail.interest_amount,
          balance_pa: currentBalance,
          due_amount: interestDetail.interest_amount,
          due_date: lastDueDate,
          closure_amount: 0,
          paid_on: new Date().toISOString().split("T")[0],
          payment_mode: getValues("mode_of_payment"),
          payment_mode_tn: getpaymenttype(
            getValues("mode_of_payment"),
            "text_tn"
          ),
          trans_or_recipt_id: "",
          loan: response.loan_id,
          trans_seq_num: sequence,
        };
        const updateEntity = {
          ...entityDetail,
          last_interest_num: sequenceNumber,
        };
        //udpate the entity
        logger.log("Initiate update entity");
        await api.put(`entity/${entityId}`, updateEntity);
        logger.log("update entity Successfully");
        handleFundAmount(status, interestDetail.interest_amount);
        logger.log("Initiate post Transactions");
        updateTransaction = await api
          .post("loan/Transactions", transaction)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("post Transactions Successfully");
      } else {
        transaction = {
          ...exisitingLoanTransaction,
          amount_paid: interestDetail.interest_amount,
          balance_pa: loanAmount,
          payment_mode: getValues("mode_of_payment"),
          payment_mode_tn: getpaymenttype(
            getValues("mode_of_payment"),
            "text_tn"
          ),
          due_amount: interestDetail.interest_amount,
          due_date: lastDueDate,
          paid_on: created_on,
        };
        //console.log("transaction - ", transaction);
        //console.log("transaction1 - ", exisitingLoanTransaction);
        logger.log("Initiate update Transactions");
        updateTransaction = await api
          .put(`loan/Transactions/${transaction.transaction_id}`, transaction)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("update Transactions Successfully");
        let amount_minus_previous =
          parseInt(loanAmount) - parseInt(materialHeader.loan_amount);
        const interestDetails = Extension.interestRateCul(
          "month",
          amount_minus_previous,
          created_on,
          lastDueDate,
          scheme_data.duration,
          scheme_data.rate_of_interest_minimum,
          scheme_data.rate_of_interest_maximum,
          false,
          true
        );
        if (requestedamount > materialHeader.loan_amount) {
          handleFundAmount(status, interestDetails.interest_amount);
        }
      }
    }
    try {
      const promises = materialList.map(async (item) => {
        const newRecord = {
          ...item,
          loan: response.loan_id,
          item: item.item_id,
          gold_category_tn: getgoldCategory(item.gold_category, "text_tn"),
          gold_category: getgoldCategory(item.gold_category, "text"),
        };
        //console.log("New record - ", newRecord);
        logger.log("Initiate post loandetails");
        const response1 = await api.post("loan/loandetails/", newRecord);
        logger.log("post loandetails Successfully");
        //console.log("response - ", response1.data);
        return response1.data;
      });
      // Wait for all promises to resolve
      const results = await Promise.all(promises);
      //console.log(results);
      if (results) {
        Object.entries(updateLoan).forEach(([key, value]) => {
          setValue(key, "");
        });
        const date = new Date().toISOString().split("T")[0];
        setValue("created_on", date);
        // setValue("loan_number", "");
        setTotalMaterial({});
        setMaterialList([]);
      }
      if (status !== "Rejected") {
        logger.log("Initiate fetch LoanCreationReceipt");
        const receiptDetail = await api
          .get(`loan/LoanCreationReceipt/${response.loan_id}`)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("fetch LoanCreationReceipt Successfully");
        const result1 = {
          ...receiptDetail,
          receipt_no: updateTransaction.trans_seq_num,
        };
        handleConfirm(status, result1);

        //console.log("results - ", results);
      }
      if (status === "Rejected") {
        SnackBar.toastMsg("success", "Loan Rejected Successfully");
        setShowEditProcess(false);
        fetchLoanDetails();
      }
      handleConfirm(status);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };
  const backBtn = () => {
    fetchLoanDetails();
    setShowEditProcess(false);
  };

  useEffect(() => {
    if (!documentCharge) {
      setDocumentCharge(0);
    }
  }, [documentCharge]);

  //edit button click fun.
  const handleEditBtn = () => {
    setEditLoan(true); // enable loan edit
    setHeaderTxt(t("common.edit loan")); //set the page title
    const marketHallGold = parseInt(marketRate?.market_gold_rate) - 300;
    const marketNormalGold = parseInt(marketRate?.market_gold_rate) - 700;

    const totally = materialList.reduce(
      (acc, item) => {
        //console.log("rate list - ", marketHallGold, marketNormalGold);
        const qty = parseInt(item.qty, 10);
        const grossWeight = parseFloat(item.gross_weight);
        const netWeight = parseFloat(item.net_weight);
        const noOfStone = parseFloat(item.no_of_stone);
        const materialType = getValues("pledgeMaterialType");
        if (
          (item.gold_category === "normal" ||
            item.gold_category === "Normal Gold") &&
          (materialType === "Gold" || materialType === "தங்கம்")
        ) {
          acc.maxLoanAmt += !isNaN(netWeight)
            ? marketNormalGold * netWeight
            : 0;
        } else if (
          (item.gold_category === "916" ||
            item.gold_category === "Hallmark Gold(916)") &&
          (materialType === "Gold" || materialType === "தங்கம்")
        ) {
          acc.maxLoanAmt += !isNaN(netWeight) ? marketHallGold * netWeight : 0;
        } else {
          acc.maxLoanAmt += !isNaN(netWeight)
            ? marketRate?.silver_rate * netWeight
            : 0;
        }
        //console.log(
        //   "total net weight - ",
        //   netWeight,
        //   item.gold_category,
        //   "-",
        //   materialType
        // );
        acc.qtyTotal += !isNaN(qty) ? qty : 0;
        acc.grossWeightTotal += !isNaN(grossWeight) ? grossWeight : 0;
        acc.netWeightTotal += !isNaN(netWeight) ? netWeight : 0;
        acc.noOfStone += !isNaN(netWeight) ? noOfStone : 0;
        //console.log(acc);
        return acc;
      },
      {
        qtyTotal: 0,
        grossWeightTotal: 0,
        netWeightTotal: 0,
        noOfStone: 0,
        maxLoanAmt: 0,
      }
    );

    setValue("market_max_amount", parseInt(totally.maxLoanAmt).toFixed(0));
    const pledgeItemType = getValues("pledgeMaterialType");
  };

  //split to separte the character and interger fun.
  const splitString = (inputString) => {
    // Use regular expressions to separate alphabetic and numeric parts
    const parts = inputString.split(/(\d+)/);
    if (parts) {
      // Separate alphabetic and numeric parts
      const alphabeticPart = parts
        .filter((part, index) => index % 2 === 0)
        .join("");
      const numericPart = parts
        .filter((part, index) => index % 2 !== 0)
        .join("");
      return { alphabeticPart, numericPart };
    } else {
      return { alphabeticPart: "", numericPart: "" };
    }
  };

  //Loan number generate fun.
  const generateSequence = (char, numeric) => {
    const currentChar = char.toUpperCase();
    const currentNumeric = parseInt(numeric);
    //console.log("prefix char code - ", currentChar.charCodeAt(1));
    if (currentNumeric === 1000) {
      let nextChar;
      if (currentChar.charAt(0) === "Z") {
        const charLength = currentChar.length + 1;
        nextChar = "A".repeat(charLength);
      } else {
        nextChar = String.fromCharCode(currentChar.charCodeAt(0) + 1);
      }

      return `${nextChar}0001`;
    } else {
      return `${currentChar}${(currentNumeric + 1).toString().padStart(4, 0)}`;
    }
  };

  const handleRemarksChange = (value) => {
    translate.engine = "google";
    translate.key = process.env.DEEPL_KEY;
    translate(
      value === "" ||
        value === undefined ||
        value === null ||
        value === undefined
        ? "Type Here"
        : value,
      "ta"
    )
      .then((result) => {
        setValue("remarks_tn", result);
      })
      .catch((error) => {
        logger.log(error);
      });
  };
  const handleStoneCult = (value) => {
    const stoneType = getValues("stone_type");

    //stone type is empty throw the validate msg.
    if (stoneType === "") {
      SnackBar.toastMsg("error", "Item Type is requied field!");
      setValue("no_of_stone", "");
      return;
    }
    const grossWeight = getValues("gross_weight");
    if (grossWeight) {
      const netWeight = grossWeight - value * 0.025 - 0.5;
      setValue("net_weight", netWeight.toFixed(2));
    }
  };

  function convertBase64(e, maxWidth, maxHeight) {
    console.log("Function Called");
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target.result) {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            // Check if image needs resizing
            if (width > maxWidth || height > maxHeight) {
              const ratio = Math.min(maxWidth / width, maxHeight / height);
              width *= ratio;
              height *= ratio;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            const base64Data = canvas.toDataURL("image/jpeg", 0.7); // Convert to base64
            // console.log(base64Data);
            setImage(base64Data);
          };

          img.src = event.target.result;
        } else {
          SnackBar.toastMsg("error", t("toast_messages.failed_to_load"));
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleContextMenu = (event, row) => {
    if (!image) {
      return;
    }
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRowContextMenu = (event, row) => {
    event.preventDefault();
    setSelectedRow(row);
    setRowContextMenu(
      rowContextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleRowClose = () => {
    setRowContextMenu(null);
  };

  // pre loader open fun.
  const handleLoadingOpen = () => {
    setLoading(true);
  };

  //pre loader close fun.
  const handleLoadingClose = () => {
    setLoading(false);
  };

  return (
    <div>
      <Toaster limit={3} />
      <Loading open={loading} handleClose={handleLoadingClose} />
      <Paper elevation={3} className={`add-loan-wrapper`}>
        <Box
          className={"loanHeader"}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Box sx={{ gap: "15px", display: "flex", alignItems: "center" }}>
            <IconButton
              sx={{
                background: "#B1F164",
                color: "#555",
                "&:hover": {
                  background: "#82B643",
                  color: "#fff",
                  transform: "scale(1.2)",
                },
              }}
              onClick={backBtn}
            >
              <KeyboardBackspace />
            </IconButton>
            <Typography variant="h5" sx={Styles.titleText()}>
              {headerTxt}
            </Typography>
            <Typography
              variant="h5"
              className={`edit-loan-number`}
              sx={Styles.titleText()}
            >
              {loanNumber}
            </Typography>
          </Box>

          <Box sx={{ gap: "15px", display: "flex" }}>
            {status === "Approved" && appendEdit && !editLoan && (
              <AppendLoan
                max_loan_amount={thresHoldAmount}
                loan_amount={requestedamount}
                schemeId={schemeId}
                branch_id={getValues("branch_id")}
                hands_on_amount={getValues("hands_on_amount")}
                modeOfPayement={getValues("mode_of_payment")}
                loan_id={getValues("loan_id")}
                created_on={getValues("created_on")}
                backBtn={backBtn}
                setHeaderTxt={setHeaderTxt}
              />
            )}

            {status === "Approved" &&
              roleId === 1 &&
              !editLoan &&
              editLoanBtn && (
                <Button
                  type="button"
                  value={""}
                  size="small"
                  className="add-btn"
                  onClick={handleEditBtn}
                >
                  {t("common.edit loan")}
                </Button>
              )}
          </Box>
        </Box>

        <form
          onSubmit={handleSubmit(handleForm)}
          // style={{
          //   pointerEvents:
          //     status === "Rejected" ? "none" : editLoan ? "" : "none",
          // }}
        >
          <Box
            className={`add-loan-header ${
              materialList.length > 0 ? "active" : ""
            } ${status}`}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 8 }}
              style={{
                pointerEvents:
                  status === "Rejected" ? "none" : editLoan ? "" : "none",
              }}
            >
              {inputFields.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={item.gridSize?.xs} // Add the "?." to safely access nested properties
                    sm={item.gridSize?.sm}
                    md={item.gridSize?.md}
                    lg={item.gridSize?.lg}
                    key={index}
                  >
                    <Typography sx={Styles.textlabel()}>
                      {item.title && t(`loan creation.${item.title}`)}
                    </Typography>
                    <Paper sx={{ background: "none" }} variant="standarded">
                      {item.inputType === "input" &&
                        item.title !== "documentation charges" &&
                        item.title !== "requested loan amount" &&
                        !item.subset && (
                          <CommonTextField
                            name={item.name}
                            register={register}
                            type={item.type}
                            readOnly={item.readOnly}
                            position={item.position}
                            comboFixWord={item.ComboFixWord}
                          />
                        )}
                      {item.title === "requested loan amount" &&
                        !item.subsets && (
                          <TextField
                            fullWidth
                            size="small"
                            value={requestedamount}
                            sx={{
                              ...Styles.dropDown(),
                              "& .MuiSvgIcon-root,.MuiTypography-root": {
                                color: "var(--font-color)", // Change the color of the dropdown icon
                              },
                            }}
                            onChange={(e) => setRequestedAmount(e.target.value)}
                            type={item.type}
                            defaultValue={0}
                            onKeyDown={(e) => {
                              ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault();
                            }}
                            InputProps={{
                              readOnly: item.readOnly,
                              inputProps: {
                                min: 0,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  {"₹"}
                                </InputAdornment>
                              ),
                              style: {
                                backgroundColor: "var(--text-field)",
                                color: "var(--font-color)",
                                maxHeight: "40px",
                                fontFamily: "var(--font-family)",
                                fontSize: "var(--font-size)",
                              },
                            }}
                          />
                        )}
                      {item.title === "documentation charges" &&
                        !item.subsets && (
                          <TextField
                            sx={{
                              ...Styles.dropDown(),
                              "& .MuiSvgIcon-root,.MuiTypography-root": {
                                color: "var(--font-color)", // Change the color of the dropdown icon
                              },
                            }}
                            fullWidth
                            size="small"
                            value={documentCharge}
                            onChange={(e) => setDocumentCharge(e.target.value)}
                            type={item.type}
                            defaultValue={0}
                            onKeyDown={(e) => {
                              ["e", "E", "+", "-", "."].includes(e.key) &&
                                e.preventDefault();
                            }}
                            InputProps={{
                              readOnly: item.readOnly,
                              inputProps: {
                                min: 0,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  {"₹"}
                                </InputAdornment>
                              ),
                              style: {
                                backgroundColor: "var(--text-field)",
                                maxHeight: "40px",
                                color: "var(--font-color)",
                                fontFamily: "var(--font-family)",
                                fontSize: "var(--font-size)",
                              },
                            }}
                          />
                        )}
                      {item.inputType === "select" &&
                        !item.subset &&
                        item.field === "scheme" && (
                          <Controller
                            name={item.name}
                            control={control}
                            rules={{ required: true }}
                            defaultValue=""
                            render={({ field }) => (
                              <Select
                                // file deepcode ignore DOMXSS: The select file need to display the fields
                                {...field}
                                disabled={item.readOnly}
                                value={field.value || ""}
                                sx={{
                                  ...Styles.dropDown(),
                                  "& .MuiSvgIcon-root,.MuiTypography-root": {
                                    color: "var(--font-color)", // Change the color of the dropdown icon
                                  },
                                }}
                                fullWidth
                                size="small"
                                onChange={(event) => {
                                  field.onChange(event);
                                  if (
                                    item.clickEvent &&
                                    item.field === "scheme" &&
                                    item.clickType === "onChange"
                                  ) {
                                    handleSchemeChange(event.target.value);
                                  }
                                }}
                                inputProps={{
                                  style: {
                                    height: "15px",
                                  },
                                }}
                                style={{ height: "40px" }}
                              >
                                {item.data.map((scheme, index) => (
                                  <MenuItem
                                    key={index}
                                    value={scheme.scheme_id}
                                    className="common-font-style"
                                  >
                                    {`${Extension.languageBasedValue(
                                      scheme.scheme_name,
                                      scheme.scheme_name_tn
                                    )} - ${scheme.rate_of_interest_minimum} %`}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        )}
                      {item.inputType === "select" &&
                        !item.subset &&
                        item.field !== "scheme" && (
                          <Controller
                            name={item.name}
                            control={control}
                            rules={{ required: true }}
                            defaultValue=""
                            render={({ field }) => (
                              <SelectDropDown
                                field={field}
                                name={item.name}
                                clickFunction={
                                  item.clickEvent && item.field === "branch"
                                    ? handleBranchChange
                                    : item.field === "scheme"
                                    ? handleSchemeChange
                                    : ""
                                }
                                clickType={item.clickEvent && item.clickType}
                                register={register}
                                readOnly={
                                  item.field === "branch" && roleId !== 1
                                    ? true
                                    : item.readOnly
                                }
                                display_name={
                                  language === "tm"
                                    ? item.tm
                                    : item.display_name
                                }
                                value={item.value}
                                data={item.data}
                                setValue={setValue}
                              />
                            )}
                          />
                        )}
                      {item.subset && (
                        <Grid
                          container
                          // spacing={{ xs: 2, md: 3, sm: 3 }}
                          // columns={{ xs: 1, sm: 3, md: 6, lg:1 }}
                          rowSpacing={1}
                          columnSpacing={2}
                        >
                          {item.subsets.map((subsetItem, subsetIndex) => (
                            <Grid
                              item
                              xs={subsetItem.gridSize.xs}
                              sm={subsetItem.gridSize.sm}
                              md={subsetItem.gridSize.md}
                              lg={subsetItem.gridSize.lg}
                              key={`${index}_${subsetIndex}`}
                            >
                              <Typography sx={Styles.textlabel()}>
                              {subsetItem.title == "fund amount" && status === "Approved" && appendEdit && !editLoan ?
                              t(`loan creation.${subsetItem.title}`):subsetItem.title !== "fund amount" && t(`loan creation.${subsetItem.title}`)}
                              </Typography>
                              {subsetItem.inputType === "input" &&
                              subsetItem.title !== "threshold amount" && subsetItem.title !== "fund amount" ? (
                                <CommonTextField
                                  name={subsetItem.name}
                                  register={register}
                                  type={subsetItem.type}
                                  readOnly={subsetItem.readOnly}
                                  position={subsetItem.position}
                                  comboFixWord={subsetItem.ComboFixWord}
                                />
                              ) : (
                                subsetItem.title === "threshold amount" && subsetItem.title !== "fund amount" && (
                                  <TextField
                                    sx={{
                                      ...Styles.dropDown(),
                                      "& .MuiSvgIcon-root,.MuiTypography-root":
                                        {
                                          color: "var(--font-color)", // Change the color of the dropdown icon
                                        },
                                    }}
                                    fullWidth
                                    size="small"
                                    value={thresHoldAmount}
                                    onChange={(e) =>
                                      setThresHoldAmount(e.target.value)
                                    }
                                    type={item.type}
                                    defaultValue={0}
                                    onKeyDown={(e) => {
                                      ["e", "E", "+", "-"].includes(e.key) &&
                                        e.preventDefault();
                                    }}
                                    InputProps={{
                                      readOnly: subsetItem.readOnly,
                                      style: {
                                        backgroundColor: "var(--text-field)",
                                        maxHeight: "40px",
                                        color: "var(--font-color)",
                                        fontFamily: "var(--font-family)",
                                        fontSize: "var(--font-size)",
                                      },
                                      inputProps: {
                                        min: 0,
                                      },
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          {"₹"}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )
                              )}{" "}
                              {
                                subsetItem.inputType === "input" && subsetItem.title == "fund amount" && status === "Approved" && appendEdit && !editLoan ?
                                <CommonTextField
                                name={subsetItem.name}
                                register={register}
                                type={subsetItem.type}
                                readOnly={subsetItem.readOnly}
                                position={subsetItem.position}
                                comboFixWord={subsetItem.ComboFixWord}
                              />:('')
                              }
                              {subsetItem.inputType === "select" && (
                                <Controller
                                  name={subsetItem.name}
                                  control={control}
                                  rules={{ required: true }}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <SelectDropDown
                                      field={field}
                                      name={subsetItem.name}
                                      clickFunction={
                                        subsetItem.clickEvent &&
                                        subsetItem.field === "branch"
                                          ? handleBranchChange
                                          : subsetItem.field === "scheme"
                                          ? handleSchemeChange
                                          : ""
                                      }
                                      clickType={
                                        subsetItem.clickEvent &&
                                        subsetItem.clickType
                                      }
                                      register={register}
                                      readOnly={
                                        subsetItem.field === "branch" &&
                                        roleId !== 1
                                          ? true
                                          : subsetItem.readOnly
                                      }
                                      data={subsetItem.data}
                                      display_name={
                                        language === "tm"
                                          ? subsetItem.tm
                                          : subsetItem.display_name
                                      }
                                      value={subsetItem.value}
                                      setValue={setValue}
                                    />
                                  )}
                                />
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Grid container rowSpacing={1} columnSpacing={1}>
            {editLoan && (
              <>
                <Grid item xs={6} sm={4} md={3} lg={1.5}>
                  <Typography sx={Styles.textlabel()}>
                    {t("loan creation.pledge item name")}
                  </Typography>
                  <Paper>
                    <Controller
                      name={"item_id"}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <SelectDropDown
                          field={field}
                          name={"item_id"}
                          readOnly={false}
                          register={register}
                          data={pledgeItemDropDown}
                          setValue={setValue}
                          display_name={
                            language === "tm" ? "item_name_tn" : "item_name"
                          }
                          tm={"item_name_tn"}
                          value={"item_id"}
                        />
                      )}
                    />
                  </Paper>
                </Grid>
                {pledgeMaterialType &&
                  (pledgeMaterialType === "gold" ||
                    pledgeMaterialType === "Gold" ||
                    pledgeMaterialType === "தங்கம்") && (
                    <Grid item xs={6} sm={4} md={3} lg={1.5}>
                      <Typography sx={Styles.textlabel()}>
                        {t("loan creation.gold category")}
                      </Typography>
                      <Paper>
                        <Controller
                          name={"gold_category"}
                          control={control}
                          rules={{ required: true }}
                          defaultValue=""
                          render={({ field }) => (
                            <SelectDropDown
                              field={field}
                              name={"gold_category"}
                              readOnly={false}
                              register={register}
                              data={goldCategory}
                              display_name={
                                language === "tm" ? "text_tn" : "text"
                              }
                              value={"value"}
                              setValue={setValue}
                            />
                          )}
                        />
                      </Paper>
                    </Grid>
                  )}
                <Grid item xs={6} sm={4} md={3} lg={1.5}>
                  <Typography sx={Styles.textlabel()}>
                    {t("loan creation.item type")}
                  </Typography>
                  <Paper>
                    <Controller
                      name={"stone_type"}
                      control={control}
                      rules={{ required: false }}
                      defaultValue=""
                      render={({ field }) => (
                        <SelectDropDown
                          field={field}
                          name={"stone_type"}
                          readOnly={false}
                          register={register}
                          clickFunction={handleStoneType}
                          clickType={"onChange"}
                          display_name={language === "tm" ? "text_tn" : "text"}
                          value={"value"}
                          data={[
                            {
                              text: "With Stone",
                              value: "with_stone",
                              text_tn: "கல்லுடன்",
                            },
                            {
                              text: "Without Stone",
                              value: "without_stone",
                              text_tn: "கல் இல்லாமல்",
                            },
                            {
                              text: "With Enamel",
                              value: "with_enamel",
                              text_tn: "பற்சிப்பியுடன்",
                            },
                            {
                              text: "Without Enamel",
                              value: "without_enamel",
                              text_tn: "பற்சிப்பி இல்லாமல்",
                            },
                          ]}
                          setValue={setValue}
                        />
                      )}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={6} sm={4} md={3} lg={1.5}>
                  <Typography sx={Styles.textlabel()}>
                    {" "}
                    {t("loan creation.quantity")}
                  </Typography>
                  <Paper>
                    <CommonTextField
                      name={"qty"}
                      register={register}
                      type="number"
                      readOnly={false}
                      position={"end"}
                      comboFixWord={""}
                    />
                  </Paper>
                </Grid>

                <Grid item xs={6} sm={4} md={3} lg={1.5}>
                  <Typography sx={Styles.textlabel()}>
                    {t("loan creation.no of stones")}
                  </Typography>
                  <Paper>
                    <CommonTextField
                      name={"no_of_stone"}
                      register={register}
                      type="number"
                      readOnly={
                        (stoneType && stoneType === "with_stone") ||
                        stoneType === "with_enamel"
                          ? false
                          : true
                      }
                      position={"end"}
                      clickFunction={handleStoneCult}
                      clickEvent={true}
                      comboFixWord={""}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={1.5}>
                  <Typography sx={Styles.textlabel()}>
                    {t("loan creation.gross weight")}
                  </Typography>
                  <Paper>
                    <CommonTextField
                      name={"gross_weight"}
                      register={register}
                      type="float"
                      pattern={"float"}
                      clickFunction={handleGrossWeigth}
                      clickEvent={true}
                      readOnly={false}
                      position={"end"}
                      comboFixWord={"g"}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={6} sm={4} md={3} lg={1.5}>
                  <Typography sx={Styles.textlabel()}>
                    {t("loan creation.net")}
                  </Typography>
                  <Paper>
                    <CommonTextField
                      name={"net_weight"}
                      register={register}
                      pattern={"float"}
                      type="float"
                      clickFunction={handleNetWeight}
                      clickEvent={true}
                      readOnly={false}
                      position={"end"}
                      comboFixWord={"g"}
                    />
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={1.5}
                  lg={0.5}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <input
                    type="file"
                    id="image-upload"
                    style={{ display: "none" }}
                    onInput={convertBase64}
                  />
                  <Tooltip
                    title={
                      image
                        ? t("loan creation.enlarge_image")
                        : t("loan creation.add_image")
                    }
                  >
                    <Avatar
                      src={image ? `${image}` : AddImage}
                      variant="rounded"
                      onContextMenu={(e) => handleContextMenu(e)}
                      alt=""
                      sx={{ height: 50, width: 50, cursor: "pointer" }}
                      onClick={() =>
                        document.getElementById("image-upload").click()
                      }
                    />
                  </Tooltip>
                  <ImageProvision
                    image={image}
                    setContextMenu={setContextMenu}
                    handleClose={handleClose}
                    contextMenu={contextMenu}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={2}
                  md={1.5}
                  lg={1}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <IconButton
                    sx={{
                      bgcolor: "#B1F164",
                      color: "#555",
                      "&:hover": {
                        bgcolor: "#82B643",
                        color: "#fff",
                        transform: "scale(1.2)",
                      },
                      mt: 4,
                    }}
                    type="submit"
                  >
                    {saveBtnVisible ? (
                      <LibraryAddCheckRounded sx={{ fontSize: "30px" }} />
                    ) : (
                      <LibraryAddRounded sx={{ fontSize: "30px" }} />
                    )}
                  </IconButton>
                </Grid>
              </>
            )}
          </Grid>
          <input type="hidden" {...register("loan_number")} />

          <Grid item xs={12} sx={{ mt: 3 }}>
            <TableContainer
              component={Paper}
              elevation={3}
              sx={{ maxHeight: 440 }}
              variant="elevation"
            >
              <Table stickyHeader aria-label="table">
                <TableHead>
                  <StyledTableRow>
                    {pledgeMaterialType &&
                      (pledgeMaterialType === "gold" ||
                        pledgeMaterialType === "Gold" ||
                        pledgeMaterialType === "தங்கம்") && (
                        <StyledTableCell>
                          {t("loan creation.gold category")}
                        </StyledTableCell>
                      )}
                    <StyledTableCell>
                      {t("loan creation.pledge item name")}
                    </StyledTableCell>

                    <StyledTableCell>
                      {t("loan creation.quantity")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t("loan creation.no of stones")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t("loan creation.gross weight")}
                    </StyledTableCell>

                    <StyledTableCell>{t("loan creation.net")}</StyledTableCell>

                    {/* <StyledTableC{t("loan creation.gold")}ell>Image</StyledTableCell> */}
                    <StyledTableCell>
                      {t("loan creation.image")}
                    </StyledTableCell>
                    {editLoan && materialList.length > 0 && (
                      <StyledTableCell>
                        {t("loan creation.action")}
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {materialList.length > 0 ? (
                    materialList.map((row, index) => (
                      <StyledTableRow key={index}>
                        {pledgeMaterialType &&
                          (pledgeMaterialType === "gold" ||
                            pledgeMaterialType === "Gold" ||
                            pledgeMaterialType === "தங்கம்") && (
                            <StyledTableCell>
                              {(() => {
                                let foundItem = goldCategory.find(
                                  (item) =>
                                    item.value === row.gold_category ||
                                    item.text === row.gold_category
                                );
                                return (
                                  foundItem &&
                                  (language === "tm"
                                    ? foundItem.text_tn
                                    : foundItem.text)
                                );
                              })()}
                            </StyledTableCell>
                          )}
                        <StyledTableCell>
                          {(() => {
                            //console.log(
                            //   "Plend Item Drop Down -",
                            //   pledgeItemDropDown
                            // );
                            let foundItem = pledgeItemDropDown.find(
                              (item) => item.item_id === row.item_id
                            );

                            return (
                              foundItem &&
                              (language === "tm"
                                ? foundItem.item_name_tn
                                : foundItem.item_name)
                            );
                          })()}
                        </StyledTableCell>
                        <StyledTableCell>{row.qty}</StyledTableCell>
                        <StyledTableCell>
                          {row.no_of_stone === "" || !row.no_of_stone
                            ? 0
                            : row.no_of_stone}
                        </StyledTableCell>
                        <StyledTableCell>
                          {parseFloat(row.gross_weight).toFixed(3)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {parseFloat(row.net_weight).toFixed(3)}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            pointerEvents: "",
                          }}
                        >
                          {row?.image ? (
                            <Avatar
                              src={row?.image}
                              variant="rounded"
                              onContextMenu={(e) =>
                                handleRowContextMenu(e, row)
                              }
                              alt=""
                              sx={{ height: 50, width: 50, margin: "0 auto" }}
                            />
                          ) : (
                            "--"
                          )}
                          <ImageProvision
                            image={selectedRow?.image}
                            setContextMenu={setRowContextMenu}
                            handleClose={handleRowClose}
                            contextMenu={rowContextMenu}
                          />
                        </StyledTableCell>
                        {editLoan && (
                          <StyledTableCell>
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <IconButton
                                sx={{
                                  bgcolor: "#1e88e5",
                                  color: "#fff",
                                  "&:hover": {
                                    bgcolor: "#0d47a1",
                                  },
                                }}
                                onClick={() => handleEditMaterial(index)}
                              >
                                <EditNoteSharp />
                              </IconButton>
                              <IconButton
                                sx={{
                                  bgcolor: "#ff8a80",
                                  color: "#fff",
                                  "&:hover": {
                                    bgcolor: "#d32f2f",
                                  },
                                }}
                                onClick={() => handleDeleteMat(index)}
                              >
                                <DeleteRounded />
                              </IconButton>
                            </Stack>
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={10}>
                        <Typography
                          sx={{
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--font-size)",
                          }}
                          component="p"
                          variant="h6"
                        >
                          {t("loan creation.no_data_found")}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                  <StyledTableRow>
                    <StyledTableCell
                      style={{ textAlign: "right" }}
                      colSpan={
                        pledgeMaterialType === "gold" ||
                        pledgeMaterialType === "Gold" ||
                        pledgeMaterialType === "தங்கம்"
                          ? 2
                          : 1
                      }
                    >
                      Total :
                    </StyledTableCell>
                    <StyledTableCell>
                      <CountUp
                        end={totalMaterial.qtyTotal}
                        useIndianSeparators={true}
                        duration={1.5}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <CountUp
                        useIndianSeparators={true}
                        end={totalMaterial.noOfStone}
                        duration={1.5}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <CountUp
                        end={totalMaterial.grossWeightTotal}
                        duration={1.5}
                        prefix={""}
                        suffix=" g"
                        decimals={3}
                        useIndianSeparators={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <CountUp
                        end={totalMaterial.netWeightTotal}
                        duration={1.5}
                        decimals={3}
                        prefix={""}
                        suffix=" g"
                        useIndianSeparators={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell />
                    {editLoan && materialList.length > 0 && (
                      <StyledTableCell>
                        <Button
                          type="button"
                          value={""}
                          size="small"
                          className="add-btn"
                          onClick={() => showConfirmationDialog("Update")}
                        >
                          {t("common.update loan")}
                        </Button>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </form>

        <Box sx={{ /* width: { md: '100%' }, */ mt: 2 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={2}
            flexDirection={{ xs: "column-reverse", sm: "row-reverse" }}
            sx={{ display: "flex", alignItems: "end" }}
          >
            {roleId === 1 &&
              status !== "Approved" &&
              status !== "Rejected" &&
              status !== "Closed" &&
              status !== "Disposed" && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "2rem",
                  }}
                >
                  <Button
                    type="button"
                    value={""}
                    size="small"
                    className="add-btn"
                    onClick={() => showConfirmationDialog("Approved")}
                  >
                    {t("common.approve loan")}
                  </Button>
                  <Button
                    type="button"
                    value={""}
                    size="small"
                    variant="contained"
                    className="add-btn error"
                    onClick={() => showConfirmationDialog("Rejected")}
                  >
                    {t("common.reject")}
                  </Button>
                </Grid>
              )}
            {status === "Rejected" && (
              <Grid item xs={12} sm={6} md={4}>
                <Typography sx={Styles.textlabel()}>
                  {language === "tm" ? "கருத்துக்கள்" : "Remarks"}
                </Typography>
                <CommonTextField
                  name={"remarks1"}
                  register={register}
                  type={"text"}
                  readOnly={true}
                  defaultValue={
                    language === "tm"
                      ? materialHeader.remarks_tn
                      : materialHeader.remarks
                  }
                />
              </Grid>
            )}
            {status === "Pending" && roleId === 1 && (
              <>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography sx={Styles.textlabel()}>கருத்துக்கள்</Typography>
                  <CommonTextField
                    name={"remarks_tn"}
                    register={register}
                    type={"text"}
                    readOnly={status !== "Pending" ? true : false}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography sx={Styles.textlabel()}>Remarks</Typography>
                  <CommonTextField
                    name={"remarks"}
                    register={register}
                    type={"text"}
                    readOnly={status !== "Pending" ? true : false}
                    clickFunction={handleRemarksChange}
                    clickEvent={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};
