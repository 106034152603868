import { MenuItem, OutlinedInput, Select } from "@mui/material";
import React from "react";
import Styles from "../common/Styles";

export const SelectDropDown = (props) => {
  const {
    name,
    register,
    readOnly,
    display_name,
    value,
    data,
    field,
    clickFunction,
    clickType,
    setValue,
    required,
    defaultValue,
  } = props;

  return (
    <Select
      {...field}
      {...register(name, {
        required: name === "mode_of_payment" || required ? false : true,
      })}
      sx={{
        backgroundColor: "var(--text-field)",
        color: "var(--font-color)",
        fontFamily: "var(--font-family)",
        fontSize: "var(--font-size)",
        "& .MuiSvgIcon-root": {
          color: "var(--font-color)", // Change the color of the dropdown icon
        },
      }}
      fullWidth
      required={name === "mode_of_payment" ? false : required}
      size="small"
      defaultValue={defaultValue}
      inputProps={{
        style: {
          height: "15px",
        },
      }}
      onChange={(e) => {
        if (clickType === "onChange") {
          clickFunction(e.target.value);
        }
        setValue(name, e.target.value); // Update the form state
      }}
      input={<OutlinedInput readOnly={readOnly} />}
      style={{ height: "40px" }} // Adjust the height here
      MenuProps={Styles.MenuProps()}
    >
      {data.map((item, index) => {
        return (
          <MenuItem
            key={index}
            value={item[value]}
            className="common-font-style"
          >
            {item[display_name] ? item[display_name] : item.text}
          </MenuItem>
        );
      })}
    </Select>
  );
};
