import { Paper, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import Styles from "../Styles";

const CustomeDatePicker = ({ value, onChange, readOnly, label }) => {
  const shouldDisableDate = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const minBirthDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const maxnBirthDate = new Date(
      currentDate.getFullYear() - 80,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return date >= minBirthDate || date <= maxnBirthDate;
  };

  return (
    <>
      <Typography
        sx={{
          ...Styles.textlabel(),
          color: readOnly ? "var(--app-bar-color)" : "var(--font-color)",
        }}
      >
        {label}
      </Typography>
      <Paper style={{ backgroundColor: "transparent" }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            slotProps={{
              textField: {
                size: "small",
                sx: {
                  backgroundColor: readOnly
                    ? "var(--disabled-field)"
                    : "var(--text-field)",
                  color: readOnly
                    ? "var(--disabled-font)"
                    : "var(--font-color)",
                  // fontFamily: "var(--font-family)",
                  // fontSize: "var(--font-size)",
                  "& .MuiSvgIcon-root,.MuiTypography-root": {
                    backgroundColor: readOnly
                      ? "var(--disabled-field)"
                      : "var(--text-field)",
                    color: readOnly
                      ? "var(--disabled-font)"
                      : "var(--font-color)", // Change the color of the dropdown icon
                  },
                  "& input": {
                    height: "20px",
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                    backgroundColor: readOnly
                      ? "var(--disabled-field)"
                      : "var(--text-field)",
                    color: readOnly
                      ? "var(--disabled-font)"
                      : "var(--font-color)",
                  },
                  width: "100%",
                },
              },
            }}
            format="DD-MM-YYYY"
            readOnly={readOnly === null || readOnly === "" ? false : readOnly}
            value={value}
            onChange={onChange}
            shouldDisableYear={shouldDisableDate}
            // maxDate={new Date()}
            // minDate={minDate}
          />
        </LocalizationProvider>
      </Paper>
    </>
  );
};

export default CustomeDatePicker;
