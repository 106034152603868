import { InputAdornment, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import Styles from "../Styles";

const ErrorTextfield = (props) => {
  const {
    label,
    value,
    required,
    onBlur,
    error,
    onChange,
    type,
    maxLength,
    readOnly,
    position,
    comboFixWord,
    name,
  } = props;

  const handleInputChange = (e) => {
    if (type === "number") {
      e.target.value = Math.max(0, parseInt(e.target.value))
        .toString()
        .slice(0, maxLength);
    } else {
      e.target.value = e.target.value.slice(0, maxLength);
    }

    onChange(e);
  };

  const stylesCSS = {
    input: {
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
        display: "none",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
        display: "none",
      },
    },
  };

  return (
    <>
      <Typography sx={{
          ...Styles.textlabel(),
          color: readOnly ? "var(--app-bar-color)" : "var(--font-color)",
        }}>{label}</Typography>
      <Paper style={{ backgroundColor: "transparent" }}>
        <TextField
          name={name}
          sx={{
            backgroundColor: readOnly
              ? "var(--disabled-field)"
              : "var(--text-field)",
            ...stylesCSS.input,
            color: "var(--font-color)",
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-size)",
            "& textarea": {
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
              color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
            },
            "& .MuiSvgIcon-root,.MuiTypography-root": {
              color: readOnly ? "var(--disabled-font)" : "var(--font-color)", // Change the color of the dropdown icon
            },
          }}
          fullWidth
          type={type === "" || null ? "text" : type}
          value={value}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (type === "number") {
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
            }
          }}
          size="small"
          required={required}
          error={Boolean(error)}
          onBlur={onBlur}
          inputProps={{
            style: {
              minHeight: "15px",
              maxHeight: "22px",
              color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            },
          }}
          InputProps={{
            maxLength: maxLength,
            readOnly: readOnly == null || readOnly === "" ? false : readOnly,
            startAdornment: position === "start" && (
              <InputAdornment position={position}>
                {comboFixWord}
              </InputAdornment>
            ),
            endAdornment: position === "end" && (
              <InputAdornment position={position}>
                {comboFixWord}
              </InputAdornment>
            ),
          }}
        />
      </Paper>
      {error ? (
        <span style={{ ...Styles.textlabel(), color: "red" }}>{error}</span>
      ) : null}
    </>
  );
};

export default ErrorTextfield;
