import { Button } from "@mui/material";
import React from "react";
import Styles from "../Styles";

const CustomCommonButton = (props) => {
  const { onClick, icon, text, disabled, color } = props;
  return (
    <Button
      type="submit"
      variant={color === "error" ? "outlined" : "contained"}
      color={color ?? "success"}
      size="small"
      disabled={disabled === "" ? false : disabled}
      sx={{
        ...Styles.commonButton(),
        fontFamily: "var(--font-family)", fontSize: "var(--font-size)",
        backgroundColor: color ? "" : "#B0F062",
        color: color ? "red" : "black",
        display: "flex",
        alignItems: "center",
        minWidth: "150px",
      }}
      onClick={onClick}
    >
      {icon}
      {text}
    </Button>
  );
};

export default CustomCommonButton;
