import {
  AddOutlined,
  ArrowCircleLeftOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  MoreVert,
  PublishedWithChanges,
  Save,
} from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Avatar,
  Box,
  Checkbox,
  Grid,
  MobileStepper,
  Modal,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import api from "../api/http-common";
import AddImageIcon from "../assets/AddImageIcon.svg";
import { AppData } from "../common/AppData";
import BulkUpload from "../common/Components/BulkUpload";
import CustomCommonButton from "../common/Components/CustomCommonButton";
import CustomeDatePicker from "../common/Components/CustomeDatePicker";
import Dropdown from "../common/Components/Dropdown";
import ErrorTextfield from "../common/Components/ErrorTextfield";
import MultiDisplayDropdown from "../common/Components/MultiDisplayDropdown";
import Textfield from "../common/Components/TextField";
import Extension from "../common/Extension";
import GridTable from "../common/GridTable";
import Loading from "../common/Loading";
import SnackBar from "../common/SnackBar";
import Styles from "../common/Styles";
import Logger from "../common/Logger";

const logger = new Logger({ level: "log" });
const OCCUPATION_CHOICES = [
  { value: "Doctor", name: "Doctor", name_tn: "மருத்துவர்" },
  { value: "Engineer", name: "Engineer", name_tn: "பொறியாளர்" },
  { value: "Farmer", name: "Farmer", name_tn: "உழவர்" },
  { value: "Government", name: "Government", name_tn: "அரசாங்கம்" },
  { value: "Private", name: "Private", name_tn: "தனியார்" },
  {
    value: "Self-Employment",
    name: "Self-Employment",
    name_tn: "சுய வேலைவாய்ப்பு",
  },
  { value: "Teacher", name: "Teacher", name_tn: "ஆசிரியர்" },
  { value: "Others", name: "Others", name_tn: "மற்றவை" },
];

const GENDERS = [
  { value: "male", name: "Male", name_tn: "ஆண்" },
  { value: "female", name: "Female", name_tn: "பெண்" },
  { value: "trans", name: "Transgender", name_tn: "திருநங்கை" },
];

const Customer = () => {
  // const { userCredentails, setUserCredentails } =
  //   useContext(UserDetailsContext);
  const { t } = useTranslation("global");

  const Columns = AppData.customer.map((column) => ({
    ...column,
    headerName: t(`customer master.${column.field}`),
  }));
  // const roleId = userCredentails.userdata.role_id;
  // const branch_id = userCredentails.userdata.branch_id;
  // const entity_id = userCredentails.entitydata.entity_id;

  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const entity_id = sessionStorage.getItem("entityId");

  const [searchQuery, setSearchQuery] = useState("");
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [customerPopup, setCustomerPopup] = useState(false);
  const [bulkPopUp, setBulkPopUp] = useState(false);
  const [emailId, setEmailId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [entityId, setEntityId] = useState(entity_id);
  const [branchId, setBranchId] = useState(roleId === 1 ? "" : branch_id);
  const [customerId, setCustomerId] = useState("");
  const [refCustomerId, setrefCustomerId] = useState("");
  const [firstName, setFirstname] = useState("");
  const [firstName_tn, setFirstname_tn] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastName_tn, setLastName_tn] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [occupation, setOccupation] = useState("");
  const [permanentAddress, setPermanentAddress] = useState("");
  const [currentAddress, setCurrentAddress] = useState("");
  const [permanentAddress_tn, setPermanentAddress_tn] = useState("");
  const [currentAddress_tn, setCurrentAddress_tn] = useState("");
  const [photo, setPhoto] = useState("noimage");
  const [aadharNo, setAadharNo] = useState("");
  const [panNo, setPanNo] = useState("NoPAN");
  const [bankName, setBankName] = useState("");
  const [bankName_tn, setBankName_tn] = useState("");
  const [bankAccNo, setBankAccNo] = useState("");
  const [referenceBy, setReferenceBy] = useState("");
  const [reference_by_tn, SetReference_by_tn] = useState("");
  const [branchName, setBranchName] = useState("");
  const [branchName_tn, setBranchName_tn] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [readOnly, setReadOnly] = useState(false);
  const [fradulent, setFradulent] = useState(false);
  //Arrays
  const [customers, setCustomers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [sequenceId, setSequenceId] = useState([]);

  //Error Manage states
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [IFSCError, setIFSCError] = useState("");
  const [adhaarError, setAdhaarError] = useState("");

  const [loading, setLoading] = useState(true);
  const [customerUpdate, setCustomerUpdate] = useState(null);
  const [loanExists, setLoanExists] = useState(false);
  const [addbuttonDisabled, setAddbuttonDisabled] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [remarks_tn, setRemarks_tn] = useState("");
  const clearTextFields = () => {
    setEmailId("");
    setMobileNumber("");
    setEntityId("");
    setBranchId("");
    setrefCustomerId("");
    setFirstname("");
    setLastName("");
    setFirstname_tn("");
    setLastName_tn("");
    setGender("");
    setDob("");
    setOccupation("");
    setPermanentAddress("");
    setCurrentAddress("");
    setPermanentAddress_tn("");
    setCurrentAddress_tn("");
    setPhoto("");
    setAadharNo("");
    setPanNo("");
    setBankName("");
    setBankName_tn("");
    setBankAccNo("");
    setBranchName("");
    setBranchName_tn("");
    setIfscCode("");
    setReferenceBy("");
    SetReference_by_tn("");
    setRemarks("");
    setRemarks_tn("");
  };

  //validation messages
  const validations = [
    {
      value: branchId,
      message: "Please Select The Branch",
      message_tn: "தயவுசெய்து கிளை தேர்ந்தெடுக்கவும்",
    },
    {
      value: firstName,
      message: "Please Enter First Name",
      message_tn: "தயவுசெய்து முதல் பெயரை உள்ளிடவும்",
    },
    {
      value: firstName_tn,
      message: "Please Enter First Name in Tamil",
      message_tn: "தமிழில் முதல் பெயரை உள்ளிடவும்",
    },
    {
      value: lastName,
      message: "Please Enter Last Name",
      message_tn: "தயவுசெய்து கடைசி பெயரை உள்ளிடவும்",
    },
    {
      value: lastName_tn,
      message: "Please Enter Last Name in Tamil",
      message_tn: "தமிழில் கடைசி பெயரை உள்ளிடவும்",
    },
    {
      value: mobileNumber,
      message: "Please Enter Mobile Number",
      message_tn: "தயவுசெய்து கைபேசி எண் உள்ளிடவும்",
    },
    {
      value: aadharNo,
      message: "Please Enter Aadhar Number",
      message_tn: "தயவுசெய்து ஆதார் எண் உள்ளிடவும்",
    },
    {
      value: permanentAddress,
      message: "Please Enter Permanent Address",
      message_tn: "தயவுசெய்து நிரந்தர முகவரியை உள்ளிடவும்",
    },
    {
      value: permanentAddress_tn,
      message: "Please Enter Permanent Address in Tamil",
      message_tn: "தமிழில் நிரந்தர முகவரியை உள்ளிடவும்",
    },
    {
      value: currentAddress_tn,
      message: "Please Enter Current Address in Tamil",
      message_tn: "தமிழில் தற்போதைய முகவரியை உள்ளிடவும்",
    },
    {
      value: currentAddress,
      message: "Please Enter Current Address",
      message_tn: "தயவுசெய்து தற்போதைய முகவரியை உள்ளிடவும்",
    },
    {
      value: gender,
      message: "Please Select Gender",
      message_tn: "தயவுசெய்து பாலினத்தை தேர்ந்தெடுக்கவும்",
    },
    {
      value: occupation,
      message: "Please Enter Occupation",
      message_tn: "தயவுசெய்து தொழில்நுட்பத்தை உள்ளிடவும்",
    },
    {
      value: dob,
      message: "Please Enter Date of Birth",
      message_tn: "பிறந்த தேதியை உள்ளிடவும்",
    },
  ];

  const getData = async () => {
    try {
      handleLoadingOpen();
      const branchAPI =
        roleId === 1 ? `/branch/byEntity/${entity_id}` : `branch/${branch_id}`;
      const customerAPI =
        roleId === 1
          ? `customer/customers/${entity_id}`
          : `/customer/branch/${branch_id}`;
      logger.log("Initiate Fetch branch");
      const branchRes = await api.get(branchAPI);
      logger.log("Fetch branch Successfully");
      setBranches(branchRes.data);
      const prefix = roleId !== 1 ? branchRes.data.branch_prefix : "";
      logger.log("Initiate Fetch customers");
      const customerRes = await api.get(customerAPI);
      logger.log("Fetch customers Successfully");
      setCustomers(customerRes.data);
      const ids = customerRes.data.map((item) => item.cus_login_id);
      const sortedSequences = ids.sort((a, b) => a.localeCompare(b));
      const lastSequence = Extension.findLastSequence(sortedSequences, prefix);
      setSequenceId(lastSequence);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };

  //popup visible function
  const ShowAddPopup = () => {
    handleOpenCustomerPopup();
    if (roleId === 2) {
      setCustomerId(sequenceId);
      setBranchId(branch_id);
    }
    setEntityId(entity_id);
    setPanNo("No PAN");
    setPhoto(null);
  };

  //show bulk upload popup
  const showBulkPopUp = () => {
    handleOpenBulkPopUp();
  };

  //add customer function
  const addCustomer = async () => {
    if (!emailId) {
      setEmailError("");
    }
    if (
      // aadharNo &&
      // bankAccNo &&
      // bankName &&
      // bankName_tn &&
      branchId &&
      // branchName &&
      // branchName_tn &&
      dob &&
      firstName &&
      firstName_tn &&
      lastName &&
      lastName_tn &&
      entityId 
      // ifscCode &&
      // gender &&
      // occupation &&
      // mobileNumber &&
      // permanentAddress &&
      // permanentAddress_tn &&
      // currentAddress_tn &&
      // currentAddress
    ) {
      try {
        handleLoadingOpen();
        setAddbuttonDisabled(true);
        console.log("###########################")
        const date = new Date(dob);
        const fd = date? Extension.formatDate(date):"";
        const occupation_tn = occupation? (OCCUPATION_CHOICES.find(
          (occ) => occ.value === occupation)).name_tn: "";
        const gender_tn = gender? (GENDERS.find((gend) => gend.value === gender).name_tn):"";
        let customer_id = "";
        const branchid = branchId === "" ? branch_id : branchId;
        const Prefix =
          roleId === 1
            ? branches.find((item) => item.branch_id === branchid).branch_prefix
            : branches.branch_prefix;
        logger.log("Initiate Fetch customers");
        const customerRes = await api.get(`/customer/branch/${branchId}`);
        logger.log("Fetch customers Successfully");
        const ids = customerRes.data.map((item) => item.cus_login_id);
        const sortedSequences = ids.sort((a, b) => a.localeCompare(b));
        const lastSequence = Extension.findLastSequence(
          sortedSequences,
          Prefix
        );
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@")
        customer_id = lastSequence;
        const data = {
          aadhar_card_number: aadharNo,
          bank_account_number: bankAccNo,
          bank_name: bankName,
          bank_name_tn: bankName_tn,
          branch_id: roleId === 1 ? branchId : branch_id,
          branch_name: branchName,
          cus_login_id: customer_id,
          branch_name_tn: branchName_tn,
          dob: dob ? fd : null,
          first_name: firstName,
          ifsc_code: ifscCode,
          first_name_tn: firstName_tn,
          last_name: lastName,
          last_name_tn: lastName_tn,
          pan_card_number: panNo,
          photos: photo || "no image",
          reference_customer_id: refCustomerId,
          shop_id: entity_id,
          gender: gender,
          occupation: occupation,
          mobile_number: mobileNumber,
          permanent_address: permanentAddress,
          current_address: currentAddress,
          email_id: emailId,
          reference_by: referenceBy,
          current_address_tn: currentAddress_tn,
          permanent_address_tn: permanentAddress_tn,
          gender_tn: gender_tn,
          occupation_tn: occupation_tn,
          reference_by_tn,
        };
        console.log(data)
        logger.log("Initiate post customer");
        await api.post("customer/customers", data);
        // .catch((err) => console.log(err));
        logger.log("Post customer Successfully");
        getData();
        handleCloseCustomerPopup();
        SnackBar.toastMsg(
          "success",
          t("toast_messages.customer_added_successfully")
        );
      } catch (error) {
        if (error.response.data.dob) {
          SnackBar.toastMsg("error", "Please Enter the valid Date");
          return;
        }
        logger.log(error);
      } finally {
        setAddbuttonDisabled(false);
        handleLoadingClose();
      }
    } else {
      for (const validation of validations) {
        if (!validation.value) {
          SnackBar.toastMsg(
            "error",
            Extension.languageBasedValue(
              validation.message,
              validation.message_tn
            )
          );
          return;
        }
      }
    }
  };

  //initiate the customer update
  const enterEdit = async (customer) => {
    try {
      handleLoadingOpen();
      setReadOnly(true);
      await existingLoanCheck(customer.customer_id); //check whether the customer having existing loans
      setCustomerUpdate(customer);
      setEntityId(customer.shop_id);
      setCustomerId(customer.cus_login_id);
      setBranchId(customer.branch_id);
      setrefCustomerId(customer.reference_customer_id);
      setFirstname(customer.first_name);
      setFirstname_tn(customer.first_name_tn);
      setLastName(customer.last_name);
      setLastName_tn(customer.last_name_tn);
      setDob(customer.dob ? dayjs(customer.dob) : null);
      setPhoto(customer.photos);
      setGender(customer.gender);
      setMobileNumber(customer.mobile_number);
      setEmailId(customer.email_id);
      setCurrentAddress(customer.current_address);
      setPermanentAddress(customer.permanent_address);
      setCurrentAddress_tn(customer.current_address_tn);
      setPermanentAddress_tn(customer.permanent_address_tn);
      setOccupation(customer.occupation);
      setAadharNo(customer.aadhar_card_number);
      setPanNo(customer.pan_card_number);
      setBankName(customer.bank_name);
      setBankName_tn(customer.bank_name_tn);
      setBankAccNo(customer.bank_account_number);
      setBranchName(customer.branch_name);
      setBranchName_tn(customer.branch_name_tn);
      setIfscCode(customer.ifsc_code);
      setReferenceBy(customer.reference_by);
      SetReference_by_tn(customer.reference_by_tn);
      setFradulent(customer.fradulent);
      setRemarks(customer.fradulent_remark);
      setRemarks_tn(customer.fradulent_remark_tn);
      handleOpenCustomerPopup();
    } finally {
      handleLoadingClose();
    }
  };

  const updateCustomer = async () => {
    if (!emailId) {
      setEmailError("");
    }
    if (
      customerUpdate &&
      // aadharNo &&
      branchId 
      // mobileNumber &&
      // permanentAddress &&
      // permanentAddress_tn &&
      // currentAddress_tn &&
      // currentAddress
    ) {
      if (!remarks && !remarks_tn && fradulent) {
        return SnackBar.toastMsg("error", "Enter Remarks");
      }
      const date = new Date(dob);
      const fd = Extension.formatDate(date);
      const occupation_tn =
        occupation !== ""
          ? OCCUPATION_CHOICES.find((occ) => occ.value === occupation)?.name_tn
          : "";
      const gender_tn = GENDERS.find((gend) => gend.value === gender)?.name_tn;
      try {
        handleLoadingOpen();
        setAddbuttonDisabled(true);
        const Id = customerUpdate.customer_id;
        let customer_id = "";
        const branchIdNotSame = customerUpdate.branch_id !== branchId;
        if (branchIdNotSame) {
          const Prefix =
            roleId === 1
              ? branches.find((item) => item.branch_id === branchId)
                  .branch_prefix
              : branches.branch_prefix;
          logger.log("Initiate Fetch customers");
          const customerRes = await api.get(`/customer/branch/${branchId}`);
          logger.log("Fetch customers Successfully");
          const ids = customerRes.data.map((item) => item.cus_login_id);
          const sortedSequences = ids.sort((a, b) => a.localeCompare(b));
          const lastSequence = Extension.findLastSequence(
            sortedSequences,
            Prefix
          );
          customer_id = lastSequence;
        }
        const data = {
          ...customerUpdate,
          aadhar_card_number: aadharNo,
          bank_account_number: bankAccNo,
          bank_name: bankName,
          bank_name_tn: bankName_tn,
          branch_id: branchId,
          branch_name: branchName,
          branch_name_tn: branchName_tn,
          cus_login_id: branchIdNotSame ? customer_id : customerId,
          dob: dob ? fd : null,
          reference_by_tn: reference_by_tn,
          first_name: firstName,
          first_name_tn: firstName_tn,
          ifsc_code: ifscCode,
          last_name: lastName,
          last_name_tn: lastName_tn,
          pan_card_number: "PAN",
          photos: photo || "no image",
          reference_customer_id: refCustomerId,
          shop_id: entityId,
          gender: gender,
          gender_tn: gender_tn,
          occupation: occupation,
          occupation_tn: occupation_tn,
          mobile_number: mobileNumber,
          permanent_address: permanentAddress,
          permanent_address_tn: permanentAddress_tn,
          current_address: currentAddress,
          current_address_tn: currentAddress_tn,
          email_id: emailId,
          reference_by: referenceBy,
          fradulent: fradulent,
          fradulent_remark: remarks,
          fradulent_remark_tn: remarks_tn,
        };
        logger.log("Initiate update customer");
        await api.put(`customer/${Id}`, data);
        logger.log("Update customer Successfully");
        getData();
        handleCloseCustomerPopup();
        SnackBar.toastMsg(
          "success",
          t("toast_messages.customer_updated_successfully")
        );
      } catch (error) {
        logger.log(error);
      } finally {
        setAddbuttonDisabled(false);
        handleLoadingClose();
      }
    } else {
      for (const validation of validations) {
        if (validation.value === dob) {
          return;
        }
        if (!validation.value) {
          SnackBar.toastMsg(
            "error",
            Extension.languageBasedValue(
              validation.message,
              validation.message_tn
            )
          );
          return;
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //open customer popup
  const handleOpenCustomerPopup = () => {
    setCustomerPopup(true);
  };

  //open bulk-upload popup
  const handleOpenBulkPopUp = () => {
    setBulkPopUp(true);
  };

  //close customer popup
  const handleCloseCustomerPopup = () => {
    setCustomerPopup(false);
    setFradulent(false);
    setReadOnly(false);
    setActiveStep(0);
    clearTextFields();
    setLoanExists(false);
    setIFSCError("");
    setMobileError("");
    setEmailError("");
    setCustomerUpdate(null);
  };

  //close bulk upload popup
  const handleCloseBulkPopUp = () => {
    setBulkPopUp(false);
  };

  //existing loan check function
  const existingLoanCheck = async (cus_id) => {
    try {
      logger.log("Initiate fetch loans");
      const res = await api.get(`/loan/loans/${entity_id}`);
      logger.log("fetch loans Successfully");
      const data = res.data;
      const customerData = data.filter(
        (item) => item.customer === cus_id && item.status === "Approved"
      );
      return Array.isArray(customerData) && customerData.length === 0
        ? setLoanExists(false)
        : setLoanExists(true);
    } catch (error) {
      logger.log(error);
    }
  };

  //handle branch change function
  const handleBranchChange = async (e) => {
    const branch = e.target.value;
    setBranchId(branch);
  };

  //convert image to base64 function
  function convertBase64(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target.result) {
          const base64Image = event.target.result;
          const base64Data = base64Image.replace(
            /^data:image\/(png|jpg|jpeg);base64,/,
            ""
          );
          setPhoto(base64Data);
        } else {
          SnackBar.toastMsg("error", t("toast_messages.failed_to_load"));
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  //handle fradulent
  const handleFradulentChange = () => {
    setFradulent(!fradulent);
    if (!fradulent) {
      setRemarks("");
      setRemarks_tn("");
      return;
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setDob(dob ? dayjs(dob) : null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailId(email);
    validateEmail(email);
  };

  const handlemobileNumberChange = (e) => {
    const mobile = e.target.value;
    setMobileNumber(mobile);
    validateMobile(mobile);
  };

  const handleIFSCChange = (e) => {
    const IFSC = e.target.value.toUpperCase();
    setIfscCode(IFSC);
    validateIFSC(IFSC);
  };

  const handleAdhaarChange = (e) => {
    const adhaar = e.target.value;
    setAadharNo(adhaar);
    validateAadhaar(adhaar);
  };
  //Email Validation
  const validateEmail = (email) => {
    const emailRegex = /^[\w-]+@([\w-]+\.)+[a-zA-Z]{2,4}$/;

    if (!email) {
      setEmailError("");
    }
    if (!emailRegex.test(email)) {
      setEmailError(t("customer master.email valid error"));
    } else {
      setEmailError("");
    }
  };

  //Mobile Nuber validation
  const validateMobile = (phone) => {
    const phoneRegex = /^\+?[0-9-]+$/;

    if (!phone) {
      setMobileError(t("customer master.phone require error"));
    } else if (!phoneRegex.test(phone) || phone.length !== 10) {
      setMobileError(t("customer master.phone valid error"));
    } else {
      setMobileError("");
    }
  };

  const validateIFSC = (IFSC) => {
    const IFSCRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    if (!IFSC) {
      setIFSCError(t("customer master.IFSC require error"));
    } else if (!IFSCRegex.test(IFSC)) {
      setIFSCError(t("customer master.IFSC valid error"));
    } else {
      setIFSCError("");
    }
  };

  const validateAadhaar = (aadhaarNumber) => {
    const aadhaarRegex = /^\d{12}$/;

    if (!aadhaarNumber) {
      setAdhaarError(t("Aadhaar number is required"));
    } else if (!aadhaarRegex.test(aadhaarNumber)) {
      setAdhaarError(t("Invalid Aadhaar number"));
    } else {
      setAdhaarError("");
    }
  };

  useEffect(() => {
    if (!emailId) {
      setEmailError("");
    }
  }, [emailId]);

  const handleDateChange = (e) => {
    const date = e ? new Date(e) : null;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const minBirthDate = new Date(
      currentDate.getFullYear() - 18,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const maxnBirthDate = new Date(
      currentDate.getFullYear() - 80,
      currentDate.getMonth(),
      currentDate.getDate()
    );
    if (date >= minBirthDate || date <= maxnBirthDate) {
      <h1>("")</h1>;
    } else {
      setDob(date);
    }
  };
  const filterCustomer = customers.filter(
    (item) =>
      (selectedBranch === "" ||
        selectedBranch === "All" ||
        item.branch_id.toString() === selectedBranch.toString()) &&
      (item?.first_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.last_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.first_name_tn
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item?.last_name_tn?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.mobile_number?.toString().includes(searchQuery.toString()) ||
        item?.cus_login_id?.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const rows = filterCustomer.map((customer, index) => {
    const branch =
      branches.length > 0
        ? branches.find((branch) => branch.branch_id == customer.branch_id)
        : "";

    const isFradulent = customer.fradulent;

    const rowStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "700",
    };

    return {
      id: index + 1,
      image: (
        <Avatar
          alt={customer.name}
          src={
            customer.photos !== null
              ? `data:image/jpeg;base64,${customer.photos}`
              : null
          }
          sx={{ width: 36, height: 36 }}
        />
      ),
      customerId: (
        <Tooltip
          style={rowStyle}
          title={Extension.languageBasedValue(
            customer.fradulent_remark,
            customer.fradulent_remark_tn
          )}
        >
          {customer.cus_login_id}
        </Tooltip>
      ),
      customername: (
        <Tooltip
          style={rowStyle}
          title={Extension.languageBasedValue(
            customer.fradulent_remark,
            customer.fradulent_remark_tn
          )}
        >
          {Extension.languageBasedValue(
            customer.first_name,
            customer.first_name_tn
          )}{" "}
          {Extension.languageBasedValue(
            customer.last_name,
            customer.last_name_tn
          )}
        </Tooltip>
      ),

      branch: (
        <Tooltip
          style={rowStyle}
          title={Extension.languageBasedValue(
            customer.fradulent_remark,
            customer.fradulent_remark_tn
          )}
        >
          {branch
            ? Extension.languageBasedValue(
                branch.branch_name,
                branch.branch_name_tn
              )
            : Extension.languageBasedValue(
                branches.branch_name,
                branches.branch_name_tn
              )}
        </Tooltip>
      ),

      occupation: (
        <Tooltip
          style={rowStyle}
          title={Extension.languageBasedValue(
            customer.fradulent_remark,
            customer.fradulent_remark_tn
          )}
        >
          {Extension.languageBasedValue(
            customer.occupation,
            customer.occupation_tn
          )}
        </Tooltip>
      ),
      mbno: (
        <Tooltip
          style={rowStyle}
          title={Extension.languageBasedValue(
            customer.fradulent_remark,
            customer.fradulent_remark_tn
          )}
        >
          {customer.mobile_number}
        </Tooltip>
      ),
      moreinfo: (
        <MoreVert
          sx={{ cursor: "pointer" }}
          onClick={() => enterEdit(customer)}
        />
      ),
    };
  });

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };

  return (
    <div>
      <Paper className="commonpaper">
        <Toaster limit={3} />
        <Loading open={loading} handleClose={handleLoadingClose} />
        <Grid
          container
          spacing={{ xs: 2, md: 4, sm: 4 }}
          columns={{ xs: 1, sm: 2, md: 2, lg: 4 }}
          sx={{ elevation: 3, mb: 2, display: "flex", alignItems: "flex-end" }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <CustomCommonButton
              onClick={ShowAddPopup}
              icon={<AddOutlined />}
              text={t("common.customer")}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            {roleId === 1 ? (
              <Dropdown
                firstOption={t("common.all")}
                search={true}
                label={t("common.branch name")}
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
                array={branches}
                menuValue={"branch_id"}
                display={Extension.languageBasedValue(
                  "branch_name",
                  "branch_name_tn"
                )}
              />
            ) : roleId === 2 ? (
              <Typography variant="h5" sx={Styles.titleText()}>
                {Extension.languageBasedValue(
                  branches.branch_name,
                  branches.branch_name_tn
                )}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Textfield
              label={t("customer master.search customer")}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            sx={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <CustomCommonButton
              onClick={showBulkPopUp}
              icon={<UploadIcon />}
              text={t("common.bulkUpload")}
            />
          </Grid>
        </Grid>

        <GridTable rows={rows} columns={Columns} isLoading={loading} />
      </Paper>
      {/* <Modal
        open={webCamPopup}
        onClose={handleWebCamClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...Styles.widthPopupStyles(),width:400, height:400, display:'flex', justifyContent:'center', alignItems:'center'}}>
          <Webcam
            style={{  cursor: "pointer", width:'80%' }} />
        </Box>
      </Modal> */}

      {/*Customer Popup Modals */}
      <Modal
        open={customerPopup}
        // onClose={handleCloseCustomerPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Styles.widthPopupStyles()}>
          <Loading open={loading} handleClose={handleLoadingClose} />
          <Box
            sx={{
              backgroundColor: "var(--app-bar-color)",
              p: 1,
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Typography
              sx={{
                color: "var(--header-font-color)",
                fontSize: "var(--font-size)",
                fontFamily: "var(--font-family)",
              }}
            >
              <ArrowCircleLeftOutlined
                onClick={handleCloseCustomerPopup}
                sx={{ cursor: "pointer", mr: 1 }}
              />
              {customerUpdate
                ? t("customer master.update_cusomter")
                : t("customer master.add_cusomter")}
            </Typography>
          </Box>
          {activeStep === 0 ? (
            <Box sx={{ p: 4, pt: 1, pb: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3, sm: 3 }}
                columns={{ xs: 1, sm: 2, md: 2 }}
                sx={{ elevation: 3, mb: 2 }}
              >
                <Grid item xs={1} sm={1} md={1}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <input
                      type="file"
                      id="image-upload"
                      style={{ display: "none" }}
                      onChange={convertBase64}
                    />
                    <Avatar
                      src={
                        photo !== "noimage"
                          ? `data:image/jpeg;base64, ${photo}`
                          : AddImageIcon
                      }
                      alt=""
                      sx={{ height: 75, width: 75, cursor: "pointer" }}
                      onClick={() =>
                        document.getElementById("image-upload").click()
                      }
                    />
                  </Box>
                </Grid>
                {/* <Grid item xs={1} sm={1} md={1}  >
                  <Textfield
                    value={customerId}
                    readOnly={true}
                    onChange={(e) => setCustomerId(e.target.value)}
                    label={"Customer ID"}
                  />
                </Grid> */}
                <Grid item xs={1} sm={1} md={1}>
                  {roleId === 1 ? (
                    <Box>
                      <MultiDisplayDropdown
                        label={"Branch/ கிளை"}
                        value={branchId}
                        onChange={handleBranchChange}
                        readOnly={loanExists}
                        array={branches}
                        menuValue={"branch_id"}
                        display={["branch_name", "branch_name_tn"]}
                      />
                      {loanExists ? (
                        <span className="error-text">
                          {t("customer master.exists loan error")}
                        </span>
                      ) : null}
                    </Box>
                  ) : roleId === 2 ? (
                    <Textfield
                      value={`${branches.branch_name}/${branches.branch_name_tn}`}
                      readOnly={true}
                      label={"Branch Name/ கிளை பெயர்"}
                    />
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={{ xs: 2, md: 3, sm: 3 }}
                columns={{ xs: 1, sm: 2, md: 2 }}
                sx={{ elevation: 3, mb: 2 }}
              >
                <Grid item xs={1} sm={1} md={0.5}>
                  <Textfield
                    value={firstName}
                    readOnly={readOnly}
                    maxLength={25}
                    onChange={(e) =>
                      Extension.translateInputfrom(
                        setFirstname,
                        e,
                        setFirstname_tn,
                        true
                      )
                    }
                    label={"First Name"}
                  />
                </Grid>
                <Grid item xs={1} sm={0.5} md={0.5}>
                  <Textfield
                    value={firstName_tn}
                    readOnly={readOnly}
                    maxLength={25}
                    onChange={(e) => setFirstname_tn(e.target.value)}
                    label={"முதல் பெயர்"}
                  />
                </Grid>
                <Grid item xs={1} sm={0.5} md={0.5}>
                  <Textfield
                    value={lastName}
                    readOnly={readOnly}
                    maxLength={25}
                    onChange={(e) =>
                      Extension.translateInputfrom(
                        setLastName,
                        e,
                        setLastName_tn,
                        true
                      )
                    }
                    label={"Last Name"}
                  />
                </Grid>
                <Grid item xs={1} sm={0.5} md={0.5}>
                  <Textfield
                    value={lastName_tn}
                    readOnly={readOnly}
                    maxLength={25}
                    onChange={(e) => setLastName_tn(e.target.value)}
                    label={"கடைசி பெயர்"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <CustomeDatePicker
                    label={"DOB/ பிறந்த தேதி"}
                    value={dob}
                    readOnly={readOnly}
                    onChange={handleDateChange}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <MultiDisplayDropdown
                    label={"Gender/ பாலினம்"}
                    value={gender}
                    readOnly={readOnly}
                    onChange={(e) => setGender(e.target.value)}
                    array={GENDERS}
                    menuValue={"value"}
                    display={["name", "name_tn"]}
                  />
                </Grid>
                <Grid item xs={1} sm={0.5} md={0.5}>
                  <Textfield
                    readOnly={readOnly}
                    type={"text"}
                    value={referenceBy}
                    maxLength={25}
                    onChange={(e) =>
                      Extension.translateInputfrom(
                        setReferenceBy,
                        e,
                        SetReference_by_tn,
                        true
                      )
                    }
                    label={"Referred By"}
                  />
                </Grid>
                <Grid item xs={1} sm={0.5} md={0.5}>
                  <Textfield
                    readOnly={readOnly}
                    type={"text"}
                    value={reference_by_tn}
                    onChange={(e) =>
                      SetReference_by_tn(Extension.onlyTamilText(e))
                    }
                    label={"பரிந்துரைத்தவர்"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <Textfield
                    value={refCustomerId}
                    readOnly={readOnly}
                    onChange={(e) => setrefCustomerId(e.target.value)}
                    type={"text"}
                    label={"Reference Customer ID/பரிந்துரைத்தவர் ஐடி"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <ErrorTextfield
                    label={"Mobile Number/ கைபேசி எண்"}
                    value={mobileNumber}
                    required={false}
                    maxLength={10}
                    type={"number"}
                    onBlur={() => validateMobile(mobileNumber)}
                    error={mobileError}
                    onChange={handlemobileNumberChange}
                  />
                </Grid>

                <Grid item xs={1} sm={1} md={1}>
                  <ErrorTextfield
                    label={"Email (Optional)/ மின்னஞ்சல் (விரும்பினால்)"}
                    maxLength={50}
                    type={"text"}
                    value={emailId}
                    required={false}
                    // onBlur={() => validateEmail(emailId)}
                    error={emailError}
                    onChange={handleEmailChange}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : activeStep === 1 ? (
            <Box sx={{ p: 5, pt: 2 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3, sm: 3 }}
                columns={{ xs: 1, sm: 2, md: 2 }}
                sx={{ elevation: 3, mb: 2 }}
              >
                <Grid item xs={1} sm={1} md={1}>
                  {/* <Textfield
                    value={panNo}
                    onChange={(e) => setPanNo(e.target.value)}
                    label={"PAN Number (optional)"}
                  /> */}
                  <MultiDisplayDropdown
                    label={"Occupation/ தொழில்"}
                    value={occupation}
                    readOnly={readOnly}
                    onChange={(e) => setOccupation(e.target.value)}
                    array={OCCUPATION_CHOICES}
                    menuValue={"value"}
                    display={["name", "name_tn"]}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <ErrorTextfield
                    value={aadharNo}
                    onBlur={() => validateAadhaar(aadharNo)}
                    error={adhaarError}
                    onChange={handleAdhaarChange}
                    type={"number"}
                    label={"Aadhar Number/ ஆதார் எண்"}
                    maxLength={12}
                  />
                </Grid>
                <Grid item xs={1} sm={0.5} md={0.5}>
                  <Textfield
                    value={bankName}
                    maxLength={25}
                    onChange={(e) =>
                      Extension.translateInputfrom(
                        setBankName,
                        e,
                        setBankName_tn
                      )
                    }
                    label={"Bank Name"}
                  />
                </Grid>
                <Grid item xs={1} sm={0.5} md={0.5}>
                  <Textfield
                    value={bankName_tn}
                    maxLength={25}
                    onChange={(e) => setBankName_tn(e.target.value)}
                    label={"வங்கி பெயர்"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <Textfield
                    value={bankAccNo}
                    onChange={(e) => setBankAccNo(e.target.value)}
                    type={"number"}
                    label={"Bank Account Number/ வங்கி கணக்கு எண்"}
                    maxLength={18}
                  />
                </Grid>
                <Grid item xs={1} sm={0.5} md={0.5}>
                  <Textfield
                    value={branchName}
                    maxLength={25}
                    onChange={(e) =>
                      Extension.translateInputfrom(
                        setBranchName,
                        e,
                        setBranchName_tn
                      )
                    }
                    label={"Branch Name"}
                  />
                </Grid>
                <Grid item xs={1} sm={0.5} md={0.5}>
                  <Textfield
                    maxLength={25}
                    value={branchName_tn}
                    onChange={(e) => setBranchName_tn(e.target.value)}
                    label={"கிளை பெயர்"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <ErrorTextfield
                    label={"IFSC"}
                    maxLength={11}
                    value={ifscCode}
                    onBlur={() => validateIFSC(ifscCode)}
                    error={IFSCError}
                    onChange={handleIFSCChange}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <Textfield
                    value={currentAddress}
                    maxLength={250}
                    onChange={(e) =>
                      Extension.translateInputfrom(
                        setCurrentAddress,
                        e,
                        setCurrentAddress_tn
                      )
                    }
                    multiline={true}
                    rows={2}
                    label={"Current Address"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <Textfield
                    value={currentAddress_tn}
                    onChange={(e) => setCurrentAddress_tn(e.target.value)}
                    multiline={true}
                    maxLength={250}
                    rows={2}
                    label={"தற்போதைய முகவரி"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <Textfield
                    value={permanentAddress}
                    maxLength={250}
                    onChange={(e) =>
                      Extension.translateInputfrom(
                        setPermanentAddress,
                        e,
                        setPermanentAddress_tn
                      )
                    }
                    multiline={true}
                    rows={2}
                    label={"Permanent Address"}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <Textfield
                    maxLength={250}
                    value={permanentAddress_tn}
                    onChange={(e) => setPermanentAddress_tn(e.target.value)}
                    multiline={true}
                    rows={2}
                    label={"நிரந்தர முகவரி"}
                  />
                </Grid>
                {customerUpdate && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "15px",
                      marginLeft: "10px",
                    }}
                  >
                    <Checkbox
                      value={fradulent}
                      checked={fradulent}
                      onChange={handleFradulentChange}
                    />

                    <p className="restrict-text" style={{ marginLeft: "10px" }}>
                      Do you have to mark the customer as fraudulent? /
                      மோசடியின் கீழ் வாடிக்கையாளரைக் குறிக்க வேண்டுமா?
                    </p>
                  </div>
                )}
                {fradulent && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Textfield
                      maxLength={200}
                      value={remarks}
                      onChange={(e) =>
                        Extension.translateInputfrom(
                          setRemarks,
                          e,
                          setRemarks_tn
                        )
                      }
                      label={"Remarks"}
                    />
                  </Grid>
                )}

                {fradulent && (
                  <Grid item xs={1} sm={1} md={1}>
                    <Textfield
                      value={remarks_tn}
                      maxLength={25}
                      onChange={(e) => setRemarks_tn(e.target.value)}
                      label={"கருத்துக்கள்"}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          ) : null}

          <MobileStepper
            variant="dots"
            steps={2}
            position="static"
            activeStep={activeStep}
            sx={{
              maxWidth: "100%",
              backgroundColor: "var(--commonpaper-background)",
              flexGrow: 1,
            }}
            nextButton={
              activeStep === 1 ? (
                <>
                  {customerUpdate ? (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <CustomCommonButton
                        icon={<PublishedWithChanges />}
                        onClick={updateCustomer}
                        disabled={!!addbuttonDisabled /*  ? true : false */}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <CustomCommonButton
                        icon={<Save />}
                        onClick={addCustomer}
                        disabled={!!addbuttonDisabled}
                      />
                    </div>
                  )}
                </>
              ) : (
                <CustomCommonButton
                  icon={
                    theme === "rtl" ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )
                  }
                  onClick={handleNext}
                  // disabled={addbuttonDisabled ? true : false}
                />
                // <Button
                //   size="small"
                //   color="success"
                //   onClick={handleNext}
                //   variant="contained"
                //   sx={Styles.commonButton}
                // >
                //   {theme === "rtl" ? (
                //     <KeyboardArrowLeft />
                //   ) : (
                //     <KeyboardArrowRight />
                //   )}
                // </Button>
              )
            }
            backButton={
              <CustomCommonButton
                icon={
                  theme === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )
                }
                onClick={handleBack}
                disabled={activeStep === 0}
              />

              // <Button
              //   size="small"
              //   color="success"
              //   onClick={handleBack}
              //   disabled={activeStep === 0}
              //   variant="contained"
              //   sx={Styles.commonButton}
              // >
              //   {theme === "rtl" ? (
              //     <KeyboardArrowRight />
              //   ) : (
              //     <KeyboardArrowLeft />
              //   )}
              // </Button>
            }
          />
        </Box>
      </Modal>

      <BulkUpload
        open={bulkPopUp}
        onClose={handleCloseBulkPopUp}
        getCustomerdata={getData}
      />
    </div>
  );
};

export default Customer;
