import {
  AddBoxOutlined,
  Cancel,
  MoreVert,
  Save,
  UpdateRounded,
} from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../api/http-common";
import { AppData } from "../../common/AppData";
import CustomCommonButton from "../../common/Components/CustomCommonButton";
import MultiDisplayDropdown from "../../common/Components/MultiDisplayDropdown";
import Textfield from "../../common/Components/TextField";
import Extension from "../../common/Extension";
import GridTable from "../../common/GridTable";
import Loading from "../../common/Loading";
import SnackBar from "../../common/SnackBar";
import "./Settings/Setting.css";
import { CommonAddIcon } from "../../common/Components/IconsAndComponents";
import Logger from "../../common/Logger";

const ITEM_CATEGORY = [
  {
    name: "Gold",
    name_tn: "தங்கம்",
  },
  {
    name: "Silver",
    name_tn: "வெள்ளி",
  },
];

const AddItemComp = ({ additemRef }) => {
  // const { userCredentails, setUserCredentails } =
  //   useContext(UserDetailsContext);
  const { t } = useTranslation("global");
  // const entity_id = userCredentails.entitydata.entity_id;
  const entity_id = sessionStorage.getItem("entityId");
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const [itemCategory, setItemCategory] = useState("");
  const [items, setItems] = useState([]);
  const [item_name, setItem_name] = useState("");
  const [item_name_tn, setItem_name_tn] = useState("");
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const logger = new Logger({ level: "log" });
  const handleItemName = (e) => {
    Extension.translateInput(e, setItem_name_tn);
    setItem_name(Extension.onlyText(e));
  };

  const fields = [
    {
      id: 1,
      field: "select",
      name: "item_category",
      label: "Item Category/ பொருள் வகை",
      value: itemCategory,
      onChange: (e) => setItemCategory(e.target.value),
      array: ITEM_CATEGORY,
    },
    {
      id: 2,
      field: "text",
      name: "item_name",
      label: "Item Name",
      value: item_name,
      onChange: handleItemName,
    },
    {
      id: 3,
      field: "text",
      name: "item_name_tn",
      label: "பொருளின் பெயர்",
      value: item_name_tn,
      onChange: (e) => setItem_name_tn(e.target.value),
    },
  ];

  const clearTextFields = () => {
    setItemCategory("");
    setItem_name("");
    setItem_name_tn("");
  };

  const [addItemFieldsVisible, setAddItemFieldsVisible] = useState(false);
  const [addbuttonDisabled, setAddbuttonDisabled] = useState(false);

  const handleFieldsOpen = () => {
    setAddItemFieldsVisible(true);
  };

  const handleFieldsClose = () => {
    clearTextFields();
    setUpdateData(null);
    setReadOnly(false);
    setAddItemFieldsVisible(false);
  };

  const fetchData = async () => {
    try {
      logger.log("Initiate Fetch loan items");
      const item = await api
        .get(`/loan/items/${entity_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch loan items Successfully");
      setItems(item);
    } catch (error) {
      logger.log(error);
    }
  };

  const addItem = async () => {
    if (itemCategory && item_name && item_name_tn) {
      let uniqueItem = items.find(
        (item) =>
          item.item_name.toLowerCase() === item_name.toLowerCase() &&
          item.item_category.toLowerCase() === itemCategory.toLowerCase()
      );
      if (uniqueItem) {
        SnackBar.toastMsg("error", t("toast_messages.the_item_must_unique"));
        return;
      }
      try {
        setAddbuttonDisabled(true);
        handleLoadingOpen();
        const item_cat_tn = ITEM_CATEGORY.find(
          (item) => item.name === itemCategory
        ).name_tn;
        const data = {
          item_category: itemCategory,
          item_category_tn: item_cat_tn,
          item_name: item_name,
          item_name_tn: item_name_tn,
          entity: entity_id,
        };
        logger.log("Initiate post loan item");
        await api.post(`/loan/items`, data);
        logger.log("post loan item Successfully");
        SnackBar.toastMsg(
          "success",
          t("toast_messages.item_added_successfully")
        );
        handleFieldsClose();
        fetchData();
      } catch (error) {
        logger.log(error);
      } finally {
        handleLoadingClose();
        setAddbuttonDisabled(false);
      }
    } else {
      SnackBar.toastMsg("error", t("toast_messages.please_fill_all_details"));
    }
  };

  const [updateData, setUpdateData] = useState(null);

  const initiateUpdate = (item) => {
    setAddItemFieldsVisible(true);
    setReadOnly(true);
    setUpdateData(item);
    setItemCategory(item.item_category);
    setItem_name(item.item_name);
    setItem_name_tn(item.item_name_tn);
  };

  const updateItem = async () => {
    try {
      setAddbuttonDisabled(true);
      handleLoadingOpen();
      const item_cat_tn = ITEM_CATEGORY.find(
        (item) => item.name === itemCategory
      ).name_tn;
      const data = {
        ...updateData,
        item_category: itemCategory,
        item_category_tn: item_cat_tn,
        item_name: item_name,
        item_name_tn: item_name_tn,
      };
      logger.log("Initiate put loan item");
      await api.put(`/loan/item/${updateData.item_id}/`, data);
      logger.log("put loan item Successfully");
      SnackBar.toastMsg(
        "success",
        t("toast_messages.item_updated_successfully")
      );
      handleFieldsClose();
      fetchData();
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
      setAddbuttonDisabled(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const rows = items.map((item, index) => ({
    ...item,
    id: index + 1,
    moreinfo: (
      <MoreVert
        sx={{ cursor: "pointer" }}
        onClick={() => initiateUpdate(item)}
      />
    ),
  }));

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };

  return (
    <>
      {role_id === 1 ? (
        <div className="add-item-comp" ref={additemRef}>
          <Loading open={loading} handleClose={handleLoadingClose} />
          <h1>{t("settings.additem")}</h1>
          <div className="add-fields">
            {addItemFieldsVisible ? (
              <Grid
                container
                spacing={{ xs: 2, md: 3, sm: 4 }}
                columns={{ xs: 1, sm: 3, md: 4 }}
                sx={{
                  elevation: 3,
                  mb: 2,
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                {fields.map((field) => (
                  <Grid item xs={1} sm={2} md={1} key={field.id}>
                    {field.field === "text" ? (
                      <Textfield
                        label={field.label}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    ) : field.field === "select" ? (
                      <MultiDisplayDropdown
                        label={field.label}
                        array={field.array}
                        readOnly={readOnly}
                        value={field.value}
                        onChange={field.onChange}
                        menuValue={"name"}
                        display={["name", "name_tn"]}
                      />
                    ) : null}
                  </Grid>
                ))}
                <Grid item xs={1} sm={2} md={1}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {updateData ? (
                      <CustomCommonButton
                        text={t("settings.update")}
                        icon={<UpdateRounded />}
                        onClick={updateItem}
                        disabled={addbuttonDisabled ? true : false}
                      />
                    ) : (
                      <CustomCommonButton
                        text={t("settings.save_item")}
                        icon={<Save />}
                        onClick={addItem}
                        disabled={addbuttonDisabled ? true : false}
                      />
                    )}
                    <CustomCommonButton
                      text={t("settings.cancel")}
                      color={"error"}
                      icon={<Cancel />}
                      onClick={() => handleFieldsClose()}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <CustomCommonButton
                  text={t("settings.additem")}
                  onClick={handleFieldsOpen}
                  icon={CommonAddIcon}
                />
              </Box>
            )}
          </div>
          {/* <div className="table-component"> */}
          <GridTable
            rows={rows}
            columns={AppData.loan_item}
            height={"60vh"}
            isLoading={loading}
          />
          {/* </div> */}
        </div>
      ) : null}
    </>
  );
};

export default AddItemComp;
