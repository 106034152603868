import { ArrowCircleLeftOutlined } from "@mui/icons-material";
import { Box, Divider, Grid, Modal, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import api from "../api/http-common";
import Textfield from "./Components/TextField";
import Extension from "./Extension";
import "./ItemRateFixation.css";
import Loading from "./Loading";
import SnackBar from "./SnackBar";
import Styles from "./Styles";
import Logger from "./Logger";

const Position = "start";
const logger = new Logger({ level: "log" });
export default function ItemRateFixation({ onClose }) {
  // const { userCredentails, usetUserCredentails } =
  //   useContext(UserDetailsContext);
  const { t } = useTranslation("global");
  // const entity_id = userCredentails.entitydata.entity_id;
  const entity_id = sessionStorage.getItem("entityId");
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const [open, setOpen] = React.useState(true);
  const [normalGold, setNormalGold] = useState(0);
  const [hallGold, setHallGold] = useState(0);
  const [silver, setSilver] = useState(0);
  const [currentRate, setCurrentRate] = useState([]);
  const [marketValue, setMarketValue] = useState([]);
  const [latestFund, setLatestFund] = useState({});
  const [loading, setLoading] = useState(false);
  const today = new Date();
  const location = useLocation();

  //get current market rate
  const fetchCurrentRate = async () => {
    try {
      logger.log("Initiate fetch current market rate")
      const marketValue = await api.get(`/loan/CurrentMarketValue/`);
      const totalRates = {
        Gold: parseInt(marketValue.data.Gold),
        Silver: parseInt(marketValue.data.Silver),
      };
      setMarketValue(totalRates);
      logger.log("Fetch current market rate Successfully")
    } catch (error) {
      logger.log(error);
    }
  };

  //get fund amount
  const getFund = async () => {
    try {
      const BRANCH_API =
        role_id === 1
          ? `/Fund/CurrentTotalAmount/${entity_id}`
          : `/Fund/CurrentTotalAmountByBranch/${branch_id}`;
      logger.log("Initiate fetch fund amount")
      const crrAPI = await api
        .get(BRANCH_API)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      setLatestFund(crrAPI);
      logger.log("Fetch fund amount Successfully")
    } catch (error) {
      logger.log(error);
    }
  };

  //get current item rates
  const getData = async () => {
    try {
      handleLoadingOpen();
      logger.log("Initiate fetch current item rate")
      const res = await api.get(`/loan/current-item-rate/${entity_id}`);
      logger.log("Fetch current item rate Successfully")
      const currentRate =
        res.status !== 404 || res.data !== undefined ? res.data : [];
      setHallGold(currentRate.hallmark_gold_rate);
      setNormalGold(currentRate.gold_rate);
      setSilver(currentRate.silver_rate);
      setCurrentRate(res.data);
    } catch (error) {
      return null;
    } finally {
      handleLoadingClose();
    }
  };
  useEffect(() => {
    getData();
    fetchCurrentRate();
    getFund();
  }, []);

  //add item rates function
  const handleAdd = async () => {
    if (normalGold && hallGold && silver) {
      try {
        handleLoadingOpen();
        const now = format(today, "yyyy-MM-dd");
        const data = {
          gold_rate: normalGold,
          hallmark_gold_rate: hallGold,
          silver_rate: silver,
          Fixed_date: now,
          shop_id: entity_id,
          market_gold_rate: marketValue.Gold,
          market_silver_rate: marketValue.Silver,
        };
      logger.log("Initiate post item rate")
      await api.post(`loan/itemRate`, data);
      logger.log("post item rate Successfully")
      handleClose();
        getData();
        SnackBar.toastMsg(
          "success",
          t("toast_messages.new_price_update_success")
        );
        window.location.href = location.pathname;
      } catch (error) {
        if (error.response.data.Fixed_date) {
          SnackBar.toastMsg("error", error.response.data.Fixed_date);
        }
        logger.log(error);
      } finally {
        handleLoadingClose();
      }
    } else {
      SnackBar.toastMsg("error", t("toast_messages.please_provide_all_values"));
    }
  };

  //popup close function
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  //loading open function
  const handleLoadingOpen = () => {
    setLoading(true);
  };

  //loading close function
  const handleLoadingClose = () => {
    setLoading(false);
  };

  //Display current market value
  const LastUpdated = [
    {
      id: 1,
      label: t("item_rate.hall_mark"),
      rate: marketValue.Gold,
      background:
        "linear-gradient(to bottom, #Ffd700, #ffe030, #fbec93, #FCF1B0, #FFFDEF)",
    },
    {
      id: 2,
      label: t("item_rate.silver_gram"),
      rate: marketValue.Silver,
      background:
        "linear-gradient(to bottom, #C0C0C0, #d0d0d0, #dbdbdb, #EFEFEF, #F5F5F5)",
    },
  ];

  const currentFundAmount =
    role_id === 1 ? latestFund.entity_total_fund : latestFund.total_fund_amount;

  const readOnly = role_id === 1 ? false : true;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...Styles.widthPopupStyles() }}>
        <Loading open={loading} handleClose={handleLoadingClose} />{/* Loading component */}
        <Box
          sx={{
            p: 1,
            pr: 1,
            borderRadius: "10px 10px 0 0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "var(--app-bar-color)",
          }}
        >
          <Typography
            sx={{
              color: "var(--header-font-color)",
              fontSize: "var(--font-size)",
              fontFamily: "var(--font-family)",
            }}
          >
            <ArrowCircleLeftOutlined
              sx={{ mr: 1, cursor: "pointer" }}
              onClick={handleClose}
            />
            {t("item_rate.market_rate_fixation")}
          </Typography>
          <Typography
            sx={{
              color: "var(--header-font-color)",
              fontSize: "var(--font-size)",
              fontFamily: "var(--font-family)",
            }}
          >
            {t("item_rate.last_updated")} :{" "}
            {currentRate.Fixed_date &&
              (Extension.formatDateDMY(new Date(currentRate.Fixed_date)) ?? "")}
          </Typography>
        </Box>
        <Box sx={{ p: 3, pt: 1 }}>
          <Divider sx={{ color: "var(--app-bar-color)" }}>
            <div style={{ marginBottom: 1 }}>
              {t("item_rate.current_market_rate")}
            </div>
          </Divider>
          <Box sx={{ display: "flex" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 2, sm: 2 }}
              columns={{ xs: 1, sm: 2, md: 2 }}
              sx={{ elevation: 3, mb: 2 }}
            >
              {LastUpdated.map((items) => (
                <Grid key={items.id} item xs={1} sm={1} md={1}>
                  <Paper
                    elevation={3}
                    sx={{
                      /*width: 220,*/ height: 100,
                      p: 2,
                      borderRadius: "15px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      background: items.background,
                    }}
                  >
                    <Typography
                      className="common-font-style"
                      sx={{ textAlign: "center", mt: -1.6 }}
                    >
                      {items.label}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <CountUp
                        start={0}
                        className="common-font-style"
                        end={items.rate}
                        duration={2.5}
                        decimals={2}
                        prefix={`₹`}
                        useIndianSeparators={true}
                        style={{ fontSize: "21px" }}
                      />
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box display="flex" flexDirection="column" gap={2}>
            <Divider sx={{ color: "var(--app-bar-color)" }}>
              <div>{t(`item_rate.update_today's`)}</div>
            </Divider>
            <Grid
              container
              spacing={{ xs: 2, md: 3, sm: 3 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
              sx={{ elevation: 3, mb: 2 }}
            >
              {/* First Container */}
              <Grid item xs={1} sm={1} md={1}>
                <Textfield
                  value={hallGold}
                  onChange={(e) => setHallGold(e.target.value)}
                  type={"number"}
                  label={t("item_rate.hall_mark_label")}
                  position={Position}
                  comboFixWord={"₹"}
                  readOnly={readOnly}
                />
                <Textfield
                  value={normalGold}
                  onChange={(e) => setNormalGold(e.target.value)}
                  type={"number"}
                  label={t("item_rate.normal_gold_label")}
                  position={Position}
                  comboFixWord={"₹"}
                  readOnly={readOnly}
                />
                <Textfield
                  value={silver}
                  onChange={(e) => setSilver(e.target.value)}
                  type={"number"}
                  label={t("item_rate.silver_label")}
                  position={Position}
                  comboFixWord={"₹"}
                  readOnly={readOnly}
                />
                {role_id === 1 && (
                  <Button
                    type="submit"
                    variant="contained"
                    color={"success"}
                    size="small"
                    disabled={false}
                    sx={{
                      ...Styles.commonButton(),
                      mt: 2,
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--font-size)",
                      backgroundColor: "#B0F062",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleAdd}
                  >
                    {t("item_rate.update_rate")}
                  </Button>
                )}
              </Grid>

              {/* Second Container */}
              <Grid item xs={1} sm={1} md={1}>
                <Paper
                  elevation={3}
                  sx={{
                    /*width: 220,*/ height: "100%",
                    width: "100%",
                    p: 2,
                    mb: 1,
                    mt: 0.5,
                    borderRadius: "15px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background:
                      "linear-gradient(to bottom, #58A3FF, #81BAFF, #B0D4FF, #BCDAFF, #DEEAF9)",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      mt: -1.6,
                      fontWeight: "var(--font-weight)",
                    }}
                    className="common-font-style"
                  >
                    {t("item_rate.fund_amount")}
                  </Typography>
                  <CountUp
                    start={0}
                    className="common-font-style"
                    end={currentFundAmount}
                    duration={2.5}
                    decimals={2}
                    prefix={`₹`}
                    useIndianSeparators={true}
                    style={{ fontSize: "21px" }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
