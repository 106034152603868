import {
  DeleteRounded,
  EditNoteSharp,
  LibraryAddCheckRounded,
  LibraryAddRounded,
} from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CountUp from "react-countup";
import { Controller, useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import api from "../../api/http-common";
import AddImage from "../../assets/AddImageIcon.svg";
import CustomCommonButton from "../../common/Components/CustomCommonButton";
import { CommonAddIcon } from "../../common/Components/IconsAndComponents";
import ImageProvision from "../../common/Components/ImageProvision";
import Extension from "../../common/Extension";
import ItemRateFixation from "../../common/ItemRateFixation";
import Loading from "../../common/Loading";
import { LoanCreation } from "../../common/ReceiptGenerate/LoanCreation";
import { LoanCreationTM } from "../../common/ReceiptGenerate/LoanCreationTM";
import SnackBars from "../../common/SnackBar";
import Styles from "../../common/Styles";
import { CommonTextField } from "../../ui/CommonTextField";
import { SelectDropDown } from "../../ui/SelectDropDown";
import "./LoanProcess.css";
import Logger from "../../common/Logger";
import SMS_API_FUNCTION from "../../common/SMS_API_FUNCTION";

//Pledge Material Name drop down values

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#777",
    color: "#fff",
    textAlign: "center",
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: "center",
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#D9D9D9",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "#999", // Change the background color on hover
  },
  "&:last-child": {
    position: "sticky",
    bottom: 0,
    backgroundColor: "#999", // Change the background color of the sticky row
  },
  "&:last-child td": {
    color: "#fff",
    fontSize: 18,
    padding: 8,
  },
  "&:last-child td:first-child": {
    color: "#fff",
    fontSize: 28,
  },
}));

const inputFieldsData = [
  {
    subset: true,
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    subsets: [
      {
        type: "typo",
        inputType: "typography",
        required: true,
        gridSize: { xs: 6, sm: 6, md: 6, lg: 6 },
        readOnly: true,
      },
      {
        title: "fund amount",
        type: "number",
        inputType: "input",
        name: "fund_amount",
        gridSize: { xs: 6, sm: 6, md: 6, lg: 6 },
        readOnly: true,
        ComboFixWord: "",
        position: "start",
      },
    ],
  },
  // {
  //   subset: false,
  //   // title: "loan id",
  //   type: "typo",
  //   inputType: "typography",
  //   required: true,
  //   // name: "loan_number",
  //   gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
  //   readOnly: true,
  // },
  {
    field: "branch",
    subset: false,
    title: "branch",
    type: "text",
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    inputType: "select",
    name: "branch_id",
    required: true,
    value: "branch_id",
    display_name: "branch_name",
    tm: "branch_name_tn",
    readOnly: false,
    ComboFixWord: "",
    position: "start",
    data: [],
    clickEvent: true,
    clickType: "onChange",
  },
  {
    field: "customer",
    subset: false,
    title: "search customer",
    type: "text",
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    inputType: "select",
    name: "customer",
    readOnly: false,
    ComboFixWord: "",
    position: "start",
    data: [],
  },
  {
    field: "scheme",
    subset: false,
    title: "scheme name",
    type: "text",
    inputType: "select",
    name: "scheme",
    display_name: "scheme_name",
    value: "scheme_id",
    tm: "scheme_name_tn",
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    readOnly: false,
    ComboFixWord: "",
    position: "start",
    data: [],
    clickEvent: true,
    clickType: "onChange",
  },
  {
    subset: true,
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    subsets: [
      {
        title: "pledge material type",
        type: "text",
        inputType: "input",
        gridSize: { xs: 6, sm: 6, md: 6, lg: 6 },
        name: "pledgeMaterialType",
        readOnly: true,
        ComboFixWord: "",
        position: "start",
        data: [],
      },
      {
        title: "loan date",
        type: "date",
        inputType: "input",
        name: "created_on",
        gridSize: { xs: 6, sm: 6, md: 6, lg: 6 },
        readOnly: false,
        ComboFixWord: "",
        position: "start",
      },
    ],
  },
  {
    subset: false,
    title: "locker id",
    type: "text",
    inputType: "input",
    required: false,
    name: "locker_id",
    gridSize: { xs: 12, sm: 6, md: 6, lg: 4 },
    readOnly: false,
    //   },
    // ],
  },
];

const BottomFields = [
  {
    subset: false,
    title: "threshold amount",
    type: "number",
    inputType: "input",
    name: "max_loan_amount",
    gridSize: { xs: 10, sm: 5, md: 5, lg: 2.4 },
    readOnly: true,
    ComboFixWord: "\u20B9",
    position: "start",
  },
  {
    subset: false,
    title: "requested loan amount",
    type: "number",
    inputType: "input",
    name: "loan_amount",
    gridSize: { xs: 10, sm: 5, md: 5, lg: 2.4 },
    readOnly: false,
    ComboFixWord: "\u20B9",
    position: "start",
  },
  {
    field: "mode_of_payment",
    title: "mode of payment",
    type: "text",
    inputType: "select",
    name: "mode_of_payment",
    gridSize: { xs: 10, sm: 5, md: 5, lg: 2.4 },
    readOnly: false,
    ComboFixWord: "",
    position: "start",
    display_name: "text",
    value: "value",
    data: [
      { text: "Cash", value: "By Cash", text_tn: "பணம்" },
      {
        text: "Account Transfer",
        value: "By Account",
        text_tn: "கணக்கு பரிமாற்றம்",
      },
      { text: "UPI/ Others", value: "UPI/Others", text_tn: "UPI/ மற்றவை" },
    ],
    tm: "text_tn",
    clickEvent: false,
    clickType: "onChange",
  },
  {
    subset: false,
    title: "documentation charges",
    type: "number",
    inputType: "input",
    name: "doc_charges",
    gridSize: { xs: 10, sm: 5, md: 5, lg: 2.4 },
    readOnly: false,
    ComboFixWord: "\u20B9",
    position: "start",
  },
  {
    subset: false,
    title: "hands on amount",
    type: "number",
    inputType: "input",
    name: "hands_on_amount",
    gridSize: { xs: 10, sm: 5, md: 5, lg: 2.4 },
    readOnly: true,
    ComboFixWord: "\u20B9",
    position: "start",
  },
];

export const AddLoanProcess = () => {
  const { t,i18n } = useTranslation("global");
  const currentLang = i18n.language;
  const logger = new Logger({ level: "log" });
  const { register, setValue, handleSubmit, control, getValues } = useForm();
  const [inputFields, setInputFields] = useState(inputFieldsData);
  const [materialList, setMaterialList] = useState([]);
  const [totalMaterial, setTotalMaterial] = useState([]);
  const [saveBtnVisible, setSaveBtnVisible] = useState(false);
  const [pledgeItemDropDown, setPledgeItemDropDown] = useState([]);
  const [status, setStatus] = useState("Pending");
  const [entityDetail, setEntityDetail] = useState({});
  const [documentCharge, setDocumentCharge] = useState(0);
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const userId = parseInt(sessionStorage.getItem("userId"));
  const entityId = sessionStorage.getItem("entityId");
  const branchId = parseInt(sessionStorage.getItem("branchId"));
  const [radioBtnOption, setRadioBtnOption] = useState("normal");
  const [stoneType, setStoneType] = useState("");
  const [requestedamount, setRequestedAmount] = useState(0);
  const [thresHoldAmount, setThresHoldAmount] = useState(0);
  const [schemeId, setSchemeId] = useState(0);
  const [materialRate, setMaterialRate] = useState(null);
  const [marketRate, setMarketRate] = useState(null);
  const [branch_Id, setBranchId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showItemRateFixation, setShowItemRateFixation] = useState(false);
  const [image, setImage] = useState("");
  const [contextMenu, setContextMenu] = React.useState(null);
  const [rowContextMenu, setRowContextMenu] = React.useState(null);
  const [selectedRow, setSelectedRow] = useState([]);

  const handleImageClick = () => {
    setShowItemRateFixation(true);
  };

  const handleCloseItemRateFixation = () => {
    setShowItemRateFixation(false);
  };

  const language = localStorage.getItem("language");

  const clearTextField = () => {
    setThresHoldAmount(0);
    setRequestedAmount(0);
    // setValue("mode_of_payment", "");
    setValue("hands_on_amount", 0);
  };

  useEffect(() => {
    // All form values are empty
    const allValues = getValues();
    Object.entries(allValues).forEach(([key, value]) => {
      setValue(key, "");
    });
    setImage("");
    setMaterialList([]);
    const date = new Date().toISOString().split("T")[0];
    setValue("created_on", date);
    setValue("customer", null);
    // setValue("max_loan_amount", 0);
    setThresHoldAmount(0);
    setValue("hands_on_amount", 0);
    shopDetails();
    if (roleId !== 1) {
      setValue("branch_id", branchId);
      handleBranchChange(branchId);
    }
  }, [language]);

  const [goldCategory, setGoldCategory] = useState([
    { text: "Normal Gold", value: "normal", text_tn: "சாதாரண தங்கம்" },
    {
      text: "Hallmark Gold(916)",
      value: "916",
      text_tn: "ஹால்மார்க் தங்கம் (916)",
    },
  ]);
  const statusCategory = {
    Approved: "அனுமதிக்கப்பட்டது",
    Pending: "நிலுவையில் உள்ளது",
    Closed: "மூடப்பட்டது",
    Rejected: "நிராகரிக்கப்பட்டது",
    Disposed: "அகற்றப்பட்டது",
  };
  const [pledgeMaterialType, setPledgeMaterialType] = useState("");
  const Navigate = useNavigate();

  useEffect(() => {
    setValue("qty", 1);
    fetchInitialDatas();
  }, []);

  const fetchDataAndTransform = async (apiEndpoint, idKey, nameKey) => {
    logger.log(`Initiate Fetch ${apiEndpoint}`);
    const response = await api.get(apiEndpoint);
    logger.log(`Fetch ${apiEndpoint} Successfully`);

    return response.data.map((item) => ({
      ...item,
      value: item[idKey],
      text: item[nameKey],
    }));
  };

  const fetchInitialDatas = async () => {
    try {
      shopDetails();
      handleLoadingOpen();
      // get the entity API response based on the entity Id
      logger.log("Initiate Fetch entity");
      const entityDetail = await api
        .get(`entity/${entityId}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch entity Successfully");
      //set the entity response for entity detail state
      setEntityDetail(entityDetail);
    } catch (error) {
      logger.log(error);
      Swal.fire({
        title:
          roleId === 1
            ? "Item rate has not been updated!"
            : "Item rate has not been updated. Please contact the admin!",
        icon: "error",
        text: "Please contact with admin!",
        showCancelButton: false,
        confirmButtonText: "Ok",
        confirmButtonColor: "#1976d2",
        preConfirm: () => {
          Navigate("/loanprocess");
        },
        didClose: () => {
          Navigate("/loanprocess");
        },
      });
    } finally {
      handleLoadingClose();
    }

    const branchList = await fetchDataAndTransform(
      `branch/byEntity/${entityId}`,
      "branch_id",
      "branch_name"
    );
    logger.log("Initiate Fetch entity");
    const entityRes = await api
      .get(`entity/${entityId}`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch entity Successfully");
    handleInputFields("branch", branchList, entityRes);
    setValue("branch_id", branchId);
    handleBranchChange(branchId);
    setValue("mode_of_payment", "By Cash");

    const schemeList = await fetchDataAndTransform(
      roleId === 1
        ? `scheme/schemes/${entityId}`
        : `/scheme/schemesForManager/${entityId}`,
      "scheme_id",
      "scheme_name"
    );
    handleInputFields("scheme", schemeList);
  };

  const shopDetails = async () => {
    try {
      logger.log("Initiate Fetch current item rate");
      const shop = await api
        .get(`loan/current-item-rate/${entityId}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch current item rate Successfully");
      setValue("itemRate", shop.rate_item_id);
      setMaterialRate(shop);
      logger.log("Initiate Fetch current item rate");
      const market = await api
        .get(`loan/current-item-rate/${entityId}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch current item rate Successfully");
      setMarketRate(market);
    } catch (error) {
      logger.log(error);
    }
  };

  const getFundAmount = async () => {
    try {
      const API =
        roleId === 1
          ? `/Fund/CurrentTotalAmountByBranch/${branch_Id}`
          : `/Fund/CurrentTotalAmountByBranch/${branchId}`;
      logger.log("Fetch current market rate Successfully");
      await api
        .get(API)
        .then((res) => setValue("fund_amount", res.data.total_fund_amount ?? 0))
        .catch((err) => {
          logger.log(err);
          setValue("fund_amount", 0);
        });
      logger.log("Fetch current market rate Successfully");
    } catch (error) {
      logger.log(error);
    }
  };

  useEffect(() => {
    getFundAmount();
  }, [branch_Id]);
  //Loan number generate fun.
  const generateLoanId = (char, numeric) => {
    const currentChar = char.toUpperCase();
    const currentNumeric = parseInt(numeric);
    if (currentNumeric === 1000) {
      let nextChar;
      if (currentChar.charAt(0) === "Z") {
        const charLength = currentChar.length + 1;
        nextChar = "A".repeat(charLength);
      } else {
        nextChar = String.fromCharCode(currentChar.charCodeAt(0) + 1);
      }

      return `${nextChar}0001`;
    } else {
      return `${currentChar}${(currentNumeric + 1).toString().padStart(4, 0)}`;
    }
  };

  //split to separte the character and interger fun.
  const splitString = (inputString) => {
    // Use regular expressions to separate alphabetic and numeric parts
    const parts = inputString.split(/(\d+)/);
    if (parts) {
      // Separate alphabetic and numeric parts
      const alphabeticPart = parts
        .filter((part, index) => index % 2 === 0)
        .join("");
      const numericPart = parts
        .filter((part, index) => index % 2 !== 0)
        .join("");
      return { alphabeticPart, numericPart };
    } else {
      return { alphabeticPart: "", numericPart: "" };
    }
  };

  // const fetchIntialDatas = async () => {
  //   // get and update the branch list for inputfield state
  //   const branch = await api.get("branch/").then((res) => res.data);
  //   const branchList = branch.map((item) => ({
  //     ...item,
  //     value: item.branch_id,
  //     text: item.branch_name
  //   }));
  //   handleInputFields("branch", branchList);
  //   const scheme = await api.get("scheme/").then((res) => res.data);
  //   const schemeList = scheme.map((item) => ({
  //     ...item,
  //     value: item.scheme_id,
  //     text: item.scheme_name
  //   }));
  //   handleInputFields("scheme", schemeList);
  // };

  const [entity, setEntity] = useState([]);
  const getEntityData = useCallback(async () => {
    try {
      logger.log("Initiate Fetch entity");
      const entityData = await api
        .get(`/entity/${entityId}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch entity Successfully");
      setEntity(entityData);
    } catch (error) {
      logger.log(error);
    }
  }, [entityId]);

  useEffect(() => {
    getEntityData();
  }, [getEntityData]);
  //Confirmation message popup

  
  const handleConfirm = (data) => {
    console.log(data)
    Swal.fire({
      title: "Confirmed!",
      icon: "success",
      text:
        roleId !== 1 && requestedamount > thresHoldAmount
          ? `Loan ${data.loan_number} Requested to Admin!`
          : `Loan ${data.loan_number} Approved!`,
      showCancelButton: false,
      cancelButtonText: "Download",
      showDenyButton: true,
      denyButtonText: "Print",
      confirmButtonText: "Ok",
      // reverseButtons: true, // Reverse the order of the buttons
      confirmButtonColor: "#1976d2",
      preConfirm: () => {
        Navigate("/loanprocess");
      },
      didClose: () => {
        Navigate("/loanprocess");
      },
    })
      .then((result) => {
        if (result.isConfirmed) {
          Navigate("/loanprocess");
        } else if (result.isDenied) {
          if (language === "tm") {
            LoanCreationTM({ ...data, ...entity }, false);
          } else {
            LoanCreation({ ...data, ...entity }, false);
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      })
      .catch((err) => logger.log(err));
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Cancelled!",
      icon: "error",
      text: "Loan request cancelled!",
      showCancelButton: false,
      confirmButtonText: "Ok",
      confirmButtonColor: "#1976d2",
    });
  };

  const showConfirmationDialog = () => {
    const market_amount = parseInt(getValues("market_max_amount"));
    const customer = getValues("customer");
    const mop = getValues("mode_of_payment");
    if (!customer) {
      SnackBars.toastMsg("error", "Please Choose The Customer!");
      return;
    } else if (!requestedamount) {
      SnackBars.toastMsg("error", "Please Enter The Requested Amount!");
      return;
    } else if (!mop) {
      SnackBars.toastMsg("error", "Please Choose The Mode Of Payment!");
      return;
    }
    if (market_amount < parseInt(requestedamount)) {
      SnackBars.toastMsg(
        "error",
        "The Requested Amount Is Higher Than The Maximum Market Amount"
      );
      return;
    }
    if (parseInt(getValues("fund_amount")) < parseInt(requestedamount)) {
      SnackBars.toastMsg(
        "error",
        "The Requested Amount Is Higher Than The Available Fund"
      );
      return;
    }

    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "Do you want to confirm?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#00A550",
        cancelButtonColor: "#d33",
        input:
          roleId !== 1 && requestedamount > thresHoldAmount ? "" : "checkbox",
        inputPlaceholder:
          roleId !== 1 && requestedamount > thresHoldAmount
            ? ""
            : "Do you want to send the SMS",
        inputValue: roleId !== 1 && requestedamount > thresHoldAmount ? "" : 1,
        preConfirm: (checkbox) => {
          resolve(checkbox); // Resolve with the checked value
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            const checkedValue = result.value; // Get the checked value
            // Call handleLoanInterests with the checked value
            handleFormData(checkedValue);
          } else {
            handleCancel();
          }
        })
        .catch((err) => {
          logger.log(err);
          reject(err);
        });
    });
  };

  //update the initial input fields
  const handleInputFields = (field, list, entityRes) => {
    setInputFields((prevInputFields) => {
      const updatedFields = prevInputFields.map((item) => {
        if (item.field === field) {
          return {
            ...item,
            // title: field === "branch" ? entityRes.name : item.title,
            title: item.title,
            data: list,
          };
        }
        return item;
      });
      return updatedFields;
    });
  };

  //handle the branch name change fun.
  const handleBranchChange = async (value) => {
    logger.log("Initiate Fetch customers");
    const customer = await api
      .get(`customer/branch/${value}`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch customers Successfully");
    setValue("customer", null); // Branch change the customer drop down value is empty
    //update the inputField like field wise
    handleInputFields("customer", customer);
    setBranchId(value);
    setValue("branch_id", value);
  };

  const handleSchemeChange = async (value) => {
    setSchemeId(value);
    clearTextField();
    setMaterialList([]);
    logger.log("Initiate Fetch schemes");
    const scheme_data = await api
      .get(`scheme/${value}/`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch schemes Successfully");
    setValue(
      "pledgeMaterialType",
      Extension.languageBasedValue(
        scheme_data.item_category,
        scheme_data.item_category_tn
      )
    );
    setPledgeMaterialType(scheme_data.item_category);
    logger.log("Initiate Fetch items");
    const item_category_list = await api
      .get(`/loan/items/${entityId}`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch items Successfully");
    const item_category = item_category_list
      .filter((item) => item.item_category === scheme_data.item_category)
      .map((item) => ({
        ...item,
        value: item.item_id,
        text: item.item_name,
      }));
    setPledgeItemDropDown(item_category);
    const netWeight = getValues("net_weight");
    if (netWeight !== "") {
      calculateGrossWeigth(netWeight);
    }
  };

  //handle submit form data
  const handleForm = (data) => {
    // const loanTotalAmt = getValues("loan_amount");
    const stoneType = data.stone_type;
    const noOfStone = data.no_of_stone;
    const qty = data.qty;

    //validate the stone type is with stone min required no of stone is 1
    if (
      (stoneType === "with_stone" || stoneType === "with_enamel") &&
      noOfStone <= 0
    ) {
      SnackBars.toastMsg("error", "No.of Stones/ Enamel Is Required Field!");
      return;
    }
    if (qty <= 0) {
      SnackBars.toastMsg("error", "Enter the valid Quantity");
      return;
    }
    if (!getValues("customer")) {
      SnackBars.toastMsg("error", "Please Select The Customer");
      return;
    }
    setStatus((prevData) => {
      return roleId !== 1 && requestedamount > thresHoldAmount
        ? "Pending"
        : "Approved";
    });

    if (image !== null || image !== "") {
      data.image = image;
    } else {
      data.image = "";
    }
    if (data.id === "") {
      var updateMaterial = [...materialList, data];
    } else {
      const newData = [...materialList];
      newData[data.id] = { ...newData[data.id], ...data };
      var updateMaterial = newData;
    }
    handleTotalMat(updateMaterial);
    setMaterialList(updateMaterial);
    setValue("id", "");
    setValue("qty", "1");
    setValue("gross_weight", "");
    setValue("net_weight", "");
    setValue("pledgeItemName", "");
    setValue("gold_category", "");
    setValue("stone_type", "");
    setValue("no_of_stone", "");
    setImage("");
    setSaveBtnVisible(false);
  };

  const handleAddMaterial = (id) => {
    setRequestedAmount(0);
    setValue("max_loan_amount", 0);
    const itemAtIndex = { ...materialList[id], id: id };
    setStoneType(itemAtIndex.stone_type);
    setImage(itemAtIndex.image);
    Object.entries(itemAtIndex).forEach(([key, value]) => {
      setValue(key, value);
    });
    setSaveBtnVisible(true);
  };

  //handle the gross weigth change
  const handleGrossWeigth = (value) => {
    const grossWeight = value;
    const stoneType = getValues("stone_type");

    if (stoneType === "") {
      SnackBars.toastMsg("error", "Item Type is required field!");
      setValue("gross_weight", "");
      setValue("net_weight", "");
      return;
    }
    if (grossWeight === "") {
      setValue("net_weight", "");
      return;
    }
    const noOfStone = getValues("no_of_stone");
    const deduction =
      stoneType === "with_stone" || stoneType === "with_enamel"
        ? noOfStone * 0.025
        : 0;
    const subtract = stoneType === "with_enamel" ? 0.1 : 0.5;
    setValue(
      "net_weight",
      parseFloat(grossWeight - deduction - subtract).toFixed(3) < 0
        ? 0
        : parseFloat(grossWeight - deduction - subtract).toFixed(3)
    );
  };

  //handle the net weigth change
  const handleNetWeight = (value) => {
    const grossWeight = getValues("gross_weight");
    const netWeight = parseFloat(value);
    if (grossWeight === "") {
      SnackBars.toastMsg("error", "Gross Weight is required field!");
      setValue("gross_weight", "");
      setValue("net_weight", "");
      return;
    }
    if (netWeight >= parseFloat(grossWeight)) {
      setValue("net_weight", "");
      SnackBars.toastMsg("error", "Enter the less value of gross weight!");
    }
  };

  //calculate the gross weight
  const calculateGrossWeigth = async (value, value1) => {
    const scheme_id = getValues("scheme");
    const pledgeItem = value1 !== undefined ? value1 : radioBtnOption;
    if (!scheme_id || !pledgeItem) {
      setValue("net_weight", "");
      SnackBars.toastMsg("error", "Scheme Name is required field!");
      return;
    }
    logger.log("Initiate Fetch scheme");
    const scheme_data = await api
      .get(`scheme/${scheme_id}/`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch schemes Successfully");
    var grossWeight = 0;
    if (pledgeItem === "normal") {
      grossWeight = value * (scheme_data.gross_weight_detection / 100);
    } else {
      grossWeight = value * (scheme_data.stone_gross_weight_detection / 100);
    }
    // setValue("gross_weight", grossWeight.toFixed(3));
  };

  const handleTotalMat = async (materials) => {
    const totals = materials.reduce(
      (acc, item) => {
        const qty = parseInt(item.qty, 10);
        const grossWeight = parseFloat(item.gross_weight);
        const netWeight = parseFloat(item.net_weight);
        const noOfStone = parseFloat(item.no_of_stone);
        if (
          (item.gold_category === "normal" ||
            item.gold_category === "Normal Gold") &&
          (item.pledgeMaterialType === "Gold" ||
            item.pledgeMaterialType === "தங்கம்")
        ) {
          acc.maxLoanAmt += !isNaN(netWeight)
            ? materialRate.gold_rate * netWeight
            : 0;
        } else if (
          (item.gold_category === "916" ||
            item.gold_category === "Hallmark Gold(916)") &&
          (item.pledgeMaterialType === "Gold" ||
            item.pledgeMaterialType === "தங்கம்")
        ) {
          acc.maxLoanAmt += !isNaN(netWeight)
            ? materialRate.hallmark_gold_rate * netWeight
            : 0;
        } else {
          acc.maxLoanAmt += !isNaN(netWeight)
            ? materialRate.silver_rate * netWeight
            : 0;
        }
        acc.qtyTotal += !isNaN(qty) ? qty : 0;
        acc.grossWeightTotal += !isNaN(grossWeight) ? grossWeight : 0;
        acc.netWeightTotal += !isNaN(netWeight) ? netWeight : 0;
        acc.noOfStone += !isNaN(netWeight) ? noOfStone : 0;

        return acc;
      },
      {
        qtyTotal: 0,
        grossWeightTotal: 0,
        netWeightTotal: 0,
        noOfStone: 0,
        maxLoanAmt: 0,
      }
    );
    setThresHoldAmount(parseInt(totals.maxLoanAmt).toFixed(0));
    // const loanTotalAmt = getValues("loan_amount");
    setStatus((prevData) => {
      return roleId !== 1 && requestedamount > totals.maxLoanAmt
        ? "Pending"
        : "Approved";
    });
    setTotalMaterial(totals);
    const marketHallGold = parseInt(marketRate.market_gold_rate) - 300;
    const marketNormalGold = parseInt(marketRate.market_gold_rate) - 700;
    const totally = materials.reduce(
      (acc, item) => {
        const qty = parseInt(item.qty, 10);
        const grossWeight = parseFloat(item.gross_weight);
        const netWeight = parseFloat(item.net_weight);
        const noOfStone = parseFloat(item.no_of_stone);
        if (
          (item.gold_category === "normal" ||
            item.gold_category === "Normal Gold") &&
          (item.pledgeMaterialType === "Gold" ||
            item.pledgeMaterialType === "தங்கம்")
        ) {
          acc.maxLoanAmt += !isNaN(netWeight)
            ? marketNormalGold * netWeight
            : 0;
        } else if (
          (item.gold_category === "916" ||
            item.gold_category === "Hallmark Gold(916)") &&
          (item.pledgeMaterialType === "Gold" ||
            item.pledgeMaterialType === "தங்கம்")
        ) {
          acc.maxLoanAmt += !isNaN(netWeight) ? marketHallGold * netWeight : 0;
        } else {
          acc.maxLoanAmt += !isNaN(netWeight)
            ? marketRate.market_silver_rate * netWeight
            : 0;
        }
        acc.qtyTotal += !isNaN(qty) ? qty : 0;
        acc.grossWeightTotal += !isNaN(grossWeight) ? grossWeight : 0;
        acc.netWeightTotal += !isNaN(netWeight) ? netWeight : 0;
        acc.noOfStone += !isNaN(netWeight) ? noOfStone : 0;

        return acc;
      },
      {
        qtyTotal: 0,
        grossWeightTotal: 0,
        netWeightTotal: 0,
        noOfStone: 0,
        maxLoanAmt: 0,
      }
    );

    setValue("market_max_amount", parseInt(totally.maxLoanAmt).toFixed(0));
  };

  const handleStoneType = (value) => {
    setStoneType(value);
    if (value !== "with_stone" || value !== "with_enamel") {
      setValue("no_of_stone", 0);
    }
  };

  const handleStoneCult = (value) => {
    const stoneType = getValues("stone_type");

    //stone type is empty throw the validate msg.
    if (stoneType === "") {
      SnackBars.toastMsg("error", "Item Type is required field!");
      setValue("no_of_stone", "");
      return;
    }
    const grossWeight = getValues("gross_weight");
    const subtract = stoneType === "with_enamel" ? 0.1 : 0.5;
    if (grossWeight) {
      const netWeight = grossWeight - value * 0.025 - subtract;
      setValue("net_weight", netWeight.toFixed(3));
    }
  };

  const handleDeleteMat = (id) => {
    const updateList = [...materialList];
    updateList.splice(id, 1);
    setMaterialList(updateList);
    handleTotalMat(updateList);
  };
  const getpaymenttype = (value, key) => {
    const paymentMethods = [
      { text: "Cash", value: "By Cash", text_tn: "பணம்" },
      {
        text: "Account Transfer",
        value: "By Account",
        text_tn: "கணக்கு பரிமாற்றம்",
      },
      { text: "UPI/ Others", value: "UPI/Others", text_tn: "UPI/ மற்றவை" },
    ];
    const method = paymentMethods.find((method) => method.value === value);
    return method ? method[key] : "Not found";
  };

  const getgoldCategory = (value, key) => {
    const method = goldCategory.find((method) => method.value === value);
    return method ? method[key] : "";
  };

  const handsOnAmountSet = async () => {
    var created_on = getValues("created_on");
    var addOneMonth = new Date(created_on);
    addOneMonth.setMonth(addOneMonth.getMonth() + 1);
    var lastDueDate = addOneMonth.toISOString().split("T")[0];
    if (schemeId > 0) {
      logger.log("Initiate Fetch scheme");
      var scheme_data = await api
        .get(`scheme/${schemeId}/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch schemes Successfully");
      const loan_amount = requestedamount;
      const interestDetail = Extension.interestRateCul(
        "month",
        loan_amount,
        created_on,
        lastDueDate,
        scheme_data.duration,
        scheme_data.rate_of_interest_minimum,
        scheme_data.rate_of_interest_maximum,
        false,
        true
      );
      const str = (str) => {
        const data = str.replace(/\.00$/, "");
        return parseInt(data);
      };

      let handsOnAmount = 0;
      if (isNaN(parseInt(documentCharge))) {
        handsOnAmount =
          parseInt(requestedamount) - str(interestDetail.interest_amount);
      } else {
        handsOnAmount =
          parseInt(requestedamount) -
          (parseInt(documentCharge) + str(interestDetail.interest_amount));
      }

      setValue("hands_on_amount", handsOnAmount >= 0 ? handsOnAmount : 0);
    }
  };

  useEffect(() => {
    handsOnAmountSet();
  }, [requestedamount, documentCharge]);

  useEffect(() => {
    if (!documentCharge) {
      setDocumentCharge(0);
    }
  }, [documentCharge]);

  const handleFormData = async (checkedValue) => {
    handleLoadingOpen();

    var created_on = getValues("created_on");
    var lockerId = getValues("locker_id");
    var addOneMonth = new Date(created_on);
    addOneMonth.setMonth(addOneMonth.getMonth() + 1);
    var lastDueDate = addOneMonth.toISOString().split("T")[0];
    const loan_amount = requestedamount;
    // const loan_amount = getValues("loan_amount");
    const customer = getValues("customer");

    let loan_number = "";
    let branch_details = "";
    if (materialList.length > 0) {
      logger.log("Initiate Fetch scheme");
      var scheme_data = await api
        .get(`scheme/${materialList[0].scheme}/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch schemes Successfully");
      logger.log("Initiate Fetch branch");
      const branch = await api
        .get(`/branch/${branch_Id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch branch Successfully");
      if (branch.last_loan_number === null || branch.last_loan_number === "") {
        branch_details = { ...branch, last_loan_number: "A0001" };
        // setSelectedBranchDetail({ ...branch, last_loan_number: "A0001" });
        // setValue("loan_number", `${branch.branch_prefix}-A0001`);
        loan_number = `${branch.branch_prefix}-A0001`;
      } else {
        const { alphabeticPart, numericPart } = splitString(
          branch.last_loan_number
        );
        const updateNumber = generateLoanId(alphabeticPart, numericPart);
        branch_details = { ...branch, last_loan_number: updateNumber };
        // setSelectedBranchDetail({ ...branch, last_loan_number: updateNumber });
        // setValue("loan_number", `${branch.branch_prefix}-${updateNumber}`);
        loan_number = `${branch.branch_prefix}-${updateNumber}`;
      }
      const sta_tus =
        requestedamount > thresHoldAmount && roleId !== 1
          ? "Pending"
          : "Approved";
      const loanDateObject = new Date(created_on);
      const loanEndDate = new Date(loanDateObject);
      loanEndDate.setMonth(loanEndDate.getMonth() + scheme_data.duration_month);
      setStatus(sta_tus);
      const loanHeader = {
        ...materialList[0],
        loan_number,
        branch: branch.branch_id,
        status: sta_tus,
        status_tn: statusCategory[sta_tus],
        loan_amount: loan_amount,
        total_qty: totalMaterial.qtyTotal,
        total_gross_weight: totalMaterial.grossWeightTotal.toFixed(3),
        total_net_weight: totalMaterial.netWeightTotal.toFixed(3),
        current_balance_pa: loan_amount,
        last_due_date: lastDueDate,
        created_on: created_on,
        customer: customer.customer_id,
        entity: entityId,
        additional_interest: false,
        mode_of_payment: getValues("mode_of_payment"),
        mode_of_payment_tn: getpaymenttype(
          getValues("mode_of_payment"),
          "text_tn"
        ),
        created_by: userId,
        approved_on:
          sta_tus === "Approved" ||
          statusCategory[sta_tus] === "அனுமதிக்கப்பட்டது"
            ? created_on
            : null,
        approved_by:
          sta_tus === "Approved" ||
          statusCategory[sta_tus] === "அனுமதிக்கப்பட்டது" ||
          sta_tus === "Rejected" ||
          statusCategory[sta_tus] === "நிராகரிக்கப்பட்டது"
            ? userId
            : null,
        locker_id: lockerId,
        advance_amount: 0,
        documentation_charge:
          documentCharge == "" ||
          Number.isNaN(documentCharge) ||
          documentCharge <= 0
            ? 0
            : documentCharge,
        hands_on_amount: getValues("hands_on_amount"),
        plan_closer_date: new Date(loanEndDate).toISOString().split("T")[0],
      };
      logger.log("Initiate post loan");
      const response = await api
        .post("loan/loans/", loanHeader)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch post loan Successfully");
      let receipt_no;
      if (response) {
        if (
          sta_tus === "Approved" &&
          statusCategory[sta_tus] === "அனுமதிக்கப்பட்டது" &&
          sta_tus !== "Pending" &&
          statusCategory[sta_tus] !== "நிலுவையில் உள்ளது"
        ) {
          // updated transaction fields
          handleFundAmount(sta_tus);
          const interestDetail = Extension.interestRateCul(
            "month",
            loan_amount,
            created_on,
            lastDueDate,
            scheme_data.duration,
            scheme_data.rate_of_interest_minimum,
            scheme_data.rate_of_interest_maximum,
            false,
            true
          );
          let sequence;
          let sequenceNumber;
          if (
            entityDetail.last_interest_num === null ||
            entityDetail.last_interest_num === ""
          ) {
            sequence = "INTA0001";
            sequenceNumber = "A0001";
          } else {
            const { alphabeticPart, numericPart } = splitString(
              entityDetail.last_interest_num
            );

            const updateNumber = generateSequence(alphabeticPart, numericPart);
            sequenceNumber = updateNumber;
            sequence = `INT${updateNumber}`;
          }
          receipt_no = sequence;
          const transaction = {
            amount_paid: interestDetail.interest_amount,
            balance_pa: loan_amount,
            due_amount: interestDetail.interest_amount,
            due_date: lastDueDate,
            closure_amount: 0,
            paid_on: created_on,
            payment_mode: getValues("mode_of_payment"),
            payment_mode_tn: getpaymenttype(
              getValues("mode_of_payment"),
              "text_tn"
            ),
            trans_or_recipt_id: "",
            loan: response.loan_id,
            trans_seq_num: sequence,
          };
          const updateEntity = {
            ...entityDetail,
            last_interest_num: sequenceNumber,
          };
          //udpate the entity
          logger.log("Initiate update entity");
          await api.put(`entity/${entityId}`, updateEntity);
          logger.log("update entity Successfully");
          handleFundAmount(sta_tus, interestDetail.interest_amount);
          logger.log("Initiate add transaction");
          const newTransaction = await api
            .post("loan/Transactions", transaction)
            .then((res) => res.data)
            .catch((err) => logger.log(err));
          logger.log("add transaction Successfully");

          if (checkedValue) {
            SMS_API_FUNCTION.loanCreationSMS_API(
              loan_number,
              created_on,
              customer.mobile_number,
              169259,
              loan_amount
            );
          }
        }

        // udpate the branch last loan number
        logger.log("Initiate update branch");
        const updateBranch = await api
          .put(`/branch/${branch_details.branch_id}`, branch_details)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("update branch Successfully");
        try {
          const promises = materialList.map(async (item) => {
            const newRecord = {
              ...item,
              loan: response.loan_id,
              item: item.pledgeItemName,
              customer: customer.customer_id,
              gold_category_tn: getgoldCategory(item.gold_category, "text_tn"),
              gold_category: getgoldCategory(item.gold_category, "text"),
            };
            logger.log("Initiate add loan details");
            const response1 = await api.post("loan/loandetails/", newRecord);
            logger.log("add loan details Successfully");
            return response1.data;
          });
          // Wait for all promises to resolve
          const results = await Promise.all(promises);
          if (results) {
            Object.entries(loanHeader).forEach(([key, value]) => {
              setValue(key, "");
            });
            const date = new Date().toISOString().split("T")[0];
            setValue("created_on", date);
            setValue("loan_number", "");
            setValue("customer", null); // Branch change the customer drop down value is empty
            setTotalMaterial({});
            setMaterialList([]);
          }
          logger.log("Initiate loan creation receipt");
          const receiptDetail = await api
            .get(`loan/LoanCreationReceipt/${response.loan_id}`)
            .then((res) => res.data)
            .catch((err) => logger.log(err));
          logger.log("Fetch loan creation receipt Successfully");
          const result1 = {
            ...receiptDetail,
            receipt_no: receipt_no,
          };
          handleConfirm(result1);
        } catch (error) {
          logger.log(error);
        } finally {
          handleLoadingClose();
        }
      }
    }
  };

  const handleFundAmount = async (value, inr) => {
    if (value !== "Approved") {
      return;
    }
    try {
      let total_fund_amount = 0;
      total_fund_amount =
        parseInt(getValues("fund_amount")) - parseInt(requestedamount);
      if (!inr) {
        const data = {
          credit_amount: null,
          debit_amount: requestedamount,
          funded_on: new Date(getValues("created_on")).toISOString(),
          total_fund_amount,
          transaction_type: getValues("mode_of_payment"),
          fund_type: "LoanAmountDebit",
          branch: roleId === 1 ? branch_Id : branchId,
          entity: entityId,
          funded_By: userId,
        };
        //console.log(data);
        logger.log("Initiate post fund");
        const response = await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("post fund Successfully");
        //console.log("Debit amount", response);
      }
      if (inr) {
        total_fund_amount = parseInt(total_fund_amount) + parseInt(inr);
        const data = {
          credit_amount: parseInt(inr),
          debit_amount: null,
          funded_on: new Date(getValues("created_on")).toISOString(),
          total_fund_amount,
          transaction_type: getValues("mode_of_payment"),
          fund_type: "InterestAmountCredit",
          branch: roleId === 1 ? branch_Id : branchId,
          entity: entityId,
          funded_By: userId,
        };
        logger.log("Initiate post fund");
        const response = await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("post fund Successfully");
        //console.log("Inr Pick", response);
      }
    } catch (error) {
      logger.log(error);
    }
  };

  //Loan number generate fun.
  const generateSequence = (char, numeric) => {
    const currentChar = char.toUpperCase();
    const currentNumeric = parseInt(numeric);
    //console.log("prefix char code - ", currentChar.charCodeAt(1));
    if (currentNumeric === 1000) {
      let nextChar;
      if (currentChar.charAt(0) === "Z") {
        const charLength = currentChar.length + 1;
        nextChar = "A".repeat(charLength);
      } else {
        nextChar = String.fromCharCode(currentChar.charCodeAt(0) + 1);
      }

      return `${nextChar}0001`;
    } else {
      return `${currentChar}${(currentNumeric + 1).toString().padStart(4, 0)}`;
    }
  };

  // pre loader open fun.
  const handleLoadingOpen = () => {
    setLoading(true);
  };

  //pre loader close fun.
  const handleLoadingClose = () => {
    setLoading(false);
  };

  const handleContextMenu = (event, row) => {
    if (!image) {
      return;
    }
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleRowContextMenu = (event, row) => {
    event.preventDefault();
    setSelectedRow(row);
    setRowContextMenu(
      rowContextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRowClose = () => {
    setRowContextMenu(null);
  };

  function convertBase64(e, maxWidth, maxHeight) {
    // console.log("Function Called");
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target.result) {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            // Check if image needs resizing
            if (width > maxWidth || height > maxHeight) {
              const ratio = Math.min(maxWidth / width, maxHeight / height);
              width *= ratio;
              height *= ratio;
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            const base64Data = canvas.toDataURL("image/jpeg", 0.7); // Convert to base64
            // console.log(base64Data);
            setImage(base64Data);
          };

          img.src = event.target.result;
        } else {
          SnackBars.toastMsg("error", t("toast_messages.failed_to_load"));
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <div>
      <Toaster limit={3} />
      <Loading open={loading} handleClose={handleLoadingClose} />
      <Paper elevation={3} className="add-loan-wrapper">
        <>
          {roleId === 1 && (
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={2.5}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <Box className="headerContent" sx={{ mr: 2 }}>
                <Tooltip title={t("appbar.showitemfix")}>
                  <CustomCommonButton
                    icon={CommonAddIcon}
                    text={t("loancreation receipt.fund")}
                    onClick={() => Navigate("/expenditure")}
                  />
                </Tooltip>
                {showItemRateFixation && (
                  <ItemRateFixation onClose={handleCloseItemRateFixation} />
                )}
              </Box>
            </Grid>
          )}
        </>

        <form onSubmit={handleSubmit(handleForm)}>
          <Box
            className={`add-loan-header ${
              materialList.length > 0 ? "active" : ""
            }`}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 8 }}
            >
              {inputFields.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={item.gridSize?.xs} // Add the "?." to safely access nested properties
                    sm={item.gridSize?.sm}
                    md={item.gridSize?.md}
                    lg={item.gridSize?.lg}
                    key={index}
                  >
                    <Typography sx={Styles.textlabel()}>
                      {item.title && t(`loan creation.${item.title}`)}
                    </Typography>
                    <Paper sx={{ background: "none" }} variant="standarded">
                      {item.field === "customer" && (
                        <Controller
                          name={item.name}
                          control={control}
                          defaultValue={{
                            cus_login_id: "",
                            customer_id: "",
                            mobile_number: "",
                            first_name: "",
                          }}
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              {...register(item.name)}
                              value={item.data.length > 0 ? field.value : null} // Ensure value is always defined
                              options={item.data}
                              isOptionEqualToValue={(option, value) =>
                                option.cus_login_id !== "" ||
                                option.cus_login_id === value.cus_login_id
                              }
                              getOptionLabel={(option) =>
                                `${option.cus_login_id} - ${
                                  option.mobile_number
                                } - ${Extension.languageBasedValue(
                                  option.first_name,
                                  option.first_name_tn
                                )} ${Extension.languageBasedValue(
                                  option.last_name,
                                  option.last_name_tn
                                )}`
                              }
                              filterOptions={(options, { inputValue }) => {
                                const lowerCaseInput = inputValue.toLowerCase();
                                return options.filter(
                                  (option) =>
                                    option.cus_login_id
                                      .toLowerCase()
                                      .includes(lowerCaseInput) ||
                                    option.mobile_number
                                      .toString()
                                      .includes(lowerCaseInput) ||
                                    option.first_name
                                      .toLowerCase()
                                      .toString()
                                      .includes(lowerCaseInput) ||
                                    option.first_name_tn
                                      .toLowerCase()
                                      .toString()
                                      .includes(lowerCaseInput)
                                );
                              }}
                              sx={{
                                backgroundColor: "var(--text-field)",
                                "& .MuiAutocomplete-input": {
                                  fontFamily: "var(--font-family)",
                                  fontSize: "var(--font-size)",
                                  color: "var(--font-color)",
                                  height: "23px",
                                },
                                "& .MuiSvgIcon-root,.MuiTypography-root": {
                                  color: "var(--font-color)", // Change the color of the dropdown icon
                                },
                              }}
                              PaperComponent={(props) => (
                                <Paper {...props} sx={Styles.dropDown()} />
                              )}
                              onChange={(_, value) => {
                                if (value === null) {
                                  setValue(item.name, null);
                                  return;
                                }
                                //console.log(value);
                                const defaultValue = {
                                  cus_login_id: "",
                                  customer_id: "",
                                  mobile_number: "",
                                  first_name: "",
                                };

                                setValue(
                                  item.name,
                                  value || defaultValue || ""
                                );
                              }}
                              size="small"
                              inputProps={{
                                style: {
                                  height: "20px",
                                },
                              }}
                              renderOption={(props, option) => {
                                const isFraudulent = option.fradulent;
                                return (
                                  <li
                                    // file deepcode ignore DOMXSS: <please specify a reason of ignoring this>
                                    {...props}
                                    style={{
                                      fontFamily: "var(--font-family)",
                                      fontSize: "var(--font-size)",
                                      color: isFraudulent && "red",
                                      fontWeight:
                                        isFraudulent && "var(--font-weight)",
                                    }}
                                  >
                                    {isFraudulent ? (
                                      <Tooltip
                                        title={Extension.languageBasedValue(
                                          option.fradulent_remark,
                                          option.fradulent_remark_tn
                                        )}
                                      >
                                        {`${option.cus_login_id} - ${
                                          option.mobile_number
                                        } - ${Extension.languageBasedValue(
                                          option.first_name,
                                          option.first_name_tn
                                        )} ${Extension.languageBasedValue(
                                          option.last_name,
                                          option.last_name_tn
                                        )}`}
                                      </Tooltip>
                                    ) : (
                                      `${option.cus_login_id} - ${
                                        option.mobile_number
                                      } - ${Extension.languageBasedValue(
                                        option.first_name,
                                        option.first_name_tn
                                      )} ${Extension.languageBasedValue(
                                        option.last_name,
                                        option.last_name_tn
                                      )}`
                                    )}
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          )}
                        />
                      )}

                      {item.inputType === "input" && !item.subset && (
                        <CommonTextField
                          name={item.name}
                          register={register}
                          type={item.type}
                          readOnly={item.readOnly}
                          position={item.position}
                          comboFixWord={item.ComboFixWord}
                        />
                      )}
                      {item.inputType === "select" &&
                        !item.subset &&
                        item.field !== "customer" &&
                        item.field !== "scheme" && (
                          <Controller
                            name={item.name}
                            control={control}
                            rules={{ required: true }}
                            defaultValue=""
                            render={({ field }) => (
                              <SelectDropDown
                                field={field}
                                name={item.name}
                                required={item.required}
                                clickFunction={
                                  item.clickEvent && item.field === "branch"
                                    ? handleBranchChange
                                    : item.field === "scheme"
                                    ? handleSchemeChange
                                    : ""
                                }
                                clickType={item.clickEvent && item.clickType}
                                register={register}
                                readOnly={
                                  item.field === "branch" && roleId !== 1
                                    ? true
                                    : item.readOnly
                                }
                                display_name={
                                  language === "tm"
                                    ? item.tm
                                    : item.display_name
                                }
                                value={item.value}
                                data={item.data}
                                setValue={setValue}
                              />
                            )}
                          />
                        )}

                      {item.inputType === "select" &&
                        !item.subset &&
                        item.field === "scheme" && (
                          <Controller
                            name={item.name}
                            control={control}
                            rules={{ required: true }}
                            defaultValue=""
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={item.readOnly}
                                value={field.value || ""}
                                sx={{
                                  ...Styles.dropDown(),
                                  "& .MuiSvgIcon-root,.MuiTypography-root": {
                                    color: "var(--font-color)", // Change the color of the dropdown icon
                                  },
                                }}
                                style={{ height: "40px" }}
                                fullWidth
                                required
                                size="small"
                                MenuProps={Styles.MenuProps()}
                                onChange={(event) => {
                                  field.onChange(event);
                                  if (
                                    item.clickEvent &&
                                    item.field === "scheme" &&
                                    item.clickType === "onChange"
                                  ) {
                                    handleSchemeChange(event.target.value);
                                  }
                                }}
                              >
                                {item.data.map((scheme, index) => (
                                  <MenuItem
                                    key={index}
                                    value={scheme.scheme_id}
                                    className="common-font-style"
                                  >
                                    {`${Extension.languageBasedValue(
                                      scheme.scheme_name,
                                      scheme.scheme_name_tn
                                    )} - ${scheme.rate_of_interest_minimum} %`}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        )}

                      {item.subset && (
                        <Grid
                          container
                          // spacing={{ xs: 2, md: 3, sm: 3 }}
                          // columns={{ xs: 1, sm: 3, md: 6, lg:1 }}
                          rowSpacing={1}
                          columnSpacing={2}
                        >
                          {item.subsets.map((subsetItem, subsetIndex) => (
                            <Grid
                              item
                              xs={subsetItem.gridSize.xs}
                              sm={subsetItem.gridSize.sm}
                              md={subsetItem.gridSize.md}
                              lg={subsetItem.gridSize.lg}
                              key={`${index}_${subsetIndex}`}
                            >
                              {subsetItem.type === "typo" && (
                                <Typography
                                  variant="h4"
                                  sx={{
                                    ...Styles.titleText(),
                                    // color: "var(--header-font-color)",
                                    margin: "10px 0 0 0",
                                    display: "flex",
                                    alignItems: "baseline",
                                  }}
                                >
                                  {t("loan creation.new_loan")}
                                </Typography>
                              )}
                              <Typography sx={Styles.textlabel()}>
                                {subsetItem.type !== "typo" &&
                                  t(`loan creation.${subsetItem.title}`)}
                              </Typography>

                              {subsetItem.inputType === "input" &&
                                subsetItem.title !== "threshold amount" && (
                                  <CommonTextField
                                    name={subsetItem.name}
                                    register={register}
                                    type={subsetItem.type}
                                    readOnly={subsetItem.readOnly}
                                    position={subsetItem.position}
                                    comboFixWord={subsetItem.ComboFixWord}
                                  />
                                )}
                              {subsetItem.inputType === "select" && (
                                <Controller
                                  name={subsetItem.name}
                                  control={control}
                                  rules={{ required: true }}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <SelectDropDown
                                      field={field}
                                      name={subsetItem.name}
                                      clickFunction={
                                        subsetItem.clickEvent &&
                                        subsetItem.field === "branch"
                                          ? handleBranchChange
                                          : subsetItem.field === "scheme"
                                          ? handleSchemeChange
                                          : ""
                                      }
                                      clickType={
                                        subsetItem.clickEvent &&
                                        subsetItem.clickType
                                      }
                                      register={register}
                                      readOnly={
                                        subsetItem.field === "branch" &&
                                        roleId !== 1
                                          ? true
                                          : subsetItem.readOnly
                                      }
                                      data={subsetItem.data}
                                      setValue={setValue}
                                      display_name={
                                        language === "tm"
                                          ? subsetItem.tm
                                          : subsetItem.display_name
                                      }
                                      value={subsetItem.value}
                                    />
                                  )}
                                />
                              )}
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={6} sm={4} md={3} lg={1.5}>
              <Typography sx={Styles.textlabel()}>
                {t("loan creation.pledge item name")}
              </Typography>
              <Paper>
                <Controller
                  name={"pledgeItemName"}
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <SelectDropDown
                      field={field}
                      name={"pledgeItemName"}
                      readOnly={false}
                      register={register}
                      required={true}
                      data={pledgeItemDropDown}
                      setValue={setValue}
                      display_name={
                        language === "tm" ? "item_name_tn" : "item_name"
                      }
                      tm={"item_name_tn"}
                      value={"item_id"}
                    />
                  )}
                />
              </Paper>
            </Grid>
            {pledgeMaterialType &&
              (pledgeMaterialType === "gold" ||
                pledgeMaterialType === "Gold") && (
                <Grid item xs={6} sm={4} md={3} lg={1.5}>
                  <Typography sx={Styles.textlabel()}>
                    {t("loan creation.gold category")}
                  </Typography>
                  <Paper>
                    <Controller
                      name={"gold_category"}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({ field }) => (
                        <SelectDropDown
                          field={field}
                          name={"gold_category"}
                          readOnly={false}
                          required={true}
                          register={register}
                          data={goldCategory}
                          setValue={setValue}
                          display_name={language === "tm" ? "text_tn" : "text"}
                          value={"value"}
                        />
                      )}
                    />
                  </Paper>
                </Grid>
              )}
            <Grid item xs={6} sm={4} md={3} lg={1.5} style={{...(currentLang === 'tm' && {marginTop:'27px' })}}>
              <Typography sx={Styles.textlabel()}>
                {t("loan creation.item type")}
              </Typography>
              <Paper>
                <Controller
                  name={"stone_type"}
                  control={control}
                  rules={{ required: false }}
                  defaultValue=""
                  render={({ field }) => (
                    <SelectDropDown
                      field={field}
                      name={"stone_type"}
                      required={true}
                      readOnly={false}
                      register={register}
                      clickFunction={handleStoneType}
                      clickType={"onChange"}
                      display_name={language === "tm" ? "text_tn" : "text"}
                      value={"value"}
                      data={[
                        {
                          text: "With Stone",
                          value: "with_stone",
                          text_tn: "கல்லுடன்",
                        },
                        {
                          text: "Without Stone",
                          value: "without_stone",
                          text_tn: "கல் இல்லாமல்",
                        },
                        {
                          text: "With Enamel",
                          value: "with_enamel",
                          text_tn: "பற்சிப்பியுடன்",
                        },
                        {
                          text: "Without Enamel",
                          value: "without_enamel",
                          text_tn: "பற்சிப்பி இல்லாமல்",
                        },
                      ]}
                      setValue={setValue}
                    />
                  )}
                />
              </Paper>
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={1.5} style={{...(currentLang === 'tm' && {marginTop:'27px' })}}> 
              <Typography sx={Styles.textlabel()}>
                {t("loan creation.quantity")}
              </Typography>
              <Paper>
                <CommonTextField
                  name={"qty"}
                  register={register}
                  type="number"
                  readOnly={false}
                  position={"end"}
                  comboFixWord={""}
                />
              </Paper>
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={1.5}>
              <Typography sx={Styles.textlabel()}>
                {t("loan creation.no of stones")}
              </Typography>
              <Paper>
                <CommonTextField
                  name={"no_of_stone"}
                  register={register}
                  type="number"
                  readOnly={
                    (stoneType && stoneType === "with_stone") ||
                    stoneType === "with_enamel"
                      ? false
                      : true
                  }
                  position={"end"}
                  clickFunction={handleStoneCult}
                  clickEvent={true}
                  comboFixWord={""}
                />
              </Paper>
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={1.5}>
              <Typography sx={Styles.textlabel()}>
                {t("loan creation.gross weight")}
              </Typography>
              <Paper>
                <CommonTextField
                  name={"gross_weight"}
                  register={register}
                  type="float"
                  pattern={"float"}
                  clickFunction={handleGrossWeigth}
                  clickEvent={true}
                  readOnly={false}
                  position={"end"}
                  comboFixWord={"g"}
                />
              </Paper>
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={1.5} style={{...(currentLang === 'tm' && {marginTop:'27px' })}}>
              <Typography sx={Styles.textlabel()}>
                {t("loan creation.net")}
              </Typography>
              <Paper>
                <CommonTextField
                  name={"net_weight"}
                  register={register}
                  pattern={"float"}
                  type="float"
                  clickFunction={handleNetWeight}
                  clickEvent={true}
                  readOnly={false}
                  position={"end"}
                  comboFixWord={"g"}
                />
              </Paper>
            </Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={1.5}
              lg={0.5}
              sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}
            >
              <input
                type="file"
                id="image-upload"
                style={{ display: "none" }}
                onInput={convertBase64}
              />
              <Tooltip
                title={
                  image
                    ? t("loan creation.enlarge_image")
                    : t("loan creation.add_image")
                }
              >
                <Avatar
                  src={image ? `${image}` : AddImage}
                  variant="rounded"
                  onContextMenu={(e) => handleContextMenu(e)}
                  alt=""
                  sx={{ height: 50, width: 50, cursor: "pointer" }}
                  onClick={() =>
                    document.getElementById("image-upload").click()
                  }
                />
              </Tooltip>
              <ImageProvision
                image={image}
                setContextMenu={setContextMenu}
                handleClose={handleClose}
                contextMenu={contextMenu}
              />
              {/* <Modal
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
              >
                <Box
                  sx={{
                    ...Styles.customPopUp(),
                    width: "60%",
                    height: "90%",
                  }}
                >
                  {/* <Paper> *
                  <IconButton
                    onClick={() => setContextMenu(null)}
                    style={{
                      position: "absolute",
                      top: "0",
                      right: "0",
                      zIndex: 999,
                    }}
                  >
                    <Cancel sx={{ color: "var(--font-color)" }} />
                  </IconButton>
                  <Avatar
                    src={image ? `${image}` : AddImage}
                    variant="rounded"
                    alt=""
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                    }}
                  />
                  {/* </Paper> *
                </Box>
              </Modal> */}
            </Grid>
            <Grid
              item
              xs={3}
              sm={2}
              md={1.5}
              lg={1}
              sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}
            >
              <IconButton
                sx={{
                  bgcolor: "#B1F164",
                  color: "#555",
                  "&:hover": {
                    bgcolor: "#82B643",
                    color: "#fff",
                    transform: "scale(1.2)",
                  },
                  mt: 2,
                  mr: 1,
                }}
                type="submit"
              >
                {saveBtnVisible ? (
                  <LibraryAddCheckRounded sx={{ fontSize: "30px" }} />
                ) : (
                  <LibraryAddRounded sx={{ fontSize: "30px" }} />
                )}
              </IconButton>
            </Grid>
            {/* </Grid>
            </Grid> */}
            {/* </Box> */}
          </Grid>

          <input type="hidden" {...register("id")} />
          <input
            type="hidden"
            {...register("loan_number")}
            value={"loanNumber"}
          />
          <Grid item xs={12} sx={{ mt: 3 }}>
            <TableContainer
              component={Paper}
              elevation={3}
              sx={{ maxHeight: 440 }}
              variant="elevation"
            >
              <Table stickyHeader aria-label="table">
                <TableHead>
                  <StyledTableRow>
                    {pledgeMaterialType &&
                      (pledgeMaterialType === "gold" ||
                        pledgeMaterialType === "Gold" ||
                        pledgeMaterialType === "தங்கம்") && (
                        <StyledTableCell>
                          {t("loan creation.gold category")}
                        </StyledTableCell>
                      )}
                    <StyledTableCell>
                      {t("loan creation.pledge item name")}
                    </StyledTableCell>

                    <StyledTableCell>
                      {t("loan creation.quantity")}
                    </StyledTableCell>

                    <StyledTableCell>
                      {t("loan creation.no of stones")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t("loan creation.gross weight")}
                    </StyledTableCell>
                    <StyledTableCell>{t("loan creation.net")}</StyledTableCell>

                    {/* <StyledTableC{t("loan creation.gold")}ell>Image</StyledTableCell> */}
                    <StyledTableCell>
                      {t("loan creation.image")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t("loan creation.action")}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {materialList.length > 0 ? (
                    materialList.map((row, index) => (
                      <StyledTableRow key={index}>
                        {pledgeMaterialType &&
                          (pledgeMaterialType === "gold" ||
                            pledgeMaterialType === "Gold" ||
                            pledgeMaterialType === "தங்கம்") && (
                            <StyledTableCell>
                              {(() => {
                                let foundItem = goldCategory.find(
                                  (item) => item.value === row.gold_category
                                );
                                return (
                                  foundItem &&
                                  (language === "tm"
                                    ? foundItem.text_tn
                                    : foundItem.text)
                                );
                              })()}
                            </StyledTableCell>
                          )}
                        <StyledTableCell>
                          {(() => {
                            let foundItem = pledgeItemDropDown.find(
                              (item) => item.item_id === row.pledgeItemName
                            );
                            return (
                              foundItem &&
                              (language === "tm"
                                ? foundItem.item_name_tn
                                : foundItem.item_name)
                            );
                          })()}
                        </StyledTableCell>
                        <StyledTableCell>{row.qty}</StyledTableCell>
                        <StyledTableCell>
                          {row.no_of_stone === "" || !row.no_of_stone
                            ? 0
                            : row.no_of_stone}
                        </StyledTableCell>
                        <StyledTableCell>
                          {parseFloat(row.gross_weight).toFixed(3)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {parseFloat(row.net_weight).toFixed(3)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.image ? (
                            <Avatar
                              src={row?.image}
                              variant="rounded"
                              onContextMenu={(e) =>
                                handleRowContextMenu(e, row)
                              }
                              alt=""
                              sx={{ height: 50, width: 50, margin: "0 auto" }}
                            />
                          ) : (
                            "--"
                          )}
                          <ImageProvision
                            image={selectedRow?.image}
                            setContextMenu={setRowContextMenu}
                            handleClose={handleRowClose}
                            contextMenu={rowContextMenu}
                          />
                        </StyledTableCell>

                        <StyledTableCell>
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <IconButton
                              sx={{
                                bgcolor: "#1e88e5",
                                color: "#fff",
                                "&:hover": {
                                  bgcolor: "#0d47a1",
                                },
                              }}
                              onClick={() => handleAddMaterial(index)}
                            >
                              <EditNoteSharp />
                            </IconButton>
                            <IconButton
                              sx={{
                                bgcolor: "#ff8a80",
                                color: "#fff",
                                "&:hover": {
                                  bgcolor: "#d32f2f",
                                },
                              }}
                              onClick={() => handleDeleteMat(index)}
                            >
                              <DeleteRounded />
                            </IconButton>
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell colSpan={10}>
                        <Typography
                          className="common-font-style"
                          component="p"
                          variant="h6"
                        >
                          {t("loan creation.no_data_found")}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                  <StyledTableRow>
                    <StyledTableCell
                      style={{ textAlign: "right" }}
                      colSpan={
                        pledgeMaterialType === "gold" ||
                        pledgeMaterialType === "Gold" ||
                        pledgeMaterialType === "தங்கம்"
                          ? 2
                          : 1
                      }
                    >
                      {t("common.total")} :
                    </StyledTableCell>
                    <StyledTableCell>
                      <CountUp
                        end={totalMaterial.qtyTotal}
                        useIndianSeparators={true}
                        duration={1.5}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <CountUp
                        end={totalMaterial.noOfStone}
                        useIndianSeparators={true}
                        duration={1.5}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <CountUp
                        end={totalMaterial.grossWeightTotal}
                        duration={1.5}
                        prefix={""}
                        suffix={` (${t("common.gram")})`}
                        decimals={3}
                        useIndianSeparators={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <CountUp
                        end={totalMaterial.netWeightTotal}
                        duration={1.5}
                        decimals={3}
                        prefix={""}
                        useIndianSeparators={true}
                        suffix={` (${t("common.gram")})`}
                      />
                    </StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Box
            className={`add-loan-bottom ${
              materialList.length > 0 ? "active" : ""
            }`}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}
            >
              {BottomFields.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={item.gridSize?.xs}
                    sm={item.gridSize?.sm}
                    md={item.gridSize?.md}
                    lg={item.gridSize?.lg}
                    key={index}
                  >
                    <Typography sx={{ ...Styles.textlabel() }}>
                      {item.title && t(`loan creation.${item.title}`)}
                    </Typography>
                    <Paper sx={{ background: "none" }} variant="standarded">
                      {item.title === "requested loan amount" &&
                        !item.subsets && (
                          <TextField
                            sx={{
                              ...Styles.dropDown(),
                              "& .MuiSvgIcon-root,.MuiTypography-root": {
                                color: "var(--font-color)", // Change the color of the dropdown icon
                              },
                            }}
                            fullWidth
                            size="small"
                            value={requestedamount}
                            onChange={(e) =>
                              setRequestedAmount(parseInt(e.target.value))
                            }
                            type={item.type}
                            defaultValue={0}
                            onKeyDown={(e) => {
                              ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault();
                            }}
                            InputProps={{
                              readOnly: item.readOnly,
                              inputProps: {
                                min: 0,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  {"₹"}
                                </InputAdornment>
                              ),
                              style: {
                                backgroundColor: "var(--text-field)",
                                color: "var(--font-color)",
                                fontFamily: "var(--font-family)",
                                fontSize: "var(--font-size)",
                                height: "40px",
                              },
                            }}
                          />
                        )}
                      {item.title === "documentation charges" &&
                        !item.subsets && (
                          <TextField
                            sx={{
                              ...Styles.dropDown(),
                              "& .MuiSvgIcon-root,.MuiTypography-root": {
                                color: "var(--font-color)", // Change the color of the dropdown icon
                              },
                            }}
                            fullWidth
                            size="small"
                            value={documentCharge}
                            onChange={(e) => setDocumentCharge(e.target.value)}
                            type={item.type}
                            defaultValue={0}
                            onKeyDown={(e) => {
                              ["e", "E", "+", "-", "."].includes(e.key) &&
                                e.preventDefault();
                            }}
                            InputProps={{
                              readOnly: item.readOnly,
                              inputProps: {
                                min: 0,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  {"₹"}
                                </InputAdornment>
                              ),
                              style: {
                                backgroundColor: "var(--text-field)",
                                color: "var(--font-color)",
                                height: "40px",
                                fontFamily: "var(--font-family)",
                                fontSize: "var(--font-size)",
                              },
                            }}
                          />
                        )}
                      {item.title === "threshold amount" && (
                        <TextField
                          sx={{
                            ...Styles.dropDown(),
                            "& .MuiSvgIcon-root,.MuiTypography-root": {
                              color: "var(--font-color)", // Change the color of the dropdown icon
                            },
                          }}
                          fullWidth
                          size="small"
                          value={thresHoldAmount}
                          onChange={(e) => setThresHoldAmount(e.target.value)}
                          type={item.type}
                          defaultValue={0}
                          onKeyDown={(e) => {
                            ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault();
                          }}
                          InputProps={{
                            readOnly: item.readOnly,
                            inputProps: {
                              min: 0,
                            },
                            startAdornment: (
                              <InputAdornment position="start">
                                {"₹"}
                              </InputAdornment>
                            ),
                            style: {
                              backgroundColor: "var(--text-field)",
                              color: "var(--font-color)",
                              height: "40px",
                              fontFamily: "var(--font-family)",
                              fontSize: "var(--font-size)",
                            },
                          }}
                        />
                      )}
                      {item.inputType === "input" &&
                        item.title !== "requested loan amount" &&
                        item.title !== "threshold amount" &&
                        item.title !== "documentation charges" &&
                        !item.subset && (
                          <CommonTextField
                            name={item.name}
                            register={register}
                            type={item.type}
                            readOnly={item.readOnly}
                            position={item.position}
                            comboFixWord={item.ComboFixWord}
                          />
                        )}
                      {item.inputType === "select" && !item.subset && (
                        <Controller
                          name={item.name}
                          control={control}
                          rules={{ required: true }}
                          defaultValue=""
                          required={false}
                          render={({ field }) => (
                            <SelectDropDown
                              field={field}
                              name={item.name}
                              defaultValue={
                                item.name === "mode_of_payment" && "By Cash"
                              }
                              clickFunction={
                                item.clickEvent && item.field === "branch"
                                  ? handleBranchChange
                                  : item.field === "scheme"
                                  ? handleSchemeChange
                                  : ""
                              }
                              clickType={item.clickEvent && item.clickType}
                              register={register}
                              readOnly={
                                item.field === "branch" && roleId !== 1
                                  ? true
                                  : item.readOnly
                              }
                              display_name={
                                language === "tm" ? item.tm : item.display_name
                              }
                              value={item.value}
                              data={item.data}
                              setValue={setValue}
                            />
                          )}
                        />
                      )}
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {materialList.length > 0 && (
            <Box className="add-btn-container">
              <Button
                type="button"
                value={""}
                size="small"
                className="add-btn"
                onClick={showConfirmationDialog}
              >
                {roleId !== 1 &&
                // status === "Pending" &&
                requestedamount > thresHoldAmount
                  ? t("common.request to admin")
                  : t("common.approve loan")}
              </Button>
            </Box>
          )}
        </form>
      </Paper>
    </div>
  );
};
