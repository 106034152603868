import { MenuItem, Paper, Select, Typography } from "@mui/material";
import React from "react";
import Styles from "../Styles";

const Dropdown = (props) => {
  const {
    label,
    value,
    onChange,
    array,
    menuValue,
    display,
    firstOption,
    name,
    readOnly,
    search,
    customer,
  } = props;

  const FraudlentStyle = {
    color: "red",
    fontWeight: "var(--font-weight)",
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
  };

  return (
    <>
      <Typography
        sx={{
          ...Styles.textlabel(),
          color: readOnly ? "var(--app-bar-color)" : "var(--font-color)",
        }}
      >
        {label}
      </Typography>
      <Paper style={{ backgroundColor: "transparent" }}>
        <Select
          sx={{
            backgroundColor: readOnly
              ? "var(--disabled-field)"
              : "var(--text-field)",
            minHeight: "25px",
            maxHeight: "40px",
            color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-size)",
            "& .MuiSvgIcon-root": {
              color: readOnly ? "var(--disabled-font)" : "var(--font-color)", // Change the color of the dropdown icon
            },
          }}
          name={name}
          fullWidth
          value={value}
          inputProps={{
            typeof: "search",
            readOnly: readOnly === null || readOnly === "" ? false : readOnly,
            style: {
              minHeight: "25px",
            },
          }}
          onChange={onChange}
          MenuProps={Styles.MenuProps()}
        >
          {search && (
            <MenuItem value="All" className="common-font-style">
              {firstOption === "" || firstOption == null ? "None" : firstOption}
            </MenuItem>
          )}
          {array.length > 0 &&
            array.map((item, index) => (
              <MenuItem
                key={index}
                value={item[menuValue]}
                className="common-font-style"
                style={customer && item.fradulent ? FraudlentStyle : {}}
              >
                {item[display]}
              </MenuItem>
            ))}
        </Select>
      </Paper>
    </>
  );
};

export default Dropdown;
