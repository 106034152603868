import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./Auth/Login";
import Transaction from "./Pages//Transaction/Transaction";
import Auction from "./Pages/Auction/Auction";
import Collections from "./Pages/Collections";
import Customer from "./Pages/Customer";
import Expenditure from "./Pages/FundTransaction/Expenditure";
import FundTransaction from "./Pages/FundTransaction/FundTransaction";
import Inventory from "./Pages/Inventory";
import CloseInventory from "./Pages/Inventory/CloseInventory";
import LoanProcess from "./Pages/LoanProcess";
import NotFound from "./Pages/NotFound";
import Scheme from "./Pages/Scheme";
import SettingsComp from "./Pages/SettingsComp";
import Summary from "./Pages/Summary";
import SuperAdmin from "./Pages/SuperAdmin/SuperAdmin";
import TenantCreation from "./Pages/SuperAdmin/TenantCreation";
import UnderDevelopment from "./Pages/UnderDevelopment";
import User from "./Pages/User";
import { Dashboard } from "./Pages/dashboard/Dashboard";
import ManagerDashboard from "./Pages/dashboard/ManagerDashboard";
import { AddLoanProcess } from "./Pages/loanProcess/AddLoanProcess";
import AddShortCutKey from "./common/AddShortCutKey";
import { useEffect } from "react";
import JSLAppBar from "./common/JSLAppBar";

function App() {
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const navigate = useNavigate();

  if (roleId === null) {
    return navigate("/");
  }
  return (
    <div className="App" style={{ fontFamily: `var(--font-family)` }}>
      <AddShortCutKey />
      <Routes>
        <Route path="/" element={<Login />} />

        <Route
          path="/dashboard"
          element={
            <PrivateRouter roleRequired={[1]}>
              <Dashboard />
            </PrivateRouter>
          }
        />

        <Route
          path="/user"
          element={
            <PrivateRouter roleRequired={[1]}>
              <User />
            </PrivateRouter>
          }
        />

        <Route
          path="/managerdashboard"
          element={
            <PrivateRouter roleRequired={[2]}>
              <ManagerDashboard />
            </PrivateRouter>
          }
        />

        <Route
          path="/underdevelopment"
          element={
            <PrivateRouter roleRequired={[1, 2, 3]}>
              <UnderDevelopment />
            </PrivateRouter>
          }
        />

        <Route
          path="/scheme"
          element={
            <PrivateRouter roleRequired={[1]}>
              <Scheme />
            </PrivateRouter>
          }
        />

        <Route
          path="/customer"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <Customer />
            </PrivateRouter>
          }
        />

        <Route
          path="/loanprocess"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <LoanProcess />
            </PrivateRouter>
          }
        />

        <Route
          path="/createLoan"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <AddLoanProcess />
            </PrivateRouter>
          }
        />

        <Route
          path="/inventory"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <CloseInventory />
            </PrivateRouter>
          }
        />

        <Route
          path="/stockreport"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <Inventory />
            </PrivateRouter>
          }
        />

        <Route
          path="/receipt"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <Transaction />
            </PrivateRouter>
          }
        />

        <Route
          path="/transactions"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <Collections />
            </PrivateRouter>
          }
        />

        <Route
          path="/summary"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <Summary />
            </PrivateRouter>
          }
        />
        <Route
          path="/auction"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <Auction />
            </PrivateRouter>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRouter roleRequired={[1, 2, 3]}>
              <SettingsComp />
            </PrivateRouter>
          }
        />
        <Route
          path="/fundtransaction"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <FundTransaction />
            </PrivateRouter>
          }
        />
        <Route path="*" element={<NotFound />} />
        <Route
          path="/tenantmaster"
          element={
            <PrivateRouter roleRequired={[3]}>
              <SuperAdmin />
            </PrivateRouter>
          }
        />
        <Route
          path="/tenantcreation"
          element={
            <PrivateRouter roleRequired={[3]}>
              <TenantCreation />
            </PrivateRouter>
          }
        />
        <Route
          path="/expenditure"
          element={
            <PrivateRouter roleRequired={[1, 2]}>
              <Expenditure />
            </PrivateRouter>
          }
        />
      </Routes>
    </div>
  );
}

export default App;

const PrivateRouter = ({ roleRequired, children }) => {
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const navigate = useNavigate();
  useEffect(() => {
    if (!roleId) {
      return navigate("/");
    }
    if (!roleId || Number.isNaN(roleId) || !roleRequired.includes(roleId)) {
      return navigate("*");
    }
  }, [navigate, roleId, roleRequired]);

  return (
    <>
      {!isNaN(roleId) && roleRequired.includes(roleId) && (
        <JSLAppBar>{children}</JSLAppBar>
      )}
    </>
  );
};
