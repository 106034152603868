import { Autorenew } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Captcha.css";

const fonts = ["Tangerine", "fangsong", "Tangerine", "Droid+Sans"];

const colors = ["#000DFF", "black", "red", "green"];

const Captcha = ({ dataFromCaptcha }) => {
  const [captchaSequence, setCaptchaSequence] = useState("");

  //captcha generation function
  function generate() {
    let uniquechar = "";

    const randomchar = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 1; i <= 5; i++) {
      uniquechar += randomchar.charAt(Math.random() * randomchar.length);
    }
    setCaptchaSequence(uniquechar);
    sendDataToParent(uniquechar);
  }

  const sendDataToParent = (uniquechar) => {
    dataFromCaptcha(uniquechar);
  };

  useEffect(() => {
    generate();
  }, []);

  const sequenceText = captchaSequence.split("").map((char, index) => ({
    text: char,
    font: fonts[index % fonts.length],
    colors: colors[index % colors.length],
  }));
  return (
    <>
      <div className="whole-container">
        <Box className="image">
          {sequenceText.map((texts, index) => (
            <Typography
              key={index}
              sx={{
                fontFamily: texts.font,
                color: texts.colors,
                fontSize: "30px",
              }}
            >
              {texts.text}
            </Typography>
          ))}
        </Box>
        <Autorenew className="icon" onClick={generate} />
      </div>
    </>
  );
};
export default Captcha;
