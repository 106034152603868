import { MenuItem, Select, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Style from "../../../common/Styles";
import api from "../../../api/http-common";

const FontSize = ({ theme }) => {
  const userId = parseInt(sessionStorage.getItem("userId"));
  const rootStyles = getComputedStyle(document.documentElement);
  const fontsize = rootStyles.getPropertyValue('--font-family').trim();
  const [fontSize, setFontSize] = useState(fontsize);
  const { t } = useTranslation("global");

  const data = [
    { name: t("settings.verySmall"), value: "12px" },
    { name: t("settings.small"), value: "15px" },
    { name: t("settings.medium"), value: "18px" },
    { name: t("settings.large"), value: "20px" },
    { name: t("settings.veryLarge"), value: "22px" },
  ];

  useEffect(() => {
    const fetchFontSize = async () => {
      try {
        const response = await api.get(`/themes/${userId}`);
        const userTheme = response.data;
        setFontSize(userTheme.font_size);
        document.documentElement.style.setProperty("--font-size", userTheme.font_size);
      } catch (error) {
        console.error("Error fetching font size:", error);
      }
    };
    fetchFontSize();
  }, [userId]);

  const updateFontSize = async (newFontSize) => {
    try {
      const response = await api.get(`/themes/${userId}`);
      const userTheme = response.data;
      userTheme.font_size = newFontSize;
      await api.put(`/themes/${userId}`, userTheme);
    } catch (error) {
      console.error("Error updating font size:", error);
    }
  };

  const handleFontSizeChange = (e) => {
    const newFontSize = e.target.value;
    setFontSize(newFontSize);
    document.documentElement.style.setProperty("--font-size", newFontSize);
    updateFontSize(newFontSize);
  };

  useEffect(() => {
    if (theme) {
      setFontSize("18px");
      document.documentElement.style.setProperty("--font-size", "18px");
      updateFontSize("18px");
    }
  }, [theme]);

  return (
    <div className="language-preference">
      <div style={{ width: "30%", display: "flex", justifyContent: "start" }}>
        <Typography className="common-font-style">
          {t("settings.fontSize")}
        </Typography>
      </div>
      <div style={{ width: "70%", display: "flex", justifyContent: "start" }}>
        <Select
          value={fontSize}
          onChange={handleFontSizeChange}
          sx={{
            backgroundColor: "var(--text-field)",
            minHeight: "32px",
            maxHeight: "40px",
            width: 300,
            fontFamily: "var(--font-family)",
            color: "var(--font-color)",
            fontSize: "var(--font-size)",
            "& .MuiSvgIcon-root": { color: "var(--font-color)" },
          }}
          inputProps={{ typeof: "search", style: { minHeight: "15px" } }}
          MenuProps={Style.MenuProps()}
        >
          {data.map((item) => (
            <MenuItem
              sx={{ fontSize: item.value, fontFamily: "var(--font-family)" }}
              key={item.value}
              value={item.value}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default FontSize;
