import { MenuItem, Paper, Select, Typography } from "@mui/material";
import React from "react";
import Styles from "../Styles";
import { useTranslation } from "react-i18next";

const MultiDisplayDropdown = (props) => {
  const { t } = useTranslation("global");
  const {
    label,
    value,
    onChange,
    array,
    menuValue,
    display,
    firstOption,
    readOnly,
    search,
  } = props;
  // const masg = display.map()
  return (
    <>
      <Typography
        sx={{
          ...Styles.textlabel(),
          color: readOnly ? "var(--app-bar-color)" : "var(--font-color)",
        }}
      >
        {label}
      </Typography>
      <Paper style={{ backgroundColor: "transparent" }}>
        <Select
          sx={{
            backgroundColor: readOnly
              ? "var(--disabled-field)"
              : "var(--text-field)",
            minHeight: "25px",
            maxHeight: "40px",
            color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-size)",
            "& .MuiSvgIcon-root": {
              color: readOnly ? "var(--disabled-font)" : "var(--font-color)", // Change the color of the dropdown icon
            },
          }}
          fullWidth
          value={value}
          inputProps={{
            typeof: "search",
            readOnly: readOnly === null || readOnly === "" ? false : readOnly,
            style: {
              backgroundColor: readOnly
                ? "var(--disabled-field)"
                : "var(--text-field)",
              maxHeight: "15px",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            },
          }}
          onChange={onChange}
          MenuProps={Styles.MenuProps()}
        >
          {search && (
            <MenuItem value="All" className="common-font-style">
              {firstOption === "" || firstOption == null ? "None" : firstOption}
            </MenuItem>
          )}
          {array.length > 0 &&
            array.map((item, index) => (
              <MenuItem
                key={index}
                value={item[menuValue]}
                className="common-font-style"
              >
                {display.map((field, i) => (
                  <React.Fragment key={i}>
                    {item[field]}
                    {i < display.length - 1 && "/ "}
                  </React.Fragment>
                ))}
              </MenuItem>
            ))}
        </Select>
      </Paper>
    </>
  );
};

export default MultiDisplayDropdown;
