import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../api/http-common";
import { AppData } from "../../common/AppData";
import Extension from "../../common/Extension";
import GridTable from "../../common/GridTable";
import Logger from "../../common/Logger";
import "./ManagerDashboard.css";

const logger = new Logger({ level: "log" });
const AuctionTable = () => {
  const Navigate = useNavigate();
  const entity_id = parseInt(sessionStorage.getItem("entityId"));
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));

  const [auctionLoans, setAuctionLoans] = useState([]);
  const [schemeLoans, setSchemeLoans] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("global");
  const columns = AppData.auctionDashboard.map((column) => ({
    ...column,
    headerName: t(`auction process.${column.field}`),
  }));

  const getData = useCallback(async () => {
    try {
      const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-'); // Assumes date format is yyyy-mm-dd
        return `${day}-${month}-${year}`;
      };
      const LoanAPI =
        role_id === 1
          ? `loan/AuctionEligibleList/${entity_id}`
          : `/loan/AuctionEligibleListByBranch/${branch_id}`;

      const loans = await api
        .get(LoanAPI)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      const formattedLoans = loans.map(loan => ({
          ...loan,
          last_due_date: loan.last_due_date ? formatDate(loan.last_due_date) : null,
          loan_date: loan.loan_date ? formatDate(loan.loan_date) : null,
        }));
      console.log(formattedLoans)
      const schemeAPI =
        role_id === 1
          ? `/loan/schemeBasedLoanExpiry/${entity_id}`
          : `/loan/schemeBasedLoanExpiryByBranch/${branch_id}`;
      const schemes = await api
        .get(schemeAPI)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      const formattedScheme = schemes.map(scheme => ({
          ...scheme,
          last_due_date: scheme.last_due_date ? formatDate(scheme.last_due_date) : null,
          loan_date: scheme.loan_date ? formatDate(scheme.loan_date) : null,
        }));
      setSchemeLoans(formattedScheme);
      setAuctionLoans(formattedLoans);
    } catch (error) {
      logger.log(error);
    } finally {
      setLoading(false);
    }
  }, [branch_id, entity_id, role_id]);

  const auction_rows = auctionLoans?.map((item, index) => {
    const isFradulent = item.fradulent;

    const customerNameStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "var(--font-weight)",
    };
    return {
      id: index + 1,
      last_paid: item.last_due_date,
      loan_date: item.loan_date,
      loan_num: item.loan_num,
      cus_login_id: (
        <Tooltip
          title={Extension.languageBasedValue(
            item.fradulent_remark,
            item.fradulent_remark_tn
          )}
          style={customerNameStyle}
        >
          {item?.customer_num || "--"}
        </Tooltip>
      ),
      customer_name: (
        <Tooltip
          title={Extension.languageBasedValue(
            item.fradulent_remark,
            item.fradulent_remark_tn
          )}
          style={customerNameStyle}
        >
          {item
            ? Extension.languageBasedValue(
                item.customer_name,
                item.customer_name_tn
              )
            : "--"}
        </Tooltip>
      ),
      amount: `₹ ${Extension.formatCurrency(item.loan_amount)}`,
    };
  });

  const scheme_rows = schemeLoans?.map((item, index) => {
    const isFradulent = item.fradulent;

    const customerNameStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "var(--font-weight)",
    };
    return {
      id: index + 1,
      last_paid: item.last_due_date,
      loan_date: item.loan_date,
      loan_num: item.loan_num,
      cus_login_id: (
        <Tooltip
          title={Extension.languageBasedValue(
            item.fradulent_remark,
            item.fradulent_remark_tn
          )}
          style={customerNameStyle}
        >
          {item?.customer_num || "--"}
        </Tooltip>
      ),
      customer_name: (
        <Tooltip
          title={Extension.languageBasedValue(
            item.fradulent_remark,
            item.fradulent_remark_tn
          )}
          style={customerNameStyle}
        >
          {item
            ? Extension.languageBasedValue(
                item.customer_name,
                item.customer_name_tn
              )
            : "--"}
        </Tooltip>
      ),
      amount: `₹ ${Extension.formatCurrency(item.loan_amount)}`,
    };
  });

  useEffect(() => {
    getData();
  }, [getData]);

  const handleAuctionPage = () => {
    Navigate("/auction");
  };
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={2}
      sx={{ mb: 1 }}
      columns={{ xs: 1, sm: 1, md: 2, lg: 2 }}
    >
      <Grid item xs={1} sm={1} md={1} lg={1}>
        <Paper
          elevation={8}
          className="auction_table_container"
          // variant="outlined"
          // sx={{ height: { md: 300, lg: 400, xl: 450 } }}
          // onClick={handleAuctionPage}
        >
          <Box className="title">
            <Typography
              sx={{
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              }}
              variant="h6"
              component="p"
            >
              {t("expenditure.scheme_expiry")}
            </Typography>
          </Box>
          <GridTable
            rows={scheme_rows?.slice(0, 10)}
            columns={columns}
            isLoading={loading}
            height={"45vh"}
            hidePagination={true}
          />
        </Paper>
      </Grid>
      <Grid item xs={1} sm={1} md={1} lg={1}>
        <Paper
          elevation={8}
          className="auction_table_container"
          // variant="outlined"
          // onClick={() => handleContainerClick(index)}
        >
          <Box className="title">
            <Typography
              sx={{
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              }}
              variant="h6"
              component="p"
            >
              {t("expenditure.auction_expiry")}
            </Typography>
          </Box>
          <GridTable
            rows={auction_rows?.slice(0, 10)}
            columns={columns}
            isLoading={loading}
            height={"45vh"}
            hidePagination={true}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default AuctionTable;
