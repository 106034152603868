import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { MenuItem, Select, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
import Style from "../../../common/Styles";
import "./Setting.css";
import ThemePreference from "./ThemePreference";

const languages = [
  {
    name: "தமிழ்",
    value: "tm",
  },
  {
    name: "English",
    value: "en",
  },
];
const LanguagePreference = ({ generalref }) => {
  const localChecked = localStorage.getItem("language");

  const [isChecked, setIsChecked] = useState(localChecked);
  const [loader,setLoader]=useState(false);

  const[reload,setReload]=useState(false);

  const { t, i18n } = useTranslation("global");

  const handleChangeLanguage = useCallback(
    (value) => {
      localStorage.setItem("language", value);
      i18n.changeLanguage(value);
      setIsChecked(value);
    },
    [i18n]
  );

  useEffect(() => {
    setIsChecked(localChecked);
    handleChangeLanguage(localChecked);
  }, [handleChangeLanguage, localChecked]);


  return (
    <div>
    {loader?<div className="general-comp" ref={generalref} style={{marginTop:200,marginLeft:550}}><CircularProgress /></div>:
    <div className="general-comp" ref={generalref}>
      <div style={{display:"flex"}}>
      <h1>{t("settings.general")}</h1> 
      </div>
      <div className="language-preference">
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "start",
          }}
        >
          <Typography className="common-font-style">
            {t("settings.language_preference")}
          </Typography>
        </div>
        <div style={{ width: "70%", display: "flex", justifyContent: "start" }}>
          <Select
            value={isChecked}
            onChange={(e) => handleChangeLanguage(e.target.value)}
            sx={{
              backgroundColor: "var(--text-field)",
              minHeight: "32px",
              maxHeight: "40px",
              width: 300,
              fontFamily: "var(--font-family)",
              color: "var(--font-color)",
              fontSize: "var(--font-size)",
              "& .MuiSvgIcon-root": {
                color: "var(--font-color)", // Change the color of the dropdown icon
              },
            }}
            inputProps={{
              typeof: "search",
              style: {
                minHeight: "15px",
              },
            }}
            MenuProps={Style.MenuProps()}
          >
            {languages.length > 0 &&
              languages.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item["value"]}
                  sx={{
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                >
                  {item["name"]}
                </MenuItem>
              ))}
          </Select>
        </div>
      </div>

      {/* <div className="theme-preference">
      </div> */}
      {/* <SidePanel /> */}
      <ThemePreference loader={loader} setLoader={setLoader} />
    </div>}
    </div>
  );
};

export default LanguagePreference;
