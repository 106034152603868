import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { DatePicker } from "antd";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import api from "../../api/http-common";
import Extension from "../../common/Extension";
import Loading from "../../common/Loading";
import Logger from "../../common/Logger";
import { default as Style, default as Styles } from "../../common/Styles";
import { RadioBtnFilter } from "../../common/dateFilter/RadioBtnFilter";
import "./Dashboard.css";
import ChartComponent from "./chart/ChartComponent";
import { Container } from "./container/Container";
const logger = new Logger({ level: "log" });
export const Dashboard = () => {
  // const { userCredentails, usetUserCredentails } =
  //   useContext(UserDetailsContext);
  const { t,i18n } = useTranslation("global");
  const currentLang = i18n.language;
  const [branchList, setBrnachList] = useState([]);
  const [branch_id, setBranch_id] = useState("all");

  const [radioFilter, setRadioFilter] = useState("");
  const [containerCountData, setContainerCountData] = useState([]);
  const [creditDebitData, setCreditDebitData] = useState([]);
  const [schemeWiseIncome, setSchemeWiseIncome] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [marketValue, setMarketValue] = useState({});
  const [itemRate, setItemRate] = useState({});
  const [dur, setDuration] = useState("");
  const rupeeSymbol = "\u20B9";
  // const roleId = parseInt(userCredentails.userdata.role_id);
  // const branchId = parseInt(userCredentails.userdata.branch_id);
  // const entity_id = parseInt(userCredentails.entitydata.entity_id);

  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const branchId = parseInt(sessionStorage.getItem("branchId"));
  const entity_id = parseInt(sessionStorage.getItem("entityId"));
  const [fund, setFund] = useState([]);
  const [loading, setLoading] = useState(false); // pre loader initial false.
  const [auctionLoans, setAuctionLoans] = useState([]);

  useEffect(() => {
    if (roleId !== 1 || !roleId) {
      Extension.logout();
    }
  }, [roleId]);

  const fundData = useCallback(
    async (selectedBranchId) => {
      try {
        const fundAPI =
          selectedBranchId === "all"
            ? `/dashboard/fundTransferSum/${entity_id}`
            : `/dashboard/fundTransferSumByBranch/${selectedBranchId}`;
        logger.log("Initiate Fetch fund");
        const response = await api.get(fundAPI);
        logger.log("Fetch fund Successfully");
        setFund(response.data || []);
      } catch (err) {
        logger.log(err);
      }
    },
    [entity_id]
  );
  const fetchRate = useCallback(async () => {
    try {
      logger.log("Initiate Fetch current item rate");
      let itemRate = await api
        .get(`/loan/current-item-rate/${entity_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch current item rate Successfully");

      setItemRate(itemRate);
    } catch (error) {
      logger.log(error);
    }
  }, [entity_id]);

  const fetchData = useCallback(async () => {
    try {
      handleLoadingOpen();
      const url =
        roleId === 1 ? `branch/byEntity/${entity_id}` : `branch/${branchId}`;
      logger.log("Initiate Fetch branches");
      const response = await api
        .get(url)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch branches Successfully");
      logger.log("Initiate Fetch current market rate");
      let marketValue = await api
        .get(`/loan/CurrentMarketValue/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch current market rate Successfully");
      setMarketValue(marketValue);

      if (roleId === 1) {
        setBrnachList([
          { branch_id: "all", branch_name: "All", branch_name_tn: "அனைத்தும்" },
          ...response,
        ]);
      } else {
        setBrnachList(response);
      }
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }

    // }
  }, [branchId, entity_id, roleId]);

  const getAuctionDate = useCallback(
    async (selectedBranchId) => {
      try {
        const LoanAPI =
          selectedBranchId === "all"
            ? `loan/AuctionEligibleList/${entity_id}`
            : `/loan/AuctionEligibleListByBranch/${branch_id}`;
        logger.log("Initiate Fetch auction eligible loans");
        const loans = await api
          .get(LoanAPI)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Fetch auction eligible loans Successfully");
        setAuctionLoans(loans);
      } catch (error) {
        logger.log(error);
      }
    },
    [branch_id, entity_id]
  );

  useEffect(() => {
    fetchData();
    fetchRate();
    fundData(branch_id);
    getAuctionDate(branch_id);
  }, [branch_id, fetchData, fetchRate, fundData, getAuctionDate]);

  const handleRadioFilters = (data) => {
    setRadioFilter(data);
  };

  const today = new Date();

  const currentDay = format(today, "yyyy-MM-dd");

  const monthly = format(
    new Date(today.getFullYear(), today.getMonth(), 1),
    "yyyy-MM-dd"
  );

  const yearly = format(new Date(today.getFullYear(), 0, 1), "yyyy-MM-dd");
  const weekly = format(
    new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 1
    ),
    "yyyy-MM-dd"
  );

  const rows = auctionLoans?.map((item, index) => {
    const isFradulent = item.fradulent; // Assuming 'fradulent' is a property in your inv object

    const customerNameStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "var(--font-weight)",
      // Add any additional styles for fradulent customers
    };
    return {
      id: index + 1,
      last_paid: item.last_due_date,
      loan_date: item.loan_date,
      loan_num: item.loan_num,
      cus_login_id: (
        <Tooltip
          title={Extension.languageBasedValue(
            item.fradulent_remark,
            item.fradulent_remark_tn
          )}
          style={customerNameStyle}
        >
          {item?.customer_num || "--"}
        </Tooltip>
      ),
      customer_name: (
        <Tooltip
          title={Extension.languageBasedValue(
            item.fradulent_remark,
            item.fradulent_remark_tn
          )}
          style={customerNameStyle}
        >
          {item
            ? Extension.languageBasedValue(
                item.customer_name,
                item.customer_name_tn
              )
            : "--"}
        </Tooltip>
      ),
      amount: `₹ ${Extension.formatCurrency(item.loan_amount)}`,
    };
  });

  const CreditChartData = useCallback(
    async (duration, from_date, to_date) => {
      try {
        const fromDate = from_date; /* ? from_date : "0000-00-00"; */
        const toDate = to_date; /* ? to_date : "0000-00-00"; */ //Current date if there is no date range we can set as only by curent date on API
        let CreditAPI = "";
        CreditAPI =
          duration === "yearly"
            ? `/dashboard/CreditAndDebitYearByBranch/${entity_id}/${fromDate}/${toDate}`
            : `/dashboard/CreditAndDebitMonthByBranch/${entity_id}/${fromDate}/${toDate}`;
        logger.log("Initiate Fetch credit and debit");
        const chartRes = await api
          .get(CreditAPI)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Fetch credit and debit Successfully");
        setCreditDebitData(chartRes);
        setDuration(duration);
      } catch (error) {
        logger.log(error);
      }
    },
    [entity_id]
  );

  const SchemeChartData = useCallback(
    async (from_date, to_date) => {
      try {
        const fromDate = from_date; /* ?? "0000-00-00"; */
        const toDate = to_date; /* ?? "0000-00-00"; */ //Current date if there is no date range we can set as only by curent date on API
        //Cards Data //
        let schemeAPI = "";

        if (roleId === 2) {
          schemeAPI = `/dashboard/schemeWiseTransaction/${entity_id}/branch/${branchId}/${fromDate}/${toDate}`;
        } else if (roleId === 1) {
          schemeAPI =
            branch_id === "all"
              ? `/dashboard/schemeWiseTransaction/${entity_id}/${fromDate}/${toDate}`
              : `/dashboard/schemeWiseTransaction/${entity_id}/branch/${branch_id}/${fromDate}/${toDate}`;
        }
        logger.log("Initiate Fetch schemeWise transaction");
        const schemeChart = await api
          .get(schemeAPI)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Fetch schemeWise transaction Successfully");
        setSchemeWiseIncome(schemeChart);
      } catch (error) {
        logger.log(error);
      }
    },
    [branchId, branch_id, entity_id, roleId]
  );

  const CardsData = useCallback(
    async (from_date, to_date) => {
      try {
        const fromDate = from_date;
        const toDate = to_date;
        //Cards Data //
        let cardsAPI = "";

        if (roleId === 2) {
          cardsAPI = `/dashboard/CardsByBranch/${branchId}/${fromDate}/${toDate}`;
        } else if (roleId === 1) {
          cardsAPI =
            branch_id === "all"
              ? `/dashboard/Cards/${entity_id}/${fromDate}/${toDate}`
              : `/dashboard/CardsByBranch/${branch_id}/${fromDate}/${toDate}`;
          logger.log("Initiate Fetch cards");
          const cardRes = await api
            .get(cardsAPI)
            .then((res) => res.data)
            .catch((err) => logger.log(err));
          logger.log("Fetch cards Successfully");
          setContainerCountData(cardRes);
        }
      } catch (error) {
        logger.log(error);
      }
    },
    [branchId, branch_id, entity_id, roleId]
  );

  const FundData = useCallback(
    async (from_date, to_date) => {
      try {
        const fromDate = from_date;
        const toDate = to_date;
        let fundAPI = "";
        fundAPI =
          branch_id === "all"
            ? `/dashboard/fundTransferSum/${entity_id}/${fromDate}/${toDate}`
            : `/dashboard/fundTransferSumBranch/${branch_id}/${fromDate}/${toDate}`;
        logger.log("Initiate Fetch fund transfer sum");
        const response = await api
          .get(fundAPI)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Fetch fund transfer sum Successfully");
        setFund(response);
      } catch (error) {
        logger.log(error);
      }
    },
    [branch_id, entity_id]
  );

  const getData = useCallback(
    async (chartData) => {
      switch (chartData) {
        case "weekly":
          SchemeChartData(weekly, currentDay);
          CardsData(weekly, currentDay);
          CreditChartData("monthly", weekly, currentDay);
          FundData(weekly, currentDay);
          break;
        case "monthly":
          SchemeChartData(monthly, currentDay);
          CardsData(monthly, currentDay);
          CreditChartData(chartData, monthly, currentDay);
          FundData(monthly, currentDay);
          break;
        case "yearly":
          SchemeChartData(yearly, currentDay);
          CardsData(yearly, currentDay);
          CreditChartData(chartData, yearly, currentDay);
          FundData(yearly, currentDay);
          break;
        case "custom":
          const fromDate = Extension.formatDate(new Date(selectedDateRange[0]));
          const toDate = Extension.formatDate(new Date(selectedDateRange[1]));
          SchemeChartData(fromDate, toDate);
          CardsData(fromDate, toDate);
          CreditChartData("yearly", fromDate, toDate);
          FundData(fromDate, toDate);
          break;
        case "all":
          SchemeChartData("0000-00-00", "0000-00-00");
          CardsData("0000-00-00", "0000-00-00");
          CreditChartData("yearly", "0000-00-00", "0000-00-00");
          FundData("0000-00-00", "0000-00-00");
          break;
        // deepcode ignore DuplicateCaseBody: <please specify a reason of ignoring this>
        default:
          SchemeChartData("0000-00-00", "0000-00-00");
          CardsData("0000-00-00", "0000-00-00");
          CreditChartData("yearly", "0000-00-00", "0000-00-00");
          FundData("0000-00-00", "0000-00-00");
          break;
      }
    },
    [
      CardsData,
      CreditChartData,
      FundData,
      SchemeChartData,
      currentDay,
      monthly,
      selectedDateRange,
      weekly,
      yearly,
    ]
  );

  useEffect(() => {
    getData(radioFilter);
  }, [branch_id, getData, radioFilter]);

  // const handleCalendarClick = (event) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  // };

  useEffect(() => {
    if (radioFilter !== "custom") {
      setSelectedDateRange([]);
    }
  }, [radioFilter]);
  const handleDateChanged = (dates) => {
    if (dates) {
      setSelectedDateRange(dates.map((item) => item));
      setAnchorEl(null);
      setRadioFilter("custom");
      return;
    }
    setSelectedDateRange([]);
    setRadioFilter("all");
    setAnchorEl(null);
  };
  // pre loader open fun.
  const handleLoadingOpen = () => {
    setLoading(true);
  };

  //pre loader close fun.
  const handleLoadingClose = () => {
    setLoading(false);
  };

  return (
    <div>
      <Loading open={loading} handleClose={handleLoadingClose} />
      <Paper elevation={3} className="dashboard-wrapper" variant="outlined">
        {/* first container */}
        <Grid container rowSpacing={1} columnSpacing={3} sx={{ mb: 1 }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3.5}
            lg={2.4}
            sx={{
              display: "flex",
              justifyContent: roleId === 1 ? "start" : "center",
              alignItems: "center",
            }}
          >
            <Box className="formControl">
              {roleId === 1 ? (
                <>
                  <Form.Label className="common-font-style">
                    {t("common.select branch")}
                  </Form.Label>
                  <Form.Select
                    className="common-font-style"
                    size="lg"
                    value={branch_id}
                    onChange={(e) => {
                      setBranch_id(e.target.value);
                      fundData(e.target.value);
                    }}
                    style={{
                      borderRadius: "10px",
                      width: "250px",
                      backgroundColor: "var(--text-field)",
                      color: "var(--font-color)",
                    }}
                  >
                    {branchList.length > 0 &&
                      branchList.map((item) => {
                        return (
                          <option
                            key={item.branch_id}
                            className="common-font-style"
                            style={Style.dropDown()}
                            value={item.branch_id}
                          >
                            {Extension.languageBasedValue(
                              item.branch_name,
                              item.branch_name_tn
                            )}
                          </option>
                        );
                      })}
                  </Form.Select>
                </>
              ) : (
                <Typography
                  variant="h5"
                  sx={{
                    ...Styles.titleText(),
                  }}
                >
                  {Extension.languageBasedValue(
                    branchList.branch_name,
                    branchList.branch_name_tn
                  )}
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8.5} lg={4.8}>
            <Box className="date-filter-container">
              <RadioBtnFilter
                handleRadioChange={handleRadioFilters}
                option={radioFilter}
              />
              {/* <Tooltip title={t("dashboard.custom")}>
                <IconButton onClick={handleCalendarClick}>
                  <DateRange />
                </IconButton>
              </Tooltip>
              <Typography>
                <Typography
                  sx={{
                    ...Style.textlabel(),
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                  variant="h6"
                >
                  {t("dashboard.custom")}
                </Typography>
              </Typography> */}
              {/* <Popper
                open={Boolean(anchorEl)}
                onClose={handleCalendarClick}
                anchorEl={anchorEl}
              >
                <Paper> */}
              <DatePicker.RangePicker
                value={selectedDateRange}
                onChange={handleDateChanged}
                style={{
                  backgroundColor: "var(--text-field)",
                  width: "80%",
                  border: "none",
                  height: "40px",
                  marginTop: "10px",
                  color: "var(--font-color)",
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                  "& .ant-picker-input input::placeholder": {
                    color: "var(--font-color)",
                  },
                  "& .anticon": {
                    color: "var(--font-color)",
                  },
                }}
                // popupStyle={{ zIndex: 1500 }} // Adjust the zIndex as needed
              />
              {/* </Paper>
              </Popper> */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4.8} sx={{ width: "100%" }}>
            <Grid
              container
              columnSpacing={3.5}
              columns={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end",
              }}
            >
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                className="header-content"
                height={"100%"}
              >
                <Paper
                  className="item_rate_silver"
                  elevation={8}
                  style={{
                    // width: "110%",
                    // marginLeft: "20px",
                    display: "flex",
                    justifyContent: "space-around",
                    // height:'14vh'
                  }}
                >
                  <div className="item_names_rates">
                    <div className="man_item_names_rates">
                      <div
                        className={`item_rate_type-${currentLang}`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      > 
                        <span className="silver">
                          {t("dashboard.shop_rate")}
                        </span>
                        <div>
                          <span className="silvervalue" style={{ margin: "0 5px" }}>:&nbsp;</span>
                          <CountUp
                            style={{ marginRight: "20px" }}
                            className="silvervalue"
                            start={0}
                            end={itemRate ? itemRate.silver_rate : 0}
                            duration={2.5}
                            decimals={2}
                            prefix={`${rupeeSymbol} `}
                            useIndianSeparators={true}
                          />
                        </div>
                      </div>

                      <div
                        className={`item_rate_type-${currentLang}`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span className="silver">
                          {t(`dashboard.market_rate`)}
                        </span>
                        <div>
                          <span className="silvervalue" style={{ margin: "0 5px" }}>:&nbsp;</span>
                          <CountUp
                            style={{ marginRight: "20px" }}
                            start={0}
                            end={marketValue ? marketValue.Silver : 0}
                            duration={2.5}
                            className="silvervalue"
                            decimals={2}
                            prefix={`${rupeeSymbol} `}
                            useIndianSeparators={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="silver-text"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "end",
                        color: "#848482",
                      }}
                    >
                      <Typography
                        component={"p"}
                        variant="p"
                        sx={{ fontSize: 20 }}
                        className="vertical-text"
                      >
                        <b>{t("dashboard.silver")}</b>
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} className="header-content">
                <Paper
                  className="item_rate_gold"
                  elevation={8}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "end",
                    // width: "110%",
                    // marginLeft: "80px",
                  }}
                >
                  <div className="item_names_rates"  >
                    <div className="man_item_names_rates" style={{...(currentLang === 'en' && {width:'270px'  })}}>
                      <div
                        className={`item_rate_type-${currentLang}`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          ...(currentLang === 'tm' && {  })
                        }}
                      >
                        <span className="gold" >
                          {t("dashboard.normal_gold")}
                        </span>
                        <div className="goldvalue">
                          <span  >:&nbsp;</span>
                          <CountUp
                            start={0}
                            end={itemRate ? itemRate.gold_rate : 0}
                            duration={2.5}
                            decimals={2}
                            prefix={`${rupeeSymbol} `}
                            useIndianSeparators={true}
                          />
                        </div>
                      </div>
                      <div
                        className={`item_rate_type-${currentLang}`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          ...(currentLang === 'tm' && {})
                        }}
                      >
                        {/* <span className="silver">
                        {t("dashboard.shopRate")}:&nbsp;
                      </span> */}
                        <span className="gold">{t("dashboard.hall_mark")}</span>
                        <div className="goldvalue">
                          <span >:&nbsp;</span>
                          <CountUp
                            start={0}
                            end={itemRate ? itemRate.hallmark_gold_rate : 0}
                            duration={2.5}
                            decimals={2}
                            prefix={`${rupeeSymbol} `}
                            useIndianSeparators={true}
                          />
                        </div>
                      </div>
                      <div
                        className={`item_rate_type-${currentLang}`}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          ...(currentLang === 'tm' && { })
                        }}
                      >
                        <span className="gold" >
                          {t("dashboard.market_rate")}
                        </span>
                        <div className="goldvalue">
                          <span   style={{ }}>:&nbsp;</span>
                          <CountUp
                            style={{  }}
                            start={0}
                            end={marketValue ? marketValue.Gold : 0}
                            duration={2.5}
                            decimals={2}
                            prefix={`${rupeeSymbol} `}
                            useIndianSeparators={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="silver-text"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "end",
                  
                        color: "#A38A00",
                      }}
                    > 
                      <Typography
                        component={"p"}
                        variant="p"
                        sx={{ fontSize: 20 ,color: "#A38A00",
                          ...(currentLang === 'tm' && {height:'100px',marginLeft:'12px'})
                         }}
                        className="vertical-text"
                      >
                        <b>{t("dashboard.gold")}</b>
                      </Typography>
                     </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* second container  */}
        <Container countData={containerCountData} rows={rows} />
        {/* chart container  */}
        <Box sx={{ mt: 2 }}>
          <ChartComponent
            schemeWiseIncome={schemeWiseIncome}
            creditDebitIncome={creditDebitData}
            duration={dur}
            fund={fund}
          />
        </Box>
      </Paper>
    </div>
  );
};
