import { ReceiptLongOutlined } from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, Grid, Menu, TablePagination, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import api from "../../api/http-common";
import { AppData } from "../../common/AppData";
import CustomCommonButton from "../../common/Components/CustomCommonButton";
import CustomDateRangePicker from "../../common/Components/CustomDateRangePicker";
import Dropdown from "../../common/Components/Dropdown";
import ImageProvision from "../../common/Components/ImageProvision";
import Textfield from "../../common/Components/TextField";
import Extension from "../../common/Extension";
import Loading from "../../common/Loading";
import Snackbar from "../../common/SnackBar";
import Style from "../../common/Styles";
import TransactionDetails from "../Transaction/TransactionDetails";
import "./CloseInventory.css";
import Logger from "../../common/Logger";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `var(--app-bar-color)`,
    color: "var(--header-font-color)",
    width: "40px",
    // fontSize: 1,
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    border: 0,
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
    textAlign: "center",
    color: "var(--font-color)",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  // "&:nth-of-type(even)": {
  //   backgroundColor: "var(--table-even-row)",
  // },
  // "&:nth-of-type(odd)": {
  //   backgroundColor: "var(--table-odd-row)",
  // },
  "&:first-child th": {
    border: 0,
    padding: 6,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "var(--table-hover)",
  },
}));

function CollapsibleTable() {
  const { t,i18n } = useTranslation("global");
  const currentLang = i18n.language;
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const entity_Id = parseInt(sessionStorage.getItem("entityId"));
  const branch_id = sessionStorage.getItem("branchId");
  const [rowData, setRowData] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [close_the_loan, setClose_the_loan] = useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [showEditProcess, setShowEditProcess] = useState(false);
  const [contextMenu, setContextMenu] = React.useState(null);
  const [rowContextMenu, setRowContextMenu] = React.useState(null);
  const logger = new Logger({ level: "log" });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };
  const Columns = AppData.closeInventory.map((column) => ({
    ...column,
    headerName: t(`closeInventory.${column.field}`),
  }));

  const ColumnsTable = AppData.closeInventoryExpand.map((column) => ({
    ...column,
    headerName: t(`closeInventoryExpand.${column.field}`),
    valueFormatter: (params) => {
      const goldCategory = params.data.gold_category;
      return column.field === "gold_category" ? goldCategory : "Silver";
    },
  }));

  const handleDateChange = (values) => {
    if (values && values.length > 0) {
      setDates(values.map((item) => dayjs(item)));
    } else {
      setDates([]);
    }
  };
  const handlebranchFilter = (branch) => {
    setSelectedBranch(branch);
  };

  const filterRow = rowData.filter((item) => {
    const itemDate = new Date(item.created_on);
    const fromDate = new Date(dates[0]);
    const toDate = new Date(dates[1]);

    const normalizedItemDate = new Date(
      itemDate.getFullYear(),
      itemDate.getMonth(),
      itemDate.getDate()
    );

    const normalizedFromDate = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate()
    );

    const normalizedToDate = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate()
    );
    const branchMatch =
      selectedBranch === "All" ||
      selectedBranch.toLowerCase() === item.branch.branch_name.toLowerCase();

    const searchQueryMatch =
      searchQuery === "" ||
      item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.customer_name_tn.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.loan_number
        .toLowerCase()
        .toString()
        .includes(searchQuery.toLowerCase().toString()) ||
      item.cus_login_id
        .toLowerCase()
        .toString()
        .includes(searchQuery.toLowerCase().toString());

    const filterDate =
      dates.length === 0 ||
      (normalizedItemDate >= normalizedFromDate &&
        normalizedItemDate <= normalizedToDate);
    return branchMatch && searchQueryMatch && filterDate;
  });
  const rows = filterRow.map((item, index) => {
    const isFradulent = item.customer.fradulent;
    const rowStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "var(--font-weight)",
    };
    const grossWeight = item.loan_details.map((data) =>
      parseInt(data.gross_weight, 10)
    );
    const totalGrossWeight = grossWeight.reduce((a, b) => a + b, 0);

    return {
      ...item,
      id: index + 1,
      loan_date: Extension.formatDateDMY(new Date(item.created_on)),
      loan_number: item.loan_number,
      cus_login_id: (
        <Tooltip
          style={rowStyle}
          title={Extension.languageBasedValue(
            item.customer.fradulent_remark,
            item.customer.fradulent_remark_tn
          )}
        >
          {item.customer.cus_login_id}
        </Tooltip>
      ),
      customer_name: (
        <Tooltip
          style={rowStyle}
          title={Extension.languageBasedValue(
            item.customer.fradulent_remark,
            item.customer.fradulent_remark_tn
          )}
        >
          {Extension.languageBasedValue(
            item.customer.first_name,
            item.customer.first_name_tn
          )}{" "}
          {Extension.languageBasedValue(
            item.customer.last_name,
            item.customer.last_name_tn
          )}
        </Tooltip>
      ),
      scheme_name: Extension.languageBasedValue(
        item.scheme.scheme_name,
        item.schemescheme_name_tn
      ),
      loan_amount: `₹ ${Extension.formatCurrency(item.loan_amount)}`,
      Status: Extension.languageBasedValue(item.Status),
      grossWeight: totalGrossWeight.toFixed(3),
      total_net_weight: item.total_net_weight.toFixed(3),
    };
  });
  const renderRows = rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const [openRows, setOpenRows] = useState(
    Array(renderRows.length).fill(false)
  );

  const handleRowToggle = (index) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };
  const getData = async () => {
    try {
      const branchAPI =
        roleId === 1
          ? `branch/byEntity/${entity_Id}`
          : roleId === 2
          ? `branch/${branch_id}`
          : "";
          logger.log("Initiate Fetch branch");
          const branchRes = await api.get(branchAPI);
          logger.log("Fetch branch Successfully");
      setBranches(branchRes.data);
    } catch (error) {
      Snackbar.toastMsg("error", error.message);
    }
  };
  const expandAPI = async () => {
    try {
      let response;
      let modifiedData;

      if (roleId === 1) {
        logger.log("Initiate Fetch inventory loan");
        response = await api.get(`/loan/InventoryByLoan/${entity_Id}`);
        logger.log("Fetch  inventory loan Successfully");
      } else if (roleId === 2) {
        logger.log("Initiate Fetch inventory loan by branch");
        response = await api.get(`/loan/InventoryByLoanBranch/${branch_id}`);
        logger.log("Fetch inventory loan by branch Successfully");
      }

      console.log(response.data)
      const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-'); // Assumes date format is yyyy-mm-dd
        return `${day}-${month}-${year}`;
      };
      modifiedData = response.data.map((item, index) => ({
        ...item,
        created_on: item.created_on ? formatDate(item.created_on) : null,
      }));
      setRowData(modifiedData);
    } catch (err) {
      logger.log(err);
    } finally {
      handleLoadingClose();
    }
  };
  useEffect(() => {
    expandAPI();
    getData();
  }, []);

  const handleContextMenu = (event, row) => {
    event.preventDefault();
    setSelectedRowData(row);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleRowContextMenu = (event, row) => {
    // if (!image) {
    //   return;
    // }
    event.preventDefault();
    setSelectedRowData(row);
    setRowContextMenu(
      rowContextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleRowClose = () => {
    setRowContextMenu(null);
  };

  const handleLoanClosure = () => {
    setShowEditProcess(true);
    setClose_the_loan(true);
    setContextMenu(null);
  };

  return (
    <>
      {showEditProcess ? (
        <TransactionDetails
          data={selectedRowData}
          showTransactionDetails={setShowEditProcess}
          loanClosing={true}
          close_the_loan={close_the_loan}
          handleLoanClose={(value) => setClose_the_loan(value)}
        />
      ) : (
        <Paper className="commonpaper">
          <Loading open={loading} handleClose={handleLoadingClose} />
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            columns={{ xs: 1, sm: 2, md: 2, lg: 4 }}
            sx={{
              mb: 2,
            }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1} style={{...(currentLang === 'tm' && {marginTop:'27px' })}}>
              {roleId === 1 ? (
                <Dropdown
                  firstOption={t("common.all")}
                  label={t("common.select branch")}
                  search={true}
                  value={selectedBranch}
                  onChange={(e) => handlebranchFilter(e.target.value)}
                  array={branches}
                  menuValue={"branch_name"}
                  display={Extension.languageBasedValue(
                    "branch_name",
                    ".branch_name_tn"
                  )}
                />
              ) : roleId === 2 ? (
                <Typography
                  variant="h5"
                  sx={{
                    ...Style.titleText(),
                    mt: 2,
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                >
                  {Extension.languageBasedValue(
                   branches.branch_name,
                   branches.branch_name_tn
                  )}
                </Typography>
              ) : null}
            </Grid>

            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Textfield
                type={"text"}
                label={t("loan process.search things")}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} style={{...(currentLang === 'tm' && {marginTop:'27px' })}}>
              <CustomDateRangePicker
                label={t("transaction.select range")}
                values={dates}
                onChange={handleDateChange}
              />
            </Grid>
          </Grid>
          <TableContainer
            sx={{
              height: "65vh",
            }}
            variant="elevation"
            isLoading={loading}
          >
            <Table stickyHeader aria-label="table">
              <TableHead>
                <StyledTableRow>
                  {Columns.map((column) => (
                    <StyledTableCell key={column.field}>
                      {column.headerName}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {renderRows.map((row, index) => (
                  // <React.Fragment >
                  <>
                    <StyledTableRow
                      key={row.id}
                      sx={{
                        "& > *": { borderBottom: "unset" },
                        backgroundColor:
                          index % 2 === 0
                            ? "var(--table-even-row)"
                            : "var(--table-odd-row)",
                      }}
                      onClick={() => handleRowToggle(index)}
                      onContextMenu={(e) => handleContextMenu(e, row)}
                      style={{ cursor: "context-menu" }}
                    >
                      <StyledTableCell
                        sx={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                        align="right"
                      >
                        <span>{index + 1}</span>
                        <div>
                          {openRows[index] ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}{" "}
                          &nbsp;
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.created_on}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.loan_number}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.cus_login_id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.customer_name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.scheme.scheme_name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.loan_amount}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.grossWeight}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.total_net_weight}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell
                        style={{
                          paddingBottom: 0,
                          paddingTop: 0,
                          // backgroundColor: "#ffffff",
                        }}
                        colSpan={9}
                      >
                        <Collapse
                          in={openRows[index]}
                          timeout="auto"
                          unmountOnExit
                          // style={{ backgroundColor: "#ffffff" }}
                        >
                          <Box
                            sx={{
                              m: 1,
                              backgroundColor: "var(--table-even-row)",
                            }}
                          >
                            <Table
                              size="small"
                              aria-label="purchases"
                              sx={{ mb: 2 }}
                            >
                              <TableHead sx={{ height: "40px" }}>
                                <StyledTableRow>
                                  {ColumnsTable.map((column) => (
                                    <StyledTableCell
                                      key={column.field}
                                      sx={{
                                        width:
                                          (column[0] || column[1]) && "30px",
                                      }}
                                    >
                                      {column.headerName}
                                    </StyledTableCell>
                                  ))}
                                </StyledTableRow>
                              </TableHead>
                              <TableBody sx={{ height: "90px" }}>
                                {row.loan_details.map((detail, detailIndex) => (
                                  <StyledTableRow
                                    key={detail.item_id}
                                    sx={{
                                      backgroundColor:
                                        detailIndex % 2 === 0
                                          ? "var(--table-odd-row)"
                                          : "",
                                    }}
                                  >
                                    <StyledTableCell align="center">
                                      {detail.gold_category
                                        ? detail.gold_category
                                        : "Silver"}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {detail.item_name}
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                      {detail.qty}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {detail.no_of_stone}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {detail.gross_weight.toFixed(3)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {detail.net_weight.toFixed(3)}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      <>
                                        {detail?.image ? (
                                          <Avatar
                                            src={detail?.image}
                                            variant="rounded"
                                            onContextMenu={(e) =>
                                              handleRowContextMenu(e, detail)
                                            }
                                            alt=""
                                            sx={{
                                              height: 50,
                                              width: 50,
                                              cursor: "pointer",
                                              margin: "0 auto",
                                            }}
                                            // onClick={() =>
                                            //   document
                                            //     .getElementById("image-upload")
                                            //     .click()
                                            // }
                                          />
                                        ) : (
                                          "--"
                                        )}
                                        <ImageProvision
                                          image={selectedRowData?.image}
                                          setContextMenu={setRowContextMenu}
                                          handleClose={handleRowClose}
                                          contextMenu={rowContextMenu}
                                        />
                                      </>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </StyledTableCell>
                    </StyledTableRow>
                  </>
                  //  </React.Fragment>
                ))}
                {!loading && renderRows.length <= 0 && (
                  <StyledTableRow sx={{ lineHeight: 24 }}>
                    <StyledTableCell
                      sx={{ lineHeight: 24 }}
                      colSpan={20}
                      rowSpan={10}
                    >
                      <Box sx={{ mt: 25 }}>
                        <Typography
                          sx={{
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--font-size)",
                          }}
                          component="p"
                          variant="h6"
                        >
                          {t("common.there_is_no_data")}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            sx={{
              "& .MuiMenu-list": {
                p: 0,
              },
            }}
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
          >
            <div className="context-menu">
              <div>
                <span className="common-font-style">
                  {" "}
                  {t(`common.Loan Number`)}:
                </span>
                <Typography
                  variant="h4"
                  sx={{
                    ...Style.titleText(),
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                >
                  {selectedRowData?.loan_number}
                </Typography>
              </div>
              <CustomCommonButton
                icon={<ReceiptLongOutlined />}
                text={t(`common.CloseLoan`)}
                onClick={handleLoanClosure}
              />
            </div>
          </Menu>
          <div style={{ marginBottom: "0rem", marginTop: "10px" }}>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              label={`Rows: ${rowsPerPage}`}
              sx={{
                "& .MuiTablePagination-displayedRows": {
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                  color: "var(--font-color)",
                  margin: "auto",
                },
                "& .MuiTablePagination-actions": {
                  margin: "auto",
                  color: "var(--font-color)",
                },
              }}
            />
          </div>
        </Paper>
      )}
    </>
  );
}

export default CollapsibleTable;
