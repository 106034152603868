import { Download, MoreVert, ReceiptLongOutlined } from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../api/http-common";
import { AppData } from "../common/AppData";
import BulkUploadLoan from "../common/Components/BulkUploadLoan";
import CustomCommonButton from "../common/Components/CustomCommonButton";
import CustomDateRangePicker from "../common/Components/CustomDateRangePicker";
import Dropdown from "../common/Components/Dropdown";
import Textfield from "../common/Components/TextField";
import Extension from "../common/Extension";
import { CustomNoRowsOverlay } from "../common/GridTable";
import Loading from "../common/Loading";
import Logger from "../common/Logger";
import { LoanCreation } from "../common/ReceiptGenerate/LoanCreation";
import { LoanCreationTM } from "../common/ReceiptGenerate/LoanCreationTM";
import SnackBar from "../common/SnackBar";
import { default as Style, default as Styles } from "../common/Styles";
import { NotificationDetailsContext } from "../context/NotificationContext";
import TransactionDetails from "./Transaction/TransactionDetails";
import { EditLoanProcess } from "./loanProcess/EditLoanProcess";

const STATUS = [
  { name_tn: "அனுமதிக்கப்பட்டது", name: "Approved" },
  { name_tn: "நிலுவையில் உள்ளது", name: "Pending" },
  { name_tn: "மூடப்பட்டது", name: "Closed" },
  { name_tn: "நிராகரிக்கப்பட்டது", name: "Rejected" },
  { name_tn: "அகற்றப்பட்டது", name: "Disposed" },
];

const logger = new Logger({ level: "log" });
const LoanProcess = () => {
  const { t,i18n } = useTranslation("global");
  const currentLang = i18n.language;
  const language = localStorage.getItem("language");
  const { notifyPendingList, setNotifyPendingList } = useContext(
    NotificationDetailsContext
  );
  const Columns = AppData.loanprocess.map((column) => ({
    ...column,
    headerName: t(`loan process.${column.field}`),
  }));
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const branchId = sessionStorage.getItem("branchId");
  const branch_id = sessionStorage.getItem("branchId");
  const entity_Id = sessionStorage.getItem("entityId");
  const [showEditProcess, setShowEditProcess] = useState(null);
  const [loadId, setLoanId] = useState(null);
  const [state, setState] = useState(null);
  const [loanDetails, setLoanDetails] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [branches, setBranches] = useState([]);
  const [dates, setDates] = useState([]);
  const [status, setStatus] = useState(notifyPendingList ? "Pending" : "All");
  const [loading, setLoading] = useState(true);
  const [transData, setTransData] = useState("");
  const [transactionDetails, setTransactionDetails] = useState(false);
  const [close_the_loan, setClose_the_loan] = useState(false);
  const [bulkPopUp, setBulkPopUp] = useState(false);

  console.log(loanDetails)
  //get branches
  const getData = async () => {
    try {
      handleLoadingOpen();
      const branchAPI =
        roleId === 1
          ? `branch/byEntity/${entity_Id}`
          : roleId === 2
          ? `branch/${branch_id}`
          : "";
      logger.log("Initiate Fetch branch");
      const branchRes = await api.get(branchAPI);
      logger.log("Fetch branch Successfully");
      setBranches(branchRes.data);
    } catch (error) {
      SnackBar.toastMsg("error", error.message);
    } finally {
      handleLoadingClose();
    }
  };
  useEffect(() => {
    setShowEditProcess(false);
    fetchLoanDetails();
    getData();
  }, []);

  const handleDownload = async (loanId) => {
    try {
      handleLoadingOpen();
      logger.log("fetch loan receipt start in loan process");
      const receiptDetail = await api
        .get(`loan/LoanCreationReceipt/${loanId}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Receipt fetched successfully");
      logger.log("fetch entity started");
      const entity = await api
        .get(`/entity/${entity_Id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Entity data fetch successfully");
      logger.log("fetch transaction start");
      const transaction = await api
        .get(`loan/TransactionsByLoan/${loanId}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("transaction fetch successfully");
      const result = {
        ...receiptDetail,
        ...entity,
        receipt_no:
          transaction[0] && transaction[0].trans_seq_num
            ? transaction[0].trans_seq_num
            : "",
      };
      if (language === "tm") {
        // file deepcode ignore DOMXSS: it need to be show the HTML code for print the receipt
        LoanCreationTM(result, true);
      } else {
        LoanCreation(result, true);
      }
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };
  const [loanNo, setloanNo] = useState([]);
  //get load detail api func.
  const fetchLoanDetails = async () => {
    try {
      const url =
        roleId === 1
          ? `loan/loantransferfilter/${entity_Id}`
          : `loan/loantransferfilterByBranch/${branchId}`;
          logger.log("Initiate Fetch loan transfer");
      const response = await api
        .get(url)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch loan transfer Successfully");
      if (response.length <= 0) {
        setLoanDetails([]);
        return;
      }
      const loanDetail = response;
      setLoanDetails(loanDetail);
      const loanNo = loanDetail.map((item) => item.loan_number);
      console.log("@@@@@@@@@@@@")
      const loanNofilter = loanDetails.map((item) => item.loan_number);
      const commonLoanNumbers = loanNo.filter((number) =>
        loanNofilter.includes(number)
      );
      console.log(commonLoanNumbers)
      setloanNo(commonLoanNumbers);
    } catch (error) {
      logger.log(error);
      SnackBar("error", error.message);
    } finally {
      handleLoadingClose();
    }
  };

  const handlebranchFilter = (branch) => {
    setSelectedBranch(branch);
  };

  const filterData = loanDetails.filter((item) => {
    const itemDate = new Date(item.created_on);
    const fromDate = new Date(dates[0]);
    const toDate = new Date(dates[1]);

    const normalizedItemDate = new Date(
      itemDate.getFullYear(),
      itemDate.getMonth(),
      itemDate.getDate()
    );

    const normalizedFromDate = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate()
    );

    const normalizedToDate = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate()
    );
    return (
      (selectedBranch === "" ||
        selectedBranch === "All" ||
        item.branch_id === selectedBranch) &&
      (status === "All" || item.status === status) &&
      (dates.length === 0 ||
        (normalizedItemDate >= normalizedFromDate &&
          normalizedItemDate <= normalizedToDate)) &&
      (searchQuery === "" ||
        item.first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.first_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.last_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.last_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.mobile_number
          .toString()
          .includes(searchQuery.toString()) ||
        item.cus_login_id
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.loan_number
          .toString()
          .toLowerCase()
          .includes(searchQuery.toString().toLowerCase()))
    );
  });

  //edit & approved component call
  const handleLoanProcess = (state, loan_id) => {
    setState(state);
    setLoanId(loan_id);
    setShowEditProcess(true);
  };

  const handleEnterTransaction = (data) => {
    setTransData(data);
    setTransactionDetails(true);
    // setOpenPopover(false);
    setClose_the_loan(false);
  };

  const handleLoanClose = (data) => {
    setTransData(data);
    setTransactionDetails(true);
    handleClose();
    // setOpenPopover(false);
    setClose_the_loan(true);
  };

  const [selectedRowItem, setSelectedRowItem] = useState({});

  const rows = filterData.map((item, index) => {
    const isFradulent = item.fradulent;

    const rowStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "var(--font-weight)",
    };
    return {
      ...item,
      status: (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className={`status ${item.status}`}>
            {Extension.languageBasedValue(item.status, item.status_tn)}
          </span>
        </div>
      ),
      id: index + 1,

      cus_login_id: (
        <Tooltip
          title={Extension.languageBasedValue(
            item.fradulent_remark,
            item.fradulent_remark_tn
          )}
          style={rowStyle}
        >
          {item.cus_login_id}
        </Tooltip>
      ),

      customer_name: (
        <Tooltip
          title={Extension.languageBasedValue(
            item.fradulent_remark,
            item.fradulent_remark_tn
          )}
          style={rowStyle}
        >
          {Extension.languageBasedValue(
            item.first_name,
            item.first_name_tn
          )}{" "}
          {Extension.languageBasedValue(
            item.last_name,
            item.last_name_tn
          )}
        </Tooltip>
      ),
      scheme_name: Extension.languageBasedValue(
        item.scheme_name,
        item.scheme_name_tn
      ),
      loan_date: Extension.formatDateDMY(new Date(item.created_on)),
      loan_number: item.loan_number,
      amount: `₹ ${Extension.formatCurrency(item.loan_amount)}`,
      created_by: ((Extension.languageBasedValue(item.created_by,item.created_by_tn) ===""||
                  Extension.languageBasedValue(item.created_by,item.created_by_tn) ===null))&& Extension.languageBasedValue(item.status!="Approved")?
                  "-":Extension.languageBasedValue(item.created_by,item.created_by_tn),
      approved_by: (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
      {
        (Extension.languageBasedValue(item.approved_by, item.approved_by_tn) === "" ||
        Extension.languageBasedValue(item.approved_by, item.approved_by_tn) === null) ? (
          Extension.languageBasedValue(item.status) === "Approved" ? (
            "-"
          ) : (
            <span className={`status ${item.status}`}>
              {t(`loan process.yet_to_process`)}
            </span>
          )
        ) : (
          Extension.languageBasedValue(item.approved_by, item.approved_by_tn)
        )
      }


        </div>
      ),
      moreinfo: (
        <Box>
          <MoreVert
            sx={{ cursor: "pointer" }}
            onClick={() => handleLoanProcess("edit", item.loan_id)}
          />
          <>
            <Tooltip title={t("summary.download")}>
              <IconButton
                onClick={() => handleDownload(item.loan_id)}
                sx={{ ml: 1 }}
                disabled={
                  item.status === "Rejected" || item.status === "Pending"
                    ? true
                    : false
                }
              >
                <Download
                  sx={{
                    color:
                      item.status === "Rejected" || item.status === "Pending"
                        ? ""
                        : "var(--font-color)",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("transaction details.loan_close")}>
              <IconButton
                onClick={() => handleEnterTransaction(item)}
                sx={{ ml: 1 }}
                disabled={
                  item.status === "Rejected" || item.status === "Pending"
                    ? true
                    : false
                }
              >
                <ReceiptLongOutlined
                  sx={{
                    color:
                      item.status === "Rejected" || item.status === "Pending"
                        ? ""
                        : "var(--font-color)",
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        </Box>
      ),
    };
  });

  const handleDateChange = (values) => {
    if (values && values.length > 0) {
      setDates(values.map((item) => dayjs(item)));
    } else {
      setDates([]);
    }
  };

  const [contextMenu, setContextMenu] = React.useState(null);

  const handleRowContextMenu = (event, index) => {
    const rowData = rows.find((row) => parseInt(row.id) === parseInt(index));
    if (
      rowData.status_tn !== "அனுமதிக்கப்பட்டது" &&
      rowData.status_tn !== "அங்கீகரிக்கப்பட்டது"
    ) {
      return;
    }
    event.preventDefault();
    setSelectedRowItem(rowData);
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };

  const handleOpenBulkPopUp = () => {
    setBulkPopUp(true);
  };
  const handleCloseBulkPopUp = () => {
    setBulkPopUp(false);
  };
  return (
    <>
      {showEditProcess ? (
        <EditLoanProcess
          state={state}
          loanId={loadId}
          setShowEditProcess={setShowEditProcess}
          fetchLoanDetails={fetchLoanDetails}
        />
      ) : transactionDetails ? (
        <TransactionDetails
          data={transData}
          showTransactionDetails={setTransactionDetails}
          close_the_loan={close_the_loan}
          handleLoanClose={(value) => setClose_the_loan(value)}
          fetchLoanDetails={fetchLoanDetails}
        />
      ) : (
        <div>
          <Loading open={loading} handleClose={handleLoadingClose} />
          <Paper className="commonpaper">
            <div id="customContextMenu">
              <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                sx={{
                  "& .MuiMenu-list": {
                    p: 0,
                  },
                }}
                anchorPosition={
                  contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                }
              >
                <div className="context-menu">
                  <div>
                    <span className="common-font-style">
                      {t(`common.Loan Number`)}:
                    </span>
                    <Typography
                      variant="h4"
                      sx={{
                        ...Style.titleText(),
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                      }}
                    >
                      {selectedRowItem?.loan_number}
                    </Typography>
                  </div>
                  <CustomCommonButton
                    icon={<ReceiptLongOutlined />}
                    text={t(`common.CloseLoan`)}
                    onClick={() => handleLoanClose(selectedRowItem)}
                  />
                </div>
              </Menu>
            </div>
            <Grid
              container
              rowSpacing={2}
              columnSpacing={2}
              columns={{ xs: 1, sm: 2, md: 2, lg: 4 }}
              sx={{
                mb: 2,
              }}
            >
              <Grid item xs={1} sm={1} md={1} lg={1} style={{...(currentLang === 'tm' && {marginTop:'27px' })}}>
                {roleId === 1 ? (
                  <Dropdown
                    firstOption={t("common.all")}
                    label={t("common.select branch")}
                    search={true}
                    value={selectedBranch}
                    onChange={(e) => handlebranchFilter(e.target.value)}
                    array={branches}
                    menuValue={"branch_id"}
                    display={Extension.languageBasedValue(
                      "branch_name",
                      "branch_name_tn"
                    )}
                  />
                ) : roleId === 2 ? (
                  <Typography
                    variant="h5"
                    sx={{
                      ...Styles.titleText(),
                      fontFamily: "var(--font-family)",
                      mt: 2,
                    }}
                  >
                    {Extension.languageBasedValue(
                      branches.branch_name,
                      branches.branch_name_tn
                    )}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Textfield
                  type={"text"}
                  label={t("loan process.search things")}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} style={{...(currentLang === 'tm' && {marginTop:'27px' })}}>
                <Dropdown
                  firstOption={t("common.all")}
                  search={true}
                  label={t("loan process.search status")}
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setNotifyPendingList(null);
                  }}
                  array={STATUS}
                  Search={true}
                  menuValue={"name"}
                  display={Extension.languageBasedValue("name", "name_tn")}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} style={{...(currentLang === 'tm' && {marginTop:'27px' })}}>
                <CustomDateRangePicker
                  label={t("transaction.select range")}
                  values={dates}
                  onChange={handleDateChange}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={5}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  flexDirection: "row",
                }}
              >
                <CustomCommonButton
                  icon={<UploadIcon />}
                  text={t("common.bulkUpload")}
                  onClick={handleOpenBulkPopUp}
                />
              </Grid>
            </Grid>
            <DataGrid
              rows={rows}
              columns={Columns}
              slots={{
                noRowsOverlay: () => CustomNoRowsOverlay(loading, rows),
              }}
              sx={{
                height: "72vh",
                width: "100%",
                overflowY: "auto",
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
                "&, [class^=MuiDataGrid]": { border: "none" },
                "& .grid-header": {
                  backgroundColor: `var(--app-bar-color)`,
                  color: "var(--header-font-color)",
                },
                "& .grid-cell-left": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  border: "none",
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  position: "relative",
                },
                ".MuiDataGrid-row:nth-of-type(even)": {
                  backgroundColor: "var(--table-even-row)",
                },
                ".MuiDataGrid-row:nth-of-type(odd)": {
                  backgroundColor: "var(--table-odd-row)",
                },
                "& .MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "var(--font-color)",
                },
                "& .MuiTablePagination-displayedRows": {
                  margin: "auto",
                  color: "var(--font-color)",
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                },
                "& .MuiTablePagination-actions": {
                  margin: "auto",
                  color: "var(--font-color)",
                },
              }}
              componentsProps={{
                row: {
                  onContextMenu: (event) => {
                    const row = event.currentTarget.getAttribute("data-id");
                    handleRowContextMenu(event, row);
                  },
                },
              }}
              disableRowSelection
              disableRowSelectionOnClick
              disableColumnMenu
              pageSizeOptions={100}
              columnHeaderHeight={30}
            ></DataGrid>
          </Paper>
          <BulkUploadLoan
            open={bulkPopUp}
            onClose={handleCloseBulkPopUp}
            fetchData={fetchLoanDetails}
            branches={branches}
          />
        </div>
      )}
    </>
  );
};

export default LoanProcess;
