import { KeyboardBackspace } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { ToWords } from "to-words";
import translate from "translate";
import api from "../../api/http-common";
import { AppData } from "../../common/AppData";
import { rupeeSymbol } from "../../common/Components/IconsAndComponents";
import Extension from "../../common/Extension";
import GridTable from "../../common/GridTable";
import Loading from "../../common/Loading";
import Logger from "../../common/Logger";
import PrintButton from "../../common/ReceiptGenerate/PrintButton";
import SMS_API_FUNCTION from "../../common/SMS_API_FUNCTION";
import SnackBar from "../../common/SnackBar";
import Styles from "../../common/Styles";
import { CommonTextField } from "../../ui/CommonTextField";
import { SelectDropDown } from "../../ui/SelectDropDown";

const TransactionDetails = (props) => {
  const {
    data,
    showTransactionDetails,
    close_the_loan,
    handleLoanClose,
    fetchLoanDetails,
  } = props; // get the props values
  const { t } = useTranslation("global"); //Call the translator
  const [loading, setLoading] = useState(true); // pre loader initial false.
  const [loanData, setLoanData] = useState([]); // get & set loan res based on the loan Id and Initial empty array
  const { register, setValue, getValues, control, handleSubmit } = useForm(); // call the use form hook for input field value handling
  const [interseCalut, setInterestCalut] = useState(false); // Initial the second input fields columns are disable and once click that state is true that input fields are visible
  const [loanClose, setLoanClose] = useState(close_the_loan ?? false); // that is the loan close interest calculation state handle initial is false
  const logger = new Logger({ level: "log" });
  const [inputFields2, setInputFields2] = useState(
    AppData.receiptHeaderInputFields2
  ); // get the second layer input field from app data page
  const [headerTxt, setHeaderTxt] = useState(
    t("transaction details.receipt_details")
  );
  const [rows, setRows] = useState([]);
  const language = localStorage.getItem("language");
  const [repaymentValue, setRepaymentValue] = useState(0);
  const [postalCharge, setPostalCharge] = useState(0);
  const [addPostalCharge, setAddPostalCharge] = useState(false);
  const [initialInterestAmount, setInitailInterestAmount] = useState(0);
  const [SMSSend, setSMSSend] = useState(false);
  const [temBalancePA, setTemBalancePA] = useState({
    amount: 0,
    additionalInterest: false,
  }); //additional interest method is true update the loan current balance PA

  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const user_id = parseInt(sessionStorage.getItem("userId"));
  // const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const [branch_id, setBranch_id] = useState("");
  const [cutmInterest, setCutmInterest] = useState(false); // custom interest rate calculation method initial disable
  const [entityDetail, setEntityDetail] = useState({}); // Entity detail store set
  const entityId = sessionStorage.getItem("entityId"); // That entity Id get from session storage
  const [additionalInterestCul, setAdditionalInterestCul] = useState(false); // Principal amount + interest for Interest calculation state initial that is false
  //   //console.log("Transaction data - ", data, showTransactionDetails);
  const toWords = new ToWords();
  const [branch, setBranch] = useState([]);
  const [marketRate, setMarketRate] = useState([]);
  const [scrollValue, setScrollValue] = useState({
    old_balance_pa: 0,
    interest: 0,
  });
  //props change effect call
  useEffect(() => {
    if (data.length <= 0) return; // props Data in less then 0 terminate the method call.
    setInterestCalut(false); // visible none the interest calculate fields
    setCutmInterest(false); // disable the custom interest rate cal
    fetchData(); // initial data fetch function call
    setHeaderTxt(t("transaction details.receipt_details")); // change header text based on Language
  }, [props]);

  // console.log("loanData", loanData);

  //loan data state change effect call
  useEffect(() => {
    //console.log("pending");
    if (loanData.length <= 0) return; // loan Data in less then 0 terminate the method call.
    //console.log("success");
    fetchInitData();
    handleInputFields(); // input field update fun. call
    fetchTransaction(); // call the transaction list function call.
  }, [loanData]);
  const handleLanguageChange = (value) => {
    translate.engine = "google";
    translate.key = process.env.DEEPL_KEY;
    const result = translate(value, "ta");
    return result;
  };
  const getpaymenttype = (value, key) => {
    const paymentMethods = [
      { text: "Cash", value: "By Cash", text_tn: "பணம்" },
      {
        text: "Account Transfer",
        value: "By Account",
        text_tn: "கணக்கு பரிமாற்றம்",
      },
      { text: "UPI/ Others", value: "UPI/Others", text_tn: "UPI/ மற்றவை" },
    ];
    const method = paymentMethods.find((method) => method.value === value);
    return method ? method[key] : "Not found";
  };
  // transaction list function call

  const fetchInitData = async () => {
    logger.log("Initiate Fetch branch");
    const branchData = await api
      .get(`branch/${loanData.branch}`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch branch Successfully");
    setBranch(branchData);
    logger.log("Initiate Fetch current market value");
    const marketRate = await api
      .get(`/loan/CurrentMarketValue/`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch current market value Successfully");
    setMarketRate(marketRate);
  };

  const fetchTransaction = async () => {
    logger.log("Initiate Fetch TransactionsByLoan");
    const transactionList = await api
      .get(`loan/TransactionsByLoan/${loanData.loan_id}`)
      .then((res) => res.data)
      .catch((err) => logger.log(err));
    logger.log("Fetch TransactionsByLoan Successfully");
    setRows(transactionList || []);
  };

  //Table Column
  const Columns = AppData.transactionTableHeader.map((column) => ({
    ...column,
    headerName: t(`transaction details.${column.field}`),
  }));

  const transactionRows = rows?.map((item, index) => {
    return {
      ...item,
      id: index + 1,
      paid_amount: `\u20B9 ${Extension.formatAsIndianRupees(item.amount_paid)}`,
      interest_amount: `\u20B9 ${Extension.formatAsIndianRupees(
        item.due_amount
      )}`,
      paid_date: Extension.formatDateDMY(new Date(item.paid_on)),
      paid_pa: `\u20B9 ${Extension.formatAsIndianRupees(item.closure_amount)}`,
      balance_pa: `\u20B9 ${Extension.formatAsIndianRupees(item.balance_pa)}`,
      trans_or_recipt_id:
        item.trans_or_recipt_id !== "" ? item.trans_or_recipt_id : " -- ",
      payment_mode:
        Extension.languageBasedValue(item.payment_mode, item.payment_mode_tn) ??
        "--",
      print: (
        <Box>
          <PrintButton
            download={false}
            data={{
              ...loanData,
              transaction: {
                ...item,
                paid_on: new Date(item.paid_on)
                  .toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                  })
                  .replace(/\//g, "-"),
                amount_word: toWords.convert(item.amount_paid, {
                  lang: "en-IN",
                  currency: true,
                }),
                amount_word_tn: handleLanguageChange(
                  toWords.convert(item.amount_paid, {
                    lang: "en-IN",
                    currency: true,
                  })
                ),
              },
              silver_marketRate: marketRate.Silver,
              gold_marketRate: marketRate.Gold,
              branch_name: branch?.branch_name,
              branch_name_tn: branch?.branch_name_tn,
              created_on: new Date(loanData.created_on)
                .toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })
                .replace(/\//g, "-"),
            }}
          />
          <PrintButton
            download={true}
            data={{
              ...loanData,
              transaction: {
                ...item,
                paid_on: new Date(item.paid_on)
                  .toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                  })
                  .replace(/\//g, "-"),
                amount_word: toWords.convert(item.amount_paid, {
                  lang: "en-IN",
                  currency: true,
                }),
                amount_word_tn: handleLanguageChange(
                  toWords.convert(item.amount_paid, {
                    lang: "en-IN",
                    currency: true,
                  })
                ),
              },
              silver_marketRate: marketRate.Silver,
              gold_marketRate: marketRate.Gold,
              branch_name: branch.branch_name,
              branch_name_tn: branch.branch_name_tn,
              created_on: new Date(loanData.created_on)
                .toLocaleDateString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })
                .replace(/\//g, "-"),
            }}
          />
        </Box>
      ),
    };
  });

  // initial data fetch fun.
  const fetchData = async () => {
    //exception handling
    try {
      handleLoadingOpen(); //pre loader visible function call.
      setValue("interest_rate_method", "week"); // interest calculate method initial load the monthly.
      setLoanClose(false); // Initial loan closer in disable
      // get loan API response based on loan id
      logger.log("Initiate Fetch loans");
      const loanRes = await api
        .get(`loan/${data.loan_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch loans Successfully");
      // console.log("data - ", loanRes);
      setBranch_id(loanRes.branch);
      // set the loan response for loan data state
      const show_post_charge =
        calculateMonthsDifference(
          new Date(loanRes.last_due_date),
          new Date()
        ) >= 15;
      setPostalCharge(show_post_charge ? 100 : 0);
      setAddPostalCharge(show_post_charge);
      setLoanData({
        ...loanRes,
        cus_login_id: data.cus_login_id,
        cus_name: `${data.first_name} ${data.last_name}`,
        cus_name_tn: `${data.first_name_tn} ${data.last_name_tn}`,
        cus_number: `${data.mobile_number}`,
        scheme_data: `${data.scheme_name} - ${data.duration_month} months`,
        scheme_data_tn: `${data.scheme_name_tn} - ${data.duration_month} months`,
      });
      if (close_the_loan) {
        initialLoanClose(loanRes, show_post_charge ? 100 : 0);
      }
      // get the entity API response based on the entity Id
      logger.log("Initiate Fetch entity");
      const entityDetail = await api
        .get(`entity/${entityId}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch entity Successfully");
      //set the entity response for entity detail state
      setEntityDetail(entityDetail);
      //console.log("entity Detail - ", entityDetail);
    } catch (error) {
      logger.log(error);
      SnackBar.toastMsg("error", error.message); // any api connection issue throw this error message
    } finally {
      setValue("interest_rate_method", "week");
      handleLoadingClose(); //pre loader disable function call.
    }
  };

  // update the input fields
  const handleInputFields = () => {
    //set the value for input fields
    Object.entries(loanData).forEach(([key, value]) => {
      setValue(key, value);
    });
    // console.log("****Loan Res - ", loanData);
    // console.log("@@@props - ", props);
  };
  // pre loader open fun.
  const handleLoadingOpen = () => {
    setLoading(true);
  };

  //pre loader close fun.
  const handleLoadingClose = () => {
    setLoading(false);
  };

  //Interest rate calculation button click function call.
  const interestBtnCul = async (date) => {
    // console.log(date);
    setInterestCalut(true); // visible the interest enter fields
    setValue("paid_on", new Date().toISOString().split("T")[0]);
    await interestRateCul("week", false); // call the interest rate culculation function.
    setValue("payment_mode", "By Cash");
    interesMethodChange("week");
    setValue("interest_rate_method", "week");
    // Get the current date
    const currentDate = new Date();

    // Parse the given date string (yyyy-mm-dd)
    const givenDate = new Date(loanData.last_due_date);

    // Add one year to the given date
    givenDate.setFullYear(givenDate.getFullYear() + 1);

    // Compare the dates
    const compareDate = givenDate <= currentDate;
    //console.log("loan reward - ", loanData);
    if (compareDate && !loanData.additional_interest) {
      // Calculate the addition interest rate
      additionalInterst("true");
      setValue("addtional_interest", "true");
      if (roleId === 1)
        setInputFields2((prevInputFields) => {
          const updatedFields = prevInputFields.map((item) => {
            if (item.name === "addtional_interest") {
              return {
                ...item,
                visible: true,
              };
            }
            return item;
          });
          return updatedFields;
        });
    } else {
      setInputFields2((prevInputFields) => {
        const updatedFields = prevInputFields.map((item) => {
          if (item.name === "addtional_interest") {
            return {
              ...item,
              visible: false,
            };
          }
          return item;
        });
        return updatedFields;
      });
    }
  };

  //Interest rate calculation function call.
  const interestRateCul = async (
    method,
    loanCloser,
    custInterest,
    interest,
    additionInterest
  ) => {
    //exception handling
    try {
      handleLoadingOpen(); //pre loader visible function call.
      const prevInterest = getValues("interest_amount");
      let PA =
        additionInterest && !loanData.additional_interest && !loanClose
          ? loanData.current_balance_pa +
            parseFloat(prevInterest ? prevInterest : 0)
          : loanData.current_balance_pa;
      setScrollValue({
        old_balance_pa: loanData.current_balance_pa,
        interest: prevInterest,
      });
      let current_pa =
        loanData.additional_interest === true
          ? loanData.current_balance_pa
          : PA;

      setTemBalancePA({
        amount: current_pa,
        additionalInterest: additionInterest,
      });
      //get scheme API response based on loan scheme ID.
      logger.log("Initiate Fetch scheme");
      const scheme = await api
        .get(`scheme/${loanData.scheme}/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch scheme Successfully");
      const interestRateRes = Extension.interestRateCul(
        method,
        PA,
        loanData.created_on,
        // "2023-06-12",
        loanData.last_due_date,
        // "2023-10-18",
        scheme.duration_month,
        scheme.rate_of_interest_minimum,
        scheme.rate_of_interest_maximum,
        loanCloser,
        false,
        custInterest,
        interest,
        !loanData.additional_interest && !loanClose
          ? additionInterest
          : loanData.additional_interest,
        getValues("paid_on")
      );
      const maxTime = interestRateRes.max_time_period.split(" ")[0];
      // console.log("Max Time", maxTime);
      setValue("current_balance_pa", current_pa);
      if (interestRateRes.interest_perc_type === "maxRate" && roleId === 1) {
        setCutmInterest(true); // enable the custom interest rate cal
        setInputFields2((prevInputFields) => {
          const updatedFields = prevInputFields.map((item) => {
            if (item.name === "interest_percentage") {
              return {
                ...item,
                readOnly: false,
              };
            }
            return item;
          });
          //console.log("update list - ", updatedFields);
          return updatedFields;
        });
      }
      if (interestRateRes.interest_perc_type === "minRate" && roleId === 1) {
        setCutmInterest(false); // enable the custom interest rate cal
        setInputFields2((prevInputFields) => {
          const updatedFields = prevInputFields.map((item) => {
            if (item.name === "interest_percentage") {
              return {
                ...item,
                readOnly: false,
              };
            }
            return item;
          });
          //console.log("update list - ", updatedFields);
          return updatedFields;
        });
      }
      if (parseInt(maxTime) === 0) {
        setInputFields2((prevInputFields) => {
          const updatedFields = prevInputFields.map((item) => {
            if (item.name === "max_time_period") {
              return {
                ...item,
                visible: false,
              };
            }
            return item;
          });
          //console.log("update list - ", updatedFields);
          return updatedFields;
        });
      }
      if (parseInt(maxTime) > 0) {
        setInputFields2((prevInputFields) => {
          const updatedFields = prevInputFields.map((item) => {
            if (item.name === "max_time_period") {
              return {
                ...item,
                visible: true,
              };
            }
            return item;
          });
          //console.log("update list - ", updatedFields);
          return updatedFields;
        });
      }
      if (interestRateRes.remainingDays > 0) {
        setInputFields2((prevInputFields) => {
          //console.log("initial - value ", prevInputFields);
          const updatedFields = prevInputFields.map((item) => {
            if (item.name === "interest_rate_method") {
              return {
                ...item,
                readOnly: roleId === 1 ? false : true,
                visible: true,
              };
            }
            return item;
          });
          //console.log("update list - ", updatedFields);
          return updatedFields;
        });
      }

      if (interestRateRes.remainingDays <= 0) {
        setInputFields2((prevInputFields) => {
          //console.log("initial - value ", prevInputFields);
          const updatedFields = prevInputFields.map((item) => {
            if (item.name === "interest_rate_method") {
              return {
                ...item,
                visible: false,
              };
            }
            return item;
          });
          //console.log("update list - ", updatedFields);
          return updatedFields;
        });
      }
      Object.entries(interestRateRes).forEach(([key, value]) => {
        if (key === "repayment_amount") {
          //console.log("Repayment Value - ", value);
          setRepaymentValue(parseInt(interestRateRes.repayment_amount));
          setValue("repayment_amount", value);
          return;
        }
        if (key === `initialInterest`) {
          setInitailInterestAmount(value);
        }
        setValue(key, value);
      });
      setValue(
        "repayment_amount",
        loanCloser
          ? parseInt(interestRateRes.repayment_amount) + parseInt(postalCharge)
          : parseInt(interestRateRes.interest_amount) +
              parseInt(getValues("current_balance_pa"))
      );
      setRepaymentValue(
        loanCloser
          ? parseInt(interestRateRes.repayment_amount) + parseInt(postalCharge)
          : parseInt(interestRateRes.interest_amount) +
              parseInt(getValues("current_balance_pa"))
      );
      // console.log("interest rate - ", interestRateRes);
    } catch (error) {
      logger.log(error);
      SnackBar.toastMsg("error", error.message); // any api connection issue throw this error message
    } finally {
      setHeaderTxt(t("transaction details.interest_calculate")); // change the header text
      handleLoadingClose(); //pre loader disable function call.
    }
  };

  // get the month difference function.
  const calculateMonthsDifference = (date1, date2) => {
    return (
      (date2.getFullYear() - date1.getFullYear()) * 12 +
      (date2.getMonth() - date1.getMonth())
    );
  };

  //Interest percentage change function
  const handleInterest = (interest) => {
    // setValue("paid_on", new Date().toISOString().split("T")[0]);
    setAdditionalInterestCul(loanData.additional_interest || false);
    setValue("addtional_interest", loanData.additional_interest || false);
    // console.log(loanData.additional_interest);
    interestRateCul(
      getValues("interest_rate_method"),
      loanClose,
      true,
      interest,
      loanData.additional_interest ?? additionalInterestCul
    ); // call the interest rate culculation function.
  };

  const interesMethodChange = (method) => {
    // console.log(loanData.additional_interest);
    interestRateCul(
      method,
      loanClose,
      cutmInterest,
      getValues("interest_percentage"),
      loanClose
        ? loanData?.additional_interest
        : loanData?.additional_interest || additionalInterestCul
    ); // call the interest rate culculation function.
    setValue(
      "addtional_interest",
      loanData?.additional_interest || additionalInterestCul
    );
  };

  // interest rate method change function.

  const loanCloseBtnCal = () => {
    setLoanClose(true); //  loan closer state in enable
    setInterestCalut(true); // visible the interest enter fields
    interestRateCul("week", true); // call the interest rate calculation function.
    setValue("payment_mode", "By Cash");
    setHeaderTxt(t("transaction details.loan_close")); // change the header text
    setValue("paid_on", new Date().toISOString().split("T")[0]);
    setValue("interest_rate_method", "week");
    setInputFields2((prevInputFields) => {
      const updatedFields = prevInputFields.map((item) => {
        if (item.name === "addtional_interest") {
          return {
            ...item,
            visible: false,
          };
        }
        return item;
      });
      return updatedFields;
    });
    const show_post_charge =
      calculateMonthsDifference(new Date(loanData.last_due_date), new Date()) >=
      15;
    setPostalCharge(show_post_charge ? 100 : 0);
    setAddPostalCharge(show_post_charge);
    additionalInterst(loanData.additional_interest);
  };

  const initialLoanClose = async (loan_data, post, custInterest, interest) => {
    //console.log("Loan Close Called");
    try {
      setValue("paid_on", new Date().toISOString().split("T")[0]);
      const prevInterest = getValues("interest_amount");
      //console.log("Previous Interest - ", prevInterest, typeof prevInterest);

      let PA = loan_data.additionInterest
        ? loan_data.current_balance_pa +
          parseFloat(prevInterest ? prevInterest : 0)
        : loanData.current_balance_pa;
      setTemBalancePA({
        amount:
          loan_data.current_balance_pa +
          parseFloat(prevInterest ? prevInterest : 0),
        additionalInterest: loan_data.additionInterest,
      });
      //get scheme API response based on loan scheme ID.
      logger.log("Initiate Fetch scheme");
      const scheme = await api
        .get(`scheme/${loan_data.scheme}/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch scheme Successfully");
      //console.log("scheme - ", scheme);
      // call the interest rate calculation function.
      const interestRateRes = Extension.interestRateCul(
        "week",
        loan_data.loan_amount,
        loan_data.created_on,
        loan_data.last_due_date,
        scheme.duration_month,
        scheme.rate_of_interest_minimum,
        scheme.rate_of_interest_maximum,
        true,
        false,
        custInterest,
        interest,
        loan_data.additional_interest
      );

      if (interestRateRes.interest_perc_type === "maxRate" && roleId === 1) {
        setCutmInterest(true); // enable the custom interest rate cal
        setInputFields2((prevInputFields) => {
          const updatedFields = prevInputFields.map((item) => {
            if (item.name === "interest_percentage") {
              return {
                ...item,
                readOnly: false,
              };
            }
            return item;
          });
          //console.log("update list - ", updatedFields);
          return updatedFields;
        });
      }

      if (interestRateRes.remainingDays > 0) {
        setInputFields2((prevInputFields) => {
          //console.log("initial - value ", prevInputFields);
          const updatedFields = prevInputFields.map((item) => {
            if (item.name === "interest_rate_method") {
              return {
                ...item,
                readOnly: roleId === 1 ? false : true,
                visible: true,
              };
            }
            return item;
          });
          //console.log("update list - ", updatedFields);
          return updatedFields;
        });
      }
      Object.entries(interestRateRes).forEach(([key, value]) => {
        if (key === "repayment_amount") {
          //console.log("Repayment Value - ", value);
          setRepaymentValue(parseInt(value));
          setValue("repayment_amount", value);
          return;
        }
        if (key === `initialInterest`) {
          setInitailInterestAmount(value);
        }
        setValue(key, value);
      });
      setValue(
        "repayment_amount",
        parseInt(interestRateRes.repayment_amount) + post
      );
      //console.log("interest rate - ", interestRateRes);
    } finally {
      setInterestCalut(true);
      setLoanClose(true);
      setValue("payment_mode", "By Cash");
      setHeaderTxt(t("transaction details.loan_close")); // change the header text
    }
  };

  //call the interest cancel function
  const cancelBtn = () => {
    setCutmInterest(false); // disable the custom interest rate cal
    setInterestCalut(false); // disable the interest enter fields
    setLoanClose(false); // Initial loan closer in disable
    setHeaderTxt(t("transaction details.receipt_details")); // change the header text
    setValue("payment_mode", "");
    setValue("interest_rate_method", "week");
    setValue("paid_on", new Date().toISOString().split("T")[0]);
    handleLoanClose(false);
  };

  const handleConfirmation = (data) => {
    const payment_mode = data.payment_mode;
    setValue("interest_rate_method", "week");
    if (payment_mode === "") {
      SnackBar.toastMsg("error", "Mode of Payment Is Required Field!");
      return;
    } else if (
      parseFloat(data.repayment_amount) === 0 ||
      data.repayment_amount === "" ||
      data.repayment_amount === null ||
      isNaN(parseFloat(data.repayment_amount))
    ) {
      SnackBar.toastMsg("error", "Enter The PayOff Amount!");
      return;
    }
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "Do you want to confirm?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        confirmButtonColor: "#00A550",
        cancelButtonColor: "#d33",
        input: "checkbox",
        inputPlaceholder: "Do you want to send the SMS",
        inputValue: 1,
        preConfirm: (checkbox) => {
          resolve(checkbox); // Resolve with the checked value
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            const checkedValue = result.value; // Get the checked value
            // Call handleLoanInterests with the checked value
            handleLoanInterets(data, checkedValue);
          }
        })
        .catch((err) => {
          console.error("Error:", err);
          reject(err);
        });
    });
  };

  //handle loan interest and update on the DB
  const handleLoanInterets = async (data, checkedValue) => {
    const interest_amount = parseFloat(data.interest_amount);
    const repayment_amount =
      parseFloat(data.repayment_amount) + loanData.advance_amount;
    const payOffAmount = parseFloat(data.repayment_amount);
    const balancePrincipalAmt = temBalancePA.amount;
    const balance_pa =
      interest_amount < repayment_amount
        ? balancePrincipalAmt - (repayment_amount - interest_amount)
        : balancePrincipalAmt;
    const sequenceType =
      loanClose && repayment_amount >= balance_pa
        ? "loan_closer"
        : interest_amount < repayment_amount
        ? "partial_cloase"
        : interest_amount > repayment_amount
        ? "partial_interest"
        : "interest";
    const interestSequence = interestSeqc(sequenceType);
    //console.log("interest rate type - ", interestSequence);
    const amount =
      data.time_period <= 0
        ? parseInt(repayment_amount) + parseInt(initialInterestAmount)
        : balance_pa;
    const b_p = balance_pa <= 0 || loanClose ? 0 : balance_pa;
    const loanDetail = {
      ...loanData,
      last_due_date:
        interest_amount <= repayment_amount
          ? getValues("paid_on") ?? new Date().toISOString().split("T")[0]
          : loanData.last_due_date,
      current_balance_pa: b_p,
      status: b_p <= 0 ? "Closed" : loanData.status,
      status_tn: b_p <= 0 ? "மூடப்பட்டது" : loanData.status_tn,
      // actual_closer_date:
      //   b_p <= 0 ? getValues("paid_on") : null,
      actual_closer_date:
        b_p <= 0 ? new Date().toISOString().split("T")[0] : null,
      advance_amount:
        interest_amount <= repayment_amount ? 0 : repayment_amount,
      additional_interest: additionalInterestCul
        ? true
        : loanData.additional_interest,
      postal_charge: Number.isNaN(postalCharge) ? 0 : postalCharge,
    };
    const balance_amt =
      parseInt(b_p) +
      parseInt(interest_amount) +
      Number.isNaN(parseInt(postalCharge))
        ? 0
        : parseInt(postalCharge);
    if (0 > balance_amt) {
      SnackBar.toastMsg(
        "error",
        "PayOff Amount Can't Be Greater Than Balance Amount"
      );
      return;
    }
    const transaction = {
      amount_paid: payOffAmount,
      balance_pa: b_p,
      due_amount: interest_amount,
      due_date: loanData.last_due_date,
      closure_amount:
        interest_amount < repayment_amount
          ? repayment_amount - interest_amount
          : 0,
      // paid_on: new Date().toISOString().split("T")[0],
      paid_on: getValues("paid_on") ?? new Date().toISOString().split("T")[0],
      payment_mode: data.payment_mode,
      trans_or_recipt_id: data.transaction_id,
      loan: loanData.loan_id,
      trans_seq_num: interestSequence.sequence,
      payment_mode_tn: getpaymenttype(data.payment_mode, "text_tn"),
    };
    // console.log("transaction - ", transaction);
    let updateEntity;
    //update the enitity
    switch (interestSequence.type) {
      case "interest":
        updateEntity = {
          ...entityDetail,
          last_interest_num: interestSequence.sequenceNumber,
        };
        setEntityDetail(updateEntity);
        break;
      case "partial_interest":
        updateEntity = {
          ...entityDetail,
          last_partial_interest_num: interestSequence.sequenceNumber,
        };
        setEntityDetail(updateEntity);
        break;
      case "partial_cloase":
        updateEntity = {
          ...entityDetail,
          last_partial_closure_num: interestSequence.sequenceNumber,
        };
        setEntityDetail(updateEntity);
        break;
      case "loan_closer":
        updateEntity = {
          ...entityDetail,
          last_closure_num: interestSequence.sequenceNumber,
        };
        setEntityDetail(updateEntity);
        break;
      default:
        break;
    }

    // const cons =loanClose? payOffAmount+

    if (!loanClose && balance_pa < 0) {
      SnackBar.toastMsg(
        "error",
        "Enter PayOff Amount Less Than Balance Amount!"
      );
      return;
    }
    try {
      handleLoadingOpen(); //pre loader visible function call.

      //console.log("Entity - ", updateEntity);
      logger.log("Initiate update loan");
      const loanRes = await api
        .put(`loan/${loanData.loan_id}`, loanDetail)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("update loan Successfully");
      handleFundAmount(repayment_amount, sequenceType);
      console.log(checkedValue);
      if (checkedValue) {
        handleSMSThrowFunction(
          loanData.loan_number,
          getValues("paid_on") ?? new Date().toISOString().split("T")[0],
          loanData.cus_number,
          loanData.loan_amount
        );
      }
      logger.log("Initiate post transaction");
      await api
        .post(`loan/Transactions`, transaction)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("post transaction Successfully");

      //udpate the entity
      logger.log("Initiate update entity");
      await api.put(`entity/${entityId}`, updateEntity);
      logger.log("update entity Successfully");
      Object.entries(data).forEach(([key, value]) => {
        setValue(key, "");
      });
      setLoanData({
        ...loanRes,
        cus_login_id: loanData.cus_login_id,
        cus_name: loanData.cus_name,
        cus_name_tn: loanData.cus_name_tn,
        cus_number: loanData.cus_number,
        scheme_data: loanData.scheme_data,
        scheme_data_tn: loanData.scheme_data_tn,
      });
      //console.log("Loan res 222 - ", loanRes);
    } catch (error) {
      SnackBar.toastMsg("error", error.message);
    } finally {
      setHeaderTxt(t("transaction details.receipt_details")); // change the header text
      setInterestCalut(false); // disable the interest enter fields
      handleLoadingClose(); //pre loader disable function call.
      fetchTransaction();
      setAddPostalCharge(false);
      setPostalCharge(0);
      setValue("interest_amount", 0);
      setValue("payment_mode", "");
      setValue("paid_on", new Date().toISOString().split("T")[0]);
      // setPostalCharge(100);
    }
  };

  const handleSMSThrowFunction = async (loan_id, date, number,amt) => {
    await SMS_API_FUNCTION.loanCreationSMS_API(loan_id, date, number,169259,amt);
  };

  //Calculate the addition interest rate
  const additionalInterst = (value) => {
    interestRateCul(
      getValues("interest_rate_method"),
      loanClose,
      cutmInterest,
      getValues("interest_percentage"),
      value === "true" || value === true ? true : false
      // getValues("paid_on")
    ); // call the interest rate culculation function.
    setAdditionalInterestCul(value === "true" || value === true ? true : false);
  };

  // Interest Sequence calculation function
  const interestSeqc = (type) => {
    const entityDetials = entityDetail;
    let sequence;
    let sequenceNumber;
    switch (type) {
      case "interest":
        if (
          entityDetials.last_interest_num === null ||
          entityDetials.last_interest_num === ""
        ) {
          sequence = "INTA0001";
          sequenceNumber = "A0001";
        } else {
          const { alphabeticPart, numericPart } = splitString(
            entityDetials.last_interest_num
          );

          const updateNumber = generateSequence(alphabeticPart, numericPart);
          sequenceNumber = updateNumber;
          sequence = `INT${updateNumber}`;
        }
        break;
      case "partial_interest":
        if (
          entityDetials.last_partial_interest_num === null ||
          entityDetials.last_partial_interest_num === ""
        ) {
          sequence = "PARINTA0001";
          sequenceNumber = "A0001";
        } else {
          const { alphabeticPart, numericPart } = splitString(
            entityDetials.last_partial_interest_num
          );
          const updateNumber = generateSequence(alphabeticPart, numericPart);
          sequenceNumber = updateNumber;
          sequence = `PARINT${updateNumber}`;
        }
        break;
      case "loan_closer":
        if (
          entityDetials.last_closure_num === null ||
          entityDetials.last_closure_num === ""
        ) {
          sequence = "CLSA0001";
          sequenceNumber = "A0001";
        } else {
          const { alphabeticPart, numericPart } = splitString(
            entityDetials.last_closure_num
          );
          const updateNumber = generateSequence(alphabeticPart, numericPart);
          sequenceNumber = updateNumber;
          sequence = `CLS${updateNumber}`;
        }
        break;
      case "partial_cloase":
        if (
          entityDetials.last_partial_closure_num === null ||
          entityDetials.last_partial_closure_num === ""
        ) {
          sequence = "PARCLSA0001";
          sequenceNumber = "A0001";
        } else {
          const { alphabeticPart, numericPart } = splitString(
            entityDetials.last_partial_closure_num
          );
          const updateNumber = generateSequence(alphabeticPart, numericPart);
          sequenceNumber = updateNumber;
          sequence = `PARCLS${updateNumber}`;
        }
        break;
      default:
        break;
    }
    return { sequence, type, sequenceNumber };
  };

  // Sequence generate function
  //Loan number generate fun.
  const generateSequence = (char, numeric) => {
    const currentChar = char.toUpperCase();
    const currentNumeric = parseInt(numeric);
    //console.log("prefix char code - ", currentChar.charCodeAt(1));
    if (currentNumeric === 1000) {
      let nextChar;
      if (currentChar.charAt(0) === "Z") {
        const charLength = currentChar.length + 1;
        nextChar = "A".repeat(charLength);
      } else {
        nextChar = String.fromCharCode(currentChar.charCodeAt(0) + 1);
      }

      return `${nextChar}0001`;
    } else {
      return `${currentChar}${(currentNumeric + 1).toString().padStart(4, 0)}`;
    }
  };
  useEffect(() => {
    if (Number.isNaN(postalCharge) || !postalCharge) {
      setPostalCharge(0);
    }
  }, [postalCharge]);
  //split to separte the character and interger fun.
  const splitString = (inputString) => {
    // Use regular expressions to separate alphabetic and numeric parts
    const parts = inputString.split(/(\d+)/);
    if (parts) {
      // Separate alphabetic and numeric parts
      const alphabeticPart = parts
        .filter((part, index) => index % 2 === 0)
        .join("");
      const numericPart = parts
        .filter((part, index) => index % 2 !== 0)
        .join("");
      return { alphabeticPart, numericPart };
    } else {
      return { alphabeticPart: "", numericPart: "" };
    }
  };

  useEffect(() => {
    const value = parseInt(repaymentValue) + parseInt(postalCharge);
    setValue(
      "repayment_amount",
      value <= parseInt(repaymentValue) ||
        postalCharge === "" ||
        postalCharge == null
        ? repaymentValue
        : loanClose
        ? value
        : repaymentValue
    );
  }, [loanClose, postalCharge, repaymentValue, setValue]);

  const [fund_amount, setFund_amount] = useState("");

  const getFundAmount = useCallback(async () => {
    try {
      logger.log("Initiate Fetch CurrentTotalAmountByBranch");
      const API = `/Fund/CurrentTotalAmountByBranch/${branch_id}`;
      const crrapi = await api
        .get(API)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch CurrentTotalAmountByBranch Successfully");
      // console.log("Funt Amount", crrapi?.total_fund_amount);
      setFund_amount(crrapi?.total_fund_amount);
    } catch (error) {
      logger.log(error);
    }
  }, [branch_id]);

  useEffect(() => {
    getFundAmount();
  }, [branch_id, getFundAmount]);

  const handleFundAmount = async (inr, value) => {
    try {
      let total_fund_amount = 0;
      total_fund_amount = parseInt(fund_amount) + parseInt(inr);
      if (value === "loan_closer" || value === "partial_cloase") {
        const data = {
          credit_amount: inr,
          debit_amount: null,
          // funded_on: Extension.getCurrentDateTime(),
          funded_on: getValues("paid_on") ?? Extension.getCurrentDateTime(),
          total_fund_amount,
          transaction_type: getValues("payment_mode"),
          entity: entityId,
          funded_By: user_id,
          fund_type: "LoanClosureAmountCredit",
          branch: branch_id,
        };
        //console.log(data);
        logger.log("Initiate Post fund");
        const response = await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Post fund Successfully");
        //console.log("Debit Amount", response);
        return;
      }
      if (value === "partial_interest" || value === "interest") {
        const data = {
          credit_amount: inr,
          debit_amount: null,
          // funded_on: Extension.getCurrentDateTime(),
          funded_on: getValues("paid_on") ?? Extension.getCurrentDateTime(),
          total_fund_amount,
          transaction_type: getValues("payment_mode"),
          entity: entityId,
          funded_By: user_id,
          fund_type: "InterestAmountCredit",
          branch: branch_id,
        };
        //console.log(data);
        logger.log("Initiate Post fund");
        const response = await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Post fund Successfully");
        //console.log("Debit Amount", response);
        return;
      }
    } catch (error) {
      logger.log(error);
    }
  };

  const handleDateChange = async (e) => {
    // console.log(e.target.value);
    setValue("paid_on", e.target.value);
    await interestRateCul("week", false);
    // setValue("addtional_interest", false);
    // if (roleId === 2) {
    // console.log(additionalInterestCul);
    additionalInterst(additionalInterestCul);
    // console.log(loanData);
    const givenDate = new Date(loanData.last_due_date);

    // Add one year to the given date
    givenDate.setFullYear(givenDate.getFullYear() + 1);

    // Compare the dates
    const compareDate = givenDate <= new Date(e.target.value);
    if (compareDate && !loanData.additional_interest) {
      // Calculate the addition interest rate
      additionalInterst("true");
      setValue("addtional_interest", "true");

      if (loanClose) {
        const show_post_charge =
          calculateMonthsDifference(
            new Date(loanData.last_due_date),
            new Date(e.target.value)
          ) >= 15;
        // console.log(
        //   "postal charge",
        //   show_post_charge,
        //   calculateMonthsDifference(
        //     new Date(loanData.last_due_date),
        //     new Date(e.target.value)
        //   )
        // );
        setPostalCharge(show_post_charge ? 100 : 0);
        setAddPostalCharge(show_post_charge);
      }
      if (roleId === 1)
        setInputFields2((prevInputFields) => {
          const updatedFields = prevInputFields.map((item) => {
            if (item.name === "addtional_interest") {
              return {
                ...item,
                visible: !loanClose && true,
              };
            }
            return item;
          });
          return updatedFields;
        });
      // setLoanData((prevData) => {
      //   return { ...prevData, additional_interest: true };
      // });
    } else if (!compareDate || loanClose) {
      // Calculate the addition interest rate
      if (loanClose) {
        const show_post_charge =
          calculateMonthsDifference(
            new Date(loanData.last_due_date),
            new Date(e.target.value)
          ) >= 15;
        // console.log(
        //   "postal charge",
        //   show_post_charge,
        //   calculateMonthsDifference(
        //     new Date(loanData.last_due_date),
        //     new Date(e.target.value)
        //   )
        // );
        setPostalCharge(show_post_charge ? 100 : 0);
        setAddPostalCharge(show_post_charge);
      }
      additionalInterst(loanData.additional_interest);
      setValue("addtional_interest", loanData.additional_interest);
      // if (roleId === 1)
      setInputFields2((prevInputFields) => {
        const updatedFields = prevInputFields.map((item) => {
          if (item.name === "addtional_interest") {
            return {
              ...item,
              visible: false,
            };
          }
          return item;
        });
        return updatedFields;
      });
      // setLoanData((prevData) => {
      //   return { ...prevData, additional_interest: true };
      // });
    } else {
      // additionalInterst(loanData.additional_interest);
      // setValue("addtional_interest", loanData.additional_interest);
      setInputFields2((prevInputFields) => {
        const updatedFields = prevInputFields.map((item) => {
          if (item.name === "addtional_interest") {
            return {
              ...item,
              visible: false,
            };
          }
          return item;
        });
        return updatedFields;
      });
    }
  };

  return (
    <Paper className="commonpaper">
      <Loading open={loading} handleClose={handleLoadingClose} />
      <Box sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}>
        {/* Back button  */}
        <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
          <Tooltip title="Back to Receipt list" placement="top-start">
            <IconButton
              sx={{
                background: "#B1F164",
                color: "#555",
                "&:hover": {
                  background: "#82B643",
                  color: "#fff",
                  transform: "scale(1.2)",
                },
              }}
              onClick={() => {
                showTransactionDetails(false);
                handleLoanClose(false);
                fetchLoanDetails();
              }}
            >
              <KeyboardBackspace />
            </IconButton>
          </Tooltip>
          <Typography variant="h5" sx={Styles.titleText()}>
            {headerTxt}
          </Typography>
        </Box>
        <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
          {(loanData.additional_interest || additionalInterestCul) &&
            parseInt(scrollValue.old_balance_pa) > 0 &&
            interseCalut &&
            parseInt(scrollValue.old_balance_pa) <
              parseInt(getValues("current_balance_pa")) && (
              <div class="autoscroll-horizontal">
                <p>
                  {Extension.languageBasedValue(
                    `Your loan has been upgraded to principal and interest due to
                  non-payment of interest installments properly, Old Loan Amount
                  =${rupeeSymbol}
                  ${scrollValue.old_balance_pa}, and Total Interest =
                  ${rupeeSymbol}
                  ${scrollValue.interest}`,
                    `உங்கள் கடன் அசல் மற்றும் வட்டிக்கு மேம்படுத்தப்பட்டது
                  வட்டி தவணைகளை முறையாக செலுத்தாதது, பழைய கடன் தொகை
                  =${rupeeSymbol}
                  ${scrollValue.old_balance_pa}, மற்றும் மொத்த வட்டி =
                  ${rupeeSymbol}
                  ${scrollValue.interest}`
                  )}
                </p>
              </div>
            )}
          {parseFloat(data.rate_of_interest_minimum) <
            parseFloat(getValues("interest_percentage")) &&
            (interseCalut || loanClose) && (
              <div class="autoscroll-horizontal">
                <p>
                  {Extension.languageBasedValue(
                    `The scheme duration has been expired the Rate of interest ${
                      data.rate_of_interest_minimum
                    }% have been upgraded to ${getValues(
                      "interest_percentage"
                    )}%`,
                    `திட்டத்தின் காலம் காலாவதியானது, வட்டி விகிதம் ${
                      data.rate_of_interest_minimum
                    }% ${getValues(
                      "interest_percentage"
                    )}% ஆக மேம்படுத்தப்பட்டுள்ளது,`
                  )}
                </p>
              </div>
            )}
        </div>
        <Box>
          {!interseCalut &&
          loanData.status === "Approved" &&
          !close_the_loan ? (
            <>
              {/* Interest rate close calculation button */}
              <Button
                type="button"
                size="small"
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "var(--font-weight)",
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                  "&:hover": { transform: "scale(1.1)" },
                  mr: 2,
                }}
                onClick={loanCloseBtnCal}
                color="success"
              >
                {t("transaction details.loan_close")}
              </Button>
              {/* Interest rate calculation button */}
              <Button
                type="button"
                size="small"
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  fontWeight: "var(--font-weight)",
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                  "&:hover": { transform: "scale(1.1)" },
                }}
                onClick={interestBtnCul}
              >
                {t("transaction details.interest_calculate")}
              </Button>
            </>
          ) : (
            loanData.status === "Approved" && (
              <>
                {/* Interest rate cancel button */}
                <Button
                  type="button"
                  size="small"
                  variant="outlined"
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "var(--font-weight)",
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                    "&:hover": { transform: "scale(1.1)" },
                    mr: 2,
                  }}
                  onClick={cancelBtn}
                  color="error"
                >
                  {t("transaction details.cancel")}
                </Button>
                {/* Interest Receive payment button */}
                <Button
                  type="button"
                  size="small"
                  variant="contained"
                  color="success"
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "var(--font-weight)",
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                    "&:hover": { transform: "scale(1.1)" },
                  }}
                  onClick={handleSubmit(handleConfirmation)}
                >
                  {t("transaction details.receive_payment")}
                </Button>
              </>
            )
          )}
          {loanData.status !== "Approved" && (
            <Typography variant="h5" className={`status ${loanData.status}`}>
              {Extension.languageBasedValue(
                loanData.status,
                loanData.status_tn
              )}
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={`add-loan-header `}>
        {/* MUI grid container  */}
        <Grid container rowSpacing={1} columnSpacing={2}>
          {/* header first input fields and that input fields getting on the app data component */}
          {AppData.receiptHeaderInputFields1 &&
            AppData.receiptHeaderInputFields1.map((item, index) => {
              return (
                <Grid
                  item
                  xs={item.gridSize.xs}
                  sm={item.gridSize.sm}
                  md={item.gridSize.md}
                  lg={item.gridSize.lg}
                  key={index}
                >
                  <Typography sx={Styles.textlabel()}>
                    {item.title && t(`transaction details.${item.title}`)}
                  </Typography>
                  <Paper sx={{ background: "none" }} variant="standarded">
                    {item.inputType === "input" && (
                      <CommonTextField
                        name={item.name}
                        register={register}
                        type={item.type}
                        readOnly={item.readOnly}
                        position={item.position}
                        comboFixWord={item.ComboFixWord}
                      />
                    )}
                  </Paper>
                </Grid>
              );
            })}
        </Grid>
      </Box>

      {/* intersetCalut state is true visible the below container */}
      {interseCalut && (
        <Grid container rowSpacing={1} columnSpacing={2} sx={{ mb: 4 }}>
          {/* header second input fields and that input fields getting on the app data component */}
          {inputFields2 &&
            inputFields2.map((item, index) => {
              return (
                item.visible && (
                  <>
                    {item.title !== "postal_charge" &&
                      item.title !== "paid_on" && (
                        <Grid
                          item
                          xs={item.gridSize.xs}
                          sm={item.gridSize.sm}
                          md={item.gridSize.md}
                          lg={item.gridSize.lg}
                          key={index}
                        >
                          <Typography sx={Styles.textlabel()}>
                            {item.title &&
                              item.title !== "postal_charge" &&
                              t(`transaction details.${item.title}`)}
                          </Typography>
                          <Paper
                            sx={{ background: "none" }}
                            variant="standarded"
                          >
                            {item.inputType === "input" && (
                              <CommonTextField
                                name={item.name}
                                register={register}
                                type={item.type}
                                required={item.required}
                                loanClose={
                                  item.title === "pay_off_amount" &&
                                  true &&
                                  loanClose
                                }
                                readOnly={
                                  loanClose && item.title === "pay_off_amount"
                                    ? true
                                    : item.readOnly
                                }
                                position={item.position}
                                comboFixWord={item.ComboFixWord}
                                clickFunction={
                                  item.name === "interest_percentage" &&
                                  handleInterest
                                }
                                clickEvent={item.clickType}
                              />
                            )}

                            {item.inputType === "select" && (
                              <Controller
                                name={item.name}
                                control={control}
                                rules={{ required: true }}
                                defaultValue=""
                                render={({ field }) => (
                                  <SelectDropDown
                                    field={field}
                                    name={item.name}
                                    register={register}
                                    readOnly={item.readOnly}
                                    data={item.data}
                                    setValue={setValue}
                                    clickType={item.clickType}
                                    required={item.required}
                                    clickFunction={
                                      item.name === "interest_rate_method"
                                        ? interesMethodChange
                                        : "addtional_interest" &&
                                          additionalInterst
                                    }
                                    display_name={
                                      language === "tm"
                                        ? item.tm
                                        : item.display_name
                                    }
                                    value={item.value}
                                  />
                                )}
                              />
                            )}
                          </Paper>
                        </Grid>
                      )}
                    {item.title === "paid_on" && (
                      <Grid
                        item
                        xs={item.gridSize.xs}
                        sm={item.gridSize.sm}
                        md={item.gridSize.md}
                        lg={item.gridSize.lg}
                      >
                        {" "}
                        <Typography sx={Styles.textlabel()}>
                          {item.title === "paid_on" &&
                            t(`transaction details.${item.title}`)}
                        </Typography>
                        <TextField
                          sx={{
                            ...Styles.dropDown(),
                            "& .MuiSvgIcon-root,.MuiTypography-root": {
                              color: "var(--font-color)", // Change the color of the dropdown icon
                            },
                          }}
                          fullWidth
                          size="small"
                          value={getValues("paid_on")}
                          onChange={(e) => handleDateChange(e)}
                          type={item.type}
                          defaultValue={new Date().toISOString().split("T")[0]}
                          onKeyDown={(e) => {
                            ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault();
                          }}
                          InputProps={{
                            readOnly: item.readOnly,
                            inputProps: {
                              min: 0,
                            },
                            style: {
                              backgroundColor: "var(--text-field)",
                              color: "var(--font-color)",
                              fontFamily: "var(--font-family)",
                              fontSize: "var(--font-size)",
                              height: "40px",
                            },
                          }}
                        />
                      </Grid>
                    )}
                    {item.title === "postal_charge" &&
                      addPostalCharge &&
                      loanClose && (
                        <Grid
                          item
                          xs={item.gridSize.xs}
                          sm={item.gridSize.sm}
                          md={item.gridSize.md}
                          lg={item.gridSize.lg}
                        >
                          {" "}
                          <Typography sx={Styles.textlabel()}>
                            {item.title === "postal_charge" &&
                              t(`transaction details.${item.title}`)}
                          </Typography>
                          <TextField
                            sx={{
                              ...Styles.dropDown(),
                              "& .MuiSvgIcon-root,.MuiTypography-root": {
                                color: "var(--font-color)", // Change the color of the dropdown icon
                              },
                            }}
                            fullWidth
                            size="small"
                            value={postalCharge}
                            onChange={(e) => setPostalCharge(e.target.value)}
                            type={item.type}
                            defaultValue={0}
                            onKeyDown={(e) => {
                              ["e", "E", "+", "-"].includes(e.key) &&
                                e.preventDefault();
                            }}
                            InputProps={{
                              readOnly: item.readOnly,
                              inputProps: {
                                min: 0,
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  {"₹"}
                                </InputAdornment>
                              ),
                              style: {
                                backgroundColor: "var(--text-field)",
                                color: "var(--font-color)",
                                fontFamily: "var(--font-family)",
                                fontSize: "var(--font-size)",
                                height: "40px",
                              },
                            }}
                          />
                        </Grid>
                      )}
                  </>
                )
              );
            })}
        </Grid>
      )}
      {/* MUI grid container  */}

      <GridTable rows={transactionRows} columns={Columns} isLoading={loading} />
    </Paper>
  );
};

export default TransactionDetails;
