import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { rupeeSymbol } from "../../../common/Components/IconsAndComponents";
import Extension from "../../../common/Extension";
import { Chart } from "./Chart";
import "./ChartComponent.css";
import CountUp from "react-countup";

const ChartComponent = (props) => {
  const { creditDebitIncome, duration, fund } = props;
  const { t } = useTranslation("global");

  const uniqueMonth = [
    ...new Set(
      creditDebitIncome?.map((data) =>
        duration === "monthly" ? data.month : data.year
      )
    ),
  ];

  const BranchWiseIncLabel = uniqueMonth?.map((item) => {
    let label = item;
    if (duration !== "yearly") {
      const parts = item.split(" ");
      label = `${parts[0].toString().slice(0, 3)} ${parts[1]
        .toString()
        .slice(-2)}`;
      return label;
    }
    return label;
  });

  const uniqueBranch = [
    ...new Set(creditDebitIncome?.map((data) => data.branch_name)),
  ];

  const uniqueBranchTm = [
    ...new Set(creditDebitIncome?.map((data) => data.branch_name_tn)),
  ];

  const CreditchartData = uniqueBranch.map((branch, index) => {
    const branch_name_tn = uniqueBranchTm[index];
    const income = uniqueMonth.map((month) => {
      const totalCredit = creditDebitIncome.reduce((acc, item) => {
        const matchCondition =
          duration === "monthly"
            ? item.branch_name === branch && item.month === month
            : item.branch_name === branch && item.year === month;

        return matchCondition ? acc + item.total_credit : acc;
      }, 0);

      return totalCredit;
    });

    return {
      name: Extension.languageBasedValue(branch, branch_name_tn),
      data: income,
    };
  });

  const DebitchartData = uniqueBranch.map((branch, index) => {
    const branch_name_tn = uniqueBranchTm[index];
    const outcome = uniqueMonth.map((month) => {
      const totalCredit = creditDebitIncome.reduce((acc, item) => {
        const matchCondition =
          duration === "monthly"
            ? item.branch_name === branch && item.month === month
            : item.branch_name === branch && item.year === month;

        return matchCondition ? acc + item.total_debit : acc;
      }, 0);

      return totalCredit;
    });

    return {
      name: Extension.languageBasedValue(branch, branch_name_tn),
      data: outcome,
    };
  });

  const IncomeVsOutcome = uniqueMonth.map((month) => {
    const income = creditDebitIncome.reduce((acc, item) => {
      if (duration === "monthly" ? item.month === month : item.year === month) {
        return acc + item.total_credit;
      } else {
        return acc;
      }
    }, 0);
    const outcome = creditDebitIncome.reduce((acc, item) => {
      if (duration === "monthly" ? item.month === month : item.year === month) {
        return acc + item.total_debit;
      } else {
        return acc;
      }
    }, 0);
    return {
      income,
      outcome,
    };
  });

  const inVsOutLoan = [
    {
      name: t("dashboard.income"),
      data: IncomeVsOutcome?.map((item) => item.outcome),
    },
    {
      name: t("dashboard.spend"),
      data: IncomeVsOutcome?.map((item) => item.income),
    },
  ];

  const chart1Options = {
    chart: {
      stacked: true,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#fb8486", "#90be6d", "#2d9cdb"],
    xaxis: {
      categories: BranchWiseIncLabel.sort((a, b) => a.localeCompare(b)).map((item) => item),
      labels: {
        style: {
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          fontWeight: "var(--font-weight)", // Set font weight to bold
          colors: "var(--font-color)",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          colors: "var(--font-color)",
        },
        formatter: function (val) {
          return `₹ ${Extension.convertToK(val)}`;
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontFamily: "var(--font-family)",
      fontSize: "var(--font-size)",
      labels: {
        colors: "var(--font-color)",
        useSeriesColors: false,
      },
    },
    itemMargin: {
      horizontal: 20, // Adjust the horizontal margin between legend items
      vertical: 5, // Adjust the vertical margin between legend items
    },
    title: {
      text: t("dashboard.Branch Wise Outcome"),
      align: "left",
      margin: 10,
      offsetX: 10,
      offsetY: 10,
      floating: false,
      style: {
        fontFamily: "var(--font-family)",
        fontWeight: "var(--font-weight)",
        color: "var(--font-color)",
      },
    },
  };

  const chart2Options = {
    chart: {
      id: "barChart2",
      stacked: true,
      borderRadius: 30,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#fff"],
    },
    colors: ["#64cff6", "#6359e9"],
    title: {
      text: t("dashboard.Loan Given vs EMI Collected"),
      align: "left",
      margin: 10,
      offsetX: 10,
      offsetY: 10,
      floating: false,
      style: {
        fontFamily: "var(--font-family)",
        fontWeight: "var(--font-weight)",
        color: "var(--font-color)",
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories:BranchWiseIncLabel.sort((a, b) => a.localeCompare(b)).map((item) => item),
      labels: {
        style: {
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          fontWeight: "var(--font-weight)",
          colors: "var(--font-color)",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          colors: "var(--font-color)",
        },
        formatter: function (val) {
          return `₹ ${Extension.convertToK(val)}`;
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontFamily: "var(--font-family)",
      fontSize: "var(--font-size)",
      color: "var(--font-color)", // Legend item color
      labels: {
        colors: "var(--font-color)",
        useSeriesColors: false,
      },
      style: {
        fontFamily: "var(--font-family)",
        fontSize: "var(--font-size)",
      },
      itemMargin: {
        horizontal: 20,
      },
      itemTextTransform: "rotate-45",
    },
  };

  const chart3Options = {
    chart: {
      id: "lineChart",
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.7,
        opacityTo: 0.3,
      },
    },
    colors: ["#fb8486", "#90be6d", "#2d9cdb"],
    title: {
      text: t("dashboard.Branch Wise Income"),
      align: "left",
      margin: 10,
      offsetX: 10,
      offsetY: 10,
      floating: false,
      style: {
        fontFamily: "var(--font-family)",
        fontWeight: "var(--font-weight)",
        color: "var(--font-color)",
      },
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 8,
    },
    xaxis: {
      categories: BranchWiseIncLabel.map((item) => item),
      labels: {
        style: {
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          fontWeight: "var(--font-weight)",
          colors: "var(--font-color)",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          colors: "var(--font-color)",
        },
        formatter: function (val) {
          return `₹ ${Extension.convertToK(val)}`;
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontFamily: "var(--font-family)",
      fontSize: "var(--font-size)",
      color: "var(--font-color)", // Legend item color
      labels: {
        colors: "var(--font-color)",
        useSeriesColors: false,
      },
      itemMargin: {
        horizontal: 20,
      },
      itemTextTransform: "rotate-45",
    },
  };

  const array = [
    {
      id: 1,
      name: t("dashboard.total_FundAmountCredit"),
      value: fund?.total_FundAmountCredit,
      background:
        "linear-gradient(to bottom, #B1D696F0, #B1D696F0, #B1D696F0,#B1D696F0,#B1D696F0)",
    },
    {
      id: 2,
      name: t("dashboard.total_InterestAmountCredit"),
      value: fund?.total_InterestAmountCredit,
      background:
        "linear-gradient(to bottom, #B1D696F0, #B1D696F0, #B1D696F0,#B1D696F0,#B1D696F0)",
    },
    {
      id: 3,
      name: t("dashboard.total_LoanClosureAmountCredit"),
      value: fund?.total_LoanClosureAmountCredit,
      background:
        "linear-gradient(to bottom, #B1D696F0, #B1D696F0, #B1D696F0,#B1D696F0,#B1D696F0)",
    },
    {
      id: 4,
      name: t("dashboard.total_LoanAmountDebit"),
      value: fund?.total_LoanAmountDebit,
      background:
        "linear-gradient(to bottom, #70B5DCD4, #70B5DCD4, #70B5DCD4, #70B5DCD4, #70B5DCD4)",
    },
    {
      id: 5,
      name: t("dashboard.total_ExpenditureAmountDebit"),
      value: fund?.total_ExpenditureAmountDebit,
      background:
        "linear-gradient(to bottom, #70B5DCD4, #70B5DCD4, #70B5DCD4, #70B5DCD4, #70B5DCD4)",
      // color: "white",
    },
  ];
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <Paper className="char-container">
          <Chart options={chart1Options} series={CreditchartData} type="bar" />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Paper className="char-container">
          <Chart options={chart3Options} series={DebitchartData} type="area" />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Paper className="char-container">
          <Chart options={chart2Options} series={inVsOutLoan} type="bar" />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Paper
          className="char-container"
          sx={{
            width: "100%",
            height: "100%",
            padding: "15px",
            paddingBottom: "10px",
            border: "2px solid var(--dashboard-border) !important",
          }}
        >
          <div style={{ display: "flex", width: "100%" }}>
            <Typography
              variant="p"
              sx={{
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
                fontWeight: "var(--font-weight)",
                color: "var(--font-color)",
              }}
              className="title_style"
            >
              {t(`dashboard.fund_details`)}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
                marginBottom: 0,
              }}
            >
              <span
                style={{
                  height: "15px",
                  width: "15px",
                  background:
                    "linear-gradient(to bottom, #B1D696F0, #B1D696F0, #B1D696F0,#B1D696F0,#B1D696F0)",
                  borderRadius: "1px",
                  // marginTop: "5%",
                  marginRight: "12px",
                }}
              />
              <span
                className="legend_style"
                style={{ marginTop: "5px", marginRight: "20px" }}
              >
                {t("dashboard.credit")}
              </span>
              <span
                style={{
                  height: "15px",
                  width: "15px",
                  background:
                    "linear-gradient(to bottom, #70B5DCD4, #70B5DCD4, #70B5DCD4, #70B5DCD4, #70B5DCD4)",
                  fontWeight: "var(--font-weight)",
                  borderRadius: "1px",
                  marginRight: "12px",
                  // marginBottom: "10px",
                }}
              />
              <span className="legend_style">{t("dashboard.debit")}</span>
            </div>
          </div>
          {array.map((data) => (
            <Grid
              container
              columnSpacing={2}
              rowSpacing={3}
              columns={{ xs: 1, sm: 2, md: 2, lg: 2 }}
              key={data.id}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Paper
                  className="fundStyle"
                  elevation={3}
                  style={{
                    background: data.background,
                    color: data.color,
                  }}
                >
                  <span>{data.name}</span>
                </Paper>
              </Grid>

              <Grid item xs={1} sm={1} md={1} lg={1}>
                <Paper
                  className="fundStyle"
                  elevation={3}
                  style={{
                    background: "#A8A0A071",
                    fontWeight: "var(--font-weight)",
                    fontSize: "var(--font-size)",
                  }}
                >
                  <span>
                    <CountUp
                      start={0}
                      end={data.value}
                      duration={2.5}
                      className="common-font-style"
                      useIndianSeparators={true}
                      prefix={rupeeSymbol}
                      style={{ fontSize: 55 }}
                    />
                  </span>
                </Paper>
              </Grid>
            </Grid>
          ))}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChartComponent;
