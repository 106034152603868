import { DownloadForOffline } from "@mui/icons-material";
import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { format } from "date-fns";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import React, { useCallback, useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import api from "../api/http-common";
import { AppData } from "../common/AppData";
import CustomCommonButton from "../common/Components/CustomCommonButton";
import CustomDateRangePicker from "../common/Components/CustomDateRangePicker";
import CustomRadioButton from "../common/Components/CustomRadioButton";
import Dropdown from "../common/Components/Dropdown";
import Textfield from "../common/Components/TextField";
import Extension from "../common/Extension";
import GridTable from "../common/GridTable";
import Loading from "../common/Loading";
import Styles from "../common/Styles";
import Logger from "../common/Logger";

//custom filter array
const Filter = [
  {
    label: "Yearly",
    value: "yearly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Today",
    value: "today",
  },
];
const logger = new Logger({ level: "log" });
const Collections = () => {
  // const { userCredentails, setUserCredentails } =
  //   useContext(UserDetailsContext);
  const { t } = useTranslation("global");
  const columns = AppData.Collections.map((column) => ({
    ...column,
    headerName: t(`transaction.${column.field}`),
  }));

  const PeriodFilter = Filter.map((fil) => ({
    ...fil,
    label: t(`transaction.${fil.value}`),
  }));

  //radio button array
  const RadioArray = [
    {
      name: t("transaction.all"),
      value: "",
    },
    {
      name: t("transaction.credit"),
      value: "credit",
    },
    {
      name: t("transaction.debit"),
      value: "debit",
    },
  ];

  //get today date
  const today = new Date();
  const currentDate = format(today, "yyyy-MM-dd");

  // const role_id = userCredentails.userdata.role_id;
  // const branch_id = userCredentails.userdata.branch_id;
  // const entity_id = userCredentails.entitydata.entity_id;
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const entity_id = parseInt(sessionStorage.getItem("entityId"));

  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [cumFilter, setCumFilter] = useState("All");
  const [radioFilter, setRadioFilter] = useState("");

  const [branches, setBranches] = useState([]);
  const [collections, setCollections] = useState([]);
  const [transData, setTransData] = useState([]);

  //get all the data
  const getData = useCallback(async () => {
    try {
      handleLoadingOpen();

      const branchAPI =
        role_id === 1 ? `branch/byEntity/${entity_id}` : `branch/${branch_id}`; //get branch based on role
      const transAPI =
        role_id === 1
          ? `/loan/collections/${entity_id}`
          : `loan/collectionsByBranch/${branch_id}`; //get collection based on role
      logger.log("Initiate Fetch branch");
      const branchRes = await api.get(branchAPI);
      logger.log("Fetch branch Successfully");
      setBranches(branchRes.data); //store in state
      logger.log("Initiate Fetch collections");
      const transRes = await api.get(transAPI);
      logger.log("Fetch collections Successfully");
      const transData =
        role_id === 1
          ? transRes.data
          : transRes.data.filter((data) => data.date === currentDate); //show today data only
      setTransData(transData);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  }, [branch_id, currentDate, entity_id, role_id]);

  useEffect(() => {
    getData();
  }, [getData]);

  //show calculation of the income and outcome func
  const calculateCollections = (row) => {
    const calculate = (category) =>
      row
        .filter((data) => data.category === category)
        .map((item) => item.amount)
        .reduce((sum, item) => sum + item, 0);

    const Credit = calculate("credit");
    const Debit = calculate("debit");

    return {
      Credit,
      Debit,
    };
  };

  //table filtration function
  const filterRows = transData.filter((item) => {
    const itemDate = new Date(item.date);
    const fromDate = new Date(dates[0]);
    const toDate = new Date(dates[1]);

    const normalizedItemDate = new Date(
      itemDate.getFullYear(),
      itemDate.getMonth(),
      itemDate.getDate()
    );

    const normalizedFromDate = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate()
    );

    const normalizedToDate = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate()
    );

    return (
      (selectedBranch === "" ||
        selectedBranch === "All" ||
        item.branch_id.toString() === selectedBranch.toString()) &&
      (radioFilter === "" ||
        item.category.toString() === radioFilter.toString()) &&
      (dates.length === 0 ||
        (normalizedItemDate >= normalizedFromDate &&
          normalizedItemDate <= normalizedToDate)) &&
      (item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.customer_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.customer_id.toString().includes(searchQuery.toString()) ||
        item.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.loan_number.toString().includes(searchQuery.toString()))
    );
  });

  //dynamically change the total calculation
  useEffect(() => {
    const collectionData = calculateCollections(filterRows);
    setCollections(collectionData);
  }, [filterRows]);

  //set row to table based on the filtration
  const rows = filterRows.map((trans, index) => {
    const isFradulent = trans.fradulent;
    const rowStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "var(--font-weight)",
      // Add any additional styles for fradulent customers
    };
    return {
      id: index + 1,
      cus_id: (
        <Tooltip
          title={Extension.languageBasedValue(
            trans.fradulent_remark,
            trans.fradulent_remark_tn
          )}
          style={rowStyle}
        >
          {trans.customer_id}
        </Tooltip>
      ),
      cus_name: (
        <Tooltip
          title={Extension.languageBasedValue(
            trans.fradulent_remark,
            trans.fradulent_remark_tn
          )}
          style={rowStyle}
        >
          {Extension.languageBasedValue(
            trans.customer_name,
            trans.customer_name_tn
          )}
        </Tooltip>
      ),
      loan_id: trans.loan_number,
      pof: trans.amount,
      poc: Extension.languageBasedValue(
        trans.category === "credit" ? "Credit" : "Debit",
        trans.category === "credit" ? "வரவு" : "செலவு"
      ),

      pay_off_amount: (
        <span
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            color:
              trans.category === "credit"
                ? "var(--positive-color)"
                : "var(--negative-color)",
          }}
        >
          {trans.category === "credit" ? "+" : "-"} ₹{" "}
          {Extension.formatCurrency(trans.amount)}
        </span>
      ),
      paid_on: Extension.formatDateDMY(new Date(trans.date)),
    };
  });

  const totalCollections = [
    {
      label: t("transaction.total amount credited"),
      collection: collections.Credit,
      background:
        "linear-gradient(to bottom, #BFF377, #C5E992, #C9E99D, #CBE1AA, #DCEEC2)",
    },
    {
      label: t("transaction.total amount debited"),
      collection: collections.Debit,
      background:
        "linear-gradient(to bottom, #F77B7B, #ED8989, #E89090, #DAA4A4, #E6BFBF)",
    },
  ];

  const handleDateChange = (values) => {
    if (values && values.length > 0) {
      setDates(values.map((item) => dayjs(item)));
    } else {
      setDates([]);
    }
  };

  //custom filter function
  const handleCumFilter = async (e) => {
    logger.log("Initiate Fetch collections");
    const transres = await api.get(`/loan/collections/${entity_id}`);
    logger.log("Fetch collections Successfully");
    const transData = transres.data;
    const today = new Date();
    const monthly = format(
      new Date(today.getFullYear(), today.getMonth(), 1),
      "yyyy-MM-dd"
    );

    const yearly = format(new Date(today.getFullYear(), 0, 1), "yyyy-MM-dd");
    const weekly = format(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay() + 1
      ),
      "yyyy-MM-dd"
    );
    const value = e ? e.target.value : "today";

    switch (value) {
      case "weekly":
        const weekData = transData.filter(
          (data) =>
            data.date >= weekly && data.date <= format(today, "yyyy-MM-dd")
        );
        setTransData(weekData);
        break;
      case "monthly":
        const monthData = transData.filter(
          (data) =>
            data.date >= monthly && data.date <= format(today, "yyyy-MM-dd")
        );
        setTransData(monthData);
        break;
      case "yearly":
        const yearlyData = transData.filter(
          (data) =>
            data.date >= yearly && data.date <= format(today, "yyyy-MM-dd")
        );
        setTransData(yearlyData);
        break;
      case "All":
        setTransData(transData);
        break;
      default:
        const todayData = transData.filter(
          (data) => data.date === format(today, "yyyy-MM-dd")
        );
        setTransData(todayData);
        break;
    }
    setCumFilter(value);
  };

  //loading open func
  const handleLoadingOpen = () => {
    setLoading(true);
  };

  //loading close func
  const handleLoadingClose = () => {
    setLoading(false);
  };

  //export table to excel
  const exportToXLSX = () => {
    const dataRows = rows.map((trans) => {
      return {
        [t("transaction.id")]: trans.id,
        [t("transaction.cus_id")]: trans.cus_id.props.children,
        [t("transaction.cus_name")]: trans.cus_name.props.children,
        [t("transaction.loan_id")]: trans.loan_id,
        [t("transaction.pof")]: trans.pof,
        [t("transaction.poc")]: trans.poc,
        [t("transaction.paid_on")]: trans.paid_on,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(dataRows);
    const totalRows = rows.length + 3;
    totalCollections.forEach((collection, index) => {
      const rowNumber = totalRows + index + 1;
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [[collection.label, collection.collection]],
        { origin: { r: rowNumber, c: 0 }, skipHeader: true }
      );
    });
    worksheet["!cols"] = [
      { wch: 20 },
      { wch: 15 },
      { wch: 30 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
      { wch: 30 },
    ];
    dataRows.forEach((rowData, rowIndex) => {
      const isFradulent = rows[rowIndex] && rows[rowIndex].isFradulent;
      const rowNumber = rowIndex + 2;
      const fontColor = isFradulent ? "red" : "black";
      const fontWeight = isFradulent ? "bold" : "normal";

      const style = {
        font: {
          color: { rgb: fontColor },
          bold: fontWeight === "var(--font-weight)",
        },
      };

      XLSX.utils.sheet_add_aoa(worksheet, [[]], {
        origin: { r: rowNumber, c: 0 },
        style,
      });
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "Transactions.xlsx");
  };

  return (
    <Paper className="commonpaper">
      <Loading open={loading} handleClose={handleLoadingClose} />
      {role_id === 1 ? (
        <Grid
          container
          spacing={{ xs: 2, md: 4, sm: 4 }}
          columns={{ xs: 1, sm: 3, md: 3 }}
          sx={{
            elevation: 3,
            mb: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
          }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Grid
              container
              spacing={{ xs: 2, md: 4, sm: 4 }}
              columns={{ xs: 1, sm: 3, md: 3 }}
              sx={{
                elevation: 3,
                mb: 2,
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Grid item xs={1} sm={1} md={0.7}>
                <Dropdown
                  firstOption={t("common.all")}
                  label={t("common.select branch")}
                  value={selectedBranch}
                  search={true}
                  onChange={(e) => setSelectedBranch(e.target.value)}
                  array={branches}
                  menuValue={"branch_id"}
                  display={Extension.languageBasedValue(
                    "branch_name",
                    "branch_name_tn"
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={0.8}>
                <Dropdown
                  firstOption={t("transaction.all time")}
                  label={t("transaction.custom filter")}
                  value={cumFilter}
                  onChange={handleCumFilter}
                  search={true}
                  array={PeriodFilter}
                  menuValue={"value"}
                  display={"label"}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={1.5}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <CustomRadioButton
                  array={RadioArray}
                  onChange={(e) => setRadioFilter(e.target.value)}
                  value={radioFilter}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1.5}>
                <Textfield
                  label={t("common.search customer")}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1.5}>
                <CustomDateRangePicker
                  label={t("transaction.select range")}
                  values={dates}
                  onChange={handleDateChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Grid
              container
              spacing={{ xs: 2, md: 4, sm: 4 }}
              columns={{ xs: 1, sm: 3, md: 2 }}
              sx={{
                elevation: 3,
                mb: 2,
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              {totalCollections.map((collection) => (
                <Grid item xs={1} sm={1} md={1} key={collection.label}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 1,
                      borderRadius: "15px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      height: "15vh",
                      background: collection.background,
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        // className="common-font-style"
                        sx={{
                          textAlign: "center",
                          fontWeight: 800,
                        }}
                      >
                        {collection.label}
                      </Typography>
                      <CountUp
                        start={0}
                        end={collection.collection}
                        duration={2.5}
                        decimals={2}
                        prefix={"₹"}
                        useIndianSeparators={true}
                        style={{ fontSize: 20 }}
                      />
                    </div>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      ) : role_id === 2 ? (
        <Grid
          container
          spacing={{ xs: 2, md: 4, sm: 4 }}
          columns={{ xs: 1, sm: 3, md: 5 }}
          sx={{
            elevation: 3,
            mb: 2,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <Typography
              variant="h5"
              sx={{
                ...Styles.titleText(),
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              }}
            >
              {Extension.languageBasedValue(
                branches.branch_name,
                branches.branch_name_tn
              )}
            </Typography>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Textfield
              label={t("common.search customer")}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1.4}>
            <CustomRadioButton
              array={RadioArray}
              onChange={(e) => setRadioFilter(e.target.value)}
              value={radioFilter}
            />
          </Grid>
          {totalCollections.map((collection) => (
            <Grid item xs={1} sm={1} md={0.8} key={collection.label}>
              <Paper
                elevation={3}
                sx={{
                  p: 1,
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  background: collection.background,
                }}
              >
                <Typography
                  // className="common-font-style"
                  sx={{ textAlign: "center", fontWeight: 800 }}
                >
                  {collection.label}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CountUp
                    start={0}
                    end={collection.collection}
                    duration={2.5}
                    decimals={2}
                    prefix={"₹"}
                    useIndianSeparators={true}
                    style={{ fontSize: "21px" }}
                  />
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : null}
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          width: "100%",
          paddingBottom: 10,
        }}
      >
        <div style={{ color: `var(--app-bar-color)` }}>
          <Typography component={"p"}>*{t("transaction.includes")}</Typography>
        </div>
        <CustomCommonButton
          text={t("transaction.download")}
          icon={<DownloadForOffline />}
          onClick={exportToXLSX}
        />
      </div>

      <GridTable
        rows={rows}
        columns={columns}
        isLoading={loading}
        height={role_id === 1 ? "60vh" : "75vh"}
      />
    </Paper>
  );
};

export default Collections;
