import React, { useState, useEffect, useCallback } from "react";
import CustomRadioButton from "../../../common/Components/CustomRadioButton";
import "./Setting.css";
import { useTranslation } from "react-i18next";
import ColorPicking from "./ColorPicking";
import CustomFont from "./customFont";
import FontSize from "./FontSize";
import api from "../../../api/http-common";

const getModeArray = (language) => {
  if (language === "tm") {
    return [
      { name: "டார்க்", value: "dark" },
      { name: "லைட்", value: "light" },
    ];
  } else {
    return [
      { name: "Dark", value: "dark" },
      { name: "Light", value: "light" },
    ];
  }
};

const ThemePreference = ({loader, setLoader}) => {
  const language = localStorage.getItem("language");
  const mode_array = getModeArray(language);

  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const userId = parseInt(sessionStorage.getItem("userId"));
  const { t } = useTranslation("global");
  const body = document.querySelector('body');
  const currentTheme = body.getAttribute('data-theme');
  const [theme, setTheme] = useState(currentTheme);
  const [default_theme, setDefault_theme] = useState(false);
  const [themeChanged, setThemeChanged] = useState(false);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchThemeSettings = async () => {
      try {
        const response = await api.get(`/themes/${userId}`);
        const themeSettings = response.data;
        setSettings(themeSettings);
        setTheme(themeSettings.theme);
        document.documentElement.style.setProperty("--app-bar-color", themeSettings.app_bar_color);
        document.querySelector("body").setAttribute("data-theme", themeSettings.theme);
      } catch (error) {
        console.error("Error fetching theme settings:", error);
      }
    };

    fetchThemeSettings();
  }, [userId]);

  const updateThemeSettings = async (updatedSettings) => {
    console.log(updatedSettings);
    try {
      await api.put(`/themes/${userId}`, updatedSettings);
    } catch (error) {
      console.error("Error updating theme settings:", error);
    }
  };

  const handleTheme = (e) => {
    const newTheme = e.target.value;
    console.log(newTheme);
    const fontColor = newTheme === "light" ? "#000000" : "#ffffff";

    document.documentElement.style.setProperty("--app-bar-color", settings.app_bar_color);
    document.querySelector("body").setAttribute("data-theme", newTheme);
    document.documentElement.style.setProperty("--font-color", fontColor);

    setSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings, theme: newTheme };
      updateThemeSettings(updatedSettings);
      return updatedSettings;
    });

    setTheme(newTheme);
    setDefault_theme(false);
    setThemeChanged(true);
  };

  const handleDefaultTheme = useCallback((data) => {
    const defaultAppBarColor = "#3c5d90";
    const fontColor = data === "light" ? "#000000" : "#ffffff";

    document.documentElement.style.setProperty("--app-bar-color", defaultAppBarColor);
    document.querySelector("body").setAttribute("data-theme", data);
    document.documentElement.style.setProperty("--font-color", fontColor);

    const updatedSettings = {
      ...settings,
      theme: data,
      app_bar_color: defaultAppBarColor,
      font_color: fontColor,
    };
    setTheme(data);
    setDefault_theme(true);
    updateThemeSettings(updatedSettings);
    setSettings(updatedSettings);
  }, [settings]);

  const fontColor = theme === "light" ? "#000000" : "#ffffff";

  return (
    <>
      <FontSize theme={default_theme} />
      <CustomFont theme={theme} default_theme={default_theme} fontColor={fontColor} />
      <div className="language-preference">
        <div style={{ width: "30%", display: "flex", justifyContent: "start" }}>
          <div className="themes_name">{t("settings.mode_preference")}</div>
        </div>
        <div style={{ width: "70%", display: "flex", justifyContent: "start" }}>
          <div style={{ width: "100%", marginLeft: role_id === 1 ? "-25px" : "-20px" }}>
            <CustomRadioButton
              array={mode_array}
              value={theme}
              onChange={handleTheme}
            />
          </div>
        </div>
      </div>
      <ColorPicking
        mode={theme}
        handleTheme={handleDefaultTheme}
        themeChanged={themeChanged}
        setThemeChanged={setThemeChanged}
        handleDefaultTheme={setDefault_theme}
        loader={loader}
        setLoader={setLoader}
      />
    </>
  );
};

export default ThemePreference;
