import { MoreVert } from "@mui/icons-material";
import { Grid, Paper, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../api/http-common";
import { AppData } from "../../common/AppData";
import Dropdown from "../../common/Components/Dropdown";
import Textfield from "../../common/Components/TextField";
import Extension from "../../common/Extension";
import GridTable from "../../common/GridTable";
import Loading from "../../common/Loading";
import Logger from "../../common/Logger";
import Styles from "../../common/Styles";
// import { UserDetailsContext } from "../../context/UserDetailsContext";
import TransactionDetails from "./TransactionDetails";

const logger = new Logger({ level: "log" });
const Transaction = () => {
  // const { userCredentails, setUserCredentails } =
  //   useContext(UserDetailsContext);
  const { t } = useTranslation("global");
  const Columns = AppData.transaction.map((column) => ({
    ...column,
    headerName: t(`receipt.${column.field}`),
  }));
  // const role_id = userCredentails.userdata.role_id;
  // const branch_id = userCredentails.userdata.branch_id;
  // const entity_id = userCredentails.entitydata.entity_id;

  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const entity_id = parseInt(sessionStorage.getItem("entityId"));

  const [branches, setBranches] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [schemes, setSchemes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [selectedScheme, setSelectedScheme] = useState("All");
  const [transData, setTransData] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [transactionDetails, setTransactionDetails] = useState(false);

  const getAllData = async () => {
    try {
      handleLoadingOpen();
      const BranchAPI =
        role_id === 1 ? `/branch/byEntity/${entity_id}` : `branch/${branch_id}`;
      const transactionAPI =
        role_id === 1
          ? `/loan/loantransferfilter/${entity_id}`
          : `loan/loantransferfilterByBranch/${branch_id}`;
      const schemeAPI =
        role_id === 1
          ? `scheme/schemes/${entity_id}`
          : `/scheme/schemesForManager/${entity_id}`;
      logger.log("Initiate Fetch branches");
      const branchRes = await api.get(BranchAPI);
      logger.log("Fetch branches Successfully");
      setBranches(branchRes.data);
      logger.log("Initiate Fetch loan transfer filter");
      const trans = await api.get(transactionAPI);
      logger.log("Fetch loan transfer filter Successfully");
      const transData = trans.data.filter((data) => data.status === "Approved");
      setTransactions(transData);
      //console.log(transData);
      logger.log("Initiate Fetch schemes");
      const schemeRes = await api.get(schemeAPI);
      logger.log("Fetch schemes Successfully");
      //console.log(schemeRes.data);
      setSchemes(schemeRes.data);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleEnterTransaction = (data) => {
    setTransData(data);
    setTransactionDetails(true);
  };

  useEffect(() => {
    if (selectedBranch === "" || selectedScheme === "") setSelectedDate("");
    //console.log("choose - ", selectedBranch, selectedBranch);
  }, [selectedBranch, selectedScheme]);

  const filteredRow = transactions?.filter(
    (transaction) =>
      (selectedBranch === "" ||
        selectedBranch === "All" ||
        transaction?.branch_id?.toString() === selectedBranch?.toString()) &&
      (selectedScheme === "" ||
        selectedScheme === "All" ||
        transaction?.scheme_name.toLowerCase() ===
          selectedScheme.toLowerCase()) &&
      (transaction.first_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
        transaction?.last_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        transaction?.first_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        transaction?.last_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        transaction?.cus_login_id
          .toLowerCase()
          .toString()
          .includes(searchQuery.toLowerCase().toString()) ||
        transaction.mobile_number
          .toString()
          .includes(searchQuery.toLowerCase()) ||
        transaction?.loan_number?.toString().includes(searchQuery.toString()) ||
        transaction.scheme_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) &&
      transaction?.created_on?.toString().includes(selectedDate)
  );

  const rows = filteredRow.map((trans, index) => {
    const isFradulent = trans.fradulent;

    const rowStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "var(--font-weight)",
      // Add any additional styles for fradulent customers
    };
    return {
      id: index + 1,

      cusID: (
        <Tooltip
          title={Extension.languageBasedValue(
            trans.fradulent_remark,
            trans.fradulent_remark_tn
          )}
          style={rowStyle}
        >
          {trans.cus_login_id}
        </Tooltip>
      ),
      cusName: (
        <Tooltip
          title={Extension.languageBasedValue(
            trans.fradulent_remark,
            trans.fradulent_remark_tn
          )}
          style={rowStyle}
        >
          {Extension.languageBasedValue(
            trans.first_name,
            trans.first_name_tn
          )}{" "}
          {Extension.languageBasedValue(
            trans.last_name,
            trans.last_name_tn
          )}
        </Tooltip>
      ),
      loanId: trans.loan_number,
      schemename: Extension.languageBasedValue(
        trans.scheme_name,
        trans.scheme_name_tn
      ),
      amount: `₹ ${Extension.formatCurrency(trans.loan_amount)}`,
      loan_date: Extension.formatDateDMY(new Date(trans.created_on)),
      status: Extension.languageBasedValue(trans.status, trans.status_tn),
      moreinfo: (
        <MoreVert
          sx={{ cursor: "pointer" }}
          onClick={() => handleEnterTransaction(trans)}
        />
      ),
    };
  });

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };

  return (
    <>
      {transactionDetails ? (
        <TransactionDetails
          data={transData}
          showTransactionDetails={setTransactionDetails}
          fetchLoanDetails={getAllData}
          handleLoanClose={(value) => setTransactionDetails(value)}
        />
      ) : (
        <Paper className="commonpaper">
          <Loading open={loading} handleClose={handleLoadingClose} />
          <Grid
            container
            spacing={{ xs: 2, md: 4, sm: 4 }}
            columns={{ xs: 1, sm: 2, md: 2, lg: 4 }}
            sx={{
              elevation: 3,
              mb: 2,
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}>
              {role_id === 1 ? (
                <Dropdown
                  firstOption={t("common.all")}
                  label={t("common.branch name")}
                  value={selectedBranch}
                  search={true}
                  onChange={(e) => setSelectedBranch(e.target.value)}
                  array={branches}
                  menuValue={"branch_id"}
                  display={Extension.languageBasedValue(
                    "branch_name",
                    "branch_name_tn"
                  )}
                />
              ) : role_id === 2 ? (
                <Typography variant="h5" sx={Styles.titleText()}>
                  {Extension.languageBasedValue(
                    branches.branch_name,
                    branches.branch_name_tn
                  )}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Textfield
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                label={t("common.search customer")}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Dropdown
                firstOption={t("common.all")}
                label={t("common.scheme name")}
                value={selectedScheme}
                search={true}
                onChange={(e) => setSelectedScheme(e.target.value)}
                array={schemes}
                menuValue={"scheme_name"}
                display={Extension.languageBasedValue(
                  "scheme_name",
                  "scheme_name_tn"
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Textfield
                //   value={searchQuery}
                type={"date"}
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                label={t("receipt.date filter")}
              />
            </Grid>
          </Grid>
          <GridTable rows={rows} columns={Columns} isLoading={loading} />
        </Paper>
      )}
    </>
  );
};

export default Transaction;
