import { InputAdornment, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import Styles from "../Styles";

const Textfield = (props) => {
  const {
    value,
    name,
    onChange,
    label,
    type,
    readOnly,
    position,
    comboFixWord,
    maxLength,
    multiline,
    required,
    register,
    rows,
  } = props;

  const Type = type ?? "text";

  const handleInputChange = (e) => {
    if (Type === "number") {
      e.target.value = e.target.value
        .replace(/[^0-9]/g, "")
        .slice(0, maxLength);
    } else if (Type === "text") {
      e.target.value = e.target.value.slice(0, maxLength);
    }
    onChange(e);
  };

  const stylesCSS = {
    input: {
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
        display: "none",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: 0,
        display: "none",
      },
    },
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography
        sx={{
          ...Styles.textlabel(),
          color: readOnly ? "var(--app-bar-color)" : "var(--font-color)",
        }}
      >
        {label}
      </Typography>
      <Paper style={{ backgroundColor: "transparent" }}>
        <TextField
          type={Type === "" || Type == null ? "text" : Type}
          name={name}
          {...register}
          required={required ?? false}
          sx={{
            backgroundColor: readOnly
              ? "var(--disabled-field)"
              : "var(--text-field)",
            ...stylesCSS.input,
            color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-size)",
            "& textarea": {
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
              color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
            },
            "& .MuiSvgIcon-root,.MuiTypography-root": {
              color: readOnly ? "var(--disabled-font)" : "var(--font-color)", // Change the color of the dropdown icon
            },
          }}
          fullWidth
          inputProps={{
            style: {
              minHeight: multiline ? "" : "15px",
              maxHeight: multiline ? "" : "22px",
              color: readOnly ? "var(--disabled-font)" : "var(--font-color)",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            },
          }}
          value={value}
          onChange={handleInputChange}
          size="small"
          onKeyDown={(e) => {
            if (Type === "number") {
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
            } else {
              return;
            }
          }}
          multiline={multiline === "" ? false : multiline}
          rows={multiline ? rows : ""}
          InputProps={{
            readOnly: readOnly == null || readOnly === "" ? false : readOnly,
            startAdornment: position === "start" && (
              <InputAdornment position={position}>
                {comboFixWord}
              </InputAdornment>
            ),
            endAdornment: position === "end" && (
              <InputAdornment position={position}>
                {comboFixWord}
              </InputAdornment>
            ),
          }}
        />
      </Paper>
    </div>
  );
};

export default Textfield;
