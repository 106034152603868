import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import toast from "react-hot-toast";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

class SnackBar {
  SimpleSnackbar(open, color, handleClose, title) {
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={color} sx={{ width: "100%" }}>
        {title}
      </Alert>
    </Snackbar>;
  }

  toastMsg(warning, message) {
    toast[warning](message, {
      style: {
        border:
          warning === "success" ? "1px solid #008D21" : "1px solid #FF0000",
        padding: "10px",
        // backgroundColor: "var(--text-field)",
        color: warning === "success" ? "#008D21" : "#FF0000",
        fontFamily: "var(--font-family)",
        fontSize: "var(--font-size)",
      },
      // icon: '👏',
      position: "top-right",
      reverseOrder: false,
      // iconTheme: {
      //   primary: '#713200',
      //   secondary: '#FFFAEE',
      // },
    });
  }
}

const snc = new SnackBar();
export default snc;
