import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import global_en from "./common/Languages/global_en.json";
import global_tm from "./common/Languages/global_tm.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import { UserDetailsProvider } from "./context/UserDetailsContext";
import { BrowserRouter } from "react-router-dom";
import { NotificationDetailsProvider } from "./context/NotificationContext";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    tm: {
      global: global_tm,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserDetailsProvider>
        <NotificationDetailsProvider>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </NotificationDetailsProvider>
      </UserDetailsProvider>
    </BrowserRouter>
  </React.StrictMode>
);
