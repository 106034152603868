import { Add, Cancel, PublishedWithChanges, Save } from "@mui/icons-material";

export const rupeeSymbol = "\u20B9";
export const rupeePosition = "start";
export const percentagePosition = "end";
export const CommonAddIcon = <Add />;
export const CommonSaveIcon = <Save />;
export const CommonUpdateIcon = <PublishedWithChanges />;
export const commonCancelIcon = <Cancel />;
export const CommonFontStyle = "san-serif";
