import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
} from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { DatePicker } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Styles from "../Styles";
import Style from "../Styles";

const { RangePicker } = DatePicker;

const CustomDateRangePicker = ({ label, values, onChange }) => {
  const { t } = useTranslation("global");
  return (
    <>
      <Typography sx={Styles.textlabel()}>{label}</Typography>
      <Paper
        sx={{
          backgroundColor: "var(--commonpaper-background)",
        }}
      >
        <RangePicker
          format={"DD-MM-YYYY"}
          value={values}
          onChange={onChange}
          variant="filled"
          style={{
            backgroundColor: "var(--text-field)",
            width: "100%",
            border: "none",
            height: "40px",
            color: "var(--font-color)",
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-size)",
            ".ant-picker-input input::placeholder": {
              color: "var(--font-color)",
            },
            "& .anticon": {
              color: "var(--font-color)",
            },
          }}
          popupStyle={Style.dropDown()}
          placeholder={[t("common.fromdate"), t("common.todate")]}
          superPrevIcon={<ArrowCircleLeftOutlined />}
          superNextIcon={<ArrowCircleRightOutlined />}
        ></RangePicker>
      </Paper>
    </>
  );
};

export default CustomDateRangePicker;
