import { createContext, useState } from "react";

const UserDetailsContext = createContext();

const UserDetailsProvider = ({ children }) => {
  var [userCredentails, setUserCredentails] = useState([]);

  return (
    <UserDetailsContext.Provider
      value={{ userCredentails, setUserCredentails }}
    >
      {children}
    </UserDetailsContext.Provider>
  );
};

export { UserDetailsContext, UserDetailsProvider };
