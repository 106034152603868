import { Box, Typography } from "@mui/material";
import * as React from "react";
import JSL_LOGO from "../assets/GoldSilver.png";

function ResponsiveAppBar() {
  return (
    // <AppBar position="static" sx={{ background: "transparent" }}>
    <Box
      href="/"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ml: 3,
        mt: 3,
      }}
    >
      <img src={JSL_LOGO} height={50} alt="" />
      <Typography
        variant="h4"
        noWrap
        component="a"
        href="/"
        sx={{
          ml: 2,
          display: { xs: "none", md: "flex" },
          fontFamily: "cambria",
          fontSize: 40,
          letterSpacing: ".3rem",
          // color: "ivory",
          textDecoration: "none",
        }}
      >
        JSL
      </Typography>
    </Box>
  );
}
export default ResponsiveAppBar;
