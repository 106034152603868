import {
  Cancel,
  Done,
  DownloadForOffline,
  MoreVert,
  Phone,
  Update,
} from "@mui/icons-material";
import { Button, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { saveAs } from "file-saver";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import api from "../../api/http-common";
import { AppData } from "../../common/AppData";
import CustomCommonButton from "../../common/Components/CustomCommonButton";
import Dropdown from "../../common/Components/Dropdown";
import Textfield from "../../common/Components/TextField";
import Extension from "../../common/Extension";
import { CustomNoRowsOverlay } from "../../common/GridTable";
import Loading from "../../common/Loading";
import SnackBar from "../../common/SnackBar";
import Styles from "../../common/Styles";
import { EditLoanProcess } from "../loanProcess/EditLoanProcess";
import "./Auction.css";
import Interaction from "./Backlogs/Interaction";
import Logger from "../../common/Logger";
import CustomDateRangePicker from "../../common/Components/CustomDateRangePicker";
import dayjs from "dayjs";
import { format } from "date-fns";

const STATUS = [
  { name_tn: "ஏலம் விடக்கூடியது", name: "Auctionable", value: false },
  { name_tn: "திட்டம் காலாவதியானது", name: "Scheme Expired", value: true },
];

const Filter = [
  {
    label: "Yearly",
    value: "yearly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Today",
    value: "today",
  },
];

const Auction = ({ renews }) => {
  // const { userCredentails, setUserCredentails } =
  //   useContext(UserDetailsContext);
  const { t } = useTranslation("global");
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const branch_id = sessionStorage.getItem("branchId");
  const entity_Id = sessionStorage.getItem("entityId");
  const logger = new Logger({ level: "log" });

  const PeriodFilter = Filter.map((fil) => ({
    ...fil,
    label: t(`transaction.${fil.value}`),
  }));

  const columns =
    role_id === 1
      ? AppData.auction.map((column) => ({
          ...column,
          headerName: t(`auction process.${column.field}`),
        }))
      : AppData.auctionmanager.map((column) => ({
          ...column,
          headerName: t(`auction process.${column.field}`),
        }));

  // const role_id = userCredentails.userdata.role_id;
  // const branch_id = userCredentails.userdata.branch_id;
  // const entity_Id = userCredentails.entitydata.entity_id;

  const currentDate = Extension.formatDate(new Date());

  const [branches, setBranches] = useState([]);
  const [users, setUsers] = useState([]);
  const [loans, setLoans] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [loading, setLoading] = useState(true);
  const [updates, setUpdates] = useState("");
  const [loanId, setLoanId] = useState(null);
  const [loanData, setLoanData] = useState({});
  const [state, setState] = useState(null);
  const [showEditProcess, setShowEditProcess] = useState(false);
  const [showInteraction, setShowInteraction] = useState(false);
  const [items, setItems] = useState([]);
  const [loanFilter, setLoanFilter] = useState("All");
  const [cumFilter, setCumFilter] = useState("All");
  const [dates, setDates] = useState([]);

  const getData = async () => {
    try {
      handleLoadingOpen();
      const BranchAPI =
        role_id === 1 ? `branch/byEntity/${entity_Id}` : `branch/${branch_id}`;
      logger.log("Initiate Fetch users");
      const UserData = await api
        .get(`/users/byEntity/${entity_Id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch users Successfully");
      setUsers(UserData);
      const LoanAPI =
        role_id === 1
          ? `loan/AuctionEligibleList/${entity_Id}`
          : `/loan/AuctionEligibleListByBranch/${branch_id}`;
      logger.log("Initiate Fetch branch");
      const branchRes = await api.get(BranchAPI);
      logger.log("Fetch branch Successfully");
      setBranches(branchRes.data);

      const SchemeExpiryApi =
        role_id === 1
          ? `/loan/schemeBasedLoanExpiry/${entity_Id}`
          : `/loan/schemeBasedLoanExpiryByBranch/${branch_id}`;

      logger.log("Initiate Fetch scheme expire loans");
      const schemeExpiries = await api
        .get(SchemeExpiryApi)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch scheme expire loans Successfully");
      logger.log("Initiate Fetch auction eligible loans");
      const loans = await api
        .get(LoanAPI)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch auction eligible loans Successfully");
      setLoans(loans.concat(schemeExpiries));
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };

  const handleBranchChange = async (e) => {
    const branch = e.target.value;
    setSelectedBranch(branch);
  };

  useEffect(() => {
    getData();
  }, []);

  const [readOnly, setReadOnly] = useState(Array(loans.length).fill(true));

  const handleReadOnly = (index) => {
    const updatedReadOnly = [...readOnly];
    updatedReadOnly[index] = !updatedReadOnly[index];
    setReadOnly(updatedReadOnly);
  };

  const handleUpdate = (index) => {
    if (updates) {
      const loanID = loans[index].loan_num;
      SnackBar.toastMsg("success", `Loan of ${loanID} is Updated`);
      handleReadOnly(index);
    } else {
      SnackBar.toastMsg("error", `Please Update Something`);
    }
  };

  const handleDisclose = async (item) => {
    try {
      const loan_id = item.loan_id;
      logger.log("Initiate Fetch loans");
      const loan_data = await api
        .get(`loan/${loan_id}`)
        .then((res) => res.data)
        .catch((error) => logger.log(error));
      logger.log("Fetch loans Successfully");
      const data = {
        ...loan_data,
        status: "Disposed",
        status_tn: "அகற்றப்பட்டது",
        actual_closer_date: currentDate,
      };
      logger.log("Initiate put dispose");
      await api
        .put(`/loan/${loan_id}`, data)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Put dispose Successfully");
      getData();
      SnackBar.toastMsg("success", `Loan of ${item.loan_num} is Disposed`);
    } catch (error) {
      logger.log(error);
    }
  };

  const handleCancel = () => {
    Swal.fire({
      title: "Cancelled!",
      icon: "error",
      text: "Dispose Cancelled!",
      showCancelButton: false,
      confirmButtonText: "Ok",
      confirmButtonColor: "#1976d2",
    });
  };

  const showConfirmationDialog = (item) => {
    Swal.fire({
      title: "Do you want to confirm?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#00A550",
      cancelButtonColor: "#d33",
    })
      .then((result) => {
        if (result.isConfirmed) {
          handleDisclose(item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          handleCancel();
        }
      })
      .catch((err) => logger.log(err));
  };

  const filterRows = loans.filter((item) => {
    const itemDate = new Date(item.loan_date);
    const fromDate = new Date(dates[0]);
    const toDate = new Date(dates[1]);

    const normalizedItemDate = new Date(
      itemDate.getFullYear(),
      itemDate.getMonth(),
      itemDate.getDate()
    );

    const normalizedFromDate = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate()
    );

    const normalizedToDate = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate()
    );

    const today = new Date();
    const monthly = format(
      new Date(today.getFullYear(), today.getMonth(), 1),
      "yyyy-MM-dd"
    );

    const yearly = format(new Date(today.getFullYear(), 0, 1), "yyyy-MM-dd");
    const weekly = format(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay() + 1
      ),
      "yyyy-MM-dd"
    );

    return (
      (selectedBranch === "All" ||
        item.branch_id.toString() === selectedBranch.toString()) &&
      (searchQuery === "" ||
        item.customer_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.customer_num
          .toLowerCase()
          .toString()
          .includes(searchQuery.toLowerCase().toString()) ||
        item.customer_num
          .toLowerCase()
          .toString()
          .includes(searchQuery.toLowerCase().toString())) &&
      (dates.length === 0 ||
        (normalizedItemDate >= normalizedFromDate &&
          normalizedItemDate <= normalizedToDate)) &&
      (loanFilter === "" ||
        loanFilter === "All" ||
        item.schemeExpiry === loanFilter) &&
      (cumFilter === "" ||
        cumFilter === "All" ||
        (cumFilter === "weekly" &&
          item.loan_date >= weekly &&
          item.loan_date <= format(today, "yyyy-MM-dd")) ||
        (cumFilter === "monthly" &&
          item.loan_date >= monthly &&
          item.loan_date <= format(today, "yyyy-MM-dd")) ||
        (cumFilter === "yearly" &&
          item.loan_date >= yearly &&
          item.loan_date <= format(today, "yyyy-MM-dd")) ||
        (cumFilter === "today" &&
          item.loan_date >= format(today, "yyyy-MM-dd") &&
          item.loan_date <= format(today, "yyyy-MM-dd")))
    );
  });

  //custom filter function
  const handleCumFilter = useCallback(() => {
    const data = filterRows;
    const today = new Date();
    const monthly = format(
      new Date(today.getFullYear(), today.getMonth(), 1),
      "yyyy-MM-dd"
    );

    const yearly = format(new Date(today.getFullYear(), 0, 1), "yyyy-MM-dd");
    const weekly = format(
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay() + 1
      ),
      "yyyy-MM-dd"
    );
    const value = cumFilter || "All";

    switch (value) {
      case "weekly":
        const weekData = data.filter(
          (data) =>
            data.date >= weekly && data.date <= format(today, "yyyy-MM-dd")
        );
        return weekData;
      case "monthly":
        const monthData = data.filter(
          (data) =>
            data.date >= monthly && data.date <= format(today, "yyyy-MM-dd")
        );
        return monthData;
      case "yearly":
        const yearlyData = data.filter(
          (data) =>
            data.date >= yearly && data.date <= format(today, "yyyy-MM-dd")
        );
        return yearlyData;
      case "today":
        const todayData = data.filter(
          (data) => data.date === format(today, "yyyy-MM-dd")
        );
        return todayData;
      case "All":
        return data;
      default:
    }
  }, [cumFilter, filterRows]);

  useEffect(() => {
    handleCumFilter();
  }, [handleCumFilter]);

  const rows = filterRows.map((item, index) => {
    const isFradulent = item.fradulent;

    const customerNameStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "var(--font-weight)",
    };
    return {
      id: index + 1,
      schemeExpiry: item.schemeExpiry,
      last_paid: Extension.formatDateDMY(new Date(item.last_due_date)),
      loan_date: Extension.formatDateDMY(new Date(item.loan_date)),
      loan_num: item.loan_num,
      cus_login_id: (
        <Tooltip
          title={Extension.languageBasedValue(
            item.fradulent_remark,
            item.fradulent_remark_tn
          )}
          style={customerNameStyle}
        >
          {item?.customer_num || "--"}
        </Tooltip>
      ),
      customer_name: (
        <Tooltip
          title={Extension.languageBasedValue(
            item.fradulent_remark,
            item.fradulent_remark_tn
          )}
          style={customerNameStyle}
        >
          {item
            ? Extension.languageBasedValue(
                item.customer_name,
                item.customer_name_tn
              )
            : "--"}
        </Tooltip>
      ),
      scheme_name: Extension.languageBasedValue(
        item.scheme_name,
        item.scheme_name_tn
      ),
      amount: `₹ ${Extension.formatCurrency(item.loan_amount)}`,
      status:
        role_id === 1 ? (
          <Button
            color="error"
            variant="contained"
            className="common-font-style"
            onClick={() => showConfirmationDialog(item)}
          >
            {t("auction process.dispose")}
          </Button>
        ) : (
          <>
            {readOnly[index] ? (
              <input
                type="number"
                className="table-input-field"
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e) => setUpdates(e.target.value)}
              />
            ) : null}
            {readOnly[index] ? (
              <>
                <Cancel
                  sx={{ cursor: "pointer" }}
                  color="error"
                  onClick={() => handleReadOnly(index)}
                />
                <Done
                  sx={{ cursor: "pointer" }}
                  color="success"
                  onClick={() => handleUpdate(index)}
                />
              </>
            ) : (
              <Update
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={() => handleReadOnly(index)}
              />
            )}
          </>
        ),
      moreinfo: (
        <>
          <MoreVert
            onClick={() => handleLoanProcess("view", item.loan_id)}
            sx={{ cursor: "pointer" }}
          />
          <Tooltip title={t("auction process.interaction")}>
            <Phone
              color="primary"
              onClick={() =>
                handleInteraction(
                  item.loan_id,
                  item.loan_num,
                  item.customer_num,
                  item.customer_name,
                  item
                )
              }
              sx={{ cursor: "pointer" }}
            />
          </Tooltip>
        </>
      ),
    };
  });

  const exportToXLSX = () => {
    const dataRows = rows.map((auc) => {
      return {
        [t("auction process.id")]: auc.id,
        [t("auction process.loan_date")]: auc.loan_date,
        [t("auction process.loan_num")]: auc.loan_num,
        [t("auction process.cus_login_id")]: auc.cus_login_id.props.children,
        [t("auction process.customer_name")]: auc.customer_name.props.children,
        [t("auction process.scheme_name")]: auc.scheme_name,
        [t("auction process.amount")]: auc.amount,
        [t("auction process.last_paid")]: auc.last_paid,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(dataRows);

    worksheet["!cols"] = [
      { wch: 5 },
      { wch: 15 },
      { wch: 20 },
      { wch: 15 },
      { wch: 30 },
      { wch: 20 },
      { wch: 15 },
    ];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(data, "Auction.xlsx");
  };

  const handleLoanProcess = (state, loan_id) => {
    setState(state);
    setLoanId(loan_id);
    setShowEditProcess(true);
  };

  const handleInteraction = async (
    loan_id,
    loan_num,
    customer_num,
    customer_name,
    item
  ) => {
    handleLoadingOpen();
    try {
      const data = await api
        .get(`/loan/interactionByLoan/${loan_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      setItems(data);
      setLoanData({ loan_id, loan_num, customer_num, customer_name, item });
      setShowInteraction(true);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };

  const getFollowupData = async (loan_id) => {
    handleLoadingOpen();
    try {
      const data = await api
        .get(`/loan/interactionByLoan/${loan_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      setItems(data);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };

  const handleInteractionClose = () => {
    setShowInteraction(false);
  };

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };

  if (role_id !== 1 && role_id !== 2 && !role_id) {
    Extension.logout();
  }
  const getRowClassName = ({ row }) => {
    const isScheme = row.schemeExpiry;
    if (isScheme) {
      return "scheme_loan_row";
    } else {
      return "auctioned_loan_row";
    }
  };

  const handleDateChange = (values) => {
    if (values && values.length > 0) {
      setDates(values.map((item) => dayjs(item)));
    } else {
      setDates([]);
    }
  };

  return (
    <>
      {showEditProcess ? (
        <EditLoanProcess
          state={state}
          loanId={loanId}
          setShowEditProcess={setShowEditProcess}
          fetchLoanDetails={getData}
        />
      ) : (
        <Paper className="commonpaper">
          <Loading open={loading} handleClose={handleLoadingClose} />
          {showInteraction && (
            <Interaction
              loan_id={loanData}
              open={showInteraction}
              items={items}
              user={users}
              onClose={handleInteractionClose}
              fetchData={(item) => getFollowupData(item)}
            />
          )}
          <Grid
            container
            spacing={{ xs: 2, md: 4, sm: 4 }}
            columns={{ xs: 1, sm: 2, md: 5, lg: 5 }}
            sx={{
              elevation: 3,
              mb: 2,
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1}>
              {role_id === 1 ? (
                <Dropdown
                  firstOption={t("common.all")}
                  label={t("common.branch name")}
                  value={selectedBranch}
                  onChange={handleBranchChange}
                  search={true}
                  array={branches}
                  menuValue={"branch_id"}
                  display={Extension.languageBasedValue(
                    "branch_name",
                    "branch_name_tn"
                  )}
                />
              ) : role_id === 2 ? (
                <Typography
                  variant="h5"
                  sx={{
                    ...Styles.titleText(),
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                >
                  {Extension.languageBasedValue(
                    branches.branch_name,
                    branches.branch_name_tn
                  )}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Textfield
                label={t("common.search customer")}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Dropdown
                firstOption={t("common.all")}
                label={t("common.loan_type")}
                value={loanFilter}
                onChange={(e) => setLoanFilter(e.target.value)}
                search={true}
                array={STATUS}
                menuValue={"value"}
                display={Extension.languageBasedValue("name", "name_tn")}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Dropdown
                firstOption={t("transaction.all time")}
                label={t("transaction.custom filter")}
                value={cumFilter}
                onChange={(e) => setCumFilter(e.target.value)}
                search={true}
                array={PeriodFilter}
                menuValue={"value"}
                display={"label"}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <CustomDateRangePicker
                label={t("transaction.select range")}
                values={dates}
                onChange={handleDateChange}
              />
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%",
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "30%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                  marginBottom: 0,
                }}
              >
                <span
                  style={{
                    height: "15px",
                    width: "15px",
                    backgroundColor: "var(--auction-row)",
                    borderRadius: "1px",
                    // marginTop: "5%",
                    marginRight: "12px",
                  }}
                />
                <span className="legend_style" style={{ marginTop: "5px" }}>
                  {t("auction process.auction_eligible")}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                  marginBottom: 0,
                }}
              >
                <span
                  style={{
                    height: "15px",
                    width: "15px",
                    backgroundColor: "var(--scheme-expiry-row)",
                    fontWeight: "var(--font-weight)",
                    borderRadius: "1px",
                    marginRight: "12px",
                    // marginBottom: "10px",
                  }}
                />
                <span className="legend_style">
                  {t("auction process.loan_expired")}
                </span>
              </div>
            </div>
            <CustomCommonButton
              text={t("transaction.download")}
              icon={<DownloadForOffline />}
              onClick={exportToXLSX}
            />
          </div>
          <DataGrid
            rows={rows}
            columns={columns}
            slots={{
              noRowsOverlay: () => CustomNoRowsOverlay(loading, rows),
            }}
            sx={{
              height: "72vh",
              width: "100%",
              overflowY: "auto",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
              "&, [class^=MuiDataGrid]": { border: "none" },
              "& .grid-header": {
                backgroundColor: `var(--app-bar-color)`,
                color: "var(--header-font-color)",
              },
              "& .grid-cell-left": {
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                border: "none",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                position: "relative",
              },
              "& .MuiDataGrid-cell": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "var(--font-color)",
              },
              "& .MuiTablePagination-displayedRows": {
                margin: "auto",
                color: "var(--font-color)",
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              },
              "& .MuiTablePagination-actions": {
                margin: "auto",
                color: "var(--font-color)",
              },
            }}
            disableRowSelection
            disableRowSelectionOnClick
            disableColumnMenu
            getRowClassName={getRowClassName}
            pageSizeOptions={100}
            columnHeaderHeight={30}
          />
        </Paper>
      )}
    </>
  );
};

export default Auction;
