import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import api from "../../api/http-common";
import Extension from "../../common/Extension";
import Loading from "../../common/Loading";
import Logger from "../../common/Logger";
import SnackBar from "../../common/SnackBar";
import Styles from "../../common/Styles";
import { CommonTextField } from "../../ui/CommonTextField";
import SMS_API_FUNCTION from "../../common/SMS_API_FUNCTION";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
export const AppendLoan = (props) => {
  const { t } = useTranslation("global");
  const logger = new Logger({ level: "log" });
  const userId = parseInt(sessionStorage.getItem("userId"));
  const entityId = sessionStorage.getItem("entityId");
  const [loading, setLoading] = useState(false);
  const { register, setValue, getValues, handleSubmit } = useForm();
  const [schemeId, setSchemeId] = useState(0);
  const [requestedamount, setRequestedAmount] = useState(0);
  const [interestDetails, setInterestDetails] = useState(0);
  const [messageCheck, setMessageCheck] = useState(true);

  useEffect(() => {
    // setValue("max_loan_amount", 10000);
    const updateProps = { ...props };
    delete updateProps.backBtn;
    delete updateProps.setHeaderTxt;
    setSchemeId(updateProps.schemeId);
    //console.log("update loan - ", updateProps);
    Object.entries(updateProps).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [props]);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    props.setHeaderTxt(t("loan append.loan append"));
  };
  const handleClose = () => {
    setOpen(false);
    props.setHeaderTxt(t("common.loan details"));
  };

  const handleLoadingOpen = () => {
    setLoading(true);
  };

  const handleLoadingClose = () => {
    setLoading(false);
  };

  const splitString = (inputString) => {
    // Use regular expressions to separate alphabetic and numeric parts
    const parts = inputString.split(/(\d+)/);
    if (parts) {
      // Separate alphabetic and numeric parts
      const alphabeticPart = parts
        .filter((part, index) => index % 2 === 0)
        .join("");
      const numericPart = parts
        .filter((part, index) => index % 2 !== 0)
        .join("");
      return { alphabeticPart, numericPart };
    } else {
      return { alphabeticPart: "", numericPart: "" };
    }
  };

  const generateSequence = (char, numeric) => {
    const currentChar = char.toUpperCase();
    const currentNumeric = parseInt(numeric);
    //console.log("prefix char code - ", currentChar.charCodeAt(1));
    if (currentNumeric === 1000) {
      let nextChar;
      if (currentChar.charAt(0) === "Z") {
        const charLength = currentChar.length + 1;
        nextChar = "A".repeat(charLength);
      } else {
        nextChar = String.fromCharCode(currentChar.charCodeAt(0) + 1);
      }

      return `${nextChar}0001`;
    } else {
      return `${currentChar}${(currentNumeric + 1).toString().padStart(4, 0)}`;
    }
  };

  const getFundAmount = async () => {
    try {
      const BRANCH_API = `/Fund/CurrentTotalAmountByBranch/${props.branch_id}`;
      logger.log("Initiate Fetch CurrentTotalAmountByBranch");
      const Branch_res = await api
        .get(BRANCH_API)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch CurrentTotalAmountByBranch Successfully");
      //console.log("Branch Fund Amount", Branch_res);
      setValue("fund_amount", Branch_res.total_fund_amount);
    } catch (error) {
      logger.log(error);
    }
  };

  const handleCheckboxChange = (event) => {
    setMessageCheck(event.target.checked);
  };

  useEffect(() => {
    getFundAmount();
  }, []);

  const getpaymenttype = (value, key) => {
    const paymentMethods = [
      { text: "Cash", value: "By Cash", text_tn: "பணம்" },
      {
        text: "Account Transfer",
        value: "By Account",
        text_tn: "கணக்கு பரிமாற்றம்",
      },
      { text: "UPI/ Others", value: "UPI/Others", text_tn: "UPI/ மற்றவை" },
    ];
    const method = paymentMethods.find((method) => method.value === value);
    return method ? method[key] : "Not found";
  };

  const handleForm = async (data) => {
    //console.log(data);
    if (parseInt(getValues("fund_amount")) < parseInt(requestedamount)) {
      SnackBar.toastMsg(
        "error",
        "Please Enter The Amount Below The Maximum Amount"
      );
      return;
    }
    if (requestedamount <= 0) {
      SnackBar.toastMsg("error", "Please fill the request append loan amount!");
      return;
    } else {
      try {
        handleLoadingOpen();
        const loan_amount =
          parseInt(data.loan_amount) + parseInt(requestedamount);
        //console.log("Totatl amounet - ", loan_amount);
        if (loan_amount > parseInt(data.max_loan_amount)) {
          SnackBar.toastMsg(
            "error",
            "Please Enter The Amount Below The Maximum Amount"
          );
          return;
        }
        logger.log("Initiate Fetch loan");
        const loanRes = await api
          .get(`loan/${data.loan_id}`)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Fetch loan Successfully");
        //console.log("Loan Res - ", loanRes);
        //console.log("handsonamount - ", getValues("hands_on_amount"));
        //console.log("handsonamt - ", getValues("handsonamt"));
        const hands_on_amount = getValues("hands_on_amount");
        const handsonamt = getValues("handsonamt");
        const balance_amount =
          parseInt(loanRes.current_balance_pa) + parseInt(requestedamount);
        const updateLoan = {
          ...loanRes,
          loan_amount: loan_amount,
          append_date: new Date().toISOString().split("T")[0],
          loan_append: true,
          hands_on_amount: hands_on_amount + handsonamt,
          old_pa: data.loan_amount,
          current_balance_pa: balance_amount,
        };
        //console.log("updated loan - ", updateLoan);
        logger.log("Initiate update loan");
        await api
          .put(`loan/${updateLoan.loan_id}`, updateLoan)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Fetch update loan Successfully");
        //console.log("Loan res - ", loanRes, updateLoan);
        handleFundAmount(data.modeOfPayement);
        var created_on = loanRes.created_on;
        var addOneMonth = new Date(created_on);
        addOneMonth.setMonth(addOneMonth.getMonth() + 1);
        var lastDueDate = addOneMonth.toISOString().split("T")[0];
        logger.log("Initiate Fetch TransactionsByLoan");
        const exisitingLoanTransaction = await api
          .get(`loan/TransactionsByLoan/${data.loan_id}`)
          .then((res) => res.data[0])
          .catch((err) => logger.log(err));
        logger.log("Fetch TransactionsByLoan Successfully");
        //console.log("Existing Loan Trans - ", exisitingLoanTransaction);
        let sequence;
        let sequenceNumber;
        logger.log("Initiate Fetch entity");
        const entityDetail = await api
          .get(`entity/${loanRes.entity}`)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Fetch entity Successfully");
        const customerDetails = await api
          .get(`customer/${loanRes.customer}`)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
          console.log(customerDetails)
        //console.log(entityDetail);
        //console.log(entityDetail.last_append_num);
        if (
          entityDetail.last_append_num === null ||
          entityDetail.last_append_num === "" ||
          entityDetail.last_append_num === undefined
        ) {
          sequence = "APLA0001";
          sequenceNumber = "A0001";
        } else {
          const { alphabeticPart, numericPart } = splitString(
            entityDetail.last_append_num
          );

          const updateNumber = generateSequence(alphabeticPart, numericPart);
          sequenceNumber = updateNumber;
          sequence = `APL${updateNumber}`;
        }
        //console.log("sequence", sequence, sequenceNumber);

        const entityUpdateData = {
          ...entityDetail,
          last_append_num: sequenceNumber,
        };
        logger.log("Initiate update entity");
        const updateEntity = await api.put(
          `entity/${loanRes.entity}`,
          entityUpdateData
        );
        logger.log("update entity Successfully");
        //console.log(updateEntity);
        var transaction;

        transaction = {
          amount_paid: interestDetails.interest_amount,
          balance_pa: balance_amount,
          due_amount: interestDetails.interest_amount,
          due_date: lastDueDate,
          closure_amount: 0,
          paid_on: new Date().toISOString().split("T")[0],
          payment_mode: data.modeOfPayement,
          payment_mode_tn: getpaymenttype(data.modeOfPayement, "text_tn"),
          trans_or_recipt_id: "",
          loan: data.loan_id,
          trans_seq_num: sequence,
        };
        //console.log("transaction - ", transaction);
        logger.log("Initiate post Transactions");
        const addTransaction = await api
          .post(`/loan/Transactions`, transaction)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Fetch post Transactions Successfully");
        handleFundAmount(data.modeOfPayement, interestDetails.interest_amount);
        console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
        if (messageCheck) {
          console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$")
          SMS_API_FUNCTION.loanCreationSMS_API(
            loanRes.loan_number,
            updateLoan.append_date,
            customerDetails.mobile_number,
            169259,
            requestedamount
          );
        }
        SnackBar.toastMsg("success", "Loan append successfully.!");
        if (addTransaction) {
          props.backBtn();
          handleClose();
        }
      } catch (error) {
        logger.log(error);
      } finally {
        handleLoadingClose();
      }
    }
  };

  const handleFundAmount = async (mop, inr) => {
    try {
      let total_fund_amount = 0;
      total_fund_amount =
        parseInt(getValues("fund_amount")) - parseInt(requestedamount);
      if (!inr) {
        const data = {
          credit_amount: null,
          debit_amount: requestedamount,
          funded_on: Extension.getCurrentDateTime(),
          total_fund_amount,
          entity: entityId,
          transaction_type: mop,
          funded_By: userId,
          fund_type: "LoanAmountDebit",
          branch: props.branch_id,
        };
        // //console.log(data);
        logger.log("Initiate post fund");
        const response = await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("post fund Successfully");
        // //console.log("Debit Amount", response);
      }
      if (inr) {
        total_fund_amount = parseInt(total_fund_amount) + parseInt(inr);
        const data = {
          credit_amount: inr,
          debit_amount: null,
          funded_on: Extension.getCurrentDateTime(),
          total_fund_amount,
          transaction_type: mop,
          entity: entityId,
          funded_By: userId,
          fund_type: "InterestAmountCredit",
          branch: props.branch_id,
        };
        // //console.log(data);
        logger.log("Initiate post fund");
        const response = await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("post fund Successfully");
        // //console.log("Interest Pick", response);
      }
    } catch (error) {
      logger.log(error);
    }
  };

  const handsOnAmountSet = async () => {
    var created_on = getValues("created_on");
    var addOneMonth = new Date(created_on);
    addOneMonth.setMonth(addOneMonth.getMonth() + 1);
    var lastDueDate = addOneMonth.toISOString().split("T")[0];
    if (schemeId > 0) {
      var scheme_data = await api
        .get(`scheme/${schemeId}/`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      //console.log(scheme_data);
      const loan_amount = requestedamount;
      const interestDetail = Extension.interestRateCul(
        "month",
        loan_amount,
        created_on,
        lastDueDate,
        scheme_data.duration,
        scheme_data.rate_of_interest_minimum,
        scheme_data.rate_of_interest_maximum,
        false,
        true
      );
      setInterestDetails(interestDetail);
      const str = (str) => {
        const data = str.replace(/\.00$/, "");
        return parseInt(data);
      };
      // //console.log("Interest Rate - ", interestDetail);
      // //console.log("Interest Amount Handle - ", loan_amount);
      // //console.log("requested Amount- ", parseInt(requestedamount));
      // //console.log("Interest Amount", parseInt(interestDetail.interest_amount));

      let handsOnAmount = 0;
      if (isNaN(parseInt(requestedamount))) {
        return;
      }
      handsOnAmount =
        parseInt(requestedamount) - str(interestDetail.interest_amount);
      // //console.log("Hands On Amount- ", handsOnAmount);
      setValue("handsonamt", handsOnAmount >= 0 ? handsOnAmount : 0);
    }
  };

  useEffect(() => {
    handsOnAmountSet();
  }, [requestedamount]);

  return (
    <React.Fragment>
      <Toaster limit={3} />
      <Button
        type="button"
        size="small"
        variant="contained"
        sx={{
          textTransform: "capitalize",
          fontWeight: "var(--font-weight)",
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          "&:hover": { transform: "scale(1.1)" },
        }}
        onClick={handleClickOpen}
      >
        {t("loan append.loan append")}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={"sm"}
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            background: "var(--app-bar-color)",
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-size)",
            color: "var(--header-font-color)",
          }}
        >
          {t("loan append.loan append process")}
        </DialogTitle>
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "var(--header-font-color)",
            "&:hover": {
              transform: "scale(1.2)",
            },
          }}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
        <DialogContent
          dividers
          sx={{ backgroundColor: "var(--commonpaper-background)" }}
        >
          <Loading open={loading} handleClose={handleLoadingClose} />
          <form onSubmit={handleSubmit(handleForm)}>
            <Grid container rowSpacing={1} columnSpacing={2}>
              <Grid item xs={12}>
                <Typography sx={Styles.textlabel()}>
                  {t("loan creation.fund amount")}
                </Typography>
                <CommonTextField
                  name={"fund_amount"}
                  register={register}
                  type={"number"}
                  readOnly={true}
                  comboFixWord={"\u20B9"}
                  position="start"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={Styles.textlabel()}>
                  {t("loan append.threshold amount")}
                </Typography>
                <CommonTextField
                  name={"max_loan_amount"}
                  register={register}
                  type={"float"}
                  readOnly={true}
                  comboFixWord={"\u20B9"}
                  position="start"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={Styles.textlabel()}>
                  {t("loan append.allocated loan amount")}
                </Typography>
                <CommonTextField
                  name={"loan_amount"}
                  register={register}
                  type={"number"}
                  readOnly={true}
                  comboFixWord={"\u20B9"}
                  position="start"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={Styles.textlabel()}>
                  {t("loan append.append loan amount")}
                </Typography>
                <TextField
                  sx={{
                    backgroundColor: "var(--text-field)",
                    color: "var(--font-color)",
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",

                    "& .MuiSvgIcon-root,.MuiTypography-root": {
                      color: "var(--font-color)", // Change the color of the dropdown icon
                    },
                  }}
                  fullWidth
                  size="small"
                  value={requestedamount}
                  onChange={(e) => setRequestedAmount(e.target.value)}
                  type={"number"}
                  defaultValue={0}
                  onKeyDown={(e) => {
                    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
                  }}
                  InputProps={{
                    readOnly: false,
                    inputProps: {
                      min: 0,
                    },
                    startAdornment: (
                      <InputAdornment position="start">{"₹"}</InputAdornment>
                    ),
                    style: {
                      fontFamily: "var(--font-family)",
                      fontSize: "var(--font-size)",
                      color: "var(--font-color)",
                      maxHeight: "40px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={Styles.textlabel()}>
                  {t("loan append.hands on amount")}
                </Typography>
                <CommonTextField
                  name={"handsonamt"}
                  register={register}
                  type={"number"}
                  readOnly={true}
                  comboFixWord={"\u20B9"}
                  position="start"
                />
              </Grid>
              <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="send_sms"
                  checked={messageCheck}
                  onChange={handleCheckboxChange}
                />
              }
              label={t("loan append.send sms")}
            />
          </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  size="small"
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "var(--font-weight)",
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                    "&:hover": { transform: "scale(1.1)" },
                  }}
                >
                  {t("loan append.loan append")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
};
