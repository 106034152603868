import api from "../api/http-common";

const NO_OP = (message, ...optionalParams) => {};

export default class ConsoleLogger {
  constructor(options) {
    const { level } = options || {};

    this.error = console.error.bind(console);

    if (level === "error") {
      this.warn = NO_OP;
      this.log = NO_OP;
      return;
    }

    this.warn = console.warn.bind(console);

    if (level === "warn") {
      this.log = NO_OP;
      return;
    }
    this.log = (message) => {
      const data = {
        message: message,
      };
      console.log(message)
      api
        .post("logger", data)
        .then((res) => {})
        .catch((err) => console.log(err));
    };
  }
}
