import { Box } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";

const GridTable = ({ rows, columns, height, isLoading, hidePagination }) => {
  const heights = !height ? "80vh" : height;

  return (
    <Box sx={{ height: heights }}>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{
          noRowsOverlay: () => CustomNoRowsOverlay(isLoading, rows),
        }}
        sx={{
          height: "100%",
          maxWidth: "100%",
          overflowY: "auto",
          overflowX: "auto",
          fontFamily: "var(--font-family)",
          fontSize: "var(--font-size)",
          "&, [class^=MuiDataGrid]": { border: "none" },
          "& .grid-header": {
            backgroundColor: `var(--app-bar-color)`,
            color: "var(--header-font-color)",
          },
          "& .grid-cell-left": {
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            border: "none",
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            position: "relative",
          },
          ".MuiDataGrid-row:nth-of-type(even)": {
            backgroundColor: "var(--table-even-row)",
          },
          ".MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "var(--table-odd-row)",
          },
          "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "var(--font-color)",
          },
          "& .MuiTablePagination-displayedRows": {
            margin: "auto",
            color: "var(--font-color)",
            fontFamily: "var(--font-family)",
            fontSize: "var(--font-size)",
          },
          "& .MuiTablePagination-actions": {
            margin: "auto",
            color: "var(--font-color)",
          },
          "& .MuiButtonBase-root-MuiIconButton-root": {
            color: "var(--font-color)",
          },
        }}
        disableRowSelection
        disableRowSelectionOnClick
        disableColumnMenu
        hideFooter={hidePagination ?? false}
        pageSizeOptions={100}
        columnHeaderHeight={30}
      />
    </Box>
  );
};

export default GridTable;

export const CustomNoRowsOverlay = (loading, rows) => {
  const { t } = useTranslation("global");
  const Loading = loading === false ? false : true;
  return (
    <>
      {!Loading && rows.length <= 0 && (
        <GridOverlay
          sx={{
            backgroundColor: "var(--commonpaper-background)",
            color: "var(--font-color)",
          }}
        >
          <div>{t("common.there_is_no_data")}</div>
        </GridOverlay>
      )}
    </>
  );
};
