import { Divider, Grid, Paper, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CountUp from "react-countup";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import api from "../../api/http-common";
import { AppData } from "../../common/AppData";
import CustomCommonButton from "../../common/Components/CustomCommonButton";
import Dropdown from "../../common/Components/Dropdown";
import {
  CommonAddIcon,
  rupeePosition,
  rupeeSymbol,
} from "../../common/Components/IconsAndComponents";
import Textfield from "../../common/Components/TextField";
import Extension from "../../common/Extension";
import GridTable from "../../common/GridTable";
import Loading from "../../common/Loading";
import Logger from "../../common/Logger";
import SnackBar from "../../common/SnackBar";
import "./Expenditure.css";

const paymentMethods = [
  { text: "Cash", value: "By Cash", text_tn: "பணம்" },
  {
    text: "Account Transfer",
    value: "By Account",
    text_tn: "கணக்கு பரிமாற்றம்",
  },
  { text: "UPI/ Others", value: "UPI/Others", text_tn: "UPI/ மற்றவை" },
];

const logger = new Logger({ level: "log" });
const Expenditure = () => {
  const { t,i18n } = useTranslation("global");
  const currentLang = i18n.language;
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const entity_id = parseInt(sessionStorage.getItem("entityId"));
  const user_id = parseInt(sessionStorage.getItem("userId"));
  const [loading, setLoading] = useState(true);
  const [expenditureTypes, setExpenditureTypes] = useState([]);
  const [branches, setBranches] = useState([]);
  const [expenditures, setExpenditures] = useState([]);
  const [fund_amount, setFund_amount] = useState(0);
  const [expenditureAmount, setExpenditureAmount] = useState(0);
  const [expenditureType, setExpenditureType] = useState("");
  const [paymentType, setPaymentType] = useState("By Cash");
  const [exSelectedBranch, setExSelectedBranch] = useState("");
  const [description, setDescription] = useState("");
  const [entityLatestFund, setEntityLatestFund] = useState("");
  const [branchLatestFund, setBranchLatestFund] = useState({});
  const [selectedBranch, setSelectedBranch] = useState("All");
  const [users, setUsers] = useState([]);
  const [fundType, setFundType] = useState("By Cash");
  const [fundRemarks, setFundRemarks] = useState("");
  const [fundRemarks_tn, setFundRemarks_tn] = useState("");

  //Get Branches and Its Details While Entering the Screen
  const fetchData = useCallback(async () => {
    try {
      handleLoadingOpen();
      const branchAPI =
        role_id === 1
          ? `/branch/byEntity/${entity_id}`
          : `/branch/${branch_id}`;
      logger.log("Initiate Fetch branches");
      const branch = await api
        .get(branchAPI)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch branches Successfully");
      const User_API = `/users/byEntity/${entity_id}`;
      logger.log("Initiate Fetch users");
      const userData = await api
        .get(User_API)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch users Successfully");
      setUsers(userData);
      setBranches(branch);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  }, [branch_id, entity_id, role_id]);

  useEffect(() => {
    const getExpenditureType = async () => {
      logger.log("Initiate Fetch expenditure types");
      const data = await api
        .get(`/ExpenditureType/ByEntity/${entity_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch expenditure types Successfully");
      setExpenditureTypes(data);
    };
    getExpenditureType();
  }, [entity_id]);

  //Get Fund Amounts Dynamically
  const getFund = useCallback(async () => {
    try {
      const ENTITY_API =
        selectedBranch === "All" && `/Fund/CurrentTotalAmount/${entity_id}`;
      const BRANCH_API =
        role_id === 1 && selectedBranch !== "All" && !exSelectedBranch
          ? `/Fund/CurrentTotalAmountByBranch/${selectedBranch}`
          : role_id === 1 && exSelectedBranch
          ? `/Fund/CurrentTotalAmountByBranch/${exSelectedBranch}`
          : `/Fund/CurrentTotalAmountByBranch/${branch_id}`;
      let Entity_res = 0;
      if (selectedBranch === "All" && role_id === 1) {
        logger.log("Initiate Fetch entity fund");
        Entity_res = await api
          .get(ENTITY_API)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("Fetch entity fund Successfully");
        setEntityLatestFund(Entity_res);
        return;
      }
      setEntityLatestFund(0);
      logger.log("Initiate Fetch branches");
      const Branch_res = await api.get(BRANCH_API);
      logger.log("Fetch branches Successfully");
      setBranchLatestFund(Branch_res.data);
    } catch (error) {
      logger.log(error);
      if (error.response.data.detail === "Not found.") {
        setBranchLatestFund([]);
      }
    }
  }, [entity_id, role_id, selectedBranch, exSelectedBranch, branch_id]);

  const handleFundAmount = async () => {
    if (selectedBranch === "All") {
      SnackBar.toastMsg("error", "Please Select One Of The Branch");
      return;
    }
    if (fund_amount && selectedBranch && fund_amount > 0) {
      try {
        handleLoadingOpen();
        let currentFundAmount = 0;
        if (Object.keys(branchLatestFund).length !== 0) {
          currentFundAmount = branchLatestFund.total_fund_amount;
        }
        const total_fund_amount = currentFundAmount
          ? parseInt(currentFundAmount) + parseInt(fund_amount)
          : parseInt(fund_amount);
        const data = {
          credit_amount: parseInt(fund_amount),
          debit_amount: null,
          funded_on: Extension.getCurrentDateTime(),
          total_fund_amount,
          entity: entity_id,
          transaction_type: fundType,
          remarks: fundRemarks,
          remarks_tn: fundRemarks_tn,
          fund_type: "FundAmountCredit",
          branch: selectedBranch,
          funded_By: user_id,
        };
        logger.log("Initiate post fund");
        await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((error) => logger.log(error)); //Fund Addition API
        logger.log("post fund Successfully");
        getFund();
        ClearFundField();
        SnackBar.toastMsg("success", "Fund Amount Added");
      } catch (error) {
        logger.log(error);
      } finally {
        handleLoadingClose();
        setFundType("By Cash");
      }
    } else {
      if (!selectedBranch) {
        SnackBar.toastMsg("error", "Please Select The Branch");
        return;
      }
      SnackBar.toastMsg("error", "Please Enter The Fund Amount");
    }
  };

  const getAllExpenditures = useCallback(async () => {
    try {
      const API =
        role_id === 1
          ? `/Expenditure/ByEntity/${entity_id}`
          : `/Expenditure/ByBranch/${branch_id}`;
      logger.log("Initiate Fetch expenditure");
      const data = await api
        .get(API)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch expenditure Successfully");
      setExpenditures(data);
    } catch (error) {
      logger.log(error);
    }
  }, [branch_id, entity_id, role_id]);

  const addExpenditureAmount = async () => {
    if (parseInt(branchLatestFund.total_fund_amount) <= 0) {
      SnackBar.toastMsg("error", "There Is No Fund Amount");
      return;
    }
    if (role_id === 1 && !exSelectedBranch) {
      SnackBar.toastMsg("error", "Please Select The Branch");
      return;
    }
    if (expenditureAmount && description && expenditureType) {
      //Check Al the Fields Are Entered
      if (parseInt(branchLatestFund.total_fund_amount) < expenditureAmount) {
        SnackBar.toastMsg(
          "error",
          "The Expenditure Amount Is Higher Than The Available Fund Amount"
        );
        return;
      }
      try {
        handleLoadingOpen();
        const ExpenditureData = {
          description: description,
          amount: expenditureAmount,
          expenditureDate: Extension.getCurrentDateTime(),
          entity: entity_id,
          branch: role_id === 1 ? exSelectedBranch : branch_id,
          transaction_type: paymentType,
          expenditureType: expenditureType,
          expenditureBy: user_id,
        };
        logger.log("Initiate post expenditure");
        await api
          .post(`/Expenditure`, ExpenditureData)
          .then((res) => res.data)
          .catch((error) => logger.log(error)); //Post API for Expenditure
        logger.log("post expenditure Successfully");
        let currentFundAmount = 0;
        currentFundAmount = branchLatestFund.total_fund_amount; //Get Latest Fund amount for the Branch

        const total_fund_amount =
          parseInt(currentFundAmount) - parseInt(expenditureAmount);
        const data = {
          credit_amount: null,
          debit_amount: expenditureAmount,
          funded_on: Extension.getCurrentDateTime(),
          total_fund_amount,
          fund_type: "ExpenditureAmountDebit",
          branch: role_id === 1 ? exSelectedBranch : branch_id,
          entity: entity_id,
          transaction_type: paymentType,
          funded_By: user_id,
        };
        logger.log("Initiate post Expenditure");
        await api
          .post(`/Fund`, data)
          .then((res) => res.data)
          .catch((error) => logger.log(error)); //Post API for Fund Subtraction
        logger.log("Post Fund Successfully");
        SnackBar.toastMsg("success", "Expenditure Added Successfully");
        getFund();
        getAllExpenditures();
        clearExTextField();
        ClearFundField();
      } catch (error) {
        logger.log(error);
      } finally {
        handleLoadingClose();
      }
    } else {
      if (!expenditureType) {
        SnackBar.toastMsg("error", "Please Select The Expenditure Type");
        return;
      }
      if (!expenditureAmount) {
        SnackBar.toastMsg("error", "Please Enter The Expenditure Amount");
        return;
      }
      if (!description) {
        SnackBar.toastMsg("error", "Please Enter The Description");
        return;
      }
      SnackBar.toastMsg("error", "Please Fill The Expenditure Fields");
    }
  };

  const clearExTextField = () => {
    setExpenditureAmount(0);
    setSelectedBranch("All");
    setExSelectedBranch("");
    setPaymentType("By Cash");
    setExpenditureType("");
    setDescription("");
  };
  const ClearFundField = () => {
    setSelectedBranch("All");
    setFundRemarks("");
    setFundType("");
    setFundRemarks_tn("");
    setFund_amount(0);
  };

  useEffect(() => {
    getFund();
  }, [selectedBranch, exSelectedBranch, getFund]);

  useEffect(() => {
    if (selectedBranch) {
      setExSelectedBranch("");
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (exSelectedBranch) {
      setSelectedBranch("");
    }
  }, [exSelectedBranch]);

  useEffect(() => {
    fetchData();
    getAllExpenditures();
  }, [fetchData, getAllExpenditures]);

  const funtTypeFilter = [
    { text: "Cash", value: "By Cash", text_tn: "பணம்" },
    {
      text: "Account Transfer",
      value: "By Account",
      text_tn: "கணக்கு பரிமாற்றம்",
    },
    { text: "UPI/ Others", value: "UPI/Others", text_tn: "UPI/ மற்றவை" },
  ];

  const PeriodFilter = funtTypeFilter.map((fil) => ({
    ...fil,
    label: t(`expenditure.${fil.value}`),
  }));
  const rows = expenditures.map((item, index) => {
    const user = users.find(
      (data) => parseInt(data.id) === parseInt(item.expenditureBy)
    );
    const pay_method = paymentMethods.find(
      (data) => data.value === item.transaction_type
    );
    return {
      ...item,
      id: index + 1,
      amount: `${rupeeSymbol} ${Extension.formatCurrency(item.amount)}`,
      transaction_type:
        Extension.languageBasedValue(pay_method?.text, pay_method?.text_tn) ??
        "--",
      expenditureType:
        expenditureTypes.length > 0 &&
        expenditureTypes.find(
          (data) =>
            parseInt(data.expenditureType_id) === parseInt(item.expenditureType)
        ).expenditureType,
      expenditureDate: Extension.formatdateTime(item.expenditureDate),
      expenditureBy:
        user &&
        `${Extension.languageBasedValue(
          user?.first_name,
          user?.first_name_tn
        )} ${Extension.languageBasedValue(
          user?.last_name,
          user?.last_name_tn
        )}`,
    };
  });

  const columns = AppData.expenditureData.map((data) => ({
    ...data,
    headerName: t(`expenditure.${data.headerName}`),
  }));
  const handleLoadingOpen = () => {
    setLoading(true);
  };

  const handleLoadingClose = () => {
    setLoading(false);
  };
  const handleRemarksChange = (e) => {
    Extension.translateInput(e, setFundRemarks_tn);
    setFundRemarks(Extension.onlyText(e));
  };

  const preferredFundAmount =
    selectedBranch === "All"
      ? entityLatestFund.entity_total_fund
      : branchLatestFund.total_fund_amount;

  return (
    <Paper elevation={3} className="commonpaper" variant="outlined">
      <Loading open={loading} handleClose={handleLoadingClose} />
      <Toaster />
      {role_id === 1 && (
        <>
          <Divider
            className="divider_class"
            sx={{ color: `var(--app-bar-color)` }}
          >
            {t("expenditure.add_fund")}
          </Divider>
          <br />
          <Grid container spacing={2} className="grid_item">
            <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
              <Dropdown
                firstOption={t("common.all")}
                search={true}
                label={t("common.select branch")}
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
                array={branches}
                menuValue={"branch_id"}
                display={Extension.languageBasedValue(
                  "branch_name",
                  "branch_name_tn"
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
              <Dropdown
                firstOption={t("common.all")}
                label={t("common.Fund Type")}
                value={fundType}
                onChange={(e) => setFundType(e.target.value)}
                array={PeriodFilter}
                menuValue={"value"}
                display={Extension.languageBasedValue("text", "text_tn")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
              <Textfield
                label={t("expenditure.fund_amount")}
                onChange={(e) => setFund_amount(e.target.value)}
                value={fund_amount}
                type={"number"}
                position={rupeePosition}
                comboFixWord={rupeeSymbol}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Textfield
                label={"Fund Remarks"}
                value={fundRemarks}
                onChange={handleRemarksChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Textfield
                label={"நிதி கருத்துக்கள்"}
                value={fundRemarks_tn}
                onChange={(e) => setFundRemarks_tn(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={1.5}
              lg={1.5}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              <CustomCommonButton
                icon={CommonAddIcon}
                onClick={handleFundAmount}
                text={t("expenditure.fund")}
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Paper
                elevation={3}
                sx={{
                  /*width: 220,*/ height: "100%",
                  width: "100%",
                  p: 2,
                  borderRadius: "15px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(to bottom, #58A3FF, #81BAFF, #B0D4FF, #BCDAFF, #DEEAF9)",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: -1.6,
                    fontWeight: "var(--font-weight)",
                    fontFamily: "var(--font-family)",
                    fontSize: "var(--font-size)",
                  }}
                >
                  {t("item_rate.fund_amount")}
                </Typography>
                <CountUp
                  start={0}
                  end={preferredFundAmount}
                  duration={2.5}
                  decimals={2}
                  prefix={rupeeSymbol}
                  useIndianSeparators={true}
                  style={{ fontSize: "21px" }}
                />
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
      {role_id === 1 && (
        <>
          <br />
          <Divider
            className="divider_class"
            sx={{ color: `var(--app-bar-color)` }}
          >
            {t("expenditure.add_expenditure")}
          </Divider>
          <br />
        </>
      )}
      <Grid container spacing={2} className="container_class">
        {role_id === 1 && (
          <Grid item xs={12} sm={6} md={1.5} lg={1.5} >
            <Dropdown
              firstOption={t("common.all")}
              label={t("common.select branch")}
              value={exSelectedBranch}
              onChange={(e) => setExSelectedBranch(e.target.value)}
              array={branches}
              menuValue={"branch_id"}
              display={Extension.languageBasedValue(
                "branch_name",
                "branch_name_tn"
              )}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={6}
          md={role_id === 1 ? 1.5 : 2}
          lg={role_id === 1 ? 1.5 : 2}
        >
          <Dropdown
            label={t("expenditure.select_ex_type")}
            value={expenditureType}
            onChange={(e) => setExpenditureType(e.target.value)}
            array={expenditureTypes}
            menuValue={"expenditureType_id"}
            display={"expenditureType"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={role_id === 1 ? 1.5 : 2}
          lg={role_id === 1 ? 1.5 : 2}
        >
          <Dropdown
            label={t("expenditure.payment_type")}
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
            array={paymentMethods}
            menuValue={"value"}
            display={Extension.languageBasedValue("text", "text_tn")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={role_id === 1 ? 1.5 : 2}
          lg={role_id === 1 ? 1.5 : 2}
        >
          <Textfield
            label={t("expenditure.amount")}
            value={expenditureAmount}
            onChange={(e) => setExpenditureAmount(e.target.value)}
            type={"number"}
            position={rupeePosition}
            comboFixWord={rupeeSymbol}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={4}>
          <Textfield
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label={t("expenditure.description")}
          />
        </Grid>
        {role_id === 1 && (
          <Grid
            item
            xs={role_id === 1 ? 12 : 6}
            sm={role_id === 1 ? 6 : 3}
            md={role_id === 1 ? 3 : 2}
            lg={role_id === 1 ? 2 : 1}
            // className="common_button"
            sx={{ display: "flex", justifyContent: "end", width: "100%" }}
          >
            <CustomCommonButton
              onClick={addExpenditureAmount}
              icon={CommonAddIcon}
              text={t("expenditure.expenditure")}
              disabled={loading}
            />
          </Grid>
        )}
        {role_id === 2 && (
          <Grid item xs={6} sm={3} md={2} lg={2}>
            <Paper
              elevation={3}
              sx={{
                /*width: 220,*/ height: "100%",
                width: "100%",
                p: 2,
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background:
                  "linear-gradient(to bottom, #58A3FF, #81BAFF, #B0D4FF, #BCDAFF, #DEEAF9)",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  mt: -1.6,
                  fontWeight: "var(--font-weight)",
                  fontFamily: "var(--font-family)",
                  fontSize: "var(--font-size)",
                }}
              >
                {t("item_rate.fund_amount")}
              </Typography>
              <CountUp
                start={0}
                end={branchLatestFund.total_fund_amount}
                duration={2.5}
                useIndianSeparators={true}
                decimals={2}
                prefix={`₹`}
                style={{ fontSize: "21px" }}
              />
            </Paper>
          </Grid>
        )}
      </Grid>
      {role_id === 2 && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            padding: "20px 0",
          }}
        >
          <CustomCommonButton
            onClick={addExpenditureAmount}
            icon={CommonAddIcon}
            text={t("expenditure.expenditure")}
            disabled={loading}
          />
        </div>
      )}
      <GridTable
        rows={rows}
        columns={columns}
        isLoading={loading}
        height={{
          lg: role_id === 1 ? "50vh" : "70vh",
          xl: "60vh",
          md: "40vh",
          sm: "30vh",
          xs: "20vh",
        }}
      />
    </Paper>
  );
};

export default Expenditure;
