import {
  Add,
  Cancel,
  EditNoteOutlined,
  EditRounded,
  KeyboardBackspace,
  PublishedWithChanges,
  Save,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../api/http-common";
import AddImage from "../../assets/AddImageIcon.svg";
import CustomCommonButton from "../../common/Components/CustomCommonButton";
import ErrorTextfield from "../../common/Components/ErrorTextfield";
import ImageProvision from "../../common/Components/ImageProvision";
import Textfield from "../../common/Components/TextField";
import Extension from "../../common/Extension";
import Loading from "../../common/Loading";
import Logger from "../../common/Logger";
import SnackBar from "../../common/SnackBar";
import Styles from "../../common/Styles";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `var(--app-bar-color)`,
    color: "var(--header-font-color)",
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
    // fontSize: 1,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    border: 0,
    textAlign: "center",
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
    color: "var(---font-color)",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  // "&:nth-of-type(odd)": {
  //   backgroundColor: "#D9D9D9",
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "#999", // Change the background color on hover
  },
}));

const inputFields = [
  {
    name: "branch_name",
    field: "input",
    gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
    type: "text",
    title: "Name",
  },
  {
    name: "branch_name_tn",
    field: "input",
    gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
    type: "text",
    title: "பெயர்",
  },
  {
    name: "branch_phone",
    field: "input",
    gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
    type: "number",
    maxLength: 10,
    title: "Phone Number",
  },
  {
    name: "branch_mail_id",
    field: "input",
    gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
    type: "text",
    title: "Mail ID",
  },
  {
    name: "branch_prefix",
    field: "input",
    gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
    type: "text",
    title: "Prefix",
  },
  {
    name: "branch_address",
    field: "input",
    gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
    type: "text",
    title: "Address",
  },
  {
    name: "branch_address_tn",
    field: "input",
    gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
    type: "text",
    title: "முகவரி",
  },
];

const TenantCreation = (props) => {
  const { updateEntitys: updateEntities, entityUpdateClose, entity_id } = props;
  // //console.log(props);
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const logger = new Logger({ level: "log" });
  const { t } = useTranslation("global");
  const [name, setName] = useState("");
  const [name_tn, setName_tn] = useState("");
  const [address, setAddress] = useState("");
  const [address_tn, setAddress_tn] = useState("");
  const [phone, setPhone] = useState("");
  const [gst, setGST] = useState("");
  const [mailId1, setMailId1] = useState("");
  const [mailId2, setMailId2] = useState("");
  const [subDomain, setSubDomain] = useState("");
  const [readOnly, setReadOnly] = useState(updateEntities ?? false);
  const [userReadOnly, setUserReadOnly] = useState(updateEntities ?? false);

  const [show_proprietor_details, setShow_proprietor_details] = useState(false);

  const [branch_name_tn, setBranchName_tn] = useState("");
  const [branch_address, setBranchAddress] = useState("");
  const [branch_address_tn, setBranchAddress_tn] = useState("");
  const [branch_mail_id, setBranch_mail_id] = useState("");
  const [branch_prefix, setBranchPrefix] = useState("");
  const [branch_name, setBranch_name] = useState("");
  const [branch_phone, setBranch_phone] = useState("");

  const [firstName, setFirstname] = useState("");
  const [firstName_tn, setFirstname_tn] = useState("");
  const [lastName, setLastName] = useState("");
  const [lastName_tn, setLastName_tn] = useState("");
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [emailId, setEmailId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [index, setIndex] = useState("");

  const [branches, setBranches] = useState([]);
  const [entity, setEntity] = useState([]);
  const [users, setUsers] = useState([]);
  const [addItems, setAddItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entityButtonDisable, setEntityButtonDisable] = useState(false);
  const [contextMenu, setContextMenu] = React.useState(null);

  const [ids, setIds] = useState({});
  const [commonButtonDisable, setCommonButtonDisable] = useState(false);

  const [errorMessages, setErrorMessages] = useState({
    tenant_mail: "",
    tenant_alt_mail: "",
    gst: "",
    phone: "",
    login_id: "",
    password: "",
    email: "",
    phone_number: "",
    branch_phone: "",
    branch_mail_id: "",
    sub_domain:""
  });
  const [image, setImage] = useState("");
  const Navigate = useNavigate();

  if (role_id !== 3 || !role_id) {
    Extension.logout();
  }

  const handleLoadingOpen = () => {
    setLoading(true);
  };

  const handleLoadingClose = () => {
    setLoading(false);
  };

  const fetchBranchData = useCallback(async () => {
    try {
      logger.log("Initiate Fetch branch");
      const branchData = await api
        .get(`/branch/byEntity/${entity_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch branch Successfully");
      setBranches(branchData);
    } catch (error) {
      logger.log(error);
    }
  }, [entity_id]);

  const fetchEntityData = useCallback(async () => {
    try {
      handleLoadingOpen();
      logger.log("Initiate Fetch entity");
      const entityData = await api
        .get(`/entity/${entity_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch entity Successfully");
      setEntity(entityData);
      setName(entityData.name);
      setName_tn(entityData.name_tn);
      setGST(entityData.gst);
      setAddress(entityData.address);
      setAddress_tn(entityData.address_tn);
      setPhone(entityData.phone);
      setMailId1(entityData.mail_id1);
      setMailId2(entityData.mail_id2);
      setSubDomain(entityData.sub_domain)
      setImage(entityData.image);
      handleLoadingClose();
    } catch (error) {
      logger.log(error);
    }
  }, [entity_id]);

  const fetchUserData = useCallback(async () => {
    try {
      logger.log("Initiate Fetch usersProprietor");
      const userData = await api
        .get(`/usersProprietor/${entity_id}`)
        .then((res) => res.data)
        .catch((err) => logger.log(err));
      logger.log("Fetch usersProprietor Successfully");
      let user = userData[0];
      // console.log(user);
      console.log(userData);
      setUsers(user);
      setFirstname(user.first_name);
      setLastName(user.last_name);
      setEmailId(user.email);
      setLoginId(user.login_id);
      setPassword(user.password);
      setUser_image(user.image);
      setMobileNumber(user.mobile_number);
      setFirstname_tn(user.first_name_tn);
      setLastName_tn(user.last_name_tn);
    } catch (error) {
      logger.log(error);
    }
  }, [entity_id]);

  useEffect(() => {
    if (entity_id && updateEntities) {
      fetchBranchData();
      fetchEntityData();
      fetchUserData();
    }
  }, [
    entity_id,
    fetchBranchData,
    fetchEntityData,
    fetchUserData,
    updateEntities,
  ]);

  const branch_validations = [
    {
      value: branch_name,
      message: "Please Enter Branch Name",
      message_tn: "கிளையின் பெயரை உள்ளிடவும்",
    },
    {
      value: branch_name_tn,
      message: "Please Enter Branch Name in Tamil",
      message_tn: "கிளையின் பெயரை தமிழில் உள்ளிடவும்",
    },

    {
      value: branch_phone,
      message: "Please Enter Branch Phone Number",
      message_tn: "கிளை தொலைபேசி எண் உள்ளிடவும்",
    },
    {
      value: branch_prefix,
      message: "Please Enter Branch Prefix",
      message_tn: "கிளை முன்னொட்டை உள்ளிடவும்",
    },
    {
      value: branch_mail_id,
      message: "Please Enter Branch Email ID",
      message_tn: "கிளை மின்னஞ்சல் ஐடி உள்ளிடவும்",
    },
    {
      value: branch_address,
      message: "Please Enter Branch Address",
      message_tn: "கிளை முகவரி உள்ளிடவும்",
    },
    {
      value: branch_address_tn,
      message: "Please Enter Branch Address in Tamil",
      message_tn: "கிளை முகவரி தமிழில் உள்ளிடவும்",
    },
  ];

  // fetch;
  const handleForm = () => {
    if (
      branch_mail_id &&
      branch_address &&
      branch_name &&
      branch_name_tn &&
      branch_address_tn &&
      errorMessages.branch_mail_id === "" &&
      errorMessages.branch_phone === "" &&
      branch_prefix &&
      branch_phone
    ) {
      try {
        const branch = {
          branch_mail_id,
          branch_address,
          branch_name,
          branch_prefix,
          branch_address_tn,
          branch_name_tn,
          branch_phone,
        };
        const duplicateField = branches.reduce((acc, branch, idx) => {
          if (idx === index) return acc; // Skip the current branch being updated
          if (branch.branch_name === branch_name) return 'branch_name';
          if (branch.branch_phone === branch_phone) return 'branch_phone';
          if (branch.branch_prefix === branch_prefix) return 'branch_prefix';
          if (branch.branch_mail_id === branch_mail_id) return 'branch_mail_id';
          if (branch.branch_phone === branch_phone) return 'branch_phone';
          return acc;
        }, null);
    
        if (duplicateField) {
          let errorMsg;
          switch (duplicateField) {
            case 'branch_name':
              errorMsg = Extension.languageBasedValue(
                "Branch Name already exist",
                "கிளை பெயர் ஏற்கனவே உள்ளது"
              );
              break;
            case 'branch_prefix':
              errorMsg = Extension.languageBasedValue(
                "Branch prefix already exist",
                "கிளை முன்னொட்டு ஏற்கனவே உள்ளது"
              );
              break;
            case 'branch_mail_id':
              errorMsg = Extension.languageBasedValue(
                "Branch email already exist",
                "கிளை மின்னஞ்சல் ஏற்கனவே உள்ளது"
              );
              break;
              case 'branch_phone':
                errorMsg = Extension.languageBasedValue(
                  "Branch phone number already exist",
                  "கிளை தொலைபேசி எண் ஏற்கனவே உள்ளது"
                );
                break;
            default:
              errorMsg = Extension.languageBasedValue(
                "Duplicate branch details are not allowed",
                "நகல் கிளை விவரங்கள் அனுமதிக்கப்படாது"
              );
          }
          SnackBar.toastMsg("error", errorMsg);
          return;
        }  
        setBranches([...branches, branch]);
        setAddItems([...addItems, branch]);
        clearTextFields();
      } catch (error) {
        logger.log(error);
      }
    } else {
      for (const validation of branch_validations) {
        if (!validation.value && validation.text!="mailId2") {
          SnackBar.toastMsg(
            "error",
            Extension.languageBasedValue(
              validation.message,
              validation.message_tn
            )
          );
          return;
        }
      }

      if (errorMessages.branch_phone !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Phone Number");
        return;
      }
      if (errorMessages.branch_mail_id !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Email");
        return;
      }
      // SnackBar.toastMsg("error", "Please Fill All Details Of Branch");
    }
  };

  const clearTextFields = () => {
    setBranchAddress_tn("");
    setBranchName_tn("");
    setBranch_name("");
    setBranchAddress("");
    setBranchPrefix("");
    setBranch_mail_id("");
    setBranch_phone("");
  };

  const handleBranchChange = (e) => {
    Extension.translateInput(e, setBranchName_tn);
    setBranch_name(e.target.value);
  };

  const handleAddressChange = (e) => {
    Extension.translateInput(e, setBranchAddress_tn);
    setBranchAddress(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    Extension.translateInput(e, setFirstname_tn);
    setFirstname(e.target.value);
  };

  const handleLastNameChange = (e) => {
    Extension.translateInput(e, setLastName_tn);
    setLastName(e.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const proprietorRef = useRef(null);

  const scrollToSection = (ref) => {
    // console.log(ref);
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleMailChange = (e) => {
    const emailRegex = /^[\w.-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,4}$/;
    const email = e.target.value;
    const name = e.target.name;
    console.log(name," - ",email)
    if (name === "tenant_mail") {
      setMailId1(e.target.value);
    } else if (name === "tenant_alt_mail") {
      setMailId2(email);
    } else if (name === "email") {
      setEmailId(email);
    } else {
      setBranch_mail_id(email);
    }
    if (!email && name !== "tenant_alt_mail") {
      setErrorMessages({ ...errorMessages, [name]: "Email Is Required" });
    } else if (email && !emailRegex.test(email)) {
      setErrorMessages({
        ...errorMessages,
        [name]: "Please Provide A Valid Email",
      });
    } else {
      setErrorMessages({ ...errorMessages, [name]: "" });
    }
  };

  const handlePhoneChange = (e) => {
    const mobileReg = /^\+?[0-9-]+$/;
    const name = e.target.name;
    const phone = e.target.value;

    if (name === "phone") {
      setPhone(phone);
    } else if (name === "phone_number") {
      setMobileNumber(phone);
    } else {
      setBranch_phone(phone);
    }

    if (!phone) {
      setErrorMessages({
        ...errorMessages,
        [name]: "Phone Number Is Required",
      });
    } else if (phone && phone.length!==10) {
      setErrorMessages({
        ...errorMessages,
        [name]: "Please Provide A Valid Phone Number",
      });
    }
    else if (!mobileReg.test(phone)) {
      setErrorMessages({
        ...errorMessages,
        [name]: "Please Provide A Valid Phone Number",
      });
    } else {
      setErrorMessages({ ...errorMessages, [name]: "" });
    }
  };

  const validations = [
    {
      text:"name",
      value: name,
      message: "Please Enter Entity Name",
      message_tn: "தயவுசெய்து பெயரை உள்ளிடவும்",
    },
    {
      text:"name_tn",
      value: name_tn,
      message: "Please Enter Entity Name in Tamil",
      message_tn: "தயவுசெய்து தமிழில் பெயரை உள்ளிடவும்",
    },
    {
      text:"phone",
      value: phone,
      message: "Please Enter Entity Phone Number",
      message_tn: "தயவுசெய்து தொலைபேசி எண் உள்ளிடவும்",
    },
    {
      text:"gst",
      value: gst,
      message: "Please Enter Entity GST Number",
      message_tn: "தயவுசெய்து ஜி.எஸ்.டி எண்ணை உள்ளிடவும்",
    },
    {
      text:"mailId1",
      value: mailId1,
      message: "Please Enter Entity Email ID",
      message_tn: "தயவுசெய்து மின்னஞ்சல் ஐடி ஐ உள்ளிடவும்",
    },
    {
      text:"mailId2",
      value: mailId2,
      message: "Please Enter Entity Alter Email ID",
      message_tn: "தயவுசெய்து மாற்று மின்னஞ்சல் ஐடி ஐ உள்ளிடவும்",
    },
    {
      text:"address",
      value: address,
      message: "Please Enter Entity Address",
      message_tn: "தயவுசெய்து முகவரி உள்ளிடவும்",
    },
    {
      text:"address_tn",
      value: address_tn,
      message: "Please Enter Entity Address In Tamil",
      message_tn: "தயவுசெய்து தமிழில் முகவரி உள்ளிடவும்",
    },
    {
      text:"subDomain",
      value: subDomain,
      message: "Please Enter Entity Domain",
      message_tn: "தயவுசெய்து தமிழில் டொமைன் உள்ளிடவும்",
    },
    {
      text:"firstName",
      value: firstName,
      message: "Please Enter First Name",
      message_tn: "தயவுசெய்து முதல் பெயரை உள்ளிடவும்",
    },
    {
      text:"firstName_tn",
      value: firstName_tn,
      message: "Please Enter First Name In Tamil",
      message_tn: "தயவுசெய்து தமிழில் முதல் பெயரை உள்ளிடவும்",
    },
    {
      text:"lastName",
      value: lastName,
      message: "Please Enter Last Name",
      message_tn: "தயவுசெய்து கடைசி பெயரை உள்ளிடவும்",
    },
    {
      text:"lastName_tn",
      value: lastName_tn,
      message: "Please Enter Last Name",
      message_tn: "தயவுசெய்து தமிழில் கடைசி பெயரை உள்ளிடவும்",
    },
    {
      text:"loginId",
      value: loginId,
      message: "Please Enter Login ID",
      message_tn: "தயவுசெய்து உள்நுழைவு ஐடி உள்ளிடவும்",
    },
    {
      text:"emailId",
      value: emailId,
      message: "Please Enter Email ID",
      message_tn: "தயவுசெய்து மின்னஞ்சல் ஐடி உள்ளிடவும்",
    },
    {
      text:"password",
      value: password,
      message: "Please Enter Password",
      message_tn: "தயவுசெய்து கடவுச்சொல்லை உள்ளிடவும்",
    },
    {
      text:"mobileNumber",
      value: mobileNumber,
      message: "Please Enter Mobile Number",
      message_tn: "தயவுசெய்து கைபேசி எண் உள்ளிடவும்",
    },
  ];

  const createEntity = async () => {
    if (
      name &&
      name_tn &&
      address &&
      address_tn &&
      phone &&
      gst &&
      mailId1 &&
      errorMessages.phone === "" &&
      errorMessages.tenant_mail === "" &&
      // errorMessages.tenant_alt_mail === "" &&
      subDomain &&
      addItems.length > 0
    ) {
      setEntityButtonDisable(true);
      handleLoadingOpen();
      try {
        const entityData = {
          name: name,
          name_tn: name_tn,
          address: address,
          address_tn: address_tn,
          phone: phone,
          image,
          gst: gst,
          mail_id1: mailId1,
          mail_id2: mailId2,
          last_partial_interest_num: "",
          last_interest_num: "",
          last_partial_closure_num: "",
          last_closure_num: "",
          last_append_num: "",
          sub_domain:subDomain
        };
        logger.log("Initiate post entity");
        const entity = await api
          .post("entity", entityData)
          .then((res) => res.data)
          .catch((err) => {
                 
                  if (err.response){
                    console.log("$$$$$$$$$$",err.response.data.message)
                  SnackBar.toastMsg("error", err.response.data.message); // Display the response as a toast message
                  return;
                }
                else{
                  console.log("#########",err.message)
                  logger.log(err)
                  return;
                }
          });
        logger.log("post entity Successfully");
        //console.log("entity data", entity);
        let entity_id = entity.entity_id;

        let branchData = [];
        for (let data of addItems) {
          const BranchData = {
            ...data,
            entity: entity_id,
          };
          logger.log("Initiate post branch");
          const resData = await api
            .post(`/branch`, BranchData)
            .then((res) => res.data)
            .catch((err) => logger.log(err));
          logger.log("post branch Successfully");
          branchData.push(resData);
        }
        let branch_id = branchData[0].branch_id;
        setIds({ entity_id, branch_id });

        SnackBar.toastMsg("success", "Entity Created Successfully");
        // SnackBar.toastMsg("error", "You Need To Create The Proprietor");
        setReadOnly(true);
        await setShow_proprietor_details(true);
        scrollToSection(proprietorRef);
        setErrorMessages({ tenant_alt_mail: "" });
      } catch (err) {
        console.log("error in addEntity", err);
        setEntityButtonDisable(false);
      } finally {
        await handleLoadingClose();
      }
    } else {
      if (!subDomain) {
        SnackBar.toastMsg("error", "Please Enter the Sub Domain");
        return;
      }
      if (errorMessages.phone !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Phone Number");
        return;
      }
      if (errorMessages.tenant_mail !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Email");
        return;
      }
      if (errorMessages.tenant_alt_mail !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Alternate Email");
        return;
      }

      for (const validation of validations) {
        if (!validation.value && validation.text!="mailId2") {
          SnackBar.toastMsg(
            "error",
            Extension.languageBasedValue(
              validation.message,
              validation.message_tn
            )
          );
          return;
        }
      }
      SnackBar.toastMsg("error", "Please Fill All Details");
    }
  };

  const addUser = async () => {
    if (
      loginId &&
      emailId &&
      firstName &&
      lastName &&
      firstName_tn &&
      lastName_tn &&
      password &&
      mobileNumber &&
      errorMessages.phone_number === "" &&
      errorMessages.email === ""
    ) {
      try {
        setCommonButtonDisable(true);
        handleLoadingOpen();
        const UserData = {
          login_id: loginId,
          email: emailId,
          first_name: firstName,
          first_name_tn: firstName_tn,
          last_name: lastName,
          last_name_tn: lastName_tn,
          role_id: 1,
          image: user_image,
          password,
          mobile_number: mobileNumber,
          entity_id: ids.entity_id,
          branch_id: ids.branch_id,
        };
        // console.log("User Data", UserData);
        logger.log("Initiate post user");
        await api.post("users", UserData);
        logger.log("post user Successfully");
        SnackBar.toastMsg("success", "Proprietor Created Successfully");
        handleLoadingClose();
        Navigate("/tenantmaster");
      } catch (error) {
        logger.log(error);
        if (error.response.data.login_id) {
          SnackBar.toastMsg("error", t("toast_messages.login_id"));
          return;
        } else if (error.response.data.email) {
          SnackBar.toastMsg("error", t("toast_messages.email"));
          return;
        } else {
          logger.log(error);
        }
      } finally {
        setCommonButtonDisable(false);
        handleLoadingClose();
      }
    } else {
      if (errorMessages.phone_number !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Phone Number");
        return;
      }
      if (errorMessages.email !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Email");
        return;
      }
      console.log("@@@@@@@@@@@@@@@@@")
      for (const validation of validations) {
        if (!validation.value && validation.text!="mailId2") {
          SnackBar.toastMsg(
            "error",
            Extension.languageBasedValue(
              validation.message,
              validation.message_tn
            )
          );
          return;
        }
      }
    }
  };

  const [entityUpdateData, setEntityUpdateData] = useState(false);

  const enterEntityUpdate = async () => {
    setReadOnly(false);
    setEntityUpdateData(true);
    setUserReadOnly(false);
    setShow_proprietor_details(true);
  };

  const [branchUpdateData, setBranchUpdateData] = useState(null);
  const handleUpdateBranch = (branch_id) => {
    //console.log(branch_id);
    let branchDetails = {};
    branchDetails = branches[branch_id];
    setIndex(branch_id);
    setBranchUpdateData(branchDetails);
    setBranchAddress(branchDetails.branch_address);
    setBranchAddress_tn(branchDetails.branch_address_tn);
    setBranchName_tn(branchDetails.branch_name_tn);
    setBranch_name(branchDetails.branch_name);
    setBranchPrefix(branchDetails.branch_prefix);
    setBranch_mail_id(branchDetails.branch_mail_id);
    setBranch_phone(branchDetails.branch_phone);
    //console.log(branchDetails);
  };

  const updateBranchForm = () => {
    if (
      branch_mail_id &&
      branch_address &&
      branch_name &&
      branch_name_tn &&
      branch_address_tn &&
      branch_prefix &&
      branch_phone
    ) {
      const updateBranch = {
        ...branchUpdateData,
        branch_mail_id,
        branch_address,
        branch_name,
        branch_prefix,
        branch_address_tn,
        branch_name_tn,
        branch_phone,
      };
      const duplicateField = branches.reduce((acc, branch, idx) => {
        if (idx === index) return acc; // Skip the current branch being updated
        if (branch.branch_name === branch_name) return 'branch_name';
        if (branch.branch_phone === branch_phone) return 'branch_phone';
        if (branch.branch_prefix === branch_prefix) return 'branch_prefix';
        if (branch.branch_mail_id === branch_mail_id) return 'branch_mail_id';
        if (branch.branch_phone === branch_phone) return 'branch_phone';
        return acc;
      }, null);
  
      if (duplicateField) {
        let errorMsg;
        switch (duplicateField) {
          case 'branch_name':
              errorMsg = Extension.languageBasedValue(
                "Branch Name already exist",
                "கிளை பெயர் ஏற்கனவே உள்ளது"
              );
              break;
            case 'branch_prefix':
              errorMsg = Extension.languageBasedValue(
                "Branch prefix already exist",
                "கிளை முன்னொட்டு ஏற்கனவே உள்ளது"
              );
              break;
            case 'branch_mail_id':
              errorMsg = Extension.languageBasedValue(
                "Branch email already exist",
                "கிளை மின்னஞ்சல் ஏற்கனவே உள்ளது"
              );
              break;
              case 'branch_phone':
                errorMsg = Extension.languageBasedValue(
                  "Branch phone number already exist",
                  "கிளை தொலைபேசி எண் ஏற்கனவே உள்ளது"
                );
                break;
          default:
            errorMsg = Extension.languageBasedValue(
              "Duplicate branch details are not allowed",
              "நகல் கிளை விவரங்கள் அனுமதிக்கப்படாது"
            );
        }
        SnackBar.toastMsg("error", errorMsg);
        return;
      }
  
      const updatedBranches = [...branches];
      updatedBranches[index] = { ...updatedBranches[index], ...updateBranch };
      setBranches(updatedBranches);
      console.log(updatedBranches);
      // Update addItems array if necessary
      const updatedAddItems = [...addItems, updateBranch];
      setAddItems(updatedAddItems);
      clearTextFields();
      setBranchUpdateData(null);
    } else {
      for (const validation of branch_validations) {
        if (!validation.value) {
          SnackBar.toastMsg(
            "error",
            Extension.languageBasedValue(
              validation.message,
              validation.message_tn
            )
          );
          return;
        }
      }

      if (errorMessages.branch_phone !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Phone Number");
        return;
      }
      if (errorMessages.branch_mail_id !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Email");
        return;
      }
    }
  };

  //Password Change Function
  const handlePasswordChange = (e) => {
    const pass = e.target.value.slice(0, 20);
    const alpha = /[a-zA-Z]/;
    const numeric = /\d/;
    const spl = /[!@#$%^&*(),.?":{}|<>]/;
    const name = e.target.name;
    setPassword(pass);
    if (!pass) {
      setErrorMessages({
        ...errorMessages,
        [name]: "Password Is Required",
      });
    } else if (!alpha.test(pass)) {
      setErrorMessages({
        ...errorMessages,
        [name]: "Password Must Be Include Letters.",
      });
    } else if (!numeric.test(pass)) {
      setErrorMessages({
        ...errorMessages,
        [name]: "Password Must Be Include Numbers.",
      });
    } else if (!spl.test(pass)) {
      setErrorMessages({
        ...errorMessages,
        [name]: "Password Must Be Include At-least One Special Character.",
      });
    } else if (pass.length < 12) {
      setErrorMessages({
        ...errorMessages,
        [name]: "Password Must Be At-least 12 Characters Long .",
      });
    } else {
      setErrorMessages({
        ...errorMessages,
        [name]: "",
      });
    }

    // validatePassword(newPassword);
  };

  const handleGSTChange = (e) => {
    const gstValue = e.target.value;
    const name = e.target.name;
    // const GSTRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    setGST(e.target.value.toUpperCase());

    if (!gstValue) {
      setErrorMessages({
        ...errorMessages,
        [name]: "GST Is Required",
      });
    } /* else if (!GSTRegex.test(gstValue)) {
      setErrorMessages({
        ...errorMessages,
        [name]: "Enter The Valid GST Number",
      });
    } */
  };

  const updateEntity = async () => {
    if (
      name &&
      name_tn &&
      address &&
      address_tn &&
      phone &&
      gst &&
      mailId1 &&
      subDomain &&
      errorMessages.phone === "" &&
      errorMessages.tenant_mail === "" &&
      // errorMessages.tenant_alt_mail === "" &&
      loginId &&
      emailId &&
      firstName &&
      lastName &&
      firstName_tn &&
      lastName_tn &&
      mobileNumber &&
      errorMessages.phone_number === "" &&
      errorMessages.email === ""
    ) {
      if (!users && !password) {
        SnackBar.toastMsg("error", "Please Enter Password");
        return;
      }
      setEntityButtonDisable(true);
      try {
        handleLoadingOpen();
        setCommonButtonDisable(true);
        const entityData = {
          ...entity,
          name: name,
          name_tn: name_tn,
          address: address,
          address_tn: address_tn,
          phone: phone,
          image,
          gst: gst,
          mail_id1: mailId1,
          mail_id2: mailId2,
          sub_domain:subDomain
        };
        logger.log("Initiate update entity");
        const entityRes = await api
          .put(`entity/${entity.entity_id}`, entityData)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("update entity Successfully");

        // console.log("Updated entity", entityRes);
        let entity_id = entityRes.entity_id;
        let updatedBranch = [];
        for (let data of addItems) {
          if (data.branch_id) {
            const one_branch = branches.find(
              (item) => item.branchId === data.branch_id
            );
            const BranchData = {
              ...data,
              ...one_branch,
            };
            logger.log("Initiate update branch");
            updatedBranch = await api
              .put(`/branch/${data.branch_id}`, BranchData)
              .then((res) => res.data)
              .catch((err) => logger.log(err));
            logger.log("update branch Successfully");
            // console.log("Updated Branch", updatedBranch);
            // return;
          }
          if (!data.branch_id) {
            const BranchData = {
              ...data,
              entity: entity_id,
            };
            logger.log("Initiate add branch");
            updatedBranch = await api
              .post(`/branch`, BranchData)
              .then((res) => res.data)
              .catch((err) => logger.log(err));
            logger.log("add branch Successfully");
            // console.log("added branch", updatedBranch);
          }
        }
        let UserData = {};
        if (users) {
          UserData = {
            ...users,
            login_id: loginId,
            email: emailId,
            first_name: firstName,
            first_name_tn: firstName_tn,
            last_name: lastName,
            last_name_tn: lastName_tn,
            role_id: 1,
            image: user_image,
            password,
            mobile_number: mobileNumber,
          };
          logger.log("Initiate update user");
          const userRes = await api
            .put(`/users/${users.id}`, UserData)
            .then((res) => res.data)
            .catch((err) => logger.log(err));
          logger.log("update user Successfully");
          // console.log("Updated User ", userRes);
          SnackBar.toastMsg("success", "Entity Updated Successfully");
          setEntityButtonDisable(false);
          fetchBranchData();
          fetchEntityData();
          handleLoadingClose();
          fetchUserData();
          return;
        }
        UserData = {
          login_id: loginId,
          email: emailId,
          first_name: firstName,
          first_name_tn: firstName_tn,
          last_name: lastName,
          last_name_tn: lastName_tn,
          role_id: 1,
          image: user_image,
          password,
          mobile_number: mobileNumber,
          entity_id: entity_id,
          branch_id: branches[0].branch_id,
        };
        logger.log("Initiate add user");
        const userRes = await api
          .post(`/users`, UserData)
          .then((res) => res.data)
          .catch((err) => logger.log(err));
        logger.log("add user Successfully");
        // console.log("Added User ", userRes);
        SnackBar.toastMsg("success", "Entity Updated Successfully");
        setErrorMessages({ tenant_alt_mail: "" });
        setEntityButtonDisable(false);
        fetchBranchData();
        fetchEntityData();
        fetchUserData();
        handleLoadingClose();
      } catch (error) {
        logger.log(error);
        setEntityButtonDisable(false);
      } finally {
        handleLoadingClose();
        setShow_proprietor_details(false);
        setEntityButtonDisable(false);
        setCommonButtonDisable(false);
        setReadOnly(true);
        setUserReadOnly(true);
      }
    } else {
      console.log("!!!!!!!!!!!!!!!",errorMessages)
      if (errorMessages.phone !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Phone Number");
        return;
      }
      if (errorMessages.tenant_mail !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Email");
        return;
      }
      if (errorMessages.tenant_alt_mail !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Alternate Email");
        return;
      }
      if (errorMessages.phone_number !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Phone Number");
        return;
      }
      if (errorMessages.email !== "") {
        SnackBar.toastMsg("error", "Please Enter The Valid Email");
        return;
      }
      for (const validation of validations) {
        console.log("-----> ",validation)
        if (!validation.value && validation.text!="mailId2") {
          SnackBar.toastMsg(
            "error",
            Extension.languageBasedValue(
              validation.message,
              validation.message_tn
            )
          );
          return;
        }
      }
    }
  };

  const filterUniqueBranches = (branches) => {
    const seen = new Set();
    return branches.filter((branch) => {
      const isDuplicate = seen.has(branch.branch_name);
      seen.add(branch.branch_name);
      return !isDuplicate;
    });
  };
  function convertBase64(e, setState, maxWidth, maxHeight) {
    // console.log("Function Called");
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target.result) {
          const img = new Image();
          img.onload = () => {
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            // Check if image needs resizing
            if (width > maxWidth || height > maxHeight) {
              const ratio = Math.min(maxWidth / width, maxHeight / height);
              width *= ratio;
              height *= ratio;
            }

            canvas.width = width; 
            canvas.height = height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);

            const base64Data = canvas.toDataURL("image/jpeg", 0.7); // Convert to base64
            // console.log(base64Data);
            setState(base64Data);
          };

          img.src = event.target.result;
        } else {
          SnackBar.toastMsg("error", t("toast_messages.failed_to_load"));
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const handleCancel = () => {
    setReadOnly(true);
    setUserReadOnly(true);
    setShow_proprietor_details(false);
    Object.keys(errorMessages).forEach((key) => {
      setErrorMessages((prevState) => ({
        ...prevState,
        [key]: "",
      }));
    });

    fetchBranchData();
    fetchEntityData();
    fetchUserData();
  };

  const [user_image, setUser_image] = useState("");

  const userInputFields = [
    {
      name: "image",
      field: "input",
      gridSize: { xs: 0.2, sm: 0.2, md: 0.2, lg: 0.2 },
      // value: user_image,
      // onChange: (e) => convertBase64(e, setUser_image),
      type: "text",
      title: "Image",
    },
    {
      name: "first_name",
      field: "file",
      gridSize: { xs: 0.8, sm: 0.8, md: 0.8, lg: 0.8 },
      value: firstName,
      onChange: (e) => handleFirstNameChange(e),
      type: "text",
      title: "First Name",
    },
    {
      name: "first_name_tn",
      field: "input",
      value: firstName_tn,
      onChange: (e) => setFirstname_tn(e.target.value),
      gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
      type: "text",
      title: "பெயர்",
    },
    {
      name: "last_name",
      field: "input",
      value: lastName,
      onChange: (e) => handleLastNameChange(e),
      gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
      type: "text",
      title: "Last Name",
    },
    {
      name: "last_name_tn",
      field: "input",
      value: lastName_tn,
      onChange: (e) => setLastName_tn(e.target.value),
      gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
      type: "text",
      title: "கடைசி பெயர்",
    },
    {
      name: "login_id",
      field: "input",
      value: loginId,
      onChange: (e) => setLoginId(e.target.value),
      gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
      type: "text",
      title: "Login ID",
    },
    {
      name: "password",
      field: "input",
      value: password,
      onChange: (e) => setPassword(e.target.value),
      gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
      type: "text",
      title: "Password",
    },
    {
      name: "email",
      field: "input",
      value: emailId,
      onChange: (e) => handleMailChange(e),
      gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
      type: "text",
      title: "Email",
    },
    {
      name: "phone_number",
      field: "input",
      value: mobileNumber,
      maxLength: 10,
      onChange: (e) => handlePhoneChange(e),
      gridSize: { xs: 1, sm: 1, md: 1, lg: 1 },
      type: "number",
      title: "Phone",
    },
  ];

  const handleContextMenu = (event, row) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };
  const [userContextMenu, setUserContextMenu] = useState(null);

  const handleUserContextMenu = (event, row) => {
    event.preventDefault();
    setUserContextMenu(
      userContextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleUserClose = () => {
    setUserContextMenu(null);
  };

  return (
    <Paper elevation={3} className="add-loan-wrapper">
      <Toaster />
      <Loading open={loading} />
      {updateEntities && (
        <div className="top-icons-style">
          <IconButton
            sx={{
              background: "#B1F164",
              color: "#555",
              "&:hover": {
                background: "#82B643",
                color: "#fff",
                transform: "scale(1.2)",
              },
            }}
            onClick={() => entityUpdateClose(false)}
          >
            <KeyboardBackspace />
          </IconButton>
          {
            readOnly && (
              <CustomCommonButton
                text={"Edit"}
                icon={<EditNoteOutlined />}
                onClick={enterEntityUpdate}
              />
            ) /* : (
              <CustomCommonButton
                text={"cancel"}
                icon={<Cancel />}
                color={"error"}
                onClick={handleCancel}
              />
            ) */
          }
        </div>
      )}
      {/* <Box className="modal-content"> */}
      <Box
        sx={{
          p: 5,
          pb: 2,
          pt: 2,
          mt: updateEntities && 2,
          pointerEvents: readOnly && "none",
          borderTopRightRadius: "var(--roundness)",
          borderTopLeftRadius: "var(--roundness)",
          backgroundColor: readOnly && "var(--menu-color-2)",
        }}
      >
        <Divider className="divider-tenant">
          {t("superadmin.tenant_details")}
        </Divider>
        <Grid
          container
          spacing={{ xs: 2, md: 3, sm: 3 }}
          columns={{ xs: 1, sm: 2, md: 3, lg: 3 }}
          sx={{ elevation: 3, mb: 2 }}
        >
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Textfield
              value={name}
              onChange={(e) =>
                Extension.translateInputfrom(setName, e, setName_tn, true)
              }
              readOnly={readOnly}
              label={"Name"}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Textfield
              value={name_tn}
              readOnly={readOnly}
              onChange={(e) => setName_tn(e.target.value)}
              label={"பெயர்"}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <ErrorTextfield
              name={"phone"}
              value={phone}
              onChange={handlePhoneChange}
              maxLength={10}
              label={"Phone"}
              readOnly={readOnly}
              type={"number"}
              required={false}
              error={errorMessages.phone}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <ErrorTextfield
              required={false}
              error={errorMessages.gst}
              name="gst"
              value={gst}
              onChange={handleGSTChange}
              maxLength={15}
              label={"GST"}
              type={"text"}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <ErrorTextfield
              name={"tenant_mail"}
              label={"Mail ID"}
              maxLength={50}
              type={"text"}
              value={mailId1}
              required={false}
              // onBlur={handleTenantMailChange}
              error={errorMessages.tenant_mail}
              readOnly={readOnly}
              onChange={handleMailChange}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <ErrorTextfield
              name={"tenant_alt_mail"}
              label={"Alter Mail ID(Optional)"}
              maxLength={50}
              type={"mail"}
              value={mailId2}
              required={false}
              onBlur={handleMailChange}
              error={errorMessages.tenant_alt_mail}
              readOnly={readOnly}
              onChange={handleMailChange}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Textfield
              value={address}
              onChange={(e) =>
                Extension.translateInputfrom(setAddress, e, setAddress_tn)
              }
              multiline={true}
              maxLength={250}
              label={"Address"}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1}>
            <Textfield
              value={address_tn}
              readOnly={readOnly}
              onChange={(e) => setAddress_tn(e.target.value)}
              multiline={true}
              maxLength={250}
              label={"முகவரி"}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={0.5} lg={0.83}>
            <ErrorTextfield
              name={"sub_domain"}
              maxLength={50}
              type={"text"}
              value={subDomain}
              readOnly={readOnly}
              onChange={(e) => setSubDomain(e.target.value)}
              required={false}
              label={t("Tenant.sub_domain")}
              error={errorMessages.subDomain}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={0.5}
            lg={0.12}
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "end",
            }}
          >
            <input
              type="file"
              id="image-upload"
              style={{ display: "none" }}
              onInput={(e) => convertBase64(e, setImage)}
              disabled={readOnly}
            />
            <Tooltip
              title={
                image
                  ? t("loan creation.enlarge_image")
                  : t("loan creation.add_logo")
              }
            >
              <Avatar
                src={image ? `${image}` : AddImage}
                variant="rounded"
                onContextMenu={(e) => handleContextMenu(e)}
                alt=""
                sx={{ height: 70, width: 70, cursor: "pointer" }}
                onClick={() => document.getElementById("image-upload").click()}
              />
            </Tooltip>
            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              sx={{
                "& .MuiMenu-list": {
                  p: 0,
                },
              }}
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              <Paper>
                <IconButton
                  onClick={() => setContextMenu(null)}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    zIndex: 999,
                  }}
                >
                  <Cancel />
                </IconButton>
                <Avatar
                  src={image ? `${image}` : AddImage}
                  variant="rounded"
                  alt=""
                  sx={{ height: 200, width: 200, cursor: "pointer" }}
                  onClick={() =>
                    document.getElementById("image-upload").click()
                  }
                />
              </Paper>
            </Menu>
          </Grid>
        </Grid>
      </Box>
      <div
        className="branch-form"
        style={{
          backgroundColor: readOnly && "var(--menu-color-2)",
        }}
      >
        <Divider className="divider-tenant">
          {t("superadmin.branch_details")}
        </Divider>
        {!readOnly && (
          <Grid
            container
            spacing={{ xs: 2, md: 3, sm: 3 }}
            columns={{ xs: 1, sm: 2, md: 4, lg: 4 }}
            sx={{ elevation: 3, mb: 2 }}
          >
            {inputFields.map((field, index) => (
              <Grid
                item
                key={index}
                // sx={{ mb: 2, display: "flex", alignItems: "end" }}
                sm={field.gridSize.sm}
                md={field.gridSize.md}
                lg={field.gridSize.lg}
                xs={field.gridSize.xs}
              >
                {field.field === "input" &&
                  field.name !== "branch_name_tn" &&
                  field.name !== "branch_phone" &&
                  field.name !== "branch_address_tn" &&
                  field.name !== "branch_mail_id" && (
                    <>
                      <Textfield
                        label={field.title}
                        name={field.name}
                        // readOnly={branchReadOnly}
                        type={field.type}
                        multiline={field.name === "branch_address" && true}
                        rows={field.name === "branch_address" && 2}
                        value={
                          field.name === "branch_name"
                            ? branch_name
                            : field.name === "branch_address"
                            ? branch_address
                            : field.name === "branch_prefix"
                            ? branch_prefix
                            : field.name === "branch_phone"
                            ? branch_phone
                            : branch_mail_id
                        }
                        onChange={(e) => {
                          if (field.name === "branch_name") {
                            handleBranchChange(e);
                          } else if (field.name === "branch_address")
                            handleAddressChange(e);
                          else if (field.name === "branch_prefix")
                            setBranchPrefix(e.target.value);
                          else if (field.name === "branch_phone")
                            setBranch_phone(e.target.value);
                          else if (field.name === "branch_mail_id")
                            handleMailChange(e);
                        }}
                      />
                      {field.name === "branch_prefix" && (
                        <span className="hint-prefix-text">
                          * Kindly provide the prefix based on which the loan
                          number and customer number will be generated.
                        </span>
                      )}
                    </>
                  )}
                {field.field === "input" &&
                  (field.name === "branch_mail_id" ||
                    field.name === "branch_phone") && (
                    <ErrorTextfield
                      label={field.title}
                      name={field.name}
                      readOnly={readOnly}
                      type={field.type}
                      value={
                        field.name === "branch_mail_id"
                          ? branch_mail_id
                          : branch_phone
                      }
                      maxLength={field.maxLength}
                      onChange={
                        field.name === "branch_mail_id"
                          ? handleMailChange
                          : handlePhoneChange
                      }
                      required={false}
                      // onBlur={handleTenantMailChange}
                      error={errorMessages[field.name]}
                    />
                  )}
                {field.field === "input" &&
                  (field.name === "branch_name_tn" ||
                    field.name === "branch_address_tn") &&
                  field.name !== "branch_phone" && (
                    <Textfield
                      // readOnly={branchReadOnly}
                      label={field.title}
                      name={field.name}
                      type={field.type}
                      value={
                        field.name === "branch_name_tn"
                          ? branch_name_tn
                          : branch_address_tn
                      }
                      multiline={field.name === "branch_address_tn" && true}
                      rows={field.name === "branch_address_tn" && 2}
                      onChange={(e) =>
                        field.name === "branch_name_tn"
                          ? setBranchName_tn(e.target.value)
                          : setBranchAddress_tn(e.target.value)
                      }
                    />
                  )}
              </Grid>
            ))}
            <Grid
              item
              sx={{
                // mb: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "end",
              }}
              sm={1}
              md={1}
              lg={1}
              xs={1}
            >
              <CustomCommonButton
                onClick={branchUpdateData ? updateBranchForm : handleForm}
                icon={branchUpdateData ? <PublishedWithChanges /> : <Add />}
              />
            </Grid>
          </Grid>
        )}
      </div>
      <Box
        sx={{
          p: 5,
          pt: 2,
          backgroundColor: readOnly && "var(--menu-color-2)",
          borderBottomLeftRadius:
            readOnly && !userReadOnly && "var(--roundness)",
          borderBottomRightRadius:
            readOnly && !userReadOnly && "var(--roundness)",
        }}
      >
        <TableContainer
          component={Paper}
          elevation={3}
          sx={{ maxHeight: 250, bgcolor: "transparent" }}
          variant="elevation"
        >
          <Table stickyHeader aria-label="table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>{t("superadmin.s.no")}</StyledTableCell>
                <StyledTableCell>{t("superadmin.branch_name")}</StyledTableCell>

                <StyledTableCell>
                  {t("superadmin.branch_prefix")}
                </StyledTableCell>

                <StyledTableCell>
                  {t("superadmin.branch_mailid")}
                </StyledTableCell>
                <StyledTableCell>
                  {t("superadmin.branch_phone")}
                </StyledTableCell>
                {!readOnly && (
                  <StyledTableCell>{t("superadmin.action")}</StyledTableCell>
                )}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {branches.length > 0 ? (
                branches.map((row, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{
                      color: readOnly
                        ? "var(--disabled-font)"
                        : "var(--font-color)",
                      backgroundColor: readOnly
                        ? "var(--disabled-field)"
                        : index % 2 === 0
                        ? "var(--table-even-row)"
                        : "var(--table-odd-row)",
                    }}
                  >
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row.branch_name}</StyledTableCell>
                    <StyledTableCell>{row.branch_prefix}</StyledTableCell>
                    <StyledTableCell>{row.branch_mail_id}</StyledTableCell>
                    <StyledTableCell>{row.branch_phone}</StyledTableCell>
                    {!readOnly && (
                      <StyledTableCell>
                        <EditRounded
                          onClick={() => handleUpdateBranch(index)}
                        />
                        {/* <DeleteRounded color="error" onClick={() => handleDeletebranch(index)} /> */}
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={10}>
                    <Typography component="p" variant="h6">
                      {t("loan creation.no_data_found")}
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!readOnly && !entityUpdateData && addItems.length > 0 && (
          <div className="save-btn-style">
            <CustomCommonButton
              icon={entityUpdateData ? <PublishedWithChanges /> : <Save />}
              onClick={entityUpdateData ? updateEntity : createEntity}
              disabled={entityButtonDisable}
            />
          </div>
        )}
      </Box>
      {(show_proprietor_details || readOnly) && (
        <div
          className="branch-form"
          style={{
            backgroundColor: userReadOnly && "var(--menu-color-2)",
            pointerEvents: userReadOnly && "none",
            borderBottomLeftRadius: "var(--roundness)",
            borderBottomRightRadius: "var(--roundness)",
          }}
        >
          <Divider className="divider-tenant">
            {t("superadmin.proprietor_details")}
          </Divider>
          <Grid
            container
            spacing={{ xs: 2, md: 3, sm: 3 }}
            columns={{ xs: 1, sm: 2, md: 4, lg: 4 }}
            sx={{
              elevation: 3,
              mb: 2,
              // pointerEvents: userReadOnly ? "none" : "",
            }}
            ref={proprietorRef}
          >
            {userInputFields.map((field, index) => (
              <Grid
                item
                key={index}
                // sx={{ mb: 2, display: "flex", alignItems: "end" }}
                sm={field.gridSize.sm}
                md={field.gridSize.md}
                lg={field.gridSize.lg}
                xs={field.gridSize.xs}
              >
                {field.name === "image" && (
                  <>
                    <input
                      type="file"
                      id="image1-upload"
                      style={{ display: "none" }}
                      onInput={(e) => convertBase64(e, setUser_image)}
                      // readOnly={readOnly}
                      // disabled={readOnly}
                    />
                    <Tooltip
                      title={
                        user_image
                          ? t("loan creation.enlarge_image")
                          : t("loan creation.add_image")
                      }
                    >
                      <Avatar
                        src={user_image ? `${user_image}` : AddImage}
                        variant="rounded"
                        onContextMenu={(e) => handleUserContextMenu(e)}
                        alt=""
                        sx={{
                          height: 70,
                          width: 70,
                          cursor: "pointer",
                          margin: "auto",
                        }}
                        onClick={() =>
                          document.getElementById("image1-upload").click()
                        }
                      />
                    </Tooltip>
                    <ImageProvision
                      image={user_image}
                      setContextMenu={setUserContextMenu}
                      handleClose={handleUserClose}
                      contextMenu={userContextMenu}
                    />
                  </>
                )}
                {field.name !== "password" &&
                  field.name !== "image" &&
                  field.name !== "email" &&
                  field.name !== "phone_number" && (
                    <Textfield
                      label={field.title}
                      name={field.name}
                      readOnly={userReadOnly}
                      type={field.type}
                      value={field.value}
                      maxLength={field.maxLength}
                      onChange={field.onChange}
                    />
                  )}
                {(field.name === "email" || field.name === "phone_number") && (
                  <ErrorTextfield
                    label={field.title}
                    name={field.name}
                    readOnly={userReadOnly}
                    type={field.type}
                    value={field.value}
                    maxLength={field.maxLength}
                    onChange={field.onChange}
                    error={errorMessages[field.name]}
                  />
                )}
                {field.name === "password" && (
                  <>
                    <Typography
                      sx={{
                        ...Styles.textlabel(),
                        color: readOnly
                          ? "var(--app-bar-color)"
                          : "var(--font-color)",
                      }}
                    >
                      Password
                    </Typography>
                    <Paper style={{ backgroundColor: "transparent" }}>
                      <TextField
                        sx={{
                          backgroundColor: userReadOnly
                            ? "var(--disabled-field)"
                            : "var(--text-field)",
                        }}
                        fullWidth
                        name="password"
                        value={password}
                        type={showPassword ? "text" : "password"}
                        onChange={handlePasswordChange}
                        // onBlur={handlePasswordChange}
                        size="small"
                        inputProps={{
                          style: {
                            minHeight: "15px",
                            maxHeight: "22px",
                            backgroundColor: userReadOnly
                              ? "var(--disabled-field)"
                              : "var(--text-field)",
                            color: "var(--font-color)",
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--font-size)",
                            "& .MuiSvgIcon-root,.MuiTypography-root": {
                              color: "var(--font-color)", // Change the color of the dropdown icon
                            },
                          },
                        }}
                        error={Boolean(errorMessages.password)}
                        InputProps={{
                          style: {
                            backgroundColor: userReadOnly
                              ? "var(--disabled-field)"
                              : "var(--text-field)",
                            color: "var(--font-color)",
                            fontFamily: "var(--font-family)",
                            fontSize: "var(--font-size)",
                            "& .MuiSvgIcon-root,.MuiTypography-root": {
                              color: "var(--font-color)", // Change the color of the dropdown icon
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Paper>
                    {errorMessages.password ? (
                      <span style={{ ...Styles.textlabel(), color: "red" }}>
                        {errorMessages.password}
                      </span>
                    ) : null}
                  </>
                )}
              </Grid>
            ))}
          </Grid>
          {!userReadOnly && (
            <div className="save-btn-style">
              <CustomCommonButton
                icon={entityUpdateData ? <PublishedWithChanges /> : <Save />}
                onClick={entityUpdateData ? updateEntity : addUser}
                disabled={commonButtonDisable}
              />
            </div>
          )}
        </div>
      )}
    </Paper>
  );
};

export default TenantCreation;
