const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// export const AppbarColor = local_theme === "light" ? "#000000" : "#3C5D90";
export const AppbarColor = "#0C2244";
export const auction_color = "#FF5E5B";
export const scheme_expiry_color = "#82642C";

export const smallFont = "12px";
export const sherifFont = "";
export const cambriaFont = "cambria";
export const ArialFont = "sands";
export const boldFont = 800;
class Styles {
  widthPopupStyles() {
    return {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: { md: 600, xs: 400, sm: 500, lg: 700, xl: 1000 },
      // maxHeight:{md:700,xs:'80%',sm:500,lg:'80%',xl:'80%'},
      maxHeight: 770,
      border: "2px solid #000",
      boxShadow: 24,
      borderRadius: "10px",
      overflowY: { xs: "auto", md: "auto" },
      bgcolor: "var(--commonpaper-background)",
      fontFamily: "var(--font-family) !important",
      fontSize: "var(--font-size) !important",
      color: "var(--font-color)",
    };
  }
  customPopUp() {
    return {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // width: { md: 600, xs: 400, sm: 500, lg: 700, xl: 1000 },
      // maxHeight:{md:700,xs:'80%',sm:500,lg:'80%',xl:'80%'},
      // maxHeight: 770,
      border: "none",
      boxShadow: 24,
      borderRadius: "10px",
      // display:'flex',
      // justifyContent:'center',
      // alignItems:'center',
      // overflowY: { xs: "auto", md: "auto" },
      bgcolor: "var(--commonpaper-background)",
      fontFamily: "var(--font-family) !important",
      fontSize: "var(--font-size) !important",
      color: "var(--font-color)",
    };
  }
  widthHeightPopupStyles() {
    return {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      // margin: { lg: "10vh auto", md: 0 },
      width: { md: 800, xs: 600, sm: 500, lg: 800, xl: 1000 },
      maxHeight: 700,
      fontFamily: "var(--font-family) !important",
      fontSize: "var(--font-size) !important",
      bgcolor: "var(--commonpaper-background)",
      color: "var(--font-color)",
      border: "2px solid #000",
      boxShadow: 24,
      borderRadius: "10px",
      overflowY: { xs: "auto", md: "auto" },
    };
  }
  textlabel() {
    return {
      // fontSize:'1em',
      // fontFamily:'serif'
      fontFamily: "var(--font-family)",
      fontSize: "var(--font-size)",
      color: "var(--font-color)",
    };
  }
  commonButton() {
    return {
      mb: { md: 0, sm: 0, xs: 2 },
      color: "black",
      width: { md: "auto", sm: "auto", xs: "100%" },
      textTransform: "capitalize",
      fontFamily: "var(--font-family)",
      fontSize: "var(--font-size)",
      fontWeight: "var(--font-weight)",
      // display:'flex',
      // alignItems:'center'
    };
  }
  titleText() {
    return {
      mb: { md: 0, sm: 0, xs: 2 },
      color: "var(--app-bar-color)",
      fontWeight: "var(--font-weight)",
      display: "flex",
      justifyContent: "center",
      // fontFamily:'serif',
      fontFamily: "var(--font-family)",
      fontSize: 25,
      width: { md: "auto", sm: "auto", xs: "100%" },
    };
  }
  MenuProps() {
    return {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          alignItems: "left",
          width: 250,
          backgroundColor: "var(--text-field)",
          color: "var(--font-color)",
          fontSize: "var(--font-size)",
        },
      },
    };
  }
  heightPaper() {
    return {
      height: "90vh",
      // maxHeight:'180vh',
      overflowY: "auto",
      p: 2,
    };
  }
  dropDown() {
    return {
      backgroundColor: "var(--text-field)",
      fontFamily: "var(--font-family)",
      fontSize: "var(--font-size)",
      color: "var(--font-color)",
    };
  }
}
const Style = new Styles();
export default Style;
