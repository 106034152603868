// PrintButton.js
import { Download, Print } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import html2pdf from "html2pdf.js"; // Import html2pdf library
import { useTranslation } from "react-i18next";
import translate from "translate";
import Extension from "../Extension";
import Logger from "../Logger";

const logger = new Logger({ level: "log" });
const PrintButton = (props) => {
  const { t } = useTranslation("global");

  const { data, download } = props;
  const [amount_word_tn, setAmountTN] = useState("");
  useEffect(() => {
    handleLanguageChange(data.transaction.amount_word);
  }, [data]);
  const handleLanguageChange = async (value) => {
    try {
      translate.engine = "google";
      translate.key = process.env.DEEPL_KEY;

      const result = await translate(value, "ta");
      setAmountTN(result);
    } catch (error) {
      logger.log(error);
      return null; // Handle the error appropriately, returning null for simplicity
    }
  };
  const handlePrint = () => {
    let printWindow;

    printWindow = window.open("", "_blank");
    printWindow.document.write(
      "<html><head><title>JSL Print</title></head><body>"
    );

    printWindow.document.write(
      '<div style="display: none;" id="printable-content">'
    );
    printWindow.document.write(
      `<style>
      body {
        font-family: Arial, sans-serif;
      } 
      .receipt {
        // margin-top: -200px; 
        padding: 20px;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: start;
    }
    
    .receipt-wrapper {
        padding: 20px;
        border: 2px solid #dedede;
        border-radius: 20px;
        background-color: #fbfbfb;
    }
    
    .reciept-container left{
      width: 25%;
    }

    .receipt-header {
        width: 100%;
        display: flex;
        /* grid-template-columns: repeat(3, 1fr); */
        justify-content: space-evenly;
        align-items: center;
        min-height: 13vh;
        font-size: 10px;
        font-weight: 700;
    }
    
    .reciept-container .title {
        font-size: 22px;
        font-weight: 600;
    }

    .reciept-container .address {
      width : 80%;
      margin: auto;
  }
    
    .reciept-container:nth-child(2) {
        text-align: center;
        /* margin-left: -80px; */
    }
    
    .reciept-container:nth-child(2) .text,
    .address {
        font-size: 10px;
        font-weight: 700;
    }
    
    /* .reciept-container:last-child {
        // padding-right: 80px;
    } */
    
    .table-container {
        display: flex;
        gap: 10px;
        margin-top: -20px;
        width: 100%;
    }
    
    
    .table-container h1 {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
    }
    
    /* .loan-detail-table h1 {
        text-align: right;
    } */
    
    .table {
        width: 100%;
    }
    
    .t-row {
        display: flex;
        width: 100%;
    }
    
    .t-row .td {
        border: 1px solid #dedede;
        background-color: #FFFFFF;
        padding: 10px 0 10px 20px;
        font-size: 12px;
    }
    .t-row .td {
        font-weight: 600;
    }
    
    .t-row .td:first-child {
        width: 30%;
    }
    
    .t-row .td:last-child {
        width: 70%;
    }
    .t-row .word {
      text-transform: capitalize;
    } 
    
    .t-row:first-child .td:first-child {
        border-radius: 10px 0 0 0;
    }
    
    .t-row:first-child .td:last-child {
        border-radius: 0 10px 0 0;
    }
    
    .t-row:last-child .td:first-child {
        border-radius: 0 0 0 10px;
    }
    
    .t-row:last-child .td:last-child {
        border-radius: 0 0 10px 0;
    }
    
    
    .footer {
        display: flex;
        font-size: 10px;
        width: 100%;
        align-items: end;
        font-weight:700;
    }
    
    .footer .content {
        width: 50%;
    }
    .footer .content .text {
        margin-top: 5px;
    
    }
    
    .footer .content:last-child {
        height: auto;
        display: flex;
        justify-content: space-around;
        align-items: end;
    }
</style>`
    );
    printWindow.document.write(`<div class="receipt container">
    <div class="receipt-wrapper">
      <div class="receipt-header ">
        <div class="reciept-container">
          <div class="left">
            <div class="text">${t(
              "transaction receipt.license no"
            )} : 12/2021-2024</div>
          </div>
        </div>
        <div class="reciept-container">
          <div class="title">${t("transaction receipt.title")}</div>
          <div class="text">${t("transaction receipt.founder")} : ${t(
      "transaction receipt.name"
    )}</div>
          <div class="address">
          ${t("transaction receipt.address")}
          </div>
        </div>
        <div class="reciept-container">
          <div class="right">`);

    printWindow.document.write(`<div class="text">${t(
      "transaction receipt.receipt no"
    )} : ${data.transaction.trans_seq_num}</div>
          </div>                        
        </div>
      </div>
      <div class="table-container">
        <div class="table">
          <div class="t-row">
            <div class="td">${t("transaction receipt.loan no")}</div>
            <div class="td">${data.loan_number}</div>
          </div>
          <div class="t-row">
            <div class="td">${t("transaction receipt.branch name")}</div>
            <div class="td">${Extension.languageBasedValue(
              data.branch_name,
              data.branch_name_tn
            )}</div>
          </div>
          <div class="t-row">
            <div class="td">${t("transaction receipt.loan date")}</div>
            <div class="td">${data.created_on}</div>
          </div>
          <div class="t-row">
            <div class="td">${t("transaction receipt.amount letter")}</div>
            <div class="td word">${Extension.languageBasedValue(
              data.transaction.amount_word,
              amount_word_tn
            )}</div>
          </div>
          <div class="t-row">
            <div class="td">${t("transaction receipt.amount")}</div>
            <div class="td"> \u20B9 ${Extension.formatAsIndianRupees(
              data.transaction.amount_paid
            )}</div>
          </div>

          <div class="t-row">
            <div class="td">${t("transaction receipt.mode of payment")}</div>
            <div class="td">${
              Extension.languageBasedValue(
                data.transaction.payment_mode,
                data.transaction.payment_mode_tn
              ) ?? "--"
            }</div>
          </div>
          <div class="t-row">
            <div class="td">${t("transaction receipt.trans_or_recipt_id")}</div>
            <div class="td">${
              data.transaction.trans_or_recipt_id === ""
                ? "--"
                : data.transaction.trans_or_recipt_id
            }</div>
          </div>
          <div class="t-row">
          <div class="td">${t("transaction receipt.market_value")}</div>
          <div class="td">
          ${t("common.gold")}-- ${
      data.gold_marketRate === "" ? "--" : data.gold_marketRate
    }${" "}${t("common.silver")}-- ${
      data.silver_marketRate === "" ? "--" : data.silver_marketRate
    }  </div>
        </div>
        </div>
      </div>
      <div class="footer">
        <div class="content">
          <div class="text">${t("transaction receipt.date")} : ${
      data.transaction.paid_on
    }</div>
          <div class="text">${t("transaction receipt.branch name")} : ${
      Extension.languageBasedValue(data.branch_name, data.branch_name_tn) ??
      "--"
    }</div>
          <div class="text">${t("transaction receipt.place")} : </div>
        </div>
        <div class="content">
          <p>${t("transaction receipt.sign_1")}</p>
          <p>${t("transaction receipt.sign_2")}</p>
        </div>
      </div>
    </div>
  </div>
    `);
    // printWindow.document.write("<div class='receipt-header'>");
    // printWindow.document.write(`<div class="receipt-container>">
    // <p>Name</p>
    // <h1>KVM</h1>
    // <div class=""
    // </div>`)
    // printWindow.document.write("<h2>Receipt</h2>");
    // printWindow.document.write("</div>");
    // printWindow.document.write("<table>");
    // printWindow.document.write(
    //   "<thead><tr><th>Item</th><th>Quantity</th><th>Price</th></tr></thead>"
    // );
    // printWindow.document.write("<tbody>");
    // data.forEach((item, index) => {
    //   printWindow.document.write(
    //     `<tr><td>${item.name}</td><td>${
    //       item.quantity
    //     }</td><td>$${item.price.toFixed(2)}</td></tr>`
    //   );
    // });
    // printWindow.document.write("</tbody></table>");
    // printWindow.document.write(
    //   `<p>Total: $${data
    //     .reduce((total, item) => total + item.quantity * item.price, 0)
    //     .toFixed(2)}</p>`
    // );
    // printWindow.document.write("</div>");
    printWindow.document.write("</div></body></html>");
    printWindow.document.getElementById("printable-content").style.display =
      "block";
    printWindow.document.close();
    {
      !download && printWindow.print();
    }

    {
      download &&
        html2pdf(printWindow.document.body, {
          margin: 10,
          filename: "receipt.pdf", // Set the desired file name
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        });
    }
    // Use html2pdf library to generate a PDF

    printWindow.onafterprint = function () {
      printWindow.close();
    };
  };

  return (
    <IconButton onClick={handlePrint} sx={{ ml: 1 }}>
      {download ? (
        <Download sx={{ color: "var(--font-color)" }} />
      ) : (
        <Print sx={{ color: "var(--font-color)" }} />
      )}
    </IconButton>
  );
};

export default PrintButton;
