import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  MdAccountBalanceWallet,
  MdAccountBox,
  MdAddHomeWork,
  MdChatBubble,
  MdDashboard,
  MdGavel,
  MdOutlineInventory2,
  MdPersonAdd,
  MdReceipt,
  MdSummarize
} from "react-icons/md";
import { TiThList } from "react-icons/ti";


export default function HeaderComponent({
  children,
  scrollToSection,
  dashboardRef,
  userRef,
  SchemeRef,
  CustomerRef,
  AlertRef,
  loanCreationRef,
  loanListRef,
  receiptRef,
  auctionRef,
  inventoryRef,
  transationRef,
  summaryRef,
}) {
  const { t } = useTranslation("global");
  const [lastScrollY, setLastScrollY] = React.useState(window.scrollY);

  const AdminList = [
    {
      text: "Dashboard",
      icon: <MdDashboard />,
      ref: dashboardRef,
    },
    { text: "User Master", icon: <MdAccountBox />, ref: userRef },
    { text: "Loan Scheme Master", icon: <TiThList />, ref: SchemeRef },
    {
      text: "Customer Master",
      icon: <MdPersonAdd />,
      ref: CustomerRef,
    },
    {
      text: "Loan Creation",
      icon: <MdAddHomeWork />,
      ref: loanCreationRef,
    },
    // {
    //   text: "Loan List",
    //   icon: <MdListAlt />,
    //   ref: loanListRef,
    // },
    {
      text: "Receipt Generation",
      icon: <MdReceipt />,
      ref: receiptRef,
    },
    {
      text: "Auction Loan Proccess",
      icon: <MdGavel />,
      ref: auctionRef,
    },
    {
      text: "Alerts",
      icon: <MdChatBubble />,
      ref: AlertRef,
    },
    {
      text: "Inventory",
      icon: <MdOutlineInventory2 />,
      ref: inventoryRef,
    },
    {
      text: "Transaction",
      icon: <MdAccountBalanceWallet />,
      ref: transationRef,
    },
    {
      text: "Summary",
      icon: <MdSummarize />,
      ref: summaryRef,
    },
  ];

  //   const ManagerList = [
  //     {
  //       text: "Dashboard",
  //       icon: <MdDashboard />,
  //       ref: dashboardRef,
  //     },
  //     {
  //       text: "Customer Master",
  //       icon: <MdPersonAdd />,
  //       path: "/customer",
  //     },
  //     {
  //       text: "Loan Creation",
  //       icon: <MdAddHomeWork />,
  //       path: "/createLoan",
  //       subMenu: null,
  //     },
  //     {
  //       text: "Loan List",
  //       icon: <MdListAlt />,
  //       path: "/loanprocess",
  //       subMenu: null,
  //     },
  //     {
  //       text: "Reciept Generation",
  //       icon: <MdReceipt />,
  //       path: "/receipt",
  //       subMenu: null,
  //     },
  //     {
  //       text: "Inventory",
  //       icon: <MdOutlineInventory2 />,
  //       path: "/inventory",
  //     },
  //     {
  //       text: "Transaction",
  //       icon: <MdAccountBalanceWallet />,
  //       path: "/transactions",
  //     },
  //     {
  //       text: "Summary",
  //       icon: <MdSummarize />,
  //       ref: summaryRef,
  //     },
  //   ];

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setLastScrollY(scrollPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        variant="permanent"
        overflow={"auto"}
        sx={{
          height: "75vh",
          // width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            // width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Box sx={{ overflow: "auto" }}>
          <List>
            {AdminList.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={() => scrollToSection(item.ref)}>
                    <ListItemIcon sx={{ fontSize: "27px" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={t(`appbar.${item.text}`)} />
                  </ListItemButton>
                </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, height: "75vh", p: 3, pt: 0 }}>
        {/* <Toolbar /> */}
        <main>
          {children}
        </main>
      </Box>
    </Box>
  );
}
