import {
  AddOutlined,
  ArrowCircleLeftOutlined,
  MoreVert,
  PublishedWithChanges,
  Save,
} from "@mui/icons-material";
import { Box, Checkbox, Grid, Modal, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../api/http-common";
import { AppData } from "../common/AppData";
import CustomCommonButton from "../common/Components/CustomCommonButton";
import Dropdown from "../common/Components/Dropdown";
import ErrorTextfield from "../common/Components/ErrorTextfield";
import MultiDisplayDropdown from "../common/Components/MultiDisplayDropdown";
import Textfield from "../common/Components/TextField";
import Extension from "../common/Extension";
import GridTable from "../common/GridTable";
import Loading from "../common/Loading";
import SnackBar from "../common/SnackBar";
import Styles from "../common/Styles";
import "./Scheme.css";
import Logger from "../common/Logger";

// import translate from "google-translate-api";

const ITEM_CATEGORY = [
  { name: "Gold", name_tn: "தங்கம்", value: "Gold" },
  { name: "Silver", name_tn: "வெள்ளி", value: "Silver" },
];

const SCHEME_DURATION = [
  { name: "1 Month", name_tn: "1 மாதம்", value: 1 },
  { name: "2 Months", name_tn: "2 மாதங்கள்", value: 2 },
  { name: "3 Months", name_tn: "3 மாதங்கள்", value: 3 },
  { name: "4 Months", name_tn: "4 மாதங்கள்", value: 4 },
  { name: "5 Months", name_tn: "5 மாதங்கள்", value: 5 },
  { name: "6 Months", name_tn: "6 மாதங்கள்", value: 6 },
  { name: "7 Months", name_tn: "7 மாதங்கள்", value: 7 },
  { name: "8 Months", name_tn: "8 மாதங்கள்", value: 8 },
  { name: "9 Months", name_tn: "9 மாதங்கள்", value: 9 },
  { name: "10 Months", name_tn: "10 மாதங்கள்", value: 10 },
  { name: "11 Months", name_tn: "11 மாதங்கள்", value: 11 },
  { name: "12 Months", name_tn: "12 மாதங்கள்", value: 12 },
];

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const logger = new Logger({ level: "log" });
const Scheme = () => {
  const { t } = useTranslation("global");
  // const { userCredentails, usetUserCredentails } =
  //   useContext(UserDetailsContext);

  // const Navigate = useNavigate();

  const column = AppData.scheme.map((data) => ({
    ...data,
    headerName: t(`loan scheme master.${data.field}`),
  }));
  const [schemes, setScheme] = useState([]);
  const [schemeName, setSchemeName] = useState("");
  const [schemeName_tn, setSchemeName_tn] = useState("");
  const [itemCategory, setItemCategory] = useState("");
  const [inrMin, setInrMin] = useState("");
  const [inrMax, setInrMax] = useState("");
  const [durationMonth, setDurationMonth] = useState("");
  // const entity_id = userCredentails.entitydata.entity_id;
  const entity_id = parseInt(sessionStorage.getItem("entityId"));
  const [searchQuery, setSearchQuery] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("All");
  const [inrMaxError, setInrMaxError] = useState("");
  const [inrMinError, setInrMinError] = useState("");
  const [accessForAll, setAccessForAll] = useState(true);
  const [addbuttonDisabled, setAddbuttonDisabled] = useState(false);

  const [schemePopup, setSchemePopup] = useState(false);
  const [loading, setLoading] = useState(true);

  //cleat all fields
  const clearTextFields = () => {
    setSchemeName("");
    setItemCategory("");
    setInrMin("");
    setInrMax("");
    setDurationMonth("");
    setSchemeName_tn("");
  };

  //get initial data
  const getData = async () => {
    try {
      handleLoadingOpen();
      logger.log("Scheme fetch start");
      const schemeRes = await api.get(`scheme/schemes/${entity_id}`);
      logger.log("Scheme fetch successfully");
      setScheme(schemeRes.data);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };

  const handleSchemeName = (e) => {
    Extension.translateInput(e, setSchemeName_tn);
    setSchemeName(e.target.value);
  };

  //add scheme
  const addScheme = async () => {
    if (schemeName && itemCategory && inrMin && inrMax && durationMonth) {
      try {
        handleLoadingOpen(); //open loading
        setAddbuttonDisabled(true); //disable dave button
        const item_category_tn = ITEM_CATEGORY.find(
          (item) => item.value === itemCategory
        ).name_tn;
        const data = {
          entity_id: entity_id,
          scheme_name: schemeName,
          scheme_name_tn: schemeName_tn,
          item_category: itemCategory,
          item_category_tn: item_category_tn,
          rate_of_interest_minimum: inrMin,
          rate_of_interest_maximum: inrMax,
          document_charge: 1,
          duration_month: durationMonth,
          gross_weight_detection: 1,
          stone_gross_weight_detection: 1,
          access_for_all: accessForAll,
        };
        logger.log("Scheme add started");
        await api.post(`scheme`, data);
        logger.log("Scheme added successfully");
        getData();
        handleCloseSchemePopup();
        SnackBar.toastMsg(
          "success",
          t("toast_messages.scheme_added_successfully")
        );
      } catch (error) {
        logger.log(error);
      } finally {
        setAddbuttonDisabled(false);
        handleLoadingClose();
      }
    } else {
      SnackBar.toastMsg("error", t("toast_messages.please_fill_all_details"));
    }
  };

  const [editScheme, setEditscheme] = useState(null);

  //initiate the update
  const enterEdit = (scheme) => {
    setEditscheme(scheme);
    setSchemeName(scheme.scheme_name);
    setSchemeName_tn(scheme.scheme_name_tn);
    setItemCategory(scheme.item_category);
    setInrMin(scheme.rate_of_interest_minimum);
    setInrMax(scheme.rate_of_interest_maximum);
    setDurationMonth(scheme.duration_month);
    setAccessForAll(scheme.access_for_all);
    handleOpenSchemePopup();
  };

  //update scheme function
  const updateScheme = async () => {
    try {
      handleLoadingOpen(); //open loading
      setAddbuttonDisabled(true); //disable save button
      const item_category_tn = ITEM_CATEGORY.find(
        (item) => item.value === itemCategory
      ).name_tn;
      const ID = editScheme.scheme_id;
      const data = {
        ...editScheme,
        scheme_name: schemeName,
        scheme_name_tn: schemeName_tn,
        item_category: itemCategory,
        item_category_tn: item_category_tn,
        rate_of_interest_minimum: inrMin,
        rate_of_interest_maximum: inrMax,
        duration_month: durationMonth,
        access_for_all: accessForAll,
      };
      logger.log("Scheme update api start");
      await api.put(`scheme/${ID}/`, data);
      logger.log("Scheme updated successfully");
      SnackBar.toastMsg(
        "success",
        t("toast_messages.scheme_updated_successfully")
      );
      getData();
      handleCloseSchemePopup(); //closet the scheme popup
    } catch (error) {
      logger.log(error);
    } finally {
      setAddbuttonDisabled(false); //enable the save button
      handleLoadingClose();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  //open scheme popup
  const handleOpenSchemePopup = () => {
    setSchemePopup(true);
  };

  //close the popup
  const handleCloseSchemePopup = () => {
    setEditscheme(null);
    clearTextFields();
    setSchemePopup(false);
    setAccessForAll(true);
  };

  //table filtration
  const filterScheme = schemes.filter((scheme) => {
    const isSchemeIdMatch =
      searchInputValue === "" ||
      searchInputValue === "All" ||
      scheme.item_category.toString() === searchInputValue.toString();

    return (
      isSchemeIdMatch &&
      (scheme.scheme_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        scheme.scheme_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        scheme.item_category_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        scheme.item_category.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  });

  //table row
  const rows = filterScheme.map((scheme, index) => {
    const duration = SCHEME_DURATION.find(
      (item) => item.value === scheme.duration_month
    );

    return {
      id: index + 1,
      schemename: Extension.languageBasedValue(
        scheme.scheme_name,
        scheme.scheme_name_tn
      ),
      item_category: Extension.languageBasedValue(
        scheme.item_category,
        scheme.item_category_tn
      ),
      duration_month: Extension.languageBasedValue(
        duration.name,
        duration.name_tn
      ),
      interestrateMin: `${scheme.rate_of_interest_minimum}%`,
      interestrateMax: `${scheme.rate_of_interest_maximum}%`,
      moreinfo: (
        <MoreVert
          sx={{ cursor: "pointer" }}
          onClick={() => enterEdit(scheme)}
        />
      ),
    };
  });

  //auto populate the min & max inr
  const handleItemChange = (e) => {
    const category = e.target.value;
    const inr_min = category === "Gold" ? 2 : category === "Silver" ? 2 : 0;
    const inr_max = category === "Gold" ? 2.5 : category === "Silver" ? 3.5 : 0;
    setInrMin(inr_min);
    setInrMax(inr_max);
    setItemCategory(category);
  };

  //handle minimum interest rate validation fun.
  const handleMinInterestRate = (e) => {
    const item_category = itemCategory;
    if (!item_category) {
      setInrMinError("Kindly choose the Item Category.");
      return;
    } else {
      setInrMin(e.target.value);
      setInrMinError("");
    }
  };

  //handle maximum interest rate validation fun.
  const handleMaxInterestRate = (e) => {
    const item_category = itemCategory;
    if (!item_category) {
      setInrMaxError("Kindly choose the Item Category.");
      return;
    } else {
      setInrMax(e.target.value);
      setInrMaxError("");
    }
  };

  //loading open func
  const handleLoadingOpen = () => {
    setLoading(true);
  };

  //loading close func
  const handleLoadingClose = () => {
    setLoading(false);
  };

  return (
    <Paper className="commonpaper">
      <Loading open={loading} handleClose={handleLoadingClose} />
      <Grid
        container
        spacing={{ xs: 2, md: 3, sm: 3 }}
        columns={{ xs: 1, sm: 3, md: 3 }}
        sx={{ elevation: 3, mb: 2, display: "flex", alignItems: "flex-end" }}
      >
        <Grid item xs={1} sm={0.5} md={0.4}>
          <CustomCommonButton
            onClick={handleOpenSchemePopup}
            icon={<AddOutlined />}
            text={t("loan scheme master.scheme")}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Dropdown
            label={t("common.item category")}
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
            array={ITEM_CATEGORY}
            search={true}
            menuValue={"value"}
            display={Extension.languageBasedValue("name", "name_tn")}
            firstOption={t("common.all")}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <Textfield
            label={t("loan scheme master.search scheme")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Grid>
      </Grid>
      <GridTable rows={rows} columns={column} isLoading={loading} />

      {/* Scheme Popup */}
      <Modal
        open={schemePopup}
        onClose={handleCloseSchemePopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={Styles.widthPopupStyles()}>
          <Loading open={loading} handleClose={handleLoadingClose} />
          <Box
            sx={{
              backgroundColor: "var(--app-bar-color)",
              p: 1,
              borderRadius: "10px 10px 0 0",
            }}
          >
            <Typography
              sx={{
                color: "var(--header-font-color)",
                fontSize: "var(--font-size)",
                fontFamily: "var(--font-family)",
              }}
            >
              <ArrowCircleLeftOutlined
                onClick={handleCloseSchemePopup}
                sx={{ cursor: "pointer", mr: 1 }}
              />
              {editScheme
                ? t("loan scheme master.update scheme")
                : t("loan scheme master.add scheme")}
            </Typography>
          </Box>
          <Box sx={{ p: 5, pt: 2 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3, sm: 3 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
              sx={{ elevation: 3, mb: 2 }}
            >
              <Grid item xs={1} sm={1} md={1}>
                <Textfield
                  value={schemeName}
                  onChange={handleSchemeName}
                  label={"Scheme Name"}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <Textfield
                  value={schemeName_tn}
                  onChange={(e) => setSchemeName_tn(e.target.value)}
                  label={"திட்டத்தின் பெயர்"}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <MultiDisplayDropdown
                  label={"Scheme Duration/ திட்டத்தின் காலம்"}
                  value={durationMonth}
                  onChange={(e) => setDurationMonth(e.target.value)}
                  array={SCHEME_DURATION}
                  menuValue={"value"}
                  display={["name", "name_tn"]}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <MultiDisplayDropdown
                  label={"Item Category/ பொருள் வகை"}
                  value={itemCategory}
                  onChange={handleItemChange}
                  array={ITEM_CATEGORY}
                  menuValue={"value"}
                  display={["name", "name_tn"]}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <ErrorTextfield
                  label={"Interest Rate Min./ குறைந்தபட்ச வட்டி விகிதம்"}
                  value={inrMin}
                  position={"end"}
                  comboFixWord={"%"}
                  required={true}
                  type={"float"}
                  error={inrMinError}
                  onChange={handleMinInterestRate}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <ErrorTextfield
                  label={"Interest Rate Max./ அதிகபட்ச வட்டி விகிதம்"}
                  value={inrMax}
                  position={"end"}
                  comboFixWord={"%"}
                  required={true}
                  type={"float"}
                  error={inrMaxError}
                  onChange={handleMaxInterestRate}
                />
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="checkboc-container">
                <Checkbox
                  {...label}
                  // defaultChecked
                  value={accessForAll}
                  checked={accessForAll /*  === true ? true : false */}
                  onChange={() => setAccessForAll(!accessForAll)}
                />
                <p className="restrict-text">
                  Do You Want To Show The Scheme To Managers?/
                  {/* <br /> */}
                  திட்டத்தை மேலாளர்களிடம் காட்ட விரும்புகிறீர்களா?
                </p>
              </div>
              <CustomCommonButton
                icon={editScheme ? <PublishedWithChanges /> : <Save />}
                onClick={editScheme ? updateScheme : addScheme}
                disabled={addbuttonDisabled ? true : false}
              />
            </div>
            {/* <Button
                variant="contained"
                size="small"
                color="success"
                sx={{ ...Styles.commonButton, float: "right" }}
                onClick={editScheme ? updateScheme : addScheme}
              >
                {editScheme ? <PublishedWithChanges /> : <Save />}
              </Button> */}
          </Box>
        </Box>
      </Modal>
    </Paper>
  );
};

export default Scheme;
