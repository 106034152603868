import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate } from "react-router-dom";

const AddShortCutKey = () => {
    const Navigate = useNavigate();
    const handleShortcut = () => {
        Navigate('/createLoan');
    };
    useHotkeys('ctrl+shift +l', handleShortcut);

    return (
        <div>
            {/* Your application content goes here */}
        </div>
    );
};

export default AddShortCutKey;
