import {
  ArrowCircleLeftOutlined,
  DownloadForOffline,
  UploadFileRounded,
} from "@mui/icons-material";
import AttachFileRoundedIcon from "@mui/icons-material/AttachFileRounded";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import api from "../../api/http-common";
import SnackBar from "../../common/SnackBar";
import Extension from "../Extension";
import Loading from "../Loading";
import Styles from "../Styles";
import CustomCommonButton from "./CustomCommonButton";
import Dropdown from "./Dropdown";
import Logger from "../Logger";

const logger = new Logger({ level: "log" });
const BulkUploadLoan = (props) => {
  const { open, onClose, fetchData } = props;
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const roleId = parseInt(sessionStorage.getItem("roleId"));
  const entity_id = parseInt(sessionStorage.getItem("entityId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const [selectedFile, setSelectedFile] = useState(null);
  const [branchData, setBranchData] = useState({});
  const [selectedBranch, setSelectedBranch] = useState("");
  const fileInputRef = useRef(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [branches, setBranches] = useState([]);

  const getData = useCallback(async () => {
    try {
      handleLoadingOpen();
      const branchAPI =
        roleId === 1 ? `/branch/byEntity/${entity_id}` : `branch/${branch_id}`;
      logger.log("Initiate Fetch branch");
      const branchRes = await api.get(branchAPI);
      logger.log("Fetch branch Successfully");
      setBranches(branchRes.data);

      // setSequenceId(lastSequence);
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  }, [branch_id, entity_id, roleId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleBranchChange = (e) => {
    const branch_data = branches?.find(
      (item) => parseInt(item.branch_id) === parseInt(e.target.value)
    );
    setBranchData(branch_data);
    setSelectedBranch(e.target.value);
  };

  const openConfirmation = () => {
    if (!selectedFile) {
      SnackBar.toastMsg("error", t("bulk upload.please_choose_the_file"));
      return;
    }
    if (!selectedBranch && roleId === 1) {
      SnackBar.toastMsg("error", t("bulk upload.please_select_the_branch"));
      return;
    }
    setConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmation = async (choice) => {
    if (choice === "yes") {
      await handleUpload();
    }
    closeConfirmation();
  };

  const handleSampleExcel = () => {
    const sampleData = [
      [
        "S.No",
        "Old Loan Num",
        "Cus Num",
        "Created On",
        "Created By",
        "Approved On",
        "Approved By",
        "Scheme Name",
        "Scheme Name Tamil",
        "Loan Amount",
        "Total Qty",
        "Total Net Weight",
        "Total Gross Weight",
        "Mode Of Payment",
        "Plan Closure Date",
        "Actual Closure Date",
        "Item Name",
        "Gold Category",
        "Shop Rate",
        "Market Rate",
        "Status",
        "Status Tamil",
        "Last Due Date",
        "Current Balance Pa",
        "Loan Append",
        "Append Amt",
        "Appended On",
        "Documentation Charge",
      ],
    ];

    const ws = XLSX.utils.aoa_to_sheet(sampleData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Loan Sample");

    // Define colors for specific columns (replace with your desired colors)
    const columnColors = {
      B: "#f2f2f2", // First Name
      C: "#cccccc", // First Name Tamil
      E: "#f2f2f2", // Last Name
      F: "#cccccc", // Last Name Tamil
      O: "#e0e4f0", // Mobile Number
    };

    // Set column widths (optional)
    const range = XLSX.utils.decode_range(ws["!ref"]);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      ws["!cols"] = ws["!cols"] || [];
      ws["!cols"][C] = { wch: 20, s: { bg: columnColors[C] } };
    }

    // Apply background colors
    for (const cellName in columnColors) {
      const col = cellName.charCodeAt(0) - 65; // Convert letter to column index (A=0, B=1, ...)
      const firstRow = 1; // Assuming headers are in the first row

      for (
        let rowNum = firstRow + 1;
        rowNum <= ws["!ref"].split(":")[1];
        rowNum++
      ) {
        // Iterate from row 2
        const cellRef = XLSX.utils.encode_cell({ c: col, r: rowNum });
        ws[cellRef].s = {
          fill: {
            bg: columnColors[cellName], // Set background color
          },
        };
      }
    }

    XLSX.writeFile(wb, "Loan Sample.xlsx");
  };

  const handleUpload = async (e) => {
    if (selectedFile) {
      handleLoadingOpen();
      const branch = roleId === 1 ? selectedBranch : branch_id;
      const branch_data =
        roleId === 1
          ? branches?.find(
              (item) => parseInt(item.branch_id) === parseInt(branch)
            )
          : branches;
      const reader = new FileReader();
      reader.readAsBinaryString(selectedFile);
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        try {
          let parsedData = []; //initiate the parsed data

          workbook.SheetNames.forEach((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            parsedData = XLSX.utils.sheet_to_json(sheet, { raw: false });
          });

          if (parsedData.length <= 0) {
            //check the there is nay data to add
            return SnackBar.toastMsg(
              "error",
              t("toast_message.there_is_no_data")
            );
          }
          let loan_number = branch_data?.last_loan_number;
          let loan_numbers = [];
          for (let element of parsedData) {
            //generate the customer login ids dor the n number of the parsed data
            if (loan_number === null || loan_number === "" || !loan_number) {
              loan_number = `A0001`;
              loan_numbers = [...loan_numbers, loan_number];
            } else {
              const { alphabeticPart, numericPart } = splitString(loan_number);
              const updateNumber = generateLoanId(alphabeticPart, numericPart);
              loan_number = `${updateNumber}`;
              loan_numbers = [...loan_numbers, loan_number];
            }
          }
          //the mandatory fields
          const mandatoryFields = [
            "Cus Num",
            "Created On",
            "Scheme Name",
            "Loan Amount",
            "Item Name",
            "Total Qty",
            "Total Net Weight",
            // "Gold Category",
            "Shop Rate",
            "Market Rate",
            "Status",
            "Status Tamil",
            "Last Due Date",
            "Current Balance Pa",
          ];
          let formatWrong = [];
          //Check the mandatory fields are includes or not?
          parsedData.forEach((item, index) => {
            mandatoryFields.forEach((field) => {
              if (!(field in item) || item[field] === "") {
                formatWrong.push(parsedData[index]);
              }
            });
          });

          if (formatWrong.length > 0) {
            SnackBar.toastMsg("error", t("bulk upload.loan_must_include"));
            return;
          }

          const convertDate = (dateStr) => {
            const [dd, mm, yyyy] = dateStr.split("-");
            return `${yyyy}-${mm}-${dd}`;
          };

          const formattedData = parsedData.map((item, index) => {
            const createdOn = item["Created On"] ? new Date(convertDate(item["Created On"])) : null;
            console.log(new Date(item["Created On"]))
            return {
              Loan_Number: item["Old Loan Num"] || "",
              New_Loan_Num: `${branch_data.branch_prefix}-${loan_numbers[index]}`,
              Customer_number: item["Cus Num"] || "",
              Created_on: item["Created On"]
              ? Extension.formatDate(new Date(item["Created On"]))
              : null,
              Created_By: item["Created By"] || "",
              Approved_On: item["Approved On"]
                ? Extension.formatDate(new Date(item["Approved On"]))
                : null,
              Approved_By: item["Approved By"] || "",
              Scheme_Name: item["Scheme Name"] || "",
              Scheme_Name_Tamil: item["Scheme Name Tamil"] || "",
              Loan_Amount: item["Loan Amount"] || "",
              No_of_Items: item["Total Qty"] || 0,
              Total_net_weight: item["Total Net Weight"] || 0,
              Total_gross_weight: item["Total Gross Weight"] || 0,
              mode_of_payment: item["Mode Of Payment"] || "",
              mode_of_payment_tn: item["Mode Of Payment Tamil"] || "",
              plan_closer_date: item["Plan Closure Date"]
                ? Extension.formatDate(new Date(item["Plan Closure Date"]))
                : null,
              actual_closer_date: item["Actual Closure Date"]
                ? Extension.formatDate(new Date(item["Actual Closure Date"]))
                : null,
              Item_name: item["Item Name"] || "",
              gold_category: item["Gold Category"] || "Silver",
              shop_rate: item["Shop Rate"] || "",
              market_rate: item["Market Rate"] || "",
              status: item["Status"] || "",
              status_tn: item["Status Tamil"] || "",
              last_due_date: item["Last Due Date"]
                ? Extension.formatDate(new Date(item["Last Due Date"]))
                : null,
              current_balance_pa: item["Current Balance Pa"] || 0,
              loan_append:
                item["Loan Append"] === "TRUE" ||
                item["Loan Append"] === "True" ||
                item["Loan Append"] === "true"
                  ? "True"
                  : "False",
              append_amt: item["Append Amt"] || 0,
              appended_on: item["Appended On"]
                ? Extension.formatDate(new Date(item["Appended On"]))
                : null,
              documentation_charge: item["Documentation Charge"] || 0,
            };
          });
          logger.log("Initiate post bulk loans");
          console.log(formattedData)
          await api.post(
            `/bulkUpload/loans/${entity_id}/${branch}`,
            formattedData
          );
          logger.log("Bulk customers post Successfully");
          const branch_details = {
            ...branch_data,
            last_loan_number: loan_numbers[parsedData.length - 1],
          };
          logger.log("Initiate put branch last loan number");
          await api.put(`branch/${branch}`, branch_details);
          logger.log("branch last loan number updated Successfully");
          SnackBar.toastMsg(
            "success",
            t("toast_messages.loans_added_successfully")
          );

          onClose();
          closeField();
          fetchData();
        } catch (error) {
          logger.log(error);
          if (error.response.data.error) {
            SnackBar.toastMsg("error", error.response.data.error);
            return;
          }
          if (error.response.data) {
            SnackBar.toastMsg("error", error.response.data);
            return;
          }
          SnackBar.toastMsg("error", t("bulk upload.issue_on_upload"));
        } finally {
          handleLoadingClose();
        }
      };
    } else {
      SnackBar.toastMsg(
        "error",
        "There is an issue on your data please check!"
      );
    }
  };

  //split string for generate sequence
  const splitString = (inputString) => {
    // Use regular expressions to separate alphabetic and numeric parts
    const parts = inputString.split(/(\d+)/);
    if (parts) {
      // Separate alphabetic and numeric parts
      const alphabeticPart = parts
        .filter((part, index) => index % 2 === 0)
        .join("");
      const numericPart = parts
        .filter((part, index) => index % 2 !== 0)
        .join("");
      return { alphabeticPart, numericPart };
    } else {
      return { alphabeticPart: "", numericPart: "" };
    }
  };

  //loan Id generation
  const generateLoanId = (char, numeric) => {
    const currentChar = char.toUpperCase();
    const currentNumeric = parseInt(numeric);
    if (currentNumeric === 1000) {
      let nextChar;
      if (currentChar.charAt(0) === "Z") {
        const charLength = currentChar.length + 1;
        nextChar = "A".repeat(charLength);
      } else {
        nextChar = String.fromCharCode(currentChar.charCodeAt(0) + 1);
      }
      return `${nextChar}0001`;
    } else {
      return `${currentChar}${(currentNumeric + 1).toString().padStart(4, 0)}`;
    }
  };

  //file change function
  const handleFileChanges = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.name.endsWith(".xlsx")) {
        setSelectedFile(file);
      } else {
        setSelectedFile(null);
      }
    } else {
      setSelectedFile(null);
    }

    if (fileInputRef.current) {
      // Reset the input value to clear the selected file
      fileInputRef.current.value = "";
    }
  };

  //clear fields
  const closeField = () => {
    setSelectedFile(null);
    setSelectedBranch("");
  };

  //loading open func
  const handleLoadingOpen = () => {
    setLoading(true);
  };
  //loading close func
  const handleLoadingClose = () => {
    setLoading(false);
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        closeField();
        fetchData();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ ...Styles.widthPopupStyles(), width: 775, height: 330 }}>
        <Loading open={loading} handleClose={handleLoadingClose} />
        <Box
          sx={{
            backgroundColor: "var(--app-bar-color)",
            p: 1,
            borderRadius: "10px 10px 0 0",
          }}
        >
          <Typography
            sx={{
              color: "var(--header-font-color)",
              fontSize: "var(--font-size)",
              fontFamily: "var(--font-family)",
            }}
          >
            <ArrowCircleLeftOutlined
              onClick={onClose}
              sx={{ cursor: "pointer", mr: 1 }}
            />
            {t("bulk upload.bulkUpload")}
          </Typography>
        </Box>
        <Box
          style={{
            width: "93%",
            marginLeft: "3%",
            marginRight: "3%",
            marginTop: "2%",
            marginBottom: "2%",
            padding: "0%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h7" sx={{ mt: 1.5, ml: 0.5 }}>
            {t("bulk upload.selectFiles")}
          </Typography>
          <Box
            sx={{
              backgroundColor: "#B8B3B352",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              ml: 0.5,
              borderRadius: 1,
            }}
          >
            <Button
              style={{
                backgroundColor: "#FFFFFF",
                borderColor: "#B8B3B396",
                border: "2px solid #000000",
                color: "#3c5d90",
              }}
              variant="contained"
              component="label"
              startIcon={<AttachFileRoundedIcon />}
              sx={{
                mb: 2,
                mt: 2,
                ml: 2,
                height: 48,
                width: "35%",
                borderRadius: 1,
              }}
            >
              {t("bulk upload.uploadFile")}
              <input
                type="file"
                onChange={handleFileChanges}
                accept=".xlsx"
                style={{ display: "none", color: "black" }}
                ref={fileInputRef}
              />
            </Button>

            <Typography
              variant="body1"
              gutterBottom
              sx={{
                color: "var(--font-color)",
                fontSize: "var(--font-size)",
                fontFamily: "var(--font-family)",
                textAlign: "center",
                mb: 1,
                // mt: 3.5,
                ml: 2,
              }}
            >
              {selectedFile
                ? t("bulk upload.selectFile", { fileName: selectedFile.name })
                : t("bulk upload.noFile")}
            </Typography>
            <Button
              style={{ backgroundColor: "#3c5d90" }}
              variant="contained"
              sx={{ width: "30%", mt: 2, mb: 2, mr: 1.5, borderRadius: 2 }}
              onClick={handleSampleExcel}
            >
              <DownloadForOffline />
              {t("bulk upload.sampleExcel")}
            </Button>
          </Box>
        </Box>

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "nowrap",
            p: "0 30px",
            mt: roleId === 1 ? 0 : 3,
          }}
        >
          <Grid
            item
            xs={1}
            sm={1}
            md={10}
            sx={{
              width: "70%",
              ml: 3.5,
              mb: roleId === 1 ? 4 : 0,
            }}
          >
            {roleId === 1 ? (
              <Dropdown
                // firstOption={t("common.all")}
                // search={true}
                label={t("common.branch name")}
                value={selectedBranch}
                onChange={handleBranchChange}
                array={branches}
                menuValue={"branch_id"}
                display={Extension.languageBasedValue(
                  "branch_name",
                  "branch_name_tn"
                )}
              />
            ) : roleId === 2 ? (
              <Typography variant="h5" sx={Styles.titleText()}>
                {Extension.languageBasedValue(
                  branches.branch_name,
                  branches.branch_name_tn
                )}
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <Button
              style={{ backgroundColor: "#3c5d90", color: "white" }}
              variant="contained"
              sx={{
                width: "80%",
                height: "45px",
                borderRadius: 2,
                ml: 1,
                "&:disabled": {
                  backgroundColor: "#a0a0a0",
                },
              }}
              startIcon={<UploadFileRounded />}
              onClick={openConfirmation}
            >
              {t("bulk upload.upload")}
            </Button>
          </Grid>
        </Grid>

        {/* Confirmation Dialog */}
        <Dialog
          open={confirmationOpen}
          onClose={closeConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              backgroundColor: "var(--commonpaper-background)",
              color: "var(--font-color)",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
          >
            {t("bulk upload.confirm")}
          </DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: "var(--commonpaper-background)",
              color: "var(--font-color)",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
          >
            <Loading open={loading} handleClose={handleLoadingClose} />
            <Typography
              sx={{
                backgroundColor: "var(--commonpaper-background)",
                color: "var(--font-color)",
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              }}
            >
              {t("bulk upload.loan_dialogue")}
              {/* Are you sure you want to add the {cusLength} customer? */}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: "var(--commonpaper-background)",
              color: "var(--font-color)",
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
            }}
          >
            <CustomCommonButton
              onClick={() => handleConfirmation("no")}
              color="error"
              text={t("bulk upload.no")}
            />
            <CustomCommonButton
              onClick={() => handleConfirmation("yes")}
              text={t("bulk upload.yes")}
              disabled={loading}
            />
            {/* <Button
            onClick={() => handleConfirmation("yes")}
            color="primary"
            autoFocus
          >
            {t("bulk upload.yes")}
          </Button> */}
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};
export default BulkUploadLoan;
