import { DownloadForOffline } from "@mui/icons-material";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import api from "../api/http-common";
import CustomCommonButton from "../common/Components/CustomCommonButton";
import Dropdown from "../common/Components/Dropdown";
import Textfield from "../common/Components/TextField";
import Extension from "../common/Extension";
import Loading from "../common/Loading";
import Styles from "../common/Styles";
import Logger from "../common/Logger";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `var(--app-bar-color)`,
    color: "var(--header-font-color)",
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
    // fontSize: 1,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    border: 0,
    textAlign: "center",
    fontFamily: "var(--font-family)",
    fontSize: "var(--font-size)",
    color: "var(---font-color)",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "var(--table-even-row)",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "var(--table-odd-row)",
  },
  "&:first-child th": {
    border: 0,
    padding: 6,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "var(--table-hover)", // Change the background color on hover
  },
}));

const Summary = () => {
  // const { userCredentails, setUserCredentails } =
  //   useContext(UserDetailsContext);
  const { t } = useTranslation("global");

  const summary = [
    {
      field: "id",
      headerName: t("common.s.no"),
    },
    {
      field: "loan_number",
      headerName: t("summary.loan number"),
    },
    {
      field: "loan_amount",
      headerName: t("common.loan amount"),
    },
    {
      field: "loan_date",
      headerName: t("common.loan date"),
    },
    {
      field: "scheme_name",
      headerName: t("common.scheme name"),
    },
    {
      field: "customer_id",
      headerName: t("common.customer id"),
    },
    {
      field: "customer_name",
      headerName: t("common.customer name"),
    },
    {
      field: "mobile_no",
      headerName: t("summary.mobile no"),
    },
    {
      field: "address",
      headerName: t("summary.address"),
    },
    {
      field: "total_interest_paid.",
      headerName: t("summary.total interest paid"),
    },
    {
      field: "last_due_date.",
      headerName: t("summary.last due date"),
    },
    {
      field: "balance_closure.",
      headerName: t("summary.balance closure amount"),
    },
    {
      field: "no_of_item.",
      headerName: t("summary.no of items"),
    },
    {
      field: "net_weight.",
      headerName: t("summary.net weight"),
    },
    {
      field: "planned_closure_date.",
      headerName: t("summary.plan close date"),
    },
    {
      field: "actual_closure_date.",
      headerName: t("summary.actual closed date"),
    },
    {
      field: "rate",
      headerName: t("summary.item rate gram"),
    },
    {
      field: "status",
      headerName: t("common.status"),
    },
  ];

  // const role_id = userCredentails.userdata.role_id;
  // const branch_id = userCredentails.userdata.branch_id;
  // const entity_Id = userCredentails.entitydata.entity_id;
  const logger = new Logger({ level: "log" });
  const role_id = parseInt(sessionStorage.getItem("roleId"));
  const branch_id = parseInt(sessionStorage.getItem("branchId"));
  const entity_Id = parseInt(sessionStorage.getItem("entityId"));

  const currentDate = Extension.formatDate(new Date());

  const tableRef = useRef(null);

  const [branches, setBranches] = useState([]);
  const [summaries, setSummaries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("All");

  const [loading, setLoading] = useState(false);

  const getData = async () => {
    try {
      handleLoadingOpen();
      const BranchAPI =
        role_id === 1 ? `branch/byEntity/${entity_Id}` : `branch/${branch_id}`;
      const SummaryAPI =
        role_id === 1
          ? `loan/Summary/${entity_Id}`
          : `loan/SummaryByBranch/${branch_id}`;

      logger.log("Get Branch data in summary start");
      const branchRes = await api.get(BranchAPI);
      setBranches(branchRes.data);
      logger.log("Get Branch successfully");
      logger.log("Get Summary data start");
      const summaryRes = await api.get(SummaryAPI);
      setSummaries(summaryRes.data);
      logger.log("Summary data fetched successfully");
    } catch (error) {
      logger.log(error);
    } finally {
      handleLoadingClose();
    }
  };

  const handleBranchChange = async (e) => {
    const branch = e.target.value;
    setSelectedBranch(branch);
  };

  useEffect(() => {
    getData();
  }, []);

  const filteredRow = summaries.filter(
    (summary) =>
      (selectedBranch === "" ||
        selectedBranch === "All" ||
        summary.branch_id.toString() === selectedBranch.toString()) &&
      (summary.customer_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
        summary.customer_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        summary.customer_id.toString().includes(searchQuery.toString()) ||
        summary.loan_number.toString().includes(searchQuery.toString()) ||
        summary.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
        summary.address_tn.toLowerCase().includes(searchQuery.toLowerCase()) ||
        summary.mobile_no.toString().includes(searchQuery.toString()) ||
        summary.scheme_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        summary.scheme_name_tn
          .toLowerCase()
          .includes(searchQuery.toLowerCase()))
  );
  const rows = filteredRow.map((summary, index) => {
    const isFradulent = summary.fradulent;

    const rowStyle = {
      color: isFradulent && "red",
      fontWeight: isFradulent && "var(--font-weight)",
    };

    return {
      id: index + 1,
      loan_number: summary.loan_number,
      loan_amount: summary.loan_amount,
      loan_date: format(new Date(summary.loan_date), "dd-MM-yyyy"),
      last_due_date:
        summary.last_due_date &&
        format(new Date(summary.last_due_date), "dd-MM-yyyy"),
      scheme_name: Extension.languageBasedValue(
        summary.scheme_name,
        summary.scheme_name_tn
      ),
      customer_id: (
        <Tooltip
          title={Extension.languageBasedValue(
            summary.fradulent_remark,
            summary.fradulent_remark_tn
          )}
          style={rowStyle}
        >
          {summary.customer_id}
        </Tooltip>
      ),
      customer_name: (
        <Tooltip
          style={rowStyle}
          title={Extension.languageBasedValue(
            summary.fradulent_remark,
            summary.fradulent_remark_tn
          )}
        >
          {Extension.languageBasedValue(
            summary.customer_name,
            summary.customer_name_tn
          )}
        </Tooltip>
      ),
      mobile_no: (
        <Tooltip
          style={rowStyle}
          title={Extension.languageBasedValue(
            summary.fradulent_remark,
            summary.fradulent_remark_tn
          )}
        >
          {summary.mobile_no}
        </Tooltip>
      ),
      address: (
        <Tooltip
          style={rowStyle}
          title={Extension.languageBasedValue(
            summary.fradulent_remark,
            summary.fradulent_remark_tn
          )}
        >
          {Extension.languageBasedValue(summary.address, summary.address_tn)}
        </Tooltip>
      ),
      adhaar_no: summary.adhaar_no,
      total_interest_paid: summary.total_interest_paid,
      total_closure_paid: summary.total_closure_paid,
      balance_closure: summary.balance_closure,
      no_of_item: summary.no_of_item,
      net_weight: summary.net_weight,
      planned_closure_date:
        summary.planned_closure_date &&
        format(new Date(summary.planned_closure_date), "dd-MM-yyyy"),
      actual_closure_date:
        summary.actual_closure_date &&
        format(new Date(summary.actual_closure_date), "dd-MM-yyyy"),
      rate: `916: ₹${
        summary.hallmark_rate_on_pledge
          ? Extension.formatCurrency(summary.hallmark_rate_on_pledge)
          : "--"
      }\n
          ${t("common.gold")}:₹${
        summary.gold_rate_on_pledge
          ? Extension.formatCurrency(summary.gold_rate_on_pledge)
          : "--"
      }\n
          ${t("common.silver")}: ₹${
        summary.silver_rate_on_pledge
          ? Extension.formatCurrency(summary.silver_rate_on_pledge)
          : "--"
      }`,
      status: Extension.languageBasedValue(summary.status, summary.status_tn),
    };
  });

  const handleLoadingOpen = () => {
    setLoading(true);
  };
  const handleLoadingClose = () => {
    setLoading(false);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 100));
    setPage(0);
  };

  const renderRows = rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  return (
    <Paper className="commonpaper">
      <Loading open={loading} handleClose={handleLoadingClose} />
      <Grid
        container
        spacing={{ xs: 2, md: 4, sm: 4 }}
        columns={{ xs: 1, sm: 3, md: 5, lg: 4 }}
        sx={{ elevation: 3, mb: 2, display: "flex", alignItems: "flex-end" }}
      >
        <Grid item xs={1} sm={1} md={1} lg={1}>
          {role_id === 1 ? (
            <Dropdown
              firstOption={t("common.all")}
              label={t("common.select branch")}
              value={selectedBranch}
              search={true}
              onChange={handleBranchChange}
              array={branches}
              menuValue={"branch_id"}
              display={Extension.languageBasedValue(
                "branch_name",
                "branch_name_tn"
              )}
            />
          ) : role_id === 2 ? (
            <Typography
              variant="h5"
              sx={{
                ...Styles.titleText(),
                fontFamily: "var(--font-family)",
                fontSize: "var(--font-size)",
              }}
            >
              {Extension.languageBasedValue(
                branches.branch_name,
                branches.branch_name_tn
              )}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={1} sm={1} md={2} lg={1.5}>
          <Textfield
            label={t("common.search customer")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Grid>
        <Grid item xs={1} sm={0.5} md={1} lg={1.5}>
          <DownloadTableExcel
            filename="Loan Summary"
            sheet={
              branches.length > 0
                ? currentDate
                : `${branches.branch_name} ${currentDate}`
            }
            currentTableRef={tableRef.current}
          >
            <CustomCommonButton
              text={t("summary.download")}
              icon={<DownloadForOffline />}
            />
          </DownloadTableExcel>
        </Grid>
      </Grid>
      <TableContainer
        // component={Paper}
        sx={{
          overflowY: "auto",
          overflowX: "auto",
          height: {
            xs: "60vh",
            sm: "65vh",
            md: "70vh",
            lg: "75vh",
            xl: "80vh",
          },
          width: "100%",
        }}
      >
        <Table
          ref={tableRef}
          stickyHeader
          sx={{ width: "130%" }}
          aria-label="sticky table"
        >
          <TableHead
            sx={{
              backgroundColor: `var(--app-bar-color)`,
            }}
          >
            <StyledTableRow>
              {summary.map((sum) => (
                <StyledTableCell key={sum.headerName}>
                  {sum.headerName}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {/* {rows.length > 0 ? ( */}
            {renderRows.map((row) => (
              <StyledTableRow key={row.id}>
                <StyledTableCell>{row.id}</StyledTableCell>
                <StyledTableCell sx={{ textAlign: "left" }}>
                  {row.loan_number}
                </StyledTableCell>
                <StyledTableCell>
                  &#x20b9; {Extension.formatCurrency(row.loan_amount) ?? "--"}
                </StyledTableCell>
                <StyledTableCell>{row.loan_date ?? "--"}</StyledTableCell>
                <StyledTableCell>{row.scheme_name ?? "--"}</StyledTableCell>
                <StyledTableCell>{row.customer_id ?? "--"}</StyledTableCell>
                <StyledTableCell sx={{ textAlign: "left" }}>
                  {row.customer_name ?? "--"}
                </StyledTableCell>
                <StyledTableCell>{row.mobile_no}</StyledTableCell>
                <StyledTableCell>{row.address}</StyledTableCell>
                <StyledTableCell>
                  {row.total_interest_paid
                    ? `₹ ${Extension.formatCurrency(row.total_interest_paid)}`
                    : "--"}
                </StyledTableCell>
                <StyledTableCell>{row.last_due_date ?? "--"}</StyledTableCell>
                <StyledTableCell>
                  &#x20b9;{" "}
                  {Extension.formatCurrency(row.balance_closure) ?? "--"}
                </StyledTableCell>
                <StyledTableCell>{row.no_of_item ?? "--"}</StyledTableCell>
                <StyledTableCell>
                  {row.net_weight ?? "--"} {t("common.g")}
                </StyledTableCell>
                <StyledTableCell>
                  {row.planned_closure_date ?? "--"}
                </StyledTableCell>
                <StyledTableCell>
                  {row.actual_closure_date ?? "--"}
                </StyledTableCell>
                <StyledTableCell>{row.rate}</StyledTableCell>
                <StyledTableCell>{row.status}</StyledTableCell>
              </StyledTableRow>
            ))}
            {!loading && rows.length <= 0 && (
              <StyledTableRow sx={{ lineHeight: 24 }}>
                <StyledTableCell
                  sx={{ lineHeight: 24 }}
                  colSpan={20}
                  rowSpan={10}
                >
                  <Box sx={{ mt: 25 }}>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-family)",
                        fontSize: "var(--font-size)",
                      }}
                      component="p"
                      variant="h6"
                    >
                      {t("common.there_is_no_data")}
                    </Typography>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ marginBottom: "0rem", marginTop: "10px" }}>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          label={`Rows: ${rowsPerPage}`}
          sx={{
            "& .MuiTablePagination-displayedRows": {
              fontFamily: "var(--font-family)",
              fontSize: "var(--font-size)",
              margin: "auto",
            },
          }}
        />
      </div>
    </Paper>
  );
};

export default Summary;
